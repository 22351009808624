import { AxiosResponse } from "axios";
import { hrApi, mrApi, psApi } from "./api";
import { IdType } from "./types";

// ------------------------------ СПИСОК ОРГАНИЗАЦИЙ

export const organizationsApi = {
  get: (): Promise<AxiosResponse<OrganizationsType>> => mrApi.get("/organizations"),
};

// ------------------------------ СПИСОК ПОДРАЗДЕЛЕНИЙ

export const departmentsApi = {
  get: (params: DepartmentsFiltersType): Promise<AxiosResponse<DepartmentsType>> =>
    psApi.get("/departments/", { params }),
};

// ------------------------------ СПИСОК ДОЛЖНОСТЕЙ

export const positionsApi = {
  get: (params: PositionsFiltersType): Promise<AxiosResponse<PositionsType>> =>
    hrApi.get("/positions", { params }),
};

// ------------------------------ ТИПЫ - СПИСОК ОРГАНИЗАЦИЙ

// ----- получаемые значения

export type OrganizationsType = {
  items: OrganizationType[];
  totalCount: number;
};

// ------------------------------ ТИПЫ - СПИСОК ПОДРАЗДЕЛЕНИЙ

// ----- передаваемые значения

export type DepartmentsFiltersType = {
  organizationCode?: string;
  isMainDepartment?: boolean;
  mainDepartmentCode?: string;
};

// ----- получаемые значения

export type DepartmentsType = DepartmentType[];

export type DepartmentType = IdType & {
  code: string;
  mainDepartment: string;
  mainDepartmentName: string;
  name: string;
  shortName: string;
  type: string;
  organization: OrganizationType;
  parent: string;
  location: LocationType;
};

type LocationType = {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  flat: string;
  name: string;
};

type OrganizationType = {
  code: string;
  name: string;
};

// ------------------------------ ТИПЫ - СПИСОК ДОЛЖНОСТЕЙ

// ----- передаваемые значения

export type PositionsFiltersType = {
  organizationCode?: string;
  departmentCode?: string;
};

// ----- получаемые значения

export type PositionsType = { items: PositionType[]; totalCount: number };

type PositionType = IdType & {
  code: string;
  name: string;
  department: DepartmentsType;
};
