export const getInclinedWord = (number: number, text: string[]): string => {
  number = Math.abs(number) % 100;

  const num = number % 10;

  return number > 10 && number < 20
    ? text[2]
    : num > 1 && num < 5
    ? text[1]
    : num === 1
    ? text[0]
    : text[2];
};
