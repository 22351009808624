import React from "react";
import { colors } from "../../styles";

export const PlusOutline = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10ZM10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5C15.2467 19.5 19.5 15.2467 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5ZM5.00046 9.99998C5.00046 9.44769 5.44818 8.99998 6.00046 8.99998H9.00036V5.99987C9.00036 5.44758 9.44807 4.99987 10.0004 4.99987C10.5526 4.99987 11.0004 5.44758 11.0004 5.99987V8.99998H14.0005C14.5527 8.99998 15.0005 9.44769 15.0005 9.99998C15.0005 10.5523 14.5527 11 14.0005 11H11.0004V13.9999C11.0004 14.5521 10.5526 14.9999 10.0004 14.9999C9.44807 14.9999 9.00036 14.5521 9.00036 13.9999V11H6.00046C5.44818 11 5.00046 10.5523 5.00046 9.99998Z"
      fill={colors.primaryDefaultDark}
    />
  </svg>
);
