import { Badge } from "@digitalatom/ui";
import React, { FC } from "react";
import { PhotosType } from "../../../../api";
import { PhotoBlock } from "../PhotoBlock/PhotoBlock";
import { Container, Data, RoomTitle, RoomTitleAndVip } from "./CardBase.styles";

export const CardBase: FC<PropsType> = (props) => {
  const { name, photos, vip = false, children, isVertical, fullHeight } = props;

  return (
    <Container isVertical={isVertical}>
      {name && (
        <RoomTitleAndVip>
          <RoomTitle>
            {name}
            {vip && <Badge text="VIP" design="minor" type="accent" />}
          </RoomTitle>
        </RoomTitleAndVip>
      )}
      <PhotoBlock photos={photos} />
      <Data isVertical={isVertical} fullHeight={fullHeight}>
        {children}
      </Data>
    </Container>
  );
};

type PropsType = {
  name?: string;
  photos: PhotosType;
  vip?: boolean;
  isVertical: boolean;
  fullHeight?: boolean;
};
