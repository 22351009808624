import { MenuItem } from "@mui/material";
import React, { FC, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ColorsType } from "../../api";
import { colors, Error } from "../../styles";
import { OptionType } from "../../utils/getOptions";
import { useRhfField } from "../../utils/useRHF";
import { OpenListButton } from "../Buttons";
import * as elements from "./SelectInput.styles";

export const SelectInput: FC<PropsType> = (props) => {
  const { name, label, title, defaultValue, options, placeholder = "Выберите из списка" } = props;
  const { disabled = false, size = "", color = colors.grayscaleInput } = props;

  const { Wrapper, Container, Label, Item, Placeholder } = elements;
  const { Title, MenuItemWrapper, MenuProps } = elements;

  const context = useFormContext();
  const { submitCount } = context.formState;
  const { error, onChange, onBlur } = useRhfField(name);

  const value = useWatch({ name }) as string | undefined;

  const [isOpen, setIsOpen] = useState(false);
  const [wasInFocus, setWasInFocus] = useState(false);

  const filteredOptions = () => options.filter(({ id }) => id !== "");

  const renderValue =
    value === "" || value === undefined
      ? () => (
          <Placeholder haslabel={+!!label} disabled={disabled}>
            {placeholder}
          </Placeholder>
        )
      : undefined;

  const handleClick = () => (disabled ? undefined : setIsOpen(!isOpen));

  const icon = () => (
    <OpenListButton isOpen={isOpen} onClick={() => undefined} disabled={disabled} />
  );

  return (
    <Wrapper>
      {title ? <Title>{title}</Title> : null}

      <Container disabled={disabled} onClick={handleClick}>
        {label ? <Label id={`${name}-label`}>{label}</Label> : null}

        <Item
          name={name}
          value={value ?? ""}
          open={isOpen}
          displayEmpty
          label={label}
          labelId={label ? `${name}-label` : ""}
          haslabel={+!!label}
          renderValue={renderValue}
          IconComponent={icon}
          MenuProps={MenuProps(size)}
          onChange={(evt) => {
            onChange(evt);
            setWasInFocus(!!error);
          }}
          onBlur={() => {
            onBlur();
            setWasInFocus(!!error);
          }}
          onClick={() => undefined}
          haserror={+!!(error && (wasInFocus || submitCount))}
          defaultValue={defaultValue ? defaultValue : ""}
          autoComplete="off"
          disabled={disabled}
          backgroundcolor={color}
        >
          {filteredOptions().map(({ id, name }, i) => (
            <MenuItem key={i} value={id}>
              <MenuItemWrapper>{name}</MenuItemWrapper>
            </MenuItem>
          ))}
        </Item>

        {error && (wasInFocus || submitCount) ? <Error>{error}</Error> : null}
      </Container>
    </Wrapper>
  );
};

type PropsType = {
  name: string;
  options: (Omit<OptionType, "name"> & { name: string | JSX.Element })[];
  placeholder?: string;
  label?: string;
  title?: string;
  defaultValue?: string | null;
  disabled?: boolean;
  size?: string | number;
  color?: ColorsType;
};
