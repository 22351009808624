import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../../styles";

const { transparent, grayscalePlaceholder, grayscaleBeautifulBlack, grayscaleLabel } = colors;

export const Container = styled.nav`
  ${flex()};
  ${size({ h: 40 })};
  ${margins({ m: "0 24 -1" })};
`;

export const NavigationButton = styled.button<{ isActive: boolean; blocked?: number }>`
  ${borders({ none: true })};
  ${({ isActive }) =>
    borders(
      isActive ? { side: "bottom", width: 2, color: grayscaleBeautifulBlack } : { none: true }
    )};
  ${({ isActive }) => {
    const color = isActive ? grayscaleBeautifulBlack : grayscalePlaceholder;
    return text({ size: 15, height: 24, weight: 600, color: color });
  }};
  ${margins({ p: "8 16" })};

  background: ${transparent};
  z-index: ${({ isActive }) => (isActive ? 1 : "auto")};

  :hover {
    color: ${grayscaleLabel};
    cursor: ${({ blocked }) => (blocked ? "not-allowed" : "pointer")};
  }
`;
