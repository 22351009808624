import styled from "@emotion/styled";
import { borders, colors, margins, text } from "../../../styles";

const { white, primaryDefault } = colors;

export const Indicator = styled.div<{ forMenu: boolean }>`
  ${({ forMenu }) => (forMenu ? undefined : "position: absolute")};
  ${({ forMenu }) => (forMenu ? undefined : "top: -4px")};
  ${({ forMenu }) => (forMenu ? undefined : "right: 0")};
  ${borders({ totalRadius: true, color: white })};
  ${text({ size: 12, height: 12, weight: 600, color: white })};
  ${({ forMenu }) => margins(forMenu ? { m: "22", p: "2 4" } : { p: "2 4" })};

  background: ${primaryDefault};
`;
