import { atom } from "jotai";
import { UpdatePeriodAndPricePropsType } from "../../pages/ApplicationLifecyclePages/Applications/Application/Blocks/SanatoriumBlock/PeriodFields";

// ------------------------------ ВАЛИДНОСТЬ ФОРМЫ

export const formIsValidState = atom<boolean>(false);

// ------------------------------ ПЕРИОД ПРОХОЖДЕНИЯ И ЦЕНА ЗАЯВКИ

export const datePeriodState = atom<
  Pick<UpdatePeriodAndPricePropsType, "since" | "until"> | undefined
>(undefined);

export const applicationPriceState = atom<number | undefined>(undefined);
