import { queryClientAtom } from "jotai/query";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { useMutation } from "react-query";
import { EntityUpdateType, notificationsApi } from "../../../api";
import {
  modalPaginationState,
  modalSearchQueryState,
  statusNotificationState,
} from "../../../atoms";
import { timerForQueries } from "../../../utils/timerForQueries";

export const useUpdateNotification = () => {
  const queryClient = useAtomValue(queryClientAtom);
  const setStatusNotification = useUpdateAtom(statusNotificationState);

  const { page, quantity } = useAtomValue(modalPaginationState);
  const queryState = useAtomValue(modalSearchQueryState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  return {
    useUpdate: useMutation(
      async (props: EntityUpdateType<{ isNewNotification: false }>) =>
        notificationsApi().update(props),
      {
        onMutate: async (props) => setStatusNotification(props.id ?? ""),
        onSettled: async () => {
          await timerForQueries(500);

          await queryClient.invalidateQueries([startIndex, size, query, "notificationsState"]);
          await queryClient.invalidateQueries(["totalCountNewNotificationsState"]);

          setStatusNotification("");
        },
      }
    ),
  };
};
