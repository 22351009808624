import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { colors, margins, button, buttonOnlyIcon } = styles;
const { buttonSecondaryDefault, buttonTextDefault } = styles;

const { grayscaleBeautifulBlack } = colors;

export const Container = styled.button<{ visibleSearch: boolean }>`
  ${button};
  ${({ visibleSearch }) => (visibleSearch ? buttonSecondaryDefault : buttonTextDefault)};
  ${buttonOnlyIcon};

  ${margins({ m: "0" })};

  svg > path {
    fill: ${grayscaleBeautifulBlack};
  }
`;
