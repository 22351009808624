import styled from "@emotion/styled";
import { colors, text } from "../../styles";

const { primaryDefault } = colors;

export const FileName = styled.span`
  ${text({ size: 15, height: 20, weight: 700, color: primaryDefault })};

  cursor: pointer;
`;
