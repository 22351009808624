import styled from "@emotion/styled";
import { button, colors, size } from "../../../styles";

const { grayscaleDisabled, grayscaleIcons } = colors;

export const Container = styled.button<{ disabled: boolean }>`
  ${button};
  ${size({ s: 46 })};

  opacity: 1;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? "1" : "0.6")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  svg > path {
    fill: ${({ disabled }) => (disabled ? grayscaleDisabled : grayscaleIcons)};
  }
`;
