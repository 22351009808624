import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { ContactForProfileType, ContactTypeType, employeesApi, IdType } from "../../api";
import { fetchById } from "../../utils/fetchData";

export const selectedContactTypeState = atom<ContactTypeType>("EMAIL");

export const contactListIsOpenState = atom<ContactTypeType | undefined>(undefined);

export const openedFromModalState = atom<boolean>(false);

// ------------------------------ МОДАЛЬНЫЕ ОКНА

export const contactsModalDataState = atom<ContactForProfileType | undefined>(undefined);
export const modalDataFactorState = atom<IdType | undefined>(undefined);

// ---------------------------- ПЕРСОНАЛЬНЫЙ НОМЕР ПОЛЬЗОВАТЕЛЯ

export const userPersonnelNumberState = atom<string>("");

// ---------------------------- ПЕРСОНАЛЬНЫЕ ДАННЫЕ

export const userProfileInfoAtom = atomWithQuery((get) => {
  const userPersonnelNumber = get(userPersonnelNumberState);

  return userDocumentsQuery({ userPersonnelNumber });
});

export const userProfileInfoState = loadable(userProfileInfoAtom);

const userDocumentsQuery = ({ userPersonnelNumber }: { userPersonnelNumber: string }) => {
  return {
    queryKey: [userPersonnelNumber, "userProfileInfo"],
    queryFn: () => fetchById(() => employeesApi().get(userPersonnelNumber, "full")),
    keepPreviousData: true,
    enabled: !!userPersonnelNumber,
  };
};
