import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../../styles";

const { white, redDefault, grayscaleAsh, warningBackground, warningDark } = colors;

export const Block = styled.div<{ isError: boolean }>`
  ${size({ h: "fit" })};
  ${({ isError }) => borders({ radius: 24, ...(isError ? { color: redDefault } : {}) })};

  ${({ isError }) => (isError ? "position: sticky; top: 0; z-index: 99;" : undefined)};

  background: ${white};
`;

export const BlockTitle = styled.div<TitlePropsType>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${({ titleType, isError }) =>
    titleType === "little"
      ? text({ size: 20, height: 26, weight: 700, color: isError ? redDefault : grayscaleAsh })
      : text({ size: 24, height: 31, weight: 600, color: isError ? redDefault : grayscaleAsh })};
  ${({ titleSeparator }) => margins({ p: titleSeparator === "wider" ? "24" : "24 24 16 24" })};
`;

export const BlockSubtitle = styled.div`
  ${text({ size: 20, height: 26, weight: 700 })};
  ${margins({ m: "0 0 16" })};
`;

export const BlockBody = styled.div`
  ${margins({ p: "24" })};
`;

export const Warning = styled.div`
  ${flex({ gap: 8 })};
  ${borders({ radius: 16 })};
  ${margins({ p: "24" })};

  background: ${warningBackground};

  & > svg > path {
    fill: ${warningDark};
  }
`;

export const WarningTitleAndDescription = styled.div`
  ${flex({ isColumn: true })};
  ${text({ size: 15, weight: 400, height: 19, color: colors.grayscaleBody })};
`;

export const WarningTitle = styled.h6`
  ${text({ size: 15, height: 20, weight: 700, color: warningDark })};
`;

export const FooterBlock = styled.div<{ footerSeparator: "narrow" | "wider" }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${({ footerSeparator }) => margins({ p: footerSeparator === "wider" ? "24" : "16 24 24 24" })};
`;

type TitlePropsType = {
  titleType: "little" | "big";
  titleSeparator: "narrow" | "wider";
  isError: boolean;
};
