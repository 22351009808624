import { hrApi } from "./api";
import { DocumentsType } from "./file";
import { entitiesBase } from "./request";
import * as types from "./types";

// ------------------------------ ДОКУМЕНТЫ

export const documentsApi = <D>() =>
  entitiesBase<undefined, D, undefined>(hrApi, "resort_Document");

// ------------------------------ ТИПЫ - ДОКУМЕНТЫ

// ----- передаваемые значения

export type DocumentCreateType<D> = { data: D };

export type DocumentUpdateType<D> = types.IdType & { data: D };

// ----- получаемые значения

export type DocumentsResponseType = { items: DocumentsType; totalCount: number };
