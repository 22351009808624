import { AxiosResponse } from "axios";
import { hrApi, orgServicesApi } from "./api";
import { entitiesBase } from "./request";
import { ContactTypeType, IdType } from "./types";

// ------------------------------ КОНТАКТЫ (ДЛЯ ЗАЯВКИ)

export const contactsApi = <P>() =>
  entitiesBase<ContactType, P, undefined>(hrApi, "resort_Contact");

// ------------------------------ КОНТАКТЫ (ДЛЯ ПРОФИЛЯ)

export const getContactsForProfileApi = (userPersonnelNumber: string) => ({
  create: ({ data }: ContactForProfileCreateType): Promise<AxiosResponse> => {
    const { ...dataWithoutPersonnelNumber } = data;
    return orgServicesApi.post(
      `/employee/${userPersonnelNumber}/contact`,
      dataWithoutPersonnelNumber
    );
  },
  update: ({ data, id }: ContactForProfileUpdateType): Promise<AxiosResponse> => {
    const { ...dataWithoutPersonnelNumber } = data;
    return orgServicesApi.put(
      `/employee/${userPersonnelNumber}/contact/${id}`,
      dataWithoutPersonnelNumber
    );
  },

  delete: async (id: string): Promise<AxiosResponse> => {
    return orgServicesApi.delete(`/employee/${userPersonnelNumber}/contact/${id}`);
  },
});

// ------------------------------ ТИПЫ - КОНТАКТЫ (ДЛЯ ЗАЯВКИ)

export type ContactType = IdType & {
  email?: string;
  phoneNumber?: string;
  workNumber?: string;
};

// ----- получаемые данные

export type ContactsType = ContactType[];

// ------------------------------ ТИПЫ - КОНТАКТЫ (ДЛЯ ПРОФИЛЯ)

export type ContactsForProfileType = ContactForProfileType[];

export type ContactForProfileType = IdType & {
  id: string;
  baseContactId: string;
  value: string;
  type: ContactTypeType;
  isMain: boolean;
  isAlternativeContact?: boolean;
  comment?: string | null;
  additionType?: AdditionTypeType;
};

export type AdditionTypeType = "OVERRIDE" | "ADDITIONAL";

// ----- передаваемые значения

export type ContactForProfileCreateType = {
  data: Omit<ContactForProfileType, "id" | "isAlternativeContact" | "comment" | "baseContactId">;
};

export type ContactForProfileUpdateType = IdType & {
  data: Omit<ContactForProfileType, "id" | "isAlternativeContact" | "comment" | "baseContactId"> & {
    oldValue?: string;
    oldType?: string;
  };
};

// ----- получаемые значения

export type ContactsForProfileResponseType = { items: ContactsForProfileType; totalCount: number };
