import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { text, button, buttonDisabled, buttonOnlyText, size } = styles;
const { buttonPrimaryDefault, buttonSecondaryDefault, buttonTransparentDefault } = styles;

export const Container = styled.button<PropsType>`
  ${button};
  ${({ type, active, variant }) =>
    active
      ? variant === "default"
        ? type === "submit"
          ? buttonPrimaryDefault
          : buttonSecondaryDefault
        : buttonTransparentDefault
      : buttonDisabled};
  ${({ needIcon }) => !needIcon && buttonOnlyText};
  ${size({ h: 48, w: "100%" })};
  ${text({ size: 16, height: 16, weight: 700 })};
`;

type PropsType = {
  type: "submit" | "button";
  needIcon: boolean;
  active: boolean;
  variant: "text" | "default";
};
