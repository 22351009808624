import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { borders, colors, flex, margins, overflowOneLine, size, text } = styles;

const { grayscaleAsh, grayscalePlaceholder, grayscaleHoverBackground } = colors;

export const SubSectionsWrapper = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => size({ h: isOpen ? "100%" : 0, max: { h: isOpen ? 1000 : 0 } })};

  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
`;

export const Button = styled.div<{ isExpand: boolean; active: boolean }>`
  ${({ isExpand }) =>
    isExpand
      ? flex({ gap: 12, horizontal: "left", vertical: "center" })
      : flex({ gap: 12, totalCenter: true })};
  ${size({ w: "100%" })};
  ${borders({ radius: 12 })};
  ${margins({ m: "0 0 8" })};

  position: relative;
  cursor: pointer;

  :hover {
    background: ${grayscaleHoverBackground};
  }

  &::after {
    content: "";

    ${size({ h: 40, w: 4 })};

    position: absolute;
    top: 0;
    left: -16px;
    display: ${({ active }) => (active ? "block" : "none")};
    background: ${grayscaleAsh};
    border-radius: 0 99em 99em 0;
  }
`;

export const Icon = styled.div<{ active: boolean }>`
  ${flex({ totalCenter: true, notResize: true })};
  ${size({ s: 40 })};

  > svg > path {
    fill: ${({ active }) => (active ? grayscaleAsh : grayscalePlaceholder)} !important;
  }
`;

export const Text = styled.p<{ active: boolean }>`
  ${overflowOneLine};
  ${size({ w: `calc(100% - 12px - 8px - 16px * 2)` })};
  ${({ active }) => {
    const color = active ? grayscaleAsh : grayscalePlaceholder;
    return text({ size: 14, height: 28, weight: 600, color, align: "start" });
  }};
  ${margins({ p: "0 8 0 0" })};

  :hover {
    ${text({ size: 14, height: 28, weight: 600, color: grayscaleAsh, align: "start" })};
  }
`;

export const TextAndArrow = styled.div<{ active?: boolean }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ w: 216 })};
  ${margins({ m: "0 12 0 0" })};

  svg > path {
    fill: ${({ active }) => (active ? grayscaleAsh : grayscalePlaceholder)};
  }

  svg:hover > path {
    fill: ${grayscaleAsh};
  }
`;
