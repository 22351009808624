import styled from "@emotion/styled";
import { colors, flex, margins, size, text } from "../../../styles";

const { black, grayscaleAsh } = colors;

export const Title = styled.div`
  ${text({ size: 17, height: 24, weight: 700, color: black })};
  ${margins({ m: "0 0 16" })};
`;

export const Item = styled.div`
  ${flex({ gap: 12, vertical: "center" })};
  ${size({ min: { h: 56 } })};
`;

export const ItemText = styled.span`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleAsh })};
`;

export const Fields = styled.div``;
