import { hrApi } from "./api";
import { EmployeeType } from "./employees";
import { LinkedDocumentsType } from "./file";
import { entitiesBase } from "./request";
import { RoomType } from "./rooms";
import * as types from "./types";

// ------------------------------ ЧЛЕНЫ СЕМЬИ

export const familyMembersApi = <FM>() =>
  entitiesBase<FamilyMemberType, FM, FamilyMembersRequestType>(hrApi, "resort_FamilyMember");

export const familyRoomMembersApi = <FM>() =>
  entitiesBase<RoomWithRoomMembersType, FM, FamilyMembersRequestType>(
    hrApi,
    "resort_RoomWithMembers"
  );

// ------------------------------ ТИПЫ - ЧЛЕНЫ СЕМЬИ

export type FamilyMemberType = types.IdType &
  types.NameType & {
    employee: EmployeeType | types.IdType | null;
    kinship: types.FamilyMemberTypeType;
    birthDate: types.BackendDateType;
    isNotOwn?: boolean;
    isSocialGroup?: boolean;
    isConcernWorker?: boolean;
    documents: LinkedDocumentsType;
  };

export type RoomWithRoomMembersType = types.IdType & {
  lastModifiedDate?: types.BigBackendDateType;
  members: FamilyMembersType;
  room: RoomType;
};

// ----- передаваемые значения

export type FamilyMembersRequestType = {
  employeeId?: string;
};

export type FamilyMemberCreateType<FM> = {
  data: FM;
};

export type FamilyMemberUpdateType<FM> = types.IdType & {
  data: FM;
};

// ----- получаемые значения

export type FamilyMembersResponseType = { items: FamilyMembersType; totalCount: number };

export type FamilyMembersType = FamilyMemberType[];
