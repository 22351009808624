import styled from "@emotion/styled";
import { flex, margins } from "../../styles";

export const Container = styled.div`
  ${flex({ gap: 8, vertical: "center" })};
  ${margins({ m: "0 0 16" })};

  * {
    cursor: inherit !important;
  }
`;
