import { useAtomValue } from "jotai/utils";
import React, { Fragment, MouseEvent } from "react";
import { IdType } from "../../../api";
import { paginationState } from "../../../atoms";
import { MenuButtons } from "../../../styles";
import { useQuery } from "../../../utils/useQuery";
import { TableMenuButton, TableMenuItemButton } from "../../Buttons";
import { Check } from "../../Check";
import { Pencil, Trash } from "../../Icons";
import { PagePropsType } from "../../Page";
import { PopoverModal } from "../../PopoverModal";
import { OBJType } from "../Table";
import { TableMethodsType } from "../useTable";
import * as elements from "./TableBodyRow.styles";
import { TableBodyRowItem } from "./TableBodyRowItem";

/**
 *
 * ------------------------------------------------------------------------------------------
 * КОМПОНЕНТ СТРОКИ ТАБЛИЦЫ
 *
 * -
 *
 * @param props - параметры
 * @param props.id - идентификатор строки (string ) (обязательный)
 * @param props.datum - данные для наполнения строки (DATUM) (обязательный)
 * @param props.number - номер строки (number) (обязательный)
 * @param props.methods - методы страницы (обязательный)
 * @param props.methods.questionText - текст запроса на удаление (string | ((props: { entity?: string; count?: number }) => string)) (не обязательный)
 * @param props.methods.questionTextDescription - описание у запросу на удаление (string | ((props: { entity?: string; count?: number }) => string)) (не обязательный)
 * @param props.methods.successText - текст подтверждения удаления (string) (не обязательный)
 * @param props.methods.queryKey - ключи запроса для обновления данных после удаления ((string | string[] | number | boolean | undefined | null)[]) (обязательный)
 * @param props.methods.api - апи для обновления данных после удаления (ApiType<VOBJ>) (обязательный)
 * @param props.methods.setModalData - установщик данных для наполнения формы редактирования ((datum: SetStateAction<DATUM | undefined>) => void) (не обязательный)
 * @param props.methods.onDelete - функция удаления строки ((id: string) => | Promise<AxiosResponse<unknown, unknown>> | void | Promise<OBJ> | OBJ[]) (не обязательный)
 * @param props.methods.titlesInit - массив объектов заголовков столбцов (не обязательный)
 * @param props.methods.titlesInit[i].id - идентификатор столбца (number) (обязательный)
 * @param props.methods.titlesInit[i].title - заголовок (string) (обязательный)
 * @param props.methods.titlesInit[i].name - наименование столбца (string) (обязательный)
 * @param props.methods.titlesInit[i].icon - иконка заголовка (ReactNode) (не обязательный)
 * @param props.methods.titlesInit[i].iconPosition - положение иконки ("left" | "right") (не обязательный)
 * @param props.methods.gridsInit - массив ширин столбцов (не обязательный)
 * @param props.methods.gridsInit[i].titleId - идентификатор столбца, null для случаев первого столбца выбора строк и последнего с меню (number | null) (обязательный)
 * @param props.methods.gridsInit[i].size - ширина столбца во фракциях или пикселях, в случае добавления столбцов для выбора строк и меню, их ширина - 56px (string) (обязательный)
 * @param props.methods.object - функция получения данных для наполнения одной строки таблицы ((datum: DATUM, number?: number) => OBJ) (не обязательный)
 * @param props.methods.additionalMenuButtons - функция, возвращающая значение, содержащее дополнительные кнопки меню ({ name: string; onClick: (datum: DATUM) => void; icon?: JSX.Element }[]) (не обязательный)
 * @param props.tableMethods.getGrids - ширины столбцов форматированные для стилей ((gridInit: GridsType) => string) (обязательный)
 * @param props.tableMethods.formatData - данные для строки (( object: OBJ, titlesInit: TitlesType ) => Record<string, string | string[] | number | undefined>) (обязательный)
 * @param props.tableMethods.selectRows - ф-я выбора строк ((identifier: string) => void) (обязательный)
 * @param props.tableMethods.isRowSelected - ф-я, возвращающая значение, указывающее на то выбрана ли строка ((identifier: string) => boolean) (обязательный)
 * @param props.tableMethods.anchorMenuButton - кнопка меню, к которой привязывается модальное окно меню (HTMLElement | undefined) (обязательный)
 * @param props.tableMethods.openMenuId - id строки ,для которой открыто меню (string | undefined) (обязательный)
 * @param props.tableMethods.openMenu - ф-я открытия меню ((evt: MouseEvent<HTMLElement>, id: string) => void) (обязательный)
 * @param props.tableMethods.closeMenu - ф-я закрытия меню ((evt: MouseEvent<HTMLElement>) => void) (обязательный)
 * @param props.tableMethods.deletionConfirmationIsOpen -  значение, указывающее на открытость окна подтверждения удаления (boolean) (обязательный)
 * @param props.tableMethods.openDeletionConfirmation - ф-я открытия окна подтверждения удаления ((evt: MouseEvent<HTMLElement>, id: string) => void) (обязательный)
 * @param props.tableMethods.closeDeletionConfirmation - ф-я закрытия окна подтверждения удаления (() => void) (обязательный)
 * @param props.needCheck - значение, указывающее на необходимость отображения ячейки выбора строки (boolean) (обязательный)
 * @param props.needMenu - значение, указывающее на необходимость отображения ячейки с меню (boolean) (обязательный)
 * @param props.hideEdit - значение, указывающее на необходимость скрытия кнопки редактирования в меню строки (boolean) (обязательный)
 * @param props.hideDelete - значение, указывающее на необходимость скрытия кнопки удаления в меню строки (boolean) (обязательный)
 * @param props.needPagination - значение, указывающее на необходимость пагинации на странице (boolean) (обязательный)
 *
 */

export const TableBodyRow = <DATUM, OBJ extends OBJType, FLDS, VOBJ, FLTRS>(
  props: PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  // ------------------------------ ПАРАМЕТРЫ

  const { id, datum, number, methods, tableMethods, needPagination } = props;
  const { needCheck = false, needMenu = false, hideEdit, hideDelete = false } = props;

  const { questionText, questionTextDescription, gridsInit, titlesInit } = methods;
  const { object, setModalData, api, queryKey, onDelete, onEdit, additionalMenuButtons } = methods;

  // ------------------------------ МЕТОДЫ И ДАННЫЕ ИЗ ХУКА ТАБЛИЦЫ

  const { getGrids, formatData, selectRows, isRowSelected, nonEditableId } = tableMethods;
  const { anchorMenuButton, openMenuId, openMenu, closeMenu, setSelectedRows } = tableMethods;

  // ------------------------------ ВЫЗОВ ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ

  const { setConfirmModalContent } = tableMethods;

  const openDeletionConfirmation = (evt: MouseEvent<HTMLButtonElement>, id: string) => {
    evt.stopPropagation();
    closeMenu(evt);
    setSelectedRows([id]);

    setConfirmModalContent({
      questionText: questionText
        ? typeof questionText === "string"
          ? `Вы действительно хотите удалить ${questionText}?`
          : `Вы действительно хотите удалить ${questionText({
              entity: (datum as { name?: string }).name || "",
            })}?`
        : "Подтверждение удаления",
      description: questionTextDescription
        ? typeof questionTextDescription === "string"
          ? questionTextDescription
          : questionTextDescription({ entity: (datum as { name?: string }).name || "" })
        : undefined,
      deletion: handleDeleteRows,
    });
  };

  // ------------------------------ ЭЛЕМЕНТЫ ВЁРСТКИ

  const { Container, TableBodyItem } = elements;

  // ------------------------------ АТОМЫ

  const { page, quantity } = useAtomValue(paginationState);

  // ------------------------------ УДАЛЕНИЕ СТРОКИ

  const deletion = useQuery().useDelete({ api, queryKey });
  const customDeletion = useQuery().useDelete({
    api: { delete: (id: string) => onDelete && onDelete(id, datum) },
    queryKey,
  });

  const handleDeleteRows = onDelete
    ? () => customDeletion.mutateAsync([id ?? number])
    : () => deletion?.mutateAsync([id]);

  // ------------------------------ НЕ ОТОБРАЖАТЬ, ЕСЛИ НЕТ ЗАГОЛОВКОВ, ШИРИН СТРОК, ОБЪЕКТА ДЛЯ НАПОЛНЕНИЯ

  if (!titlesInit || !gridsInit || !object) return null;

  // ------------------------------ НУМЕРАЦИЯ СТРОК

  const rowNumber = needPagination ? number + (page ?? "1") * quantity : number;

  // ------------------------------ ОБЪЕКТ ДАННЫХ ДЛЯ СТРОКИ

  const objectDatum = object(datum, rowNumber);

  const { notEditable, notDeletable } = objectDatum;

  const row = (data: OBJ, i?: number) => {
    const needMerge = typeof i === "number" && i !== 0;

    return (
      <>
        <Container grid={getGrids(gridsInit)}>
          {needCheck && (
            <TableBodyItem
              className="check"
              onClick={
                nonEditableId(id)
                  ? undefined
                  : needCheck && !!handleDeleteRows
                  ? () => selectRows(id)
                  : undefined
              }
              needMerge={needMerge}
            >
              {!!handleDeleteRows && (
                <Check
                  checked={isRowSelected(id)}
                  disabled={nonEditableId(id)}
                  hintText="Удаление невозможно: данные связаны с критической функцией системы"
                />
              )}
            </TableBodyItem>
          )}

          {Object.values(formatData(data ?? {}, titlesInit)).map((value, i) => (
            <TableBodyRowItem key={i} data={value} />
          ))}

          {needMenu && (
            <TableBodyItem className="menu" needMerge={needMerge}>
              {needMerge ? null : (
                <TableMenuButton
                  menuModalIsOpen={!!anchorMenuButton && openMenuId === id}
                  onClick={(evt) => openMenu(evt, id)}
                  disabled={nonEditableId(id) || (notEditable && notDeletable)}
                  hintText={
                    nonEditableId(id)
                      ? "Редактирование невозможно: данные связаны с критической функцией системы"
                      : notEditable && notDeletable
                      ? "Редактирование невозможно"
                      : ""
                  }
                />
              )}
            </TableBodyItem>
          )}
        </Container>

        <PopoverModal
          id="menu-modal"
          isOpen={!!anchorMenuButton && openMenuId === id}
          element={anchorMenuButton}
          onClose={closeMenu}
        >
          <MenuButtons>
            {additionalMenuButtons?.(datum).map(({ name, onClick, icon }) => (
              <TableMenuItemButton
                key="name"
                icon={icon}
                text={name}
                onClick={(evt) => {
                  closeMenu(evt);
                  onClick();
                }}
              />
            ))}

            {!hideEdit && !notEditable && (
              <TableMenuItemButton
                text="Изменить"
                icon={<Pencil />}
                onClick={(evt) => {
                  closeMenu(evt);
                  onEdit && onEdit(datum);
                  setModalData ? setModalData(datum) : undefined;
                }}
              />
            )}

            {!hideDelete && !notDeletable && (
              <TableMenuItemButton
                text="Удалить"
                icon={<Trash />}
                onClick={(evt) => openDeletionConfirmation(evt, id)}
              />
            )}
          </MenuButtons>
        </PopoverModal>
      </>
    );
  };

  return (
    <>
      {objectDatum.needMerge
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Array.from({ length: (objectDatum[objectDatum.needMerge] as any[])?.length }, (_, i) => {
            // типизировать any
            const newObj = {} as OBJ;

            (Object.keys(objectDatum) as (keyof OBJ)[]).forEach((key) => {
              const value = objectDatum[key];
              newObj[key] = Array.isArray(value) ? value[i] || null : null;
            });

            return <Fragment key={i}>{row(newObj, i)}</Fragment>;
          })
        : row(objectDatum)}
    </>
  );
};

type PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = IdType & {
  datum: DATUM;
  number: number;
  tableMethods: TableMethodsType<OBJ>;
  needCheck: boolean;
  needMenu: boolean;
} & Pick<
    PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>,
    "methods" | "hideEdit" | "hideDelete" | "needPagination"
  >;
