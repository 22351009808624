import React, { FC } from "react";
import { Square } from "../../Icons";
import { Container } from "./SelectAllButton.styles";

export const SelectAllButton: FC<PropsType> = (props) => {
  const { onClick, disabled = false } = props;

  return (
    <Container onClick={onClick} disabled={disabled}>
      <Square />
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
  disabled: boolean;
};
