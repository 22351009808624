import { Badge } from "@digitalatom/ui";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms";
import { SectionType } from "../../../constants/sections";
import { Hint } from "../Hint/Hint";
import { useHint } from "../Hint/useHint";
import { Button, Icon, Text } from "./SectionButtons.styles";

export const SubSectionsButton: FC<PropsType> = (props) => {
  const { subSection } = props;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  const popoverHoverMethods = useHint("subSectionButton");
  const { argsForHint } = popoverHoverMethods;

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const active = pathname.includes(subSection.rout) || location.search.includes(subSection.rout);

  return (
    <>
      <Button
        isExpand={menuIsExpand}
        active={active}
        onClick={() => navigate(subSection.rout)}
        {...argsForHint}
      >
        <Icon active={active} />

        {menuIsExpand && (
          <Text active={active} onClick={() => navigate(subSection.rout)}>
            {subSection.text}
          </Text>
        )}

        {typeof subSection.count === "number" ? (
          <Badge text={subSection.count} size="s" design="withStroke" type="accent" />
        ) : null}
      </Button>

      <Hint type="subSectionButton" popoverHoverMethods={popoverHoverMethods} />
    </>
  );
};

type PropsType = {
  subSection: SectionType;
};
