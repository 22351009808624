import React, { FC, ReactNode } from "react";
import { Columns } from "../../styles";
import { FormButton } from "../Buttons";

export const FormButtons: FC<PropsType> = (props) => {
  const { formName, onClose, saveOrAdd, onClear, additionalButton, disabled } = props;

  const buttonParams = {
    save: { textButton: "Сохранить", textSecondButton: "Отменить", onClickSecondButton: onClose },
    add: { textButton: "Добавить", textSecondButton: "Отменить", onClickSecondButton: onClose },
    clear: { textButton: "Применить", textSecondButton: "Очистить", onClickSecondButton: onClear },
    download: { textButton: "Скачать", textSecondButton: "Очистить", onClickSecondButton: onClear },
    send: { textButton: "Отправить", textSecondButton: "Отменить", onClickSecondButton: onClose },
  };

  return (
    <Columns columns={2}>
      {additionalButton ? (
        <FormButton
          text={additionalButton.text}
          type={additionalButton.type}
          icon={additionalButton.icon}
          onClick={additionalButton.onClick}
          active
        />
      ) : null}

      <FormButton
        formName={formName}
        text={buttonParams[saveOrAdd].textButton}
        active={!disabled}
      />

      {!additionalButton ? (
        <FormButton
          type="button"
          text={buttonParams[saveOrAdd].textSecondButton}
          onClick={buttonParams[saveOrAdd].onClickSecondButton}
        />
      ) : null}
    </Columns>
  );
};

type PropsType = {
  formName?: string;
  onClose?: () => void;
  saveOrAdd: "save" | "add" | "clear" | "download" | "send";
  additionalButton?: {
    text: string;
    type: "button" | "submit";
    icon?: ReactNode;
    onClick: () => void;
  };
  onClear?: () => void;
  disabled?: boolean;
};
