import styled from "@emotion/styled";
import { button, size } from "../../../styles";

export const Container = styled.button`
  ${button};
  ${size({ h: 40 })};

  position: absolute;
  top: 0;
  right: 0;
`;
