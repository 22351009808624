import { hrApi } from "./api";
import { EmployeeType } from "./employees";
import { entitiesBase } from "./request";
import { BigBackendDateType, EntityRequestBaseType, IdType, ProcessTypeType } from "./types";

// ------------------------------ УВЕДОМЛЕНИЯ

export const notificationsApi = <N>() =>
  entitiesBase<NotificationType, N, EntityRequestBaseType>(
    hrApi,
    "resort_BusinessProcessNotification"
  );

// ------------------------------ ТИПЫ - УВЕДОМЛЕНИЯ

export type NotificationType = IdType & {
  title: string;
  text: string;
  recipient: EmployeeType;
  sender: EmployeeType;
  isNewNotification: boolean;
  businessKey?: string;
  processType: ProcessTypeType;
  createdDate: BigBackendDateType;
  additionalInformation: string; // если processType === "APPLICATION", то id заявки | если processType === "REGISTRY", то наименование реестра;
};

// ----- получаемые данные

export type NotificationsType = NotificationType[];
