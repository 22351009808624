import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { employeesApi } from "../../api";
import { fetchById } from "../../utils/fetchData";
import { applicationAtom } from "../index";

export const employeePersonnelNumberState = atom<string | undefined>(undefined);

export const employeeInfoAtom = atomWithQuery((get) => {
  const personnelNumber = get(employeePersonnelNumberState);
  const application = get(applicationAtom);

  const employeePersonnelNumber = application?.employee.personnelNumber ?? personnelNumber;
  // todo: этот атом не должен ничего знать о заявках
  //       вероятно, надо в заявке (эффектом?) положить personnelNumber в employeePersonnelNumberState
  //       и тогда не надо импортировать applicationAtom

  return {
    queryKey: [employeePersonnelNumber, "employeeInfoState"],
    queryFn: () =>
      employeePersonnelNumber
        ? fetchById(() => employeesApi().get(employeePersonnelNumber, "full"))
        : undefined,
    enabled: !!employeePersonnelNumber,
  };
});
