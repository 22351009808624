import React, { Dispatch, FC, SetStateAction } from "react";
import { SectionsType } from "../../../constants/sections";
import { FolderButton } from "./FolderButton";
import { SectionButton } from "./SectionButton";

export const SectionButtons: FC<PropsType> = (props) => {
  const { sections, openSubmenu, setOpenSubMenu } = props;

  return (
    <>
      {sections.map((section, i) => {
        const { name, type } = section;

        if (type === "section") {
          return <SectionButton key={i} section={section} setOpenSubMenu={setOpenSubMenu} />;
        } else if (type === "folder") {
          const subsections =
            sections.filter(({ type, folder }) => type === "subsection" && folder === name) ?? [];

          return (
            <FolderButton
              key={i}
              section={section}
              subSections={subsections}
              openSubmenu={openSubmenu}
              setOpenSubMenu={setOpenSubMenu}
            />
          );
        }
      })}
    </>
  );
};

type PropsType = {
  sections: SectionsType;
  openSubmenu: string | undefined;
  setOpenSubMenu: Dispatch<SetStateAction<string | undefined>>;
};
