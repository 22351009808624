import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { borders, button, buttonLeftIcons, colors, flex, margins, size, text } = styles;

const { grayscaleHoverBackground, grayscaleBeautifulBlack } = colors;

export const Container = styled.button`
  ${button};
  ${buttonLeftIcons};
  ${flex({ gap: 4 })};
  ${size({ h: "min" })};
  ${borders({ radius: 8 })};
  ${margins({ p: "0 8" })};

  :hover {
    background: ${grayscaleHoverBackground};
  }

  p {
    ${text({ size: 20, height: 26, weight: 800, color: grayscaleBeautifulBlack })};
  }

  svg > path {
    fill: ${grayscaleBeautifulBlack};
  }
`;
