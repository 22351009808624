import styled from "@emotion/styled";
import { Dialog, DialogProps } from "@mui/material";
import { borders, colors, flex, margins, size, text } from "../../styles";

const { white, grayscaleLabelOpacity77, grayscaleBeautifulBlack, primaryActiveElementOpacity } =
  colors;

export const Container = styled(Dialog)<ContainerPropsType>`
  ${borders({ radius: 24 })};

  z-index: 99;

  .MuiBackdrop-root {
    background: ${primaryActiveElementOpacity};
    backdrop-filter: blur(10px);
    opacity: 0.6;
  }

  .MuiPaper-root {
    ${({ width }) =>
      size({ ...(width === "90%" ? { h: "85%" } : {}), w: width, min: { w: width } })};
    ${borders({ radius: 24 })};
    ${({ forform }) => margins({ m: "0", p: `${forform ? 0 : 24}` })};

    box-shadow: none;
    overflow: hidden;
  }
` as (props: DialogProps & ContainerPropsType) => JSX.Element;

export const Content = styled.div`
  ${margins({ m: "0", p: "0" })};

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background: ${white};
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};
    background: ${grayscaleLabelOpacity77};
  }

  &::-webkit-scrollbar:vertical {
    ${size({ w: 8 })};
  }
`;

export const TitleAndCloseButton = styled.div`
  ${flex({ horizontal: "evenly", vertical: "top" })};
  ${margins({ m: "0 0 32 0" })};

  color: ${grayscaleBeautifulBlack};
`;

export const Title = styled.h4<{ forform: number }>`
  ${text({ size: 24, height: 34, weight: 800, color: grayscaleBeautifulBlack })};
  ${({ forform }) => (forform ? margins({ m: "64 0 0 64", p: "0" }) : undefined)};

  white-space: pre-line;
`;

type ContainerPropsType = { width: number | `${number}%` | "min"; forform: number };
