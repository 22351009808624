import { hrApi } from "./api";
import { MediumOtherType } from "./dictionaries";
import { entitiesBase } from "./request";
import { SanatoriumType } from "./sanatoriums";
import { IdType } from "./types";

// ------------------------------ ГРУППОВОЙ РЕЙТИНГ

export const groupRatingOverriddenApi = <R>() =>
  entitiesBase<GroupRatingOverriddenType, R, unknown>(hrApi, "resort_GroupRatingOverridden");

// ------------------------------ ТИПЫ - ГРУППОВОЙ РЕЙТИНГ

export type GroupRatingOverriddenType = IdType & {
  value: number;
  group: MediumOtherType;
  sanatorium: SanatoriumType;
};

// получаемые данные

export type groupRatingsOverriddenType = GroupRatingOverriddenType[];
