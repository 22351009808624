import React, { FC, MouseEvent, useState } from "react";
import { IconSvg, PopoverHover, Square } from "../../uiKit";
import { Container } from "./ServiceIcon.styles";

export const ServiceIcon: FC<PropsType> = (props) => {
  const { name, icon } = props;

  // ------------------------------ < ПОДСКАЗКИ К ИКОНКАМ УСЛУГ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) => {
    setAnchorPopoverHoverElement(currentTarget);
  };

  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
  };

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = popoverHoverIsOpen ? "popover-hover" : undefined;

  // ------------------------------ ПОДСКАЗКИ К ИКОНКАМ УСЛУГ >

  return (
    <>
      <Container
        aria-owns={idPopoverHover}
        aria-haspopup="true"
        onMouseEnter={(evt) => openPopoverHover(evt)}
        onMouseLeave={closePopoverHover}
      >
        {icon ? <IconSvg icon={icon} /> : <Square />}
      </Container>

      <PopoverHover
        id={idPopoverHover}
        isOpen={popoverHoverIsOpen}
        element={anchorPopoverHoverElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: 6, horizontal: "center" }}
      >
        {name}
      </PopoverHover>
    </>
  );
};

type PropsType = {
  name: string;
  icon?: string;
};
