import React, { FC } from "react";
import { NumberInput } from "../../../../../../../uiKit";
import { Container, Description, Title, TitleBlock } from "./NumberTitleInput.styled";

export const NumberTitleInput: FC<PropsType> = (props) => {
  const { name, title, description, minValue, maxValue } = props;

  return (
    <Container>
      <TitleBlock>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleBlock>

      <NumberInput name={name} minValue={minValue} maxValue={maxValue} />
    </Container>
  );
};

type PropsType = {
  name: string;
  title: string;
  description: string;
  minValue?: number;
  maxValue?: number;
};
