import React from "react";
import { colors } from "../../styles";

export const Gear = ({ isFilled = false }: PropsType) =>
  isFilled ? (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3767 2.08555C11.1758 0.526454 8.8243 0.526454 7.62338 2.08555L6.7944 3.16178C6.31121 3.78907 5.59963 4.1999 4.81479 4.3047L3.46825 4.48451C1.51757 4.74499 0.34181 6.78147 1.09157 8.60105L1.60912 9.85708C1.91078 10.5892 1.91078 11.4108 1.60912 12.1429L1.09157 13.399C0.34181 15.2185 1.51757 17.255 3.46825 17.5155L4.81479 17.6953C5.59963 17.8001 6.31121 18.2109 6.7944 18.8382L7.62338 19.9145C8.8243 21.4736 11.1758 21.4736 12.3767 19.9145L13.2057 18.8382C13.6889 18.2109 14.4005 17.8001 15.1853 17.6953L16.5319 17.5155C18.4826 17.255 19.6583 15.2185 18.9086 13.399L18.391 12.1429C18.0893 11.4108 18.0893 10.5892 18.391 9.85708L18.9086 8.60104C19.6583 6.78147 18.4826 4.74499 16.5319 4.48451L15.1853 4.3047C14.4005 4.1999 13.6889 3.78907 13.2057 3.16178L12.3767 2.08555ZM10.0001 15C12.2092 15 14.0001 13.2091 14.0001 11C14.0001 8.79087 12.2092 7.00001 10.0001 7.00001C7.79092 7.00001 6.00006 8.79087 6.00006 11C6.00006 13.2091 7.79092 15 10.0001 15Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4277 4.30996L14.1558 5.05187C14.9203 5.83089 15.8828 6.38662 16.9397 6.6592L17.9463 6.91879C19.0301 7.1983 19.6736 8.31282 19.3737 9.3912L19.0953 10.3927C18.8029 11.4443 18.8029 12.5557 19.0953 13.6074L19.3737 14.6088C19.6736 15.6872 19.0301 16.8017 17.9463 17.0813L16.9397 17.3408C15.8828 17.6134 14.9203 18.1691 14.1558 18.9482L13.4277 19.6901C12.6437 20.4889 11.3567 20.4889 10.5727 19.6901L9.84465 18.9482C9.08013 18.1691 8.11759 17.6134 7.06067 17.3408L6.05412 17.0813C4.9703 16.8017 4.32683 15.6872 4.62667 14.6088L4.90514 13.6074C5.19754 12.5557 5.19754 11.4443 4.90514 10.3927L4.62667 9.3912C4.32683 8.31282 4.9703 7.1983 6.05412 6.91879L7.06067 6.6592C8.11759 6.38662 9.08013 5.8309 9.84465 5.05187L10.5727 4.30996C11.3567 3.5111 12.6437 3.5111 13.4277 4.30996ZM9.14529 2.9091C10.7132 1.31139 13.2872 1.31139 14.8551 2.9091L15.5832 3.65101C16.0929 4.17036 16.7346 4.54085 17.4392 4.72256L18.4458 4.98215C20.6134 5.54118 21.9003 7.77022 21.3006 9.92697L21.0222 10.9285C20.8272 11.6295 20.8272 12.3705 21.0222 13.0716L21.3006 14.0731C21.9003 16.2298 20.6134 18.4589 18.4458 19.0179L17.4392 19.2775C16.7346 19.4592 16.0929 19.8297 15.5832 20.349L14.8551 21.0909C13.2872 22.6887 10.7133 22.6887 9.14529 21.0909L8.4172 20.349C7.90752 19.8297 7.26583 19.4592 6.56122 19.2775L5.55466 19.0179C3.38702 18.4589 2.10009 16.2298 2.69977 14.0731L2.97824 13.0716C3.17317 12.3705 3.17317 11.6295 2.97824 10.9285L2.69977 9.92697C2.10009 7.77022 3.38702 5.54118 5.55466 4.98215L6.56122 4.72256C7.26583 4.54085 7.90752 4.17036 8.4172 3.65101L9.14529 2.9091ZM14.0002 12C14.0002 13.1046 13.1048 14 12.0002 14C10.8956 14 10.0002 13.1046 10.0002 12C10.0002 10.8955 10.8956 10 12.0002 10C13.1048 10 14.0002 10.8955 14.0002 12ZM16.0002 12C16.0002 14.2092 14.2094 16 12.0002 16C9.79104 16 8.00018 14.2092 8.00018 12C8.00018 9.79088 9.79104 8.00002 12.0002 8.00002C14.2094 8.00002 16.0002 9.79088 16.0002 12Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );

type PropsType = {
  isFilled?: boolean;
};
