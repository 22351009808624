import React, { FC } from "react";
import { Container, Description, Mail, WarningText } from "./WarningPage.styles";

export const WarningPage: FC<PropsType> = (props) => {
  const { code, text } = props;

  const href = `mailto:medtech-support@roseneroatom.ru?subject="Ошибка ${code}"`;

  return (
    <Container>
      {text ? (
        <WarningText>{text}</WarningText>
      ) : code === "not_auth" ? (
        <>
          <WarningText>Ресурс недоступен</WarningText>
          <Description>Попробуйте авторизоваться заново</Description>
        </>
      ) : (
        <>
          <WarningText>Код ошибки {code}</WarningText>
          <Description>Сообщите этот код администратору системы</Description>
          <Mail onClick={() => (window.location.href = href)}>medtech-support@roseneroatom.ru</Mail>
        </>
      )}
    </Container>
  );
};

type PropsType = {
  code?: string;
  text?: string;
};
