import styled from "@emotion/styled";
import { button, buttonOnlyText, colors, size } from "../../../styles";

const { redBackground, redDefault } = colors;

export const Container = styled.button`
  ${button};
  ${buttonOnlyText};
  ${size({ s: 40 })};

  flex-shrink: 0;
  flex-grow: 0;

  :hover {
    background: ${redBackground};

    & > svg > path {
      fill: ${redDefault};
    }
  }
`;
