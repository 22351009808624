import styled from "@emotion/styled";
import { colors, flex, margins, text } from "../../styles";

const { grayscaleLabel, redDark } = colors;

export const Error = styled.div`
  ${flex({ horizontal: "center" })};
  ${margins({ m: "20 0" })};
`;

export const ErrorText = styled.p`
  ${text({ size: 21, height: 23, weight: 500, color: redDark, align: "center" })};
`;

export const InfoText = styled.p<{ paddingAuto?: boolean }>`
  ${text({ size: 21, height: 23, weight: 500, color: grayscaleLabel, align: "center" })};
  ${({ paddingAuto }) => margins({ p: paddingAuto ? "25% 0" : "16 0" })};
`;
