import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import { borders, colors, margins, size, text } from "../../styles";

const { grayscaleAsh, grayscaleLabelOpacity77 } = colors;

export const Container = styled(Popover)<{ maxheight?: number }>`
  ${({ maxheight }) => (maxheight ? size({ max: { h: maxheight } }) : undefined)};

  .MuiPaper-root {
    &::-webkit-scrollbar {
      ${size({ w: 8 })};
    }

    &::-webkit-scrollbar-thumb {
      ${borders({ totalRadius: true })};
      background: ${grayscaleLabelOpacity77};
    }
  }
`;

export const Title = styled.h4`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleAsh })};
  ${margins({ m: "16 24" })};
`;
