import styled from "@emotion/styled";
import { button, colors, size } from "../../../styles";

const { grayscaleHoverBackground, grayscaleLabel } = colors;

export const Container = styled.button<{ isOpen: boolean; disabled: boolean }>`
  ${button};
  ${size({ s: 40 })};

  flex: 0 0 auto;

  :hover {
    background: ${({ disabled }) => (disabled ? undefined : grayscaleHoverBackground)};

    & > svg > path {
      fill: ${({ disabled }) => (disabled ? undefined : grayscaleLabel)};
    }
  }
`;
