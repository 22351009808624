import React, { FC } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { Price } from "./RoomsSanatoriumCard.styles";

export const PriceBlock: FC<PropsType> = (props) => {
  const { isLineText = false, isTopDescription = false, description, price } = props;

  return (
    <Price lineText={isLineText}>
      {isTopDescription ? <h4>{description}</h4> : <p>{formatPrice(price ?? 0)}</p>}
      {isTopDescription ? <p>{formatPrice(price ?? 0)}</p> : <h4>{description}</h4>}
    </Price>
  );
};

type PropsType = {
  price?: number;
  isLineText?: boolean;
  description: string;
  isTopDescription?: boolean;
};
