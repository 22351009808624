import styled from "@emotion/styled";
import { DownloadStatusType } from "../../api";
import { borders, colors, flex, grid, margins, overflowOneLine, size, text } from "../../styles";

const { transparent, white, whiteOpacity, black, grayscaleInput, grayscaleBackground } = colors;
const { grayscaleBeautifulBlack, grayscaleLabel, primaryActiveElement, primaryDefault } = colors;
const { redDefault, greenDefault } = colors;

export const Container = styled.div<{ status: DownloadStatusType }>`
  ${({ status }) =>
    status === "empty" || status === "wasFile"
      ? flex({ totalCenter: true })
      : flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ h: 157, w: "100%" })};
  ${({ status }) =>
    borders({
      width: 2,
      ...(status === "empty" ? { style: "dashed" } : {}),
      color: status === "empty" ? primaryActiveElement : grayscaleInput,
      radius: 16,
    })};
  ${margins({ m: "0 0 16 0", p: "16" })};

  position: relative;
  background: ${transparent};
  outline: none;
  cursor: ${({ status }) => (status === "empty" ? "pointer" : "default")};

  :hover {
    ${({ status }) =>
      borders({
        width: 2,
        ...(status === "empty" ? { style: "dashed" } : {}),
        color: status === "empty" ? primaryDefault : grayscaleInput,
      })};
  }
`;

export const LoadBarAndText = styled.div<{ status: DownloadStatusType }>`
  ${({ status }) =>
    status === "empty" || status === "wasFile"
      ? flex({ isColumn: true, horizontal: "center" })
      : flex({ vertical: "center" })};
  ${({ status }) => size({ w: status === "empty" ? "100%" : `calc(100% - 52px)` })};

  overflow: hidden;
`;

export const LoadBar = styled.div<{ status: DownloadStatusType }>`
  ${flex({ isColumn: true, totalCenter: true })};
  ${size({ s: 40 })};
  ${({ status }) =>
    borders({
      ...(status === "empty" || status === "wasFile"
        ? { none: true }
        : {
            width: 3,
            color:
              status === "error"
                ? redDefault
                : status === "success"
                ? greenDefault
                : grayscaleBackground,
          }),
      totalRadius: true,
    })};
  ${text({ size: 10, height: 13, weight: 900 })};
  ${margins({ m: "0 12 0 0" })};
`;

export const Progress = styled.div`
  ${size({ s: 40 })};
  ${margins({ m: "0 12 0 0" })};

  position: relative;

  .MuiCircularProgress-root {
    color: ${primaryDefault};
  }
`;

export const Percent = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
  ${borders({ width: 3, color: grayscaleBackground, totalRadius: true })};
  ${text({ size: 10, height: 10, weight: 900, color: grayscaleBeautifulBlack })};

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const Text = styled.div<{ status: DownloadStatusType }>`
  ${({ status }) =>
    status === "empty" || status === "wasFile"
      ? flex({ isColumn: true, totalCenter: true })
      : flex({ isColumn: true, vertical: "top" })};
  ${overflowOneLine};
`;

export const Title = styled.p<{ status: DownloadStatusType; needDownload?: boolean }>`
  ${({ status }) => {
    const notPhotos = status === "progress" || status === "error" || status === "success";
    const color = notPhotos ? grayscaleLabel : primaryDefault;

    return text({ size: 15, height: 20, weight: 700, color: color });
  }};

  :hover {
    ${({ needDownload }) => needDownload && "cursor: pointer"};
  }
`;

export const Description = styled.p<{ status: DownloadStatusType }>`
  ${({ status }) => {
    const color = status === "error" ? redDefault : grayscaleLabel;

    return text({ size: 13, height: 23, weight: 400, color: color });
  }};
`;

export const Input = styled.input`
  display: none;
  position: absolute;
  top: 24px;
`;

export const Photos = styled.div`
  ${grid({ gap: 8, columns: "repeat(6, 1fr)" })};
`;

export const Buttons = styled.div`
  ${flex({ gap: 8, totalCenter: true })};
  ${size({ h: 40, w: "100%" })};

  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${black} 100%);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const Button = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 24 })};
  ${borders({ totalRadius: true })};

  :hover {
    background: ${whiteOpacity};

    & > svg > path {
      fill: ${grayscaleBeautifulBlack};
    }
  }
`;

export const CheckButton = styled(Button)<{ check: boolean }>`
  & > svg > path {
    fill: ${({ check }) => (check ? greenDefault : white)};
  }
`;

export const RemoveButton = styled(Button)`
  & > svg > path {
    fill: ${white};
  }
`;
