import React from "react";
import { colors } from "../../styles";

export const Bell = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4395 5.52873L14.0983 5.54088L14.6751 5.85976C15.859 6.51444 16.7039 8.29496 16.3494 10.2486C16.0822 11.7207 15.9406 13.4582 16.3116 15.081C16.4591 15.7262 16.7245 16.2779 16.9689 16.6983C16.2451 16.9521 15.1638 17.0061 13.9275 16.875C12.6506 16.7395 11.5701 16.4498 11.2411 16.3612L11.2021 16.3507L11.1631 16.3403C10.8339 16.2524 9.75311 15.9631 8.57957 15.4419C7.44337 14.9374 6.53386 14.3499 6.03393 13.7683C6.45584 13.5264 6.96152 13.1813 7.41183 12.6963C8.54444 11.4764 9.2906 9.90089 9.79532 8.49242C10.4651 6.62338 12.0868 5.50381 13.4395 5.52873ZM18.7785 10.6894C19.2757 7.94937 18.1894 4.98185 15.8697 3.6992L15.9085 3.55422C16.0849 2.8957 15.6943 2.21882 15.0357 2.04237C14.3772 1.86592 13.7003 2.25671 13.5238 2.91524L13.485 3.06021C10.8349 3.01139 8.41057 5.03817 7.4712 7.65958C7.01544 8.93142 6.40766 10.1494 5.60257 11.0165C5.20173 11.4482 4.70141 11.6918 4.24683 11.9131C3.51052 12.2715 2.89424 12.5716 3.01526 13.5181C3.0796 14.0214 3.31169 14.3059 3.58985 14.6469C3.68548 14.7642 3.78655 14.8882 3.88812 15.03C5.47509 17.2452 9.23938 18.3732 10.3302 18.6726V19.0299C10.3302 20.3934 11.4355 21.4987 12.799 21.4987C14.0323 21.4987 15.0543 20.5945 15.2383 19.4128C16.2721 19.4058 17.3183 19.2618 18.1964 18.8639C18.3552 18.7919 18.5047 18.7351 18.6461 18.6814C19.0576 18.5251 19.401 18.3946 19.7082 17.991C20.2863 17.2318 19.9026 16.6637 19.4442 15.9852C19.1611 15.5662 18.8496 15.1052 18.7183 14.5308C18.4547 13.3774 18.5372 12.0187 18.7785 10.6894Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
