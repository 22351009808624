import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../styles";

const { transparent, grayscaleLine, grayscaleBeautifulBlack, grayscalePlaceholder } = colors;

export const Container = styled.div<{ inModal: boolean }>`
  ${flex()};
  ${({ inModal }) => margins({ m: inModal ? "6 0 9" : "16 24 12" })};
`;

export const SearchInputLabel = styled.label<{ loupePosition: string }>`
  ${size({ h: 40, w: "100%" })};

  display: block;
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: -10px;
  }
`;

export const SearchInputElement = styled.input`
  ${size({ h: 40, w: "100%" })};
  ${borders({ none: true, side: "bottom", color: grayscaleLine })};
  ${text({ size: 16, height: 23, weight: 400, color: grayscaleBeautifulBlack })};
  ${margins({ p: "0 0 0 32" })};

  background: ${transparent};
  outline: none;

  &::placeholder {
    ${text({ size: 16, height: 23, weight: 400, color: grayscalePlaceholder })};
  }
`;
