import styled from "@emotion/styled/macro";
import { Slider as SliderMui } from "@mui/material";
import { borders, colors, flex, margins, size, text } from "../../styles";

const { grayscaleBeautifulBlack, grayscaleInput, grayscalePlaceholder } = colors;
const { primaryDefaultDark, grayscaleLine, white } = colors;

export const TitleAndNumberInfo = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
`;

export const Title = styled.span`
  ${text({ size: 15, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
`;

export const NumberInfo = styled.span<{ editMode?: boolean }>`
  ${flex({ horizontal: "center" })};
  ${size({ w: 40 })};
  ${({ editMode }) => editMode && borders({ radius: 12 })};
  ${({ editMode }) => editMode && `background: ${grayscaleInput}`};
  ${({ editMode }) =>
    text({
      size: 16,
      height: 23,
      weight: 400,
      color: editMode ? grayscaleBeautifulBlack : grayscalePlaceholder,
    })};
  ${({ editMode }) => editMode && margins({ p: "12" })};
`;

export const SliderWrapper = styled.span<{ editMode?: boolean }>`
  ${({ editMode }) => !editMode && `pointer-events: none`};

  .MuiSlider-thumb {
    ${({ editMode }) => !editMode && `display: none`};
    ${size({ s: 12 })};
  }
`;

export const Slider = styled(SliderMui)`
  .MuiSlider-track {
    ${borders({ totalRadius: true })};

    opacity: 1 !important;
    background: ${primaryDefaultDark};
  }

  .MuiSlider-rail {
    ${borders({ totalRadius: true })};

    background: ${grayscaleLine};
    opacity: 1;
  }

  .MuiSlider-thumbColorPrimary {
    background: ${white};
  }
`;
