import React from "react";
import { colors } from "../../styles";

export const PaperSheets = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 18C2.89451 17.9946 2 17.0968 2 15.99V5.5175C2 3.57484 3.57484 2 5.5175 2H15.99C17.0968 2 17.9946 2.89451 18 4H6.76375C5.23737 4 4 5.23737 4 6.76375V18ZM8.01 6H19.99C21.1001 6 22 6.89991 22 8.01V19.99C22 21.1001 21.1001 22 19.99 22H8.01C6.89991 22 6 21.1001 6 19.99V8.01C6 6.89991 6.89991 6 8.01 6ZM9 8C8.44772 8 8 8.44772 8 9V19C8 19.5523 8.44772 20 9 20H19C19.5523 20 20 19.5523 20 19V9C20 8.44772 19.5523 8 19 8H9Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
