import React, { FC, MouseEvent, useState } from "react";
import { Cross, Gear } from "../../Icons";
import { PopoverHover } from "../../PopoverHover";
import { Container } from "./GearButton.styles";

export const GearButton: FC<PropsType> = (props) => {
  const { menuModalIsOpen, needMenu, onClick, needSelectingColumns, hintText } = props;

  // ------------------------------ < ПОДСКАЗКА

  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();

  const openHint = ({ currentTarget }: MouseEvent<HTMLElement>) => {
    setAnchorElement(currentTarget);
  };

  const closeHint = () => {
    setAnchorElement(undefined);
  };

  // ------------------------------ ПОДСКАЗКА >

  return (
    <>
      <Container
        onClick={onClick}
        menuModalIsOpen={menuModalIsOpen}
        needMenu={needMenu}
        needSelectingColumns={needSelectingColumns}
        aria-owns={anchorElement && "hint-gear"}
        aria-haspopup="true"
        onMouseEnter={openHint}
        onMouseLeave={closeHint}
      >
        <div>{menuModalIsOpen ? <Cross /> : <Gear isFilled />}</div>
      </Container>

      {hintText ? (
        <PopoverHover
          id="hint-gear"
          isOpen={!!anchorElement}
          element={anchorElement}
          onClose={closeHint}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: 0, horizontal: "right" }}
        >
          {hintText}
        </PopoverHover>
      ) : null}
    </>
  );
};

type PropsType = {
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
  menuModalIsOpen: boolean;
  needMenu: boolean;
  needSelectingColumns: boolean;
  hintText?: string;
};
