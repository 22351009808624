import React from "react";
import { colors } from "../../styles";

export const Group = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 12.5C19.2941 12.5 23.5 13.3571 23.5 16.7857C23.5 18.2857 23.1176 18.5 22.3529 18.5L16.2628 18.5008C16.4208 18.0117 16.5 17.4381 16.5 16.7857C16.5 15.0629 15.8686 13.7189 14.8282 12.7239C15.6113 12.5634 16.3763 12.5 17 12.5ZM8 12.5C10.2941 12.5 14.5 13.3571 14.5 16.7857C14.5 18.2857 14.1176 18.5 13.3529 18.5H2.64706C1.88235 18.5 1.5 18.2857 1.5 16.7857C1.5 13.3571 5.70588 12.5 8 12.5ZM8 5C9.6575 5 11 6.3425 11 8C11 9.6575 9.6575 11 8 11C6.3425 11 5 9.6575 5 8C5 6.3425 6.3425 5 8 5ZM17 5C18.6575 5 20 6.3425 20 8C20 9.6575 18.6575 11 17 11C15.3425 11 14 9.6575 14 8C14 6.3425 15.3425 5 17 5Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
