import React, { FC, ReactNode } from "react";
import { JustBlock } from "../JustBlock";
import { RightSubtitle } from "./RightSubtitle";
import { Container, List } from "./WithRightBlock.styles";

export const WithRightBlock: FC<PropsType> = (props) => {
  const { mainTitle, typeMainBlock = "oneBlock", mainBlockChildren, mainFooter } = props;
  const { rightSubTitle, rightTitle, rightBlockChildren, rightFooter, rightAdditional } = props;
  const { needFilteredPanel = false } = props;

  return (
    <Container typeMainBlock={typeMainBlock}>
      {typeMainBlock === "oneBlock" ? (
        <JustBlock
          title={mainTitle ? <>{mainTitle}</> : undefined}
          footer={mainFooter ? <>{mainFooter}</> : undefined}
          needFilteredPanel={needFilteredPanel}
          titleType="big"
        >
          {mainBlockChildren}
        </JustBlock>
      ) : (
        <List>{mainBlockChildren}</List>
      )}

      <List>
        <JustBlock
          title={rightTitle ? rightTitle : undefined}
          footer={rightFooter ? rightFooter : undefined}
          titleSeparator="narrow"
          footerSeparator="narrow"
        >
          {rightSubTitle ? <RightSubtitle text={rightSubTitle} first /> : null}

          {rightBlockChildren}
        </JustBlock>

        {rightAdditional ? <JustBlock>{rightAdditional}</JustBlock> : null}
      </List>
    </Container>
  );
};

type PropsType = {
  mainBlockChildren: ReactNode;
  typeMainBlock?: "oneBlock" | "blockList";
  mainTitle?: string;
  mainFooter?: JSX.Element;
  needFilteredPanel?: boolean;
  rightTitle?: string;
  rightSubTitle?: string;
  rightBlockChildren: ReactNode;
  rightFooter?: JSX.Element;
  rightAdditional?: JSX.Element;
};
