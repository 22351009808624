import styled from "@emotion/styled";
import { Select } from "@mui/material";
import { borders, colors, flex, margins, overflowOneLine, shadow, size, text } from "../../styles";

const { grayscaleHoverBackground, grayscaleLine, grayscaleBeautifulBlack } = colors;

export const Container = styled.div`
  ${flex({ vertical: "center" })};
  ${size({ w: "100%" })};
  ${borders({ radius: 100 })};
  ${margins({ p: "0 0 0 16" })};

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const Item = styled(Select)`
  ${size({ h: 48 })};
  ${borders({ radius: 100 })};
  ${margins({ p: "12 16 12 12" })};

  cursor: pointer;

  .MuiSelect-select {
    ${margins({ p: "0", needImportant: true })};

    z-index: 1;
  }

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
    ${margins({ p: "0" })};
  }
`;

export const MenuProps = () => ({
  sx: {
    height: 232,

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: 1,

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 48,
          borderRadius: 2,
          marginBottom: 1,
          px: 1,
          py: 0,

          "&:hover": { background: grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": { width: 8 },

      "&::-webkit-scrollbar-thumb": { background: grayscaleLine, borderRadius: "99em" },
    },

    ".MuiPopover-paper": { width: 360 },
  },
  anchorOrigin: { vertical: 40, horizontal: 0 },
  transformOrigin: { vertical: -8, horizontal: 16 },
});

export const InputProps = {
  sx: {
    whiteColor: {
      color: "white",
    },
    color: `${grayscaleBeautifulBlack} !important`,
  },
  IconComponent: () => null,
};

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack })};
`;

export const MenuItemShowWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 16, height: 18, weight: 700, color: grayscaleBeautifulBlack })};
`;
