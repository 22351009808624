import { AutocompleteRenderInputParams, InputAdornment, MenuItem, TextField } from "@mui/material";
import React, { HTMLAttributes, SyntheticEvent, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { OptionsType, OptionType } from "../../utils/getOptions";
import { useRhfField } from "../../utils/useRHF";
import { Check } from "../Check/Check";
import { SelectAutocompleteMultilineInputPropsType } from "./SelectAutocompleteMultilineInput";
import { MenuItemWrapper } from "./SelectAutocompleteMultilineInput.styles";

export const useSelectAutocompleteMultilineInput = (
  props: SelectAutocompleteMultilineInputPropsType
) => {
  const { name, options, label, placeholder, result = "list" } = props;
  const { iconStart, category } = props;

  const [isOpenDescription, setIsOpenDescription] = useState<string | undefined>();

  const { onBlur } = useRhfField(name);
  const context = useFormContext();
  const setValue = (data: string[]) => context.setValue(name, data);

  const values = useWatch({ name }) as string[];

  const markOptions = useMemo(
    () =>
      category
        ? category
            .map((categoryName, i) =>
              (options[i] as OptionsType).map((option) => ({ category: categoryName, option }))
            )
            .flat(1)
        : options.map((option) => ({ category: "", option: option as OptionType })),
    [category, options]
  );

  const autocompleteValue = values?.map(
    (value) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      markOptions.find(({ option }) => option.id === value)!
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      name={name}
      {...params}
      label={label}
      InputProps={
        iconStart
          ? {
              ...params.InputProps,
              startAdornment: <InputAdornment position="start">{iconStart}</InputAdornment>,
            }
          : params.InputProps
      }
      placeholder={
        result === "text" && autocompleteValue?.length
          ? autocompleteValue.map(({ option }) => option.name).join(", ")
          : placeholder
      }
      onBlur={onBlur}
      InputLabelProps={{ shrink: true }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, { option }: CategoryOptionType) =>
    option.id !== "" ? (
      <MenuItem {...props} key={option.id}>
        <MenuItemWrapper>
          <Check checked={values?.includes(`${option.id}`)} variant="filled" />
          <p>{option.name}</p>
        </MenuItemWrapper>
      </MenuItem>
    ) : null;

  const handleChange = (
    _evt: SyntheticEvent<Element, Event>,
    arg: (string | CategoryOptionType)[] | null
  ) => {
    if (!arg) return;

    const value = arg.map((item) => (typeof item === "string" ? item : String(item.option.id)));
    setValue(value);
  };

  // ------------------------------ ВЫБРАННЫЕ ПОЛЯ

  const handleOpenSelectedItem = (id: string) =>
    setIsOpenDescription((prevState) => (prevState !== id ? id : undefined));

  const isOpen = (id: string) => id === isOpenDescription;

  const handleDelete = (id: string) => {
    const filteredValues = values.filter((value) => value !== id);
    setValue(filteredValues);
  };

  return {
    autocompleteValue,

    renderInput,
    renderOption,
    markOptions,

    handleChange,

    handleOpenSelectedItem,
    isOpen,
    handleDelete,
  };
};

export type CategoryOptionType = {
  option: OptionType;
  category: string;
};
