import React from "react";
import { colors } from "../../styles";

export const SquareFilledWithCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 2 21 21" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5904 2.10156C16.7583 2.10156 17.7924 2.30126 18.8666 2.87576C19.8383 3.39541 20.6062 4.16335 21.1259 5.13501C21.7004 6.20923 21.9001 7.24337 21.9001 9.41128V14.5919C21.9001 16.7598 21.7004 17.7939 21.1259 18.8681C20.6062 19.8398 19.8383 20.6077 18.8666 21.1274C17.7924 21.7019 16.7583 21.9016 14.5904 21.9016H9.40981C7.2419 21.9016 6.20776 21.7019 5.13354 21.1274C4.16189 20.6077 3.39395 19.8398 2.8743 18.8681C2.2998 17.7939 2.1001 16.7598 2.1001 14.5919V9.41128C2.1001 7.24337 2.2998 6.20923 2.8743 5.13501C3.39395 4.16335 4.16189 3.39541 5.13354 2.87576C6.20776 2.30126 7.2419 2.10156 9.40981 2.10156H14.5904ZM14.9637 9.06516C15.3152 8.71369 15.885 8.71369 16.2365 9.06516C16.588 9.41664 16.588 9.98648 16.2365 10.338L11.6365 14.938C11.285 15.2894 10.7152 15.2894 10.3637 14.938L7.76373 12.338C7.41225 11.9865 7.41225 11.4166 7.76373 11.0652C8.1152 10.7137 8.68505 10.7137 9.03652 11.0652L11.0001 13.0288L14.9637 9.06516Z"
      fill={colors.primaryDefaultDark}
    />
  </svg>
);
