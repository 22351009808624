import { Path, useController, useForm, UseFormProps } from "react-hook-form";

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ ПОЛЯ ФОРМЫ
 *
 * -
 *
 * @param name - имя поля (string) (обязательный)
 *
 * @return object.value - текущее значение поля (D)
 * @return object.error - ошибка поля (string | undefined)
 * @return object.touched - состояние касания для поля (boolean)
 * @return object.onChange - функция, отправляющая поле в библиотеку ((...event: any[]) => void)
 * @return object.onBlur - функция, отправляющая событие onBlur поля в библиотеку (() => void))
 * @return object.register - объект { ref, name, onChange, onBlur }
 *
 */

export const useRhfField = (name: string) => {
  const controller = useController({ name });
  const { field, fieldState } = controller;

  // ------------------------------ ошибка поля

  const { error: errorObj } = fieldState;
  const error = errorObj?.message;

  // ------------------------------ тронуто ли поле

  const { isTouched: touched } = fieldState;

  // ------------------------------ событие изменения значения

  const { onChange } = field;

  // ------------------------------ событие потери фокуса

  const { onBlur } = field;

  // ------------------------------

  const { ref } = field;
  const register = { ref, name, onChange, onBlur };

  // ------------------------------

  const { isValid } = controller.formState;

  // ------------------------------ логирование на время разработки

  // const { isDirty } = fieldState;
  // const { value } = field;

  // name === "files" &&
  //   console.log(
  //     `name-${name} |`,
  //     `value-${value} |`,
  //     `error-${error} |`,
  //     `isValid-${isValid} |`,
  //     `isDirty-${isDirty}`
  //   );

  return {
    error,
    touched,
    onChange,
    onBlur,
    register,
    isValid,
  };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ МАССИВА ПОЛЕЙ ФОРМЫ
 *
 * -
 *
 * @param D - типы данных полей формы
 * @param mode - 	стратегия проверки поведения перед отправкой ("onBlur" | "onChange" | "onSubmit")
 * @param reValidateMode - стратегия проверки поведения после отправки ("onBlur" | "onChange" | "onSubmit" | "onTouched" | "all")
 * @param defaultValues - значения по умолчанию для формы (DefaultValues<D>)
 * @param resolver - объект валидации, обёрнутый в интегратор (Resolver<D, any>)
 * @param context - контекстный объект, предоставляемый для проверки схемы (any)
 * @param shouldFocusError - включить или отключить встроенное управление фокусом ("firstError" | "all")
 * @param shouldUnregister - включать и отключать отмену регистрации ввода после размонтирования (boolean)
 * @param shouldUseNativeValidation - используйте встроенный в браузер API для ограничения формы (boolean)
 * @param criteriaMode - отобразите все ошибки проверки или по одной за раз (CriteriaMode)
 * @param delayError - задержка мгновенного появления ошибки (number)
 *
 * @return object.rhfMethods
 * @return object.values - значения всех полей формы (() => D)
 * @return object.errors - значения всех ошибок формы (FieldErrors<D>)
 * @return object.setFocus - установка фокуса на конкретном поле ((name: string, options: { shouldSelect: boolean }) => void)
 * @return object.touched - объект, содержащий все поля, с которыми взаимодействовал пользователь
 * @return object.isDirty - значение, указывающее на то, было ли изменено хотя бы одно поле (boolean)
 * @return object.isValid - значение, указывающее на то, есть ли в форме ошибки (boolean)
 * @return object.setValues - ф-я для динамической установки значений полей ((newValues: NV) => void)
 * @return object.handleSubmit - ф-я отправки формы, при isValid === true
 * @return object.submitCount - количество отправок формы (number)
 *
 */

export const useRhfForm = <D>(args: UseFormProps<D>) => {
  // ------------------------------ все

  const rhfMethods = useForm<D>({ ...args, mode: "all", reValidateMode: "onChange" });

  const { setValue } = rhfMethods;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValues = <NV extends Record<string, any>>(newValues: NV) => {
    Object.keys(newValues).forEach((key) => {
      const typedKey = key as keyof NV;
      setValue(typedKey as Path<D>, newValues[typedKey]);
    });
  };

  return {
    setValues,
  };
};
