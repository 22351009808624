import debounce from "lodash/debounce";
import React, { FC, FocusEvent, useCallback, useState } from "react";
import * as elements from "./SliderNumber.styles";

export const SliderNumber: FC<PropsType> = (props) => {
  const { defaultValue, step, min, max, title, editMode, onChange, onBlur } = props;
  const { Title, TitleAndNumberInfo: TitleInfoContainer, NumberInfo, Slider } = elements;
  const { SliderWrapper } = elements;

  const [currentValue, setCurrentValue] = useState(defaultValue);

  const changeHandler = useCallback(
    debounce((val: number) => onChange && onChange(val), 100),
    []
  );

  const handleChange = (_: Event, newValue: number | number[]) => {
    setCurrentValue(newValue as number);
    changeHandler((newValue as number) || 0);
  };

  return (
    <div>
      <TitleInfoContainer>
        <Title>{title}</Title>
        <NumberInfo editMode={editMode}>{currentValue || 0}</NumberInfo>
      </TitleInfoContainer>

      <SliderWrapper editMode={editMode}>
        <Slider
          id={title}
          name={title}
          onChange={handleChange}
          onBlur={onBlur}
          value={currentValue}
          step={step}
          min={min}
          max={max}
          valueLabelDisplay="off"
        />
      </SliderWrapper>
    </div>
  );
};

export type PropsSliderNumberType = {
  editMode?: boolean;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  title: string;
};

type PropsType = {
  onChange?: (newValue: number) => void;
  onBlur?: (evt: FocusEvent) => void;
} & PropsSliderNumberType;
