import { Badge } from "@digitalatom/ui";
import React, { FC, ReactNode } from "react";
import { RoomType } from "../../../api";
import { Photo } from "../../Photo";
import { BaseCard } from "../BaseCard";
import { DescriptionBlock } from "../DescriptionBlock";
import { Content } from "../RoomsSanatoriumCard.styles";
import { Title } from "../Title";

export const RoomDictionaryCard: FC<PropsType> = (props) => {
  const { room, subTitle, copy, onClick } = props;

  const { name, description, vip, photos } = room;

  const mainPhoto = photos?.find(({ isMain }) => isMain) ?? photos?.[0];

  return (
    <BaseCard>
      <Photo file={mainPhoto?.file} height={360} />

      <Content>
        <Title title={name} subTitle={subTitle} onClick={onClick}>
          {copy}
        </Title>
        <DescriptionBlock description={description} maxLines={3} />

        {vip && <Badge text="VIP" design="minor" type="accent" />}
      </Content>
    </BaseCard>
  );
};

type PropsType = {
  room: RoomType;
  subTitle: string;
  copy?: ReactNode;
  onClick: () => void;
};
