import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { PaginationType, roomsApi } from "../../api";
import { AccommodationValuesType } from "../../pages/DictionaryPages/SanatoriumsAndRooms/Rooms/Form/hooks/usePriceForm";
import { fetchAll, fetchById } from "../../utils/fetchData";
import { getPaginationKeys } from "../../utils/useQueryKeys";
import { roomIdState, sanatoriumIdState } from "../index";

export const accommodationsState = atom<AccommodationValuesType[]>([]);

export const roomsModalDataState = atom<AccommodationValuesType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const roomsAtom = atomWithQuery((get) => {
  const { startIndex, size } = getPaginationKeys(get);
  const sanatoriumId = get(sanatoriumIdState);

  const args = { startIndex, size, sanatoriumId };

  return roomsQuery(args);
});

export const roomsState = loadable(roomsAtom);

const roomsQuery = (props: PropsType) => {
  const { startIndex, size, sanatoriumId } = props;

  const filter = sanatoriumId
    ? [{ property: "sanatorium.id", operator: "=", value: sanatoriumId }]
    : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, sanatoriumId, "roomsSanatoriumState"],
    queryFn: () => fetchAll(() => roomsApi().getAll(args)),
  };
};

// ------------------------------ КОНКРЕТНЫЙ НОМЕР

const roomAtom = atomWithQuery((get) => {
  const id = get(roomIdState);
  const accommodations = get(accommodationsState);

  return roomQuery(accommodations, id);
});

export const roomState = loadable(roomAtom);

const roomQuery = (accommodations: AccommodationValuesType[], id?: string) => ({
  queryKey: [id, accommodations, "roomState"],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  queryFn: () => fetchById(() => roomsApi().get(id!)),
  enabled: !!id,
});

type PropsType = PaginationType & {
  sanatoriumId?: string;
};
