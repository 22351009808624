import React from "react";
import { colors } from "../../styles";

export const Plus = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.9 6.20171C12.9 5.70466 12.4971 5.30171 12 5.30171C11.5029 5.30171 11.1 5.70466 11.1 6.20172L11.0999 11.0999L6.20172 11.1C5.70467 11.1 5.30172 11.5029 5.30172 12L5.30994 12.1221C5.36953 12.5614 5.74609 12.9 6.20172 12.9L11.0999 12.9001L11.1 17.7983C11.1 18.2953 11.5029 18.6983 12 18.6983C12.4971 18.6983 12.9 18.2953 12.9 17.7983L12.9001 12.9001L17.7983 12.9C18.2953 12.9 18.6983 12.4971 18.6983 12L18.6901 11.8779C18.6305 11.4386 18.2539 11.1 17.7983 11.1L12.9001 11.0999L12.9 6.20171Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
