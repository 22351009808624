import styled from "@emotion/styled";
import { borders, colors, flex, margins, overflowOneLine, size, text } from "../../../styles";

const { white, primaryBackground, primaryDefault } = colors;
const { grayscaleInput, grayscaleAsh, grayscaleLabel, grayscaleBeautifulBlack } = colors;

export const Card = styled.div<{ isNew: boolean }>`
  ${flex({ isColumn: true })};
  ${borders({ side: "bottom", color: grayscaleInput })};
  ${margins({ p: "24 24 16" })};

  position: relative;
  background: ${({ isNew }) => (isNew ? primaryBackground : white)};

  :last-of-type {
    ${borders({ none: true })};

    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const Info = styled.div`
  ${flex({ horizontal: "evenly", vertical: "top" })};
`;

export const Employee = styled.div`
  ${flex({ gap: 2, isColumn: true })};

  position: relative;
`;

export const NewMarker = styled.div`
  ${size({ s: 8 })};
  ${borders({ totalRadius: true })};

  position: absolute;
  top: 6px;
  left: -14px;
  background: ${primaryDefault};
`;

export const Name = styled.p`
  ${text({ size: 15, height: 20, weight: 700, color: grayscaleAsh })};
`;

export const Position = styled.p`
  ${text({ size: 14, height: 17, weight: 400, color: grayscaleLabel })};
`;

export const NumberAndDate = styled.div`
  ${flex({ gap: 16 })};
`;

export const Number = styled.p`
  ${size({ max: { w: 200 } })};
  ${text({ size: 14, height: 20, weight: 700, color: grayscaleLabel })};
  ${overflowOneLine};
`;

export const Date = styled.p`
  ${text({ size: 14, height: 20, weight: 400, color: grayscaleLabel })};
`;

export const Theme = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};

  p {
    ${text({ size: 15, height: 15, weight: 400, color: grayscaleBeautifulBlack })};
  }
`;

export const CommentAndButton = styled.div`
  ${flex({ gap: 16, isColumn: true })};
`;

export const Comment = styled.div`
  ${text({ size: 16, height: 23, weight: 400, color: grayscaleAsh })};
`;

export const Button = styled.div`
  ${size({ w: "fit" })};
`;
