import React, { FC, memo, useEffect, useRef } from "react";
import { FilePropsType, PhotosType } from "../../../api";
import { Photo } from "../Photo";
import * as elements from "./PhotoCarousel.styles";

export const CustomIndicator: FC<PropsType> = (props) => {
  const { currentIndex, photos, onChange } = props;
  const { Miniatures: CustomIndicatorWrapper } = elements;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const activeThumbnail = container.children[currentIndex];

      if (activeThumbnail) {
        const containerRect = container.getBoundingClientRect();
        const thumbnailRect = activeThumbnail.getBoundingClientRect();

        if (thumbnailRect.left < containerRect.left) {
          container.scrollLeft -= containerRect.left - thumbnailRect.left;
        } else if (thumbnailRect.right > containerRect.right) {
          container.scrollLeft += thumbnailRect.right - containerRect.right;
        }
      }
    }
  }, [currentIndex]);

  return (
    <CustomIndicatorWrapper ref={containerRef}>
      {photos.map(({ id, file, preview }, index) => (
        <div key={id} onClick={() => onChange(index)}>
          <Miniature file={preview || file} active={currentIndex === index} />
        </div>
      ))}
    </CustomIndicatorWrapper>
  );
};

const Miniature = memo(({ file, active }: { file: FilePropsType; active: boolean }) => (
  <Photo file={file} height={102} width={102} active={active} />
));

Miniature.displayName = "Miniature";

type PropsType = {
  currentIndex: number;
  photos: PhotosType;
  onChange: (index: number) => void;
};
