import React from "react";
import { colors } from "../../styles";

export const SixDots = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 5.5C8.67157 5.5 8 6.17157 8 7C8 7.82843 8.67157 8.5 9.5 8.5C10.3284 8.5 11 7.82843 11 7C11 6.17157 10.3284 5.5 9.5 5.5ZM14.5 5.5C13.6716 5.5 13 6.17157 13 7C13 7.82843 13.6716 8.5 14.5 8.5C15.3284 8.5 16 7.82843 16 7C16 6.17157 15.3284 5.5 14.5 5.5ZM8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12C11 12.8284 10.3284 13.5 9.5 13.5C8.67157 13.5 8 12.8284 8 12ZM14.5 10.5C13.6716 10.5 13 11.1716 13 12C13 12.8284 13.6716 13.5 14.5 13.5C15.3284 13.5 16 12.8284 16 12C16 11.1716 15.3284 10.5 14.5 10.5ZM8 17C8 16.1716 8.67157 15.5 9.5 15.5C10.3284 15.5 11 16.1716 11 17C11 17.8284 10.3284 18.5 9.5 18.5C8.67157 18.5 8 17.8284 8 17ZM14.5 15.5C13.6716 15.5 13 16.1716 13 17C13 17.8284 13.6716 18.5 14.5 18.5C15.3284 18.5 16 17.8284 16 17C16 16.1716 15.3284 15.5 14.5 15.5Z"
      fill={colors.grayscaleAsh}
    />
  </svg>
);
