import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationType } from "../../../api";
import { historyState, statusNotificationState } from "../../../atoms";
import { APPLICATIONS_APPLICATION, REGISTER_APPLICATIONS } from "../../../constants/routes";
import { Loader, OpenListButton, PopoverHover, UniversalTextButton } from "../../../uiKit";
import { formatDate } from "../../../utils/workingWithDates";
import { getFullName } from "../../../utils/workingWithNames";
import { useUpdateNotification } from "../hooks/useUpdateNotification";
import * as elements from "./NotificationCard.styles";

export const NotificationCard: FC<PropsType> = (props) => {
  const { notification, notificationIsOpen, setNotificationIsOpen } = props;
  const { onClose, closeProfileMenu } = props;

  const { id, title, text, sender, isNewNotification, businessKey } = notification;
  const { processType, createdDate, additionalInformation } = notification;

  const navigate = useNavigate();

  const setHistory = useUpdateAtom(historyState);
  const statusNotification = useAtomValue(statusNotificationState);

  const { Card, Info, Employee, NewMarker, Name, Position, NumberAndDate, Number } = elements;
  const { Date, Theme, CommentAndButton, Comment, Button } = elements;

  const commentIsOpen = notificationIsOpen === id;

  const numberRef = useRef<HTMLParagraphElement | null>(null);

  const isApplication = processType === "APPLICATION";

  const [isRegisterNameOverflow, setIsRegisterNameOverflow] = useState<boolean>(false);

  // ------------------------------ < ПОДСКАЗКА К НАИМЕНОВАНИЮ РЕЕСТРА

  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();

  const openHint = ({ currentTarget }: MouseEvent<HTMLElement>) => setAnchorElement(currentTarget);

  const closeHint = () => setAnchorElement(undefined);

  // ------------------------------ ПОДСКАЗКА К НАИМЕНОВАНИЮ РЕЕСТРА >

  // ------------------------------ < ОТМЕТКА УВЕДОМЛЕНИЯ НЕ НОВЫМ

  const update = useUpdateNotification().useUpdate;

  const updateNotification = async () => {
    try {
      await update.mutateAsync({ id, data: { isNewNotification: false } });
    } catch (err) {
      console.error(err);
    }
  };

  // ------------------------------ ОТМЕТКА УВЕДОМЛЕНИЯ НЕ НОВЫМ >

  useEffect(() => {
    setIsRegisterNameOverflow(
      !!numberRef.current && numberRef.current.scrollWidth > numberRef.current.clientWidth
    );
  }, [numberRef]);

  return (
    <Card isNew={isNewNotification}>
      {statusNotification === id ? <Loader /> : null}

      <Info>
        <Employee>
          <Name>{sender ? getFullName({ nameParts: sender }) : "Комиссия СК"}</Name>
          <Position>{sender?.positionName}</Position>

          {isNewNotification ? <NewMarker /> : null}
        </Employee>

        <NumberAndDate>
          <Number
            ref={numberRef}
            aria-owns={anchorElement && "hint-gear"}
            aria-haspopup="true"
            onMouseEnter={!isApplication && isRegisterNameOverflow ? openHint : undefined}
            onMouseLeave={!isApplication && isRegisterNameOverflow ? closeHint : undefined}
          >
            {isApplication ? `№ ${businessKey}` : additionalInformation}
          </Number>
          <Date>{formatDate({ date: createdDate, type: "forFrontend" })}</Date>
        </NumberAndDate>
      </Info>

      <Theme>
        <p>{title}</p>

        <OpenListButton
          isOpen={commentIsOpen}
          onClick={() => {
            setNotificationIsOpen(commentIsOpen ? "" : id);
            updateNotification();
          }}
        />
      </Theme>

      {commentIsOpen ? (
        <CommentAndButton>
          <Comment>{text}</Comment>

          <Button>
            <UniversalTextButton
              text={isApplication ? "Перейти в заявку" : "Перейти в реестр"}
              onClick={(evt) => {
                isApplication
                  ? navigate(`${APPLICATIONS_APPLICATION(additionalInformation ?? "not")}`)
                  : navigate(REGISTER_APPLICATIONS(businessKey ?? "not"));

                setHistory([]);

                onClose();
                evt && closeProfileMenu(evt);
              }}
            />
          </Button>
        </CommentAndButton>
      ) : null}

      <PopoverHover
        id="hint-gear"
        isOpen={!!anchorElement}
        element={anchorElement}
        onClose={closeHint}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: 0, horizontal: "right" }}
      >
        {isApplication ? `№ ${businessKey}` : additionalInformation}
      </PopoverHover>
    </Card>
  );
};

type PropsType = {
  notification: NotificationType;
  notificationIsOpen: string;
  setNotificationIsOpen: (arg: string) => void;
  onClose: () => void;
  closeProfileMenu: (evt: MouseEvent<HTMLElement>) => void;
};
