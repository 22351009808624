import styled from "@emotion/styled";
import { borders, colors, flex, size } from "../../styles";

const { grayscaleIcons, primaryBackground } = colors;

export const Container = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 44 })};
  ${borders({ radius: 12 })};

  background: ${primaryBackground};

  svg > path {
    fill: ${grayscaleIcons};
  }
`;
