import { MenuItem } from "@mui/material";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../atoms";
import * as elements from "./Pagination.styles";
import { usePagination } from "./usePagination";

export const Pagination: FC<PropsType> = (props) => {
  const { totalCount, inModal = false } = props;

  const paginationMethods = usePagination(totalCount, inModal);
  const { value, page, handleChange, handleChangeQuantity, count } = paginationMethods;
  const { hasMoreOnePage, isOpenList, handleOpenList } = paginationMethods;
  const { openListIcon, isWidthLessThanMinimum } = paginationMethods;

  const { Container, MenuItemWrapper, MenuProps, Overlay } = elements;
  const { Blur, Input, PaginationItem, QuantitySelection } = elements;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  return (
    <Overlay
      isWidthLessThanMinimum={isWidthLessThanMinimum}
      menuIsExpand={menuIsExpand}
      inModal={inModal}
    >
      <Container>
        {hasMoreOnePage && (
          <PaginationItem count={count} page={page} onChange={handleChange} size="large" />
        )}

        <QuantitySelection>
          <Input
            value={value}
            open={isOpenList}
            displayEmpty
            IconComponent={openListIcon}
            MenuProps={MenuProps}
            onChange={handleChangeQuantity}
            onClick={handleOpenList}
            defaultValue={10}
            autoComplete="off"
          >
            {[10, 50, 100, "все"].map((option, i) => (
              <MenuItem key={i} value={option}>
                <MenuItemWrapper>{option}</MenuItemWrapper>
              </MenuItem>
            ))}
          </Input>
        </QuantitySelection>
      </Container>

      <Blur inModal={inModal} />
    </Overlay>
  );
};

type PropsType = {
  totalCount: number;
  inModal?: boolean;
};
