import { adultWords, childWords } from "../constants/options";
import { getInclinedWord } from "./getInclinedWord";

export const getVacationersCountText = (props: PropsType) => {
  const { adultsCount, childrenCount } = props;

  return [
    ...(adultsCount ? [`${adultsCount} ${getInclinedWord(adultsCount, adultWords)}`] : []),
    ...(childrenCount ? [`${childrenCount} ${getInclinedWord(childrenCount, childWords)}`] : []),
  ].join(", ");
};

type PropsType = {
  adultsCount: number;
  childrenCount: number;
};
