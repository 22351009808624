import styled from "@emotion/styled";
import ReactQuill from "react-quill";
import { colors, scroll, size, text } from "../../../styles";

const { grayscaleInput, redBackground } = colors;

export const Quill = styled(ReactQuill)<{ error?: boolean }>`
  .ql-editor {
    ${size({ h: "100%" })};
    ${({ error }) => (error ? `background: ${redBackground}` : undefined)};

    overflow-wrap: anywhere;
  }

  .ql-toolbar {
    border-color: ${grayscaleInput}!important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .ql-container {
    ${size({ min: { h: 100 }, max: { h: 300 } })};
    ${scroll};

    border-color: ${grayscaleInput}!important;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background: ${grayscaleInput};
    overflow-y: auto;
  }
`;

export const Content = styled.div`
  overflow-wrap: break-word;

  h1 {
    ${text({ size: 24, height: 34, weight: 700 })};
  }

  h2 {
    ${text({ size: 20, height: 28, weight: 700 })};
  }

  .ql-align-center {
    ${text({ size: 15, height: 20, weight: 400, align: "center" })};
  }

  .ql-align-right {
    ${text({ size: 15, height: 20, weight: 400, align: "end" })};
  }

  .ql-align-justify {
    ${text({ size: 15, height: 20, weight: 400, align: "justify" })};
  }
`;
