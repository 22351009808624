import styled from "@emotion/styled";
import { borders, flex, margins, size } from "../../../../../../styles";

export const Modal = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${size({ w: 360 })};
  ${borders({ radius: 16 })};
  ${margins({ p: "16" })};
`;

export const Form = styled.form`
  ${flex({ gap: 16, isColumn: true })};
`;
