import { DateRange } from "@mui/lab";
import { MuiTextFieldProps } from "@mui/lab/internal/pickers/PureDateInput";
import { TextFieldProps } from "@mui/material";
import { getQuarter, getYear, setYear } from "date-fns";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dateSwitcherTypeState, DateSwitcherTypeType, periodSwitcherTypeState } from "../../atoms";
import { useOptionalAtom } from "../../utils/useOptionalAtom";
import * as dateMethods from "../../utils/workingWithDates";
import { LeftAndRightArrowsButtons } from "../Buttons";
import { DateAtomType, DateIntervalAtomType } from "../Header/HeaderNavigation/HeaderNavigation";

const { onePeriodMethods, startAndEndMethods } = dateMethods;
const { formatDate, getPeriodString, today } = dateMethods;

export const useDateSwitcher = (props: PropsType) => {
  const { dateAtom, dateIntervalAtom, router } = props;

  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const [periodSwitcherType, setPeriodSwitcherType] = useAtom(periodSwitcherTypeState);

  const [date, setDate] = useOptionalAtom(dateAtom);
  const [dateInterval, setDateInterval] = useOptionalAtom(dateIntervalAtom);

  const startDate = date ?? (dateInterval?.[0] ? dateInterval[0] : today);
  const endDate = dateInterval?.[1] ? dateInterval[1] : today;

  const minDate = startAndEndMethods.start["year"](setYear(today, getYear(today) - 5));
  const maxDate = startAndEndMethods.end["year"](today);

  const navigate = useNavigate();
  const { date: dateParams } = useParams();

  const text = (props: TextPropsType) => {
    const { startProps, endProps, inputProps } = props;

    return {
      day:
        startProps?.inputProps?.value === endProps?.inputProps?.value
          ? startProps?.inputProps?.value
          : getPeriodString({
              since: startProps?.inputProps?.value,
              until: endProps?.inputProps?.value,
            }),
      month:
        inputProps?.inputProps?.value.split(" ")[1] === String(getYear(today))
          ? inputProps?.inputProps?.value.split(" ")[0]
          : inputProps?.inputProps?.value,
      quarter:
        dateInterval &&
        dateInterval[0] &&
        (getYear(dateInterval[0]) !== getYear(today)
          ? `${getQuarter(dateInterval[0])} кв.${(<br />)}${getYear(dateInterval[0])} года`
          : `${getQuarter(dateInterval[0])} квартал`),
      year: inputProps?.inputProps?.value,
    };
  };

  const navigateDate = (startAndEndDates: DateRange<Date | undefined>) => {
    const [startDate, endDate] = startAndEndDates;

    if (dateParams && startDate && router) {
      endDate
        ? formatDate({ date: startDate, type: "forFrontend" }) ===
          formatDate({ date: endDate, type: "forFrontend" })
          ? navigate(`${router(`${formatDate({ date: startDate, type: "forFrontend" })}`)}`)
          : navigate(
              `${router(
                `${formatDate({ date: startDate, type: "forFrontend" })}-${formatDate({
                  date: endDate,
                  type: "forFrontend",
                })}`
              )}`
            )
        : navigate(`${router(`${formatDate({ date: startDate, type: "forFrontend" })}`)}`);
    }
  };

  const handleClickPeriod = (period: DateSwitcherTypeType) => {
    if (periodSwitcherType === period) return;

    setDateSwitcherType(period);
    setPeriodSwitcherType(period);

    const currentDate =
      !!startDate && getYear(startDate) !== getYear(today)
        ? startAndEndMethods.start["year"](startDate)
        : today;

    setDateInterval([
      startAndEndMethods.start[period](currentDate),
      startAndEndMethods.end[period](currentDate),
    ]);
  };

  const handleClickArrow = (
    direction: "left" | "right",
    dateSwitcherType: DateSwitcherTypeType
  ) => {
    if (direction === "left" ? disableBack?.day : disableNext?.day) return;

    if (date && setDate) {
      if (direction === "left") {
        setDate(onePeriodMethods.minus.day(date));
        navigateDate([onePeriodMethods.minus.day(date), undefined]);
      } else {
        setDate(onePeriodMethods.plus.day(date));
        navigateDate([onePeriodMethods.plus.day(date), undefined]);
      }
    } else if (startDate && dateIntervalAtom) {
      {
        const currentDate = (
          direction === "left"
            ? onePeriodMethods.minus[dateSwitcherType]
            : onePeriodMethods.plus[dateSwitcherType]
        )(startDate);

        if (dateSwitcherType !== "day" && dateSwitcherType !== "period") {
          if (
            direction === "left" ? disableBack?.[dateSwitcherType] : disableNext?.[dateSwitcherType]
          )
            return;
        }

        dateSwitcherType === "day"
          ? setDateInterval([currentDate, currentDate])
          : setDateInterval([
              startAndEndMethods.start[dateSwitcherType](currentDate),
              startAndEndMethods.end[dateSwitcherType](currentDate),
            ]);

        dateSwitcherType === "day" && navigateDate([currentDate, currentDate]);
      }
    }
  };

  const arrow = (direction: "left" | "right") => ({
    day: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "day" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.day : disableNext?.day}
        orientation={direction}
      />
    ),
    month: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "month" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.month : disableNext?.month}
        orientation={direction}
      />
    ),
    quarter: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "quarter" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.quarter : disableNext?.quarter}
        orientation={direction}
      />
    ),
    year: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "year" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.year : disableNext?.year}
        orientation={direction}
      />
    ),
  });

  const disableBack = date &&
    minDate && {
      day: date < minDate,
      month: onePeriodMethods.minus.month(date) < minDate,
      quarter: onePeriodMethods.minus.quarter(date) < minDate,
      year: onePeriodMethods.minus.year(date) < minDate,
    };

  const disableNext = date &&
    maxDate && {
      day: date > maxDate,
      month: onePeriodMethods.plus.month(date) > maxDate,
      quarter: onePeriodMethods.plus.quarter(date) > maxDate,
      year: onePeriodMethods.plus.year(date) > maxDate,
    };

  return {
    date,
    setDate,
    dateInterval,
    setDateInterval,

    startDate,
    endDate,
    maxDate,
    minDate,

    text,

    navigateDate,

    arrow,

    handleClickPeriod,
  };
};

type PropsType = {
  dateAtom?: DateAtomType;
  dateIntervalAtom?: DateIntervalAtomType;
  router?: (date: string | undefined) => string;
};

type TextPropsType = {
  startProps?: MuiTextFieldProps;
  endProps?: MuiTextFieldProps;
  inputProps?: TextFieldProps;
};
