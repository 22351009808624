import { useQuery } from "react-query";
import { fileApi, FileLinkType } from "../api";

const getFileBlob = async (link: string) => {
  const res = await fileApi.download({ fileRef: link });
  return new Blob([res.data], { type: res.headers["content-type"] });
};

export const downloadFile = async (link?: FileLinkType, name?: string) => {
  if (!link || !name) return;

  const blob = await getFileBlob(link);
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
};

export const useQueryPhoto = (document: FileLinkType | undefined) =>
  useQuery(["file", document], async () => (!document ? null : getFileBlob(document)), {
    staleTime: 60000,
    cacheTime: 60000,
  }).data;
