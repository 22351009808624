import { add } from "date-fns";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { FormProvider, useForm as useRhfForm } from "react-hook-form";
import { currentDateIntervalForSanatoriums, sanatoriumsFiltersState } from "../../../atoms";
import { FormButton, Loupe } from "../../../uiKit";
import { today } from "../../../utils/workingWithDates";
import { FormFilter } from "../SanatoriumListPage.styles";
import { useFilters } from "../hooks/useFilters";

export const PanelFilter: FC<PropsType> = (props) => {
  const { shortVersion = false, isAlternative } = props;

  const [filters, setFilters] = useAtom(sanatoriumsFiltersState);
  const dateInterval = useAtomValue(currentDateIntervalForSanatoriums);

  const { formName, filtersValidationSchema, fieldsPanelFilter } = useFilters(isAlternative);

  const rhfMethods = useRhfForm({
    defaultValues: filters,
    resolver: filtersValidationSchema,
    mode: "all",
    reValidateMode: "onChange",
  });
  const { formState, handleSubmit, getValues } = rhfMethods;
  const { isValid } = formState;

  return (
    <FormProvider {...rhfMethods}>
      <FormFilter
        id={formName}
        onSubmit={handleSubmit(() => undefined)}
        shortVersion={shortVersion}
      >
        {fieldsPanelFilter(shortVersion)}

        <FormButton
          text="Найти"
          formName={formName}
          variant="text"
          icon={<Loupe />}
          onClick={() => {
            isValid && shortVersion
              ? setFilters({
                  ...filters,
                  startDate: dateInterval[0] ?? today,
                  endDate: dateInterval[1] ?? add(today, { weeks: 2 }),
                })
              : setFilters({
                  ...getValues(),
                  rating: filters.rating,
                  profiles: filters.profiles,
                  startDate: dateInterval[0] ?? today,
                  endDate: dateInterval[1] ?? add(today, { weeks: 2 }),
                });
          }}
        />
      </FormFilter>
    </FormProvider>
  );
};

type PropsType = {
  shortVersion?: boolean;
  isAlternative?: boolean;
};
