import styled from "@emotion/styled";
import { button, buttonTextDefault, colors, margins, size } from "../../../styles";

const { white, grayscaleLabel, grayscaleInput } = colors;

export const Container = styled.button<{ inverted: boolean }>`
  ${button};
  ${buttonTextDefault};
  ${size({ s: 24 })};
  ${margins({ p: "0" })};

  background: ${({ inverted }) => (inverted ? grayscaleInput : white)};

  svg > path {
    fill: ${grayscaleLabel};
  }

  :hover {
    background: ${({ inverted }) => (inverted ? white : grayscaleInput)};
  }
`;
