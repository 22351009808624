import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { useState } from "react";
import { addFormState, selectedRowsState } from "../../../atoms";
import { useQuery } from "../../../utils/useQuery";
import { UniversalTextButton } from "../../Buttons";
import { ConfirmModal } from "../../ConfirmModal";
import { HeaderNavigationPropsType } from "./HeaderNavigation";
import { LeftButtons } from "./HeaderNavigation.styles";

export const HeaderNavigationLeftButtons = <VOBJ,>(props: HeaderNavigationPropsType<VOBJ>) => {
  const { buttonsStart, buttonsCenter, buttonsEnd, needDeleteButton } = props;
  const { questionText, successText, needAddButton, api, queryKey } = props;

  const selectedRows = useAtomValue(selectedRowsState);
  const setAddFormIsOpen = useUpdateAtom(addFormState);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // ------------------------------ < ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ

  const openDeleteConfirmModal = () => {
    if (!!selectedRows && selectedRows.length === 0) return;

    setOpenConfirmModal(true);
  };

  const closeDeleteConfirmModal = () => setOpenConfirmModal(false);

  const query = useQuery();
  const deletion = api && queryKey ? query.useDelete({ api, queryKey }) : undefined;
  const handleDeleteRows = deletion
    ? async () => await deletion?.mutateAsync(selectedRows)
    : undefined;

  // ------------------------------ ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ >

  return (
    <LeftButtons>
      {buttonsStart}

      {!!needAddButton && (
        <UniversalTextButton text="Добавить" onClick={() => setAddFormIsOpen(true)} />
      )}

      {buttonsCenter}

      {!!deletion && needDeleteButton && (
        <>
          <UniversalTextButton
            text="Удалить"
            enabled={!!selectedRows && selectedRows.length !== 0}
            onClick={openDeleteConfirmModal}
          />

          {!!deletion && !!handleDeleteRows && openConfirmModal && (
            <ConfirmModal
              question={{
                text: questionText
                  ? typeof questionText === "string"
                    ? `Вы действительно хотите удалить ${questionText}?`
                    : `Вы действительно хотите удалить ${questionText({
                        count: selectedRows.length,
                      })}?`
                  : "",
                coloredButton: { onDelete: handleDeleteRows },
              }}
              success={{ text: successText ? successText : "Запись удалена" }}
              isOpen
              onClose={closeDeleteConfirmModal}
            />
          )}
        </>
      )}

      {buttonsEnd}
    </LeftButtons>
  );
};
