import { useUpdateAtom } from "jotai/utils";
import React, { Suspense, useEffect } from "react";
import { historyState } from "../atoms";
import { useSections } from "../constants/sections";
import "../styles/index.css";
import { LeftMenu, Loader } from "../uiKit";
import { AppContainer } from "./App.styles";
import { AppRoutes } from "./AppRoutes";

export const App = () => {
  const sections = useSections();

  const setHistory = useUpdateAtom(historyState);

  useEffect(() => () => setHistory([]), []);

  return (
    <AppContainer className="App">
      <Suspense fallback={<Loader />}>
        <LeftMenu sections={sections} />
        <AppRoutes />
      </Suspense>
    </AppContainer>
  );
};
