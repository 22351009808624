import React, { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from "react";
import { PhotosType } from "../../../api";
import { ArrowLeft, ArrowRight } from "../../../uiKit";
import { Photo } from "../Photo";
import { CustomIndicator } from "./CustomIndicator";
import * as elements from "./PhotoCarousel.styles";

export const PhotoCarousel: FC<PropsType> = (props) => {
  const { photos, startShowedPhotoIndex = 0 } = props;

  const { PhotoContainer, CarouselWrapper, Carousel, NavigationButton } = elements;

  const photosTotalCount = photos.length;

  const [currentIndex, setCurrentIndex] = useState(startShowedPhotoIndex);

  const handleChange = useCallback((index: number) => {
    setCurrentIndex((index >= 0 ? index : photosTotalCount - 1) % photosTotalCount);
  }, []);

  const memoPhotos = useMemo(() => photos, [photos]);

  const toNext = () => handleChange(currentIndex + 1);
  const toPrev = () => handleChange(currentIndex - 1);

  const pressKey = (evt: Event) => {
    const keyboardEvent = evt as unknown as KeyboardEvent;

    if (keyboardEvent.key === "ArrowRight") toNext();
    else if (keyboardEvent.key === "ArrowLeft") toPrev();
  };

  useEffect(() => {
    window.addEventListener("keydown", pressKey);

    return () => {
      window.removeEventListener("keydown", pressKey);
    };
  }, [currentIndex, toNext, toPrev]);

  return (
    <CarouselWrapper>
      <Carousel>
        <NavigationButton onClick={toPrev}>
          <ArrowLeft />
        </NavigationButton>

        <PhotoContainer>
          <Photo file={photos[currentIndex].file} height="100%" bgSize="contain" />
        </PhotoContainer>

        <NavigationButton onClick={toNext}>
          <ArrowRight />
        </NavigationButton>
      </Carousel>

      <CustomIndicator currentIndex={currentIndex} photos={memoPhotos} onChange={handleChange} />
    </CarouselWrapper>
  );
};

type PropsType = {
  photos: PhotosType;
  startShowedPhotoIndex?: number;
};
