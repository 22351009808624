import styled from "@emotion/styled";
import { flex, grid } from "../../../../../../../../styles";

export const Container = styled.div`
  ${grid({ gap: 8, columns: "50% 50%" })};
`;

export const SelectContainer = styled.div`
  ${flex({ gap: 12, isColumn: true })};
`;
