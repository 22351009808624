import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { SortType } from "../../api";
import { applicationsTypeOptions } from "../../constants/options";
import { StatusIdsType } from "../../constants/statusIds";
import { startAndEndMethods, today } from "../../utils/workingWithDates";

// ------------------------------ ID ЗАЯВКИ

export const applicationIdState = atom<string>("");
export const registryIdState = atom<string>("");
export const taskIdState = atom<string>("");

// ------------------------------ ВИД ЗАЯВОК

export const applicationsTypeState = atom<ApplicationsTypeType | "">("");

export type ApplicationsTypeType = typeof applicationsTypeOptions[number]["id"];

// ------------------------------ СОРТИРОВКА ЗАЯВОК

export const sortTypeState = atom<SortType>("-createdDate");

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateInterval = atom<DateRange<Date>>([
  startAndEndMethods.start.year(today),
  startAndEndMethods.end.year(today),
]);

// ------------------------------ ТИП ПИСЬМА

export const letterTypeState = atom<LetterTypeType | "">("");

type LetterTypeType = "letter_approve" | "letter_adjustment" | "letter_reject";

// ------------------------------ ТИП ПОДТВЕРЖДЕНИЯ

export const confirmTypeState = atom<StatusAndConfirmTypeType>("");

export type StatusAndConfirmTypeType = `${StatusIdsType} ${ConfirmTypeType}` | "";

export type ConfirmTypeType =
  | "confirm_approve"
  | "confirm_adjustment"
  | "confirm_reject"
  | "confirm_save";

// ------------------------------ ТИП ПОДТВЕРЖДЕНИЯ

export const errorMessageState = atom<string | undefined>(undefined);

// ------------------------------ ОКНО ОТКАЗА

export const rejectIsOpenState = atom<boolean>(false);

// ------------------------------ ОКНО ЗАГРУЗКИ ДОКУМЕНТОВ

export const documentIsOpenState = atom<boolean>(false);
