import { useAtomValue } from "jotai/utils";
import React, { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { selectedRoomsState } from "../../../../../../../atoms";
import * as options from "../../../../../../../constants/options";
import { SelectInput } from "../../../../../../../uiKit";
import { Container } from "./SelectCategory.styles";

export const SelectCategory: FC<PropsType> = (props) => {
  const { name } = props;

  const context = useFormContext();
  const setValue = (data: string[]) => context.setValue(name, data);

  const values = useWatch({ name }) as string[];

  const selectedRooms = useAtomValue(selectedRoomsState);

  const isEmployeeWasSelected = !!selectedRooms.find(({ vacationers }) =>
    vacationers.adults.includes(options.employeeOption.id)
  );

  const isSpouseWasSelected = !!selectedRooms.find(({ vacationers }) =>
    vacationers.adults.includes(options.spouseOption.id)
  );

  const { familyAdultMembersOptions, employeeOption, spouseOption, otherOption } = options;

  useEffect(() => {
    if (
      values.includes(spouseOption.id) &&
      values.includes(employeeOption.id) &&
      values.filter(Boolean).length !== values.length
    ) {
      const result: string[] = [];

      for (let i = 0; i < values.length; i++) {
        if (!values[i]) result.push(otherOption.id);
        else result.push(values[i]);
      }

      setValue(result);
    }
  }, [values]);

  const getOptions = (optionId: string) =>
    familyAdultMembersOptions.filter(({ id }) => {
      if (
        optionId !== id &&
        ((isEmployeeWasSelected && id === options.employeeOption.id) ||
          (isSpouseWasSelected && id === options.spouseOption.id))
      )
        return false;

      if (optionId !== id && values.includes(id))
        return id !== employeeOption.id && id !== spouseOption.id;

      return true;
    });

  return (
    <Container>
      {Array.from(values).map((category, i) => (
        <SelectInput
          key={i}
          name={`${name}.${i}`}
          placeholder="Укажите тип взрослого"
          options={getOptions(category)}
          size={328}
          disabled={i === 0 && selectedRooms.length === 0}
        />
      ))}
    </Container>
  );
};

type PropsType = {
  name: string;
};
