import styled from "@emotion/styled";
import { InputLabel, Select, SelectProps } from "@mui/material";
import { opacify } from "polished";
import { borders, colors, margins, overflowOneLine, shadow, size, text } from "../../styles";

const { grayscaleLabel, grayscaleHoverBackground, grayscaleLine, grayscaleBeautifulBlack } = colors;
const { grayscaleDisabled, grayscaleAsh, grayscalePlaceholder, redBackground } = colors;

export const Wrapper = styled.div``;

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  * {
    cursor: inherit !important;
  }
`;

export const Item = styled(Select)<ItemPropsType>`
  ${size({ h: 56, w: "100%" })};
  ${borders({ radius: 16 })};
  ${({ haslabel }) => margins({ p: haslabel ? "8 16" : "16" })};

  background: ${({ haserror, backgroundcolor, disabled }) =>
    haserror ? redBackground : disabled ? grayscaleDisabled : backgroundcolor};
  cursor: pointer;

  .MuiSelect-select {
    ${({ haslabel }) =>
      margins({ m: haslabel ? "16 0 0" : "0", p: "0 32 0 0", needImportant: true })};

    z-index: 1;

    div {
      ${({ disabled }) =>
        text({
          size: 16,
          height: 24,
          weight: 400,
          color: disabled ? grayscaleDisabled : grayscaleAsh,
        })};
    }

    p {
      ${({ disabled }) =>
        text({
          size: 16,
          height: 24,
          weight: 400,
          color: disabled ? opacify(-0.5, grayscalePlaceholder) : grayscalePlaceholder,
        })};
      -webkit-text-fill-color: ${({ disabled }) =>
        disabled ? opacify(-0.5, grayscalePlaceholder) : grayscalePlaceholder} !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
    ${margins({ p: "0" })};
    color: ${redBackground} !important;
  }
` as (props: SelectProps & Omit<ItemPropsType, "multiline" | "disabled">) => JSX.Element;

export const Label = styled(InputLabel)`
  ${size({ max: { w: "100%" } })};
  ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
  ${margins({ m: "0" })};

  position: absolute;
  left: 16px;
  top: 6px;
  transform: none;
  z-index: 1;
`;

export const Title = styled.h5`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 0 8 0" })};
`;

export const Placeholder = styled.p<{ haslabel: number; disabled: boolean }>`
  ${overflowOneLine};
  ${size({ w: `calc(100% - 40px - 8px - 16px * 2)` })};
  ${text({ size: 16, height: 24, weight: 400 })};

  color: ${({ disabled }) =>
    disabled ? opacify(-0.5, grayscalePlaceholder) : grayscalePlaceholder} !important;

  position: absolute;
  top: ${({ haslabel }) => (haslabel ? "24px" : "16px")};
  left: 16px;
`;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleAsh })};
`;

export const MenuProps = (size: string | number) => ({
  sx: {
    height: 232,

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: 1,

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": { width: 8 },

      "&::-webkit-scrollbar-thumb": { background: grayscaleLine, borderRadius: "99em" },
    },

    ".MuiPopover-paper": { width: typeof size === "number" ? size : size === "small" ? 372 : 472 },
  },
  anchorOrigin: { vertical: 40, horizontal: 0 },
  transformOrigin: { vertical: -8, horizontal: 16 },
});

type ItemPropsType = {
  haslabel: number;
  haserror: number;
  backgroundcolor: string;
  multiline: boolean;
  disabled: boolean;
};
