import styled from "@emotion/styled";
import { borders, colors, flex, margins } from "../../styles";

const { white } = colors;

export const Container = styled.div`
  ${flex({ isColumn: true })};
  ${borders({ radius: 24 })};
  ${margins({ m: "0 0 24 0" })};

  background: ${white};
`;

export const Block = styled.div`
  ${margins({ p: "24" })};
`;

export const MainBlock = styled(Block)<{ isNeededBottomAdditionalMargin?: boolean }>`
  ${flex({ gap: 24, isColumn: true })};
  ${({ isNeededBottomAdditionalMargin }) =>
    margins({ p: `24 24 ${isNeededBottomAdditionalMargin ? 82 : 24} 24` })};
`;
