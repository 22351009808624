import React from "react";
import { colors } from "../../styles";

export const Folder = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9655 3.5C17.7061 1.52478 16.0162 0 13.9701 0H9H8H7H4.02985C1.80423 0 0 1.80423 0 4.02985V11.9701C0 14.1958 1.80423 16 4.02985 16H13.9701C16.1958 16 18 14.1958 18 11.9701V5.5V4.5V4.02985C18 3.85025 17.9883 3.67339 17.9655 3.5ZM14.6248 3.5C15.2829 3.5 15.7389 2.83668 15.2207 2.43085C14.876 2.16091 14.4419 2 13.9701 2H11.1592C10.3865 2 9.97907 2.83059 10.6693 3.17806C11.0784 3.38402 11.5406 3.5 12.0299 3.5H14.6248ZM6.50617 2C6.94141 2 7.31774 2.28661 7.50899 2.67756C8.32662 4.34895 10.0438 5.5 12.0299 5.5H15C15.5523 5.5 16 5.94772 16 6.5V11.9701C16 13.0912 15.0912 14 13.9701 14H4.02985C2.9088 14 2 13.0912 2 11.9701V4.02985C2 2.9088 2.9088 2 4.02985 2H6.50617Z"
      fill={colors.grayscalePlaceholder}
    />
  </svg>
);
