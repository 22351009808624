import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, notificationsApi } from "../api";
import { fetchAll, fetchTotalCount } from "../utils/fetchData";
import { getQueryFilter } from "../utils/getQueryFilter";
import { getPaginationModalQueryKeys } from "../utils/useQueryKeys";
import { currentUserAtom } from "./index";

export const statusNotificationState = atom<string>("");

// ------------------------------ ВСЕ УВЕДОМЛЕНИЯ

export const notificationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);
  const { query, size, startIndex } = getPaginationModalQueryKeys(get);

  const userId = user.res.id;

  const args = { startIndex, size, query, userId };

  return notificationsQuery(args);
});

export const notificationsState = loadable(notificationsAtom);

const notificationsQuery = (props: PropsType) => {
  const { startIndex, size, query, userId } = props;

  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "recipient.id", operator: "=", value: userId },
        ...(query
          ? [
              getQueryFilter(query, [
                "businessKey",
                "additionalInformation",
                "sender.firstName",
                "sender.middleName",
                "sender.lastName",
              ]),
            ]
          : []),
      ],
    },
  ];

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "notificationsState"],
    queryFn: () => fetchAll(() => notificationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ КОЛИЧЕСТВО НОВЫХ УВЕДОМЛЕНИЙ

export const totalCountNewNotificationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);

  const userId = user.res.id;

  return totalCountNewNotificationsQuery(userId);
});

export const totalCountNewNotificationsState = loadable(totalCountNewNotificationsAtom);

const totalCountNewNotificationsQuery = (userId: string) => {
  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "recipient.id", operator: "=", value: userId },
        { property: "isNewNotification", operator: "=", value: true },
      ],
    },
  ];

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: ["totalCountNewNotificationsState"],
    queryFn: () => fetchTotalCount(() => notificationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ТИПЫ

type PropsType = EntityRequestBaseType & {
  userId: string;
};
