import styled from "@emotion/styled";
import { borders, colors, flex, grid, margins, size, text } from "../../../../styles";

const { white, grayscaleAsh, grayscaleLabelOpacity77 } = colors;

export const Container = styled.div<{ isVertical: boolean }>`
  ${({ isVertical }) =>
    grid({ gap: 16, columns: isVertical ? "1fr" : "minmax(652px, 652fr) 604fr" })};
  ${borders({ radius: 16 })};
  ${margins({ p: "24" })};

  background: ${white};
`;

export const RoomTitleAndVip = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${size({ w: "100%" })};
  ${text({ size: 24, height: 34, weight: 800, color: grayscaleAsh })};
`;

export const RoomTitle = styled.div`
  ${flex({ gap: 8, vertical: "center" })};
`;

export const Data = styled.div<{ isVertical: boolean; fullHeight?: boolean }>`
  ${flex({ gap: 8, isColumn: true })};
  ${({ isVertical, fullHeight }) => size({ max: { h: isVertical || fullHeight ? "auto" : 525 } })};
  ${margins({ p: "24" })};

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    ${size({ w: 10 })};
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};

    background: ${grayscaleLabelOpacity77};
  }
`;

export const TreatmentTypes = styled.div`
  ${flex({ gap: 8 })};
`;
