import { AxiosResponse } from "axios";
import { hrApi, hrServicesApi } from "./api";
import {
  BigOtherType,
  MediumOtherType,
  OtherWithIconType,
  RegionType,
  SanatoriumAspectType,
  TreatmentMethodType,
} from "./dictionaries";
import { EmployeeType } from "./employees";
import { PhotosType } from "./photos";
import { entitiesBase, EntitiesResponseType } from "./request";
import { RoomsServiceRequestType, RoomType } from "./rooms";
import { BackendDateType, IdType, LogsType, PaginationType, PeriodType } from "./types";

// ------------------------------ САНАТОРИИ

export const sanatoriumsApi = <S>() =>
  entitiesBase<SanatoriumType, S, SanatoriumsRequestType>(hrApi, "resort_Sanatorium");

export const sanatoriumsFilterServiceApi = {
  getAll: async (
    props: SanatoriumsFilterServiceRequestType
  ): Promise<AxiosResponse<EntitiesResponseType<SanatoriumType>>> => {
    const res = hrServicesApi.post("/sanatoriumsFilterService/filter", props);
    const data = (await res).data as { sanatoriums: SanatoriumsType; size: number };
    const items = data.sanatoriums;
    const totalCount = data.size;

    return { ...res, data: { items, totalCount } };
  },

  getAllForAlternative: async (
    props: SanatoriumsAlternativeFilterServiceRequestType
  ): Promise<AxiosResponse<EntitiesResponseType<SanatoriumType>>> => {
    const response = hrServicesApi.post("sanatoriumsFilterService/filterForAlternative", props);
    const data = (await response).data;
    const items = data.sanatoriums;
    const totalCount = data.size;
    return { ...response, data: { items, totalCount } };
  },
};

export const sanatoriumsServiceApi = {
  getAll: (props: SanatoriumsServiceRequestType): Promise<SanatoriumType[]> =>
    hrServicesApi.post("/sanatoriumService/getSanatoriums", props),
  get: async (id: string): Promise<SanatoriumWithDetailRatingType> => {
    const sanatoriumData = (await sanatoriumsApi().get(id)).data; // todo: избавиться от лишних запросов, как будет готов бэк - задача 29852
    const serviceSanatorium = (await hrServicesApi.post("/sanatoriumService/getSanatorium", { id }))
      .data;
    const { ratingGroups, ratingCount, ratingOverallOverridden } = serviceSanatorium;
    return { ...sanatoriumData, ratingGroups, ratingCount, ratingOverallOverridden };
  },
};

export const sanatoriumHistoryApi = {
  getAll: async (sanatoriumId: string): Promise<AxiosResponse<LogsType>> =>
    await hrServicesApi.post("/sanatoriumLogService/getLogs", {
      sanatoriumId,
    }),
};

// ------------------------------ ТИПЫ - САНАТОРИИ

export type SanatoriumType = IdType & {
  active: boolean;
  rooms: RoomType[];
  address: string;
  containsRehab: boolean;
  profiles: BigOtherType[];
  rating?: RatingType[];
  description: string;
  services: OtherWithIconType[];
  photos: PhotosType;
  overallRatingOverridden?: number;
  treatments: TreatmentMethodType[];
  name: string;
  region: RegionType;
  price?: number;
};

export type SanatoriumWithDetailRatingType = SanatoriumType & {
  ratingGroups?: RatingGroupType[];
  ratingCount: number;
  ratingOverallOverridden: boolean;
};

export type RatingGroupType = IdType & {
  // это id aspectGroup
  overriddenId: string; // это id самой сущность
  name: string;
  overridden: boolean;
  value: number;
};

export type RatingType = IdType & {
  sanatorium: SanatoriumType;
  employee: EmployeeType;
  overall: number;
  overallReason: string;
  effectiveness: MediumOtherType;
  effectivenessReason: string;
  aspects: SanatoriumAspectType[];
  improvements: MediumOtherType[];
  improvementOther: string;
  recommendation: number;
  recommendationReason: string;
};

// ----- передаваемые значения

export type SanatoriumHistoryRequestType = PaginationType & {
  sanatoriumId?: string;
};

export type SanatoriumsRequestType = PaginationType & {
  year?: number;
  mainDepartmentCodes?: string;
  status?: string;
  export?: string;
  disabilityId?: string;
  diseaseClassId?: string;
  startDateOfDiseasePeriod?: BackendDateType;
  endDateOfDiseasePeriod?: BackendDateType;
  order?: string;
  city?: string;
  startDate?: string;
  endDate?: string;
  heath?: string;
  profiles?: string;
  rating?: string;
};

export type SanatoriumsServiceRequestType = {
  sanatoriumServiceDto: {
    isActive?: boolean;
    offset?: number;
    limit?: number;
  };
};

export type SanatoriumsFilterServiceRequestType = {
  sanatoriumFilterDto: PeriodType & {
    page: {
      priceSort: string;
      itemsPerPage?: number;
      page?: number;
    };
    sanatoriums?: IdType[];
    profiles?: IdType[];
    regions?: IdType[];
    countries?: IdType[];
    rating?: number;
    isRehab?: string;
  };
};

export type SanatoriumsAlternativeFilterServiceRequestType = {
  sanatoriumFilterDto: SanatoriumsFilterServiceRequestType["sanatoriumFilterDto"] & {
    alternativeRoomFilterDtos: AlternativeRoomFilterDtoType[];
  };
};

export type AlternativeRoomFilterDtoType = Omit<
  RoomsServiceRequestType["roomFilterDto"],
  "sanatorium"
>;

// ----- получаемые значения

export type SanatoriumsType = SanatoriumType[];
