import { Badge } from "@digitalatom/ui";
import { useAtom } from "jotai";
import React, { FC } from "react";
import { RoomWithAccommodationType } from "../../../api";
import {
  absoluteInitialVacationers,
  otherInitialVacationers,
  selectedRoomsState,
  vacationersState,
} from "../../../atoms";
import { ButtonWithConfirm, CheckMark, HtmlContent, UniversalTextButton } from "../../../uiKit";
import { formatPrice } from "../../../utils/formatPrice";
import { getInclinedWord } from "../../../utils/getInclinedWord";
import { isNotNullOrUndefined } from "../../../utils/isNotNullOrUndefined";
import { Humans } from "../../Humans/Humans";
import { CardBase } from "../components/CardBase/CardBase";
import { TreatmentTypes } from "../components/CardBase/CardBase.styles";
import { SubBlock } from "../components/SubBlock/SubBlock";
import * as elements from "./RoomFullCardForList.styles";

export const RoomFullCardForList: FC<PropsType> = (props) => {
  const { room, hideRoomSelectedInfo, hidePrice } = props;

  const { Icon, Price, PriceAndButton, Hosted } = elements;

  const viewPrice =
    !hidePrice && isNotNullOrUndefined(room.discountPrice) && isNotNullOrUndefined(room.fullPrice);
  const viewSelectButton = !hideRoomSelectedInfo && room.accommodations && !room.isReadOnly;

  const { id, rooms, places, additional, photos, description, containsRehab } = room.room;

  const [selectedRooms, setSelectedRooms] = useAtom(selectedRoomsState);
  const [vacationers, setVacationers] = useAtom(vacationersState);

  const roomWords = ["комната", "комнаты", "комнат"];
  const placeWords = ["место", "места", "мест"];
  const additionalPlaceWords = [
    "дополнительное место",
    "дополнительные места",
    "дополнительных мест",
  ];

  const selected = !!selectedRooms.find(
    ({ roomWithAccommodation }) => roomWithAccommodation.room.id === id
  );

  // ----------------------------- ОБРАБОТЧИКИ ВЫБОРА ВЫБОРА НОМЕРА

  const selectRoomHandler = () => {
    setSelectedRooms([
      ...selectedRooms,
      { vacationers, roomWithAccommodation: room as RoomWithAccommodationType },
    ]);

    setVacationers(otherInitialVacationers);
  };

  const deselectRoomHandler = () => {
    const filteredSelectedRooms = selectedRooms.filter(
      ({ roomWithAccommodation }) => roomWithAccommodation.room.id !== id
    );
    setSelectedRooms(filteredSelectedRooms);

    filteredSelectedRooms.length === 0 && setVacationers(absoluteInitialVacationers);
  };

  const buttonOnClick = selected ? deselectRoomHandler : selectRoomHandler;

  // ----------------------------- КОМПОНЕНТЫ

  const selectButton =
    viewSelectButton &&
    (room.room.vip && !selected ? (
      <ButtonWithConfirm
        confirmData={{
          question: {
            text: "Данный номер для VIP \nклиентов",
            coloredButton: {
              onClick: selectRoomHandler,
              text: "Продолжить",
            },
            outlinedButton: {
              text: "Назад",
            },
          },
        }}
        textButtonData={{ text: "Выбрать номер", type: "primary" }}
        visible
      />
    ) : (
      <UniversalTextButton
        text={selected ? "Номер выбран" : "Выбрать номер"}
        onClick={buttonOnClick}
        iconStart={
          selected && (
            <Icon>
              <CheckMark />
            </Icon>
          )
        }
        type={selected ? "secondary" : "primary"}
      />
    ));

  const price = viewPrice && (
    <PriceAndButton>
      <Price>
        <div>
          <h4>За счёт работника</h4>
          <p>{formatPrice(room.discountPrice || 0)}</p>
        </div>

        <div>
          <h4>Общая стоимость</h4>
          <span>{formatPrice(room.fullPrice || 0)}</span>
        </div>
      </Price>
      {selectButton}
    </PriceAndButton>
  );

  return (
    <CardBase isVertical photos={photos}>
      {price}

      <SubBlock
        title="Вместительность"
        content={
          <Hosted>
            {`${rooms} ${getInclinedWord(rooms, roomWords)} ・ ${places} ${getInclinedWord(
              places,
              placeWords
            )} ・ ${additional} ${getInclinedWord(additional, additionalPlaceWords)}`}

            {viewPrice && <Humans room={room as RoomWithAccommodationType} />}
          </Hosted>
        }
      />
      <SubBlock
        title="Вид лечения"
        content={
          <TreatmentTypes>
            <Badge text="СКЛ" design="minor" type="accent" />
            {containsRehab && <Badge text="РОМ" design="minor" type="accent" />}
          </TreatmentTypes>
        }
      />
      <SubBlock title="Описание" content={<HtmlContent html={description} />} />
    </CardBase>
  );
};

type PropsType = {
  room: Partial<RoomWithAccommodationType> & Pick<RoomWithAccommodationType, "room">;
  hideRoomSelectedInfo?: boolean;
  hidePrice?: boolean;
};
