import React from "react";

export const ShieldNoPicture = () => (
  <svg
    width="249"
    height="240"
    viewBox="0 0 249 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="249" height="240" fill="#EFF0F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.5 85H164.5C167.261 85 169.5 87.2386 169.5 90V121.636L151.798 103.08C150.205 101.411 147.535 101.431 145.968 103.123L118.276 133.037C118.115 133.21 117.855 133.247 117.652 133.126L103.842 124.854C102.443 124.016 100.674 124.112 99.3734 125.096L79.5 140.133V90C79.5 87.2386 81.7386 85 84.5 85ZM69.5 90C69.5 81.7157 76.2157 75 84.5 75H164.5C172.784 75 179.5 81.7157 179.5 90V150C179.5 158.284 172.784 165 164.5 165H84.5C76.2157 165 69.5 158.284 69.5 150V90ZM109.5 105C109.5 110.523 105.023 115 99.5 115C93.9772 115 89.5 110.523 89.5 105C89.5 99.4772 93.9772 95 99.5 95C105.023 95 109.5 99.4772 109.5 105Z"
      fill="#D9DBE9"
    />
  </svg>
);
