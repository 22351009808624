import React, { FC } from "react";
import { TitleContainer, TitleContent, TitleSub } from "./RoomsSanatoriumCard.styles";

export const Title: FC<PropsType> = (props) => {
  const { title, subTitle, children, onClick } = props;

  return (
    <TitleContainer>
      <TitleSub onClick={onClick}>
        <p>{title}</p>
        {subTitle && <p>{subTitle}</p>}
      </TitleSub>
      <TitleContent>{children}</TitleContent>
    </TitleContainer>
  );
};

type PropsType = {
  title: string;
  subTitle?: string;
  onClick?: () => void;
};
