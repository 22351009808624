import styled from "@emotion/styled";
import { borders, button, colors, flex, margins, size, text } from "../../../styles";

const { transparent, grayscaleHoverBackground, grayscaleBeautifulBlack } = colors;

export const Container = styled.div<{ onlyIcon?: boolean }>`
  ${flex({ gap: 16, horizontal: "evenly", vertical: "center" })};
  ${({ onlyIcon }) => size({ w: onlyIcon ? 40 : 283 })};
  ${borders({ radius: 12 })};
  ${({ onlyIcon }) => margins({ m: onlyIcon ? "10 8" : "0", p: onlyIcon ? "0" : "10 8" })};

  position: relative;
  cursor: pointer;

  :hover {
    background: ${({ onlyIcon }) => (onlyIcon ? transparent : grayscaleHoverBackground)};
  }
`;

export const Notifications = styled.div`
  ${flex({ totalCenter: true, notResize: true })};
  ${size({ s: 40 })};

  position: relative;
`;

export const TotalCountWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
`;

export const ProfileMenuButtons = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8" })};
`;

export const ProfileMenuButton = styled.div`
  ${button};
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ h: 56, w: 328 })};
  ${borders({ radius: 8 })};
  ${margins({ p: "8" })};

  cursor: pointer;

  p {
    ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack })};
  }

  &:hover {
    background: ${grayscaleHoverBackground};
  }
`;
