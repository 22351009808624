// ------------------------------ ОБЩИЕ
import { fileSizeConversion } from "../utils/fileSizeConversion";

export const required = "Поле должно быть заполнено";
export const unavailableOption = "Необходимо выбрать из доступных вариантов";
export const emptyMessage = " ";

// ------------------------------ БУЛЕВЫ ЗНАЧЕНИЯ

export const requiredAgreementTerms = "Нужно согласиться со всеми условиями размещения";

// ------------------------------ СТРОКИ

export const long = "Слишком длинное значение";

export const email = "Введите корректный e-mail";
export const phone = "Введите корректный телефон";

export const intersectionOfExperience = "Стаж не должен пересекаться";

// ------------------------------ ЦИФРЫ

export const numbers = "Допустимы только цифры";
export const requiredAge = "Укажите возраст";
export const onlyInteger = "Только целые числа";
export const minValue = (number: number) => `Минимальное значение - ${number}`;
export const maxValue = (number: number) => `Максимальное значение - ${number}`;

// ------------------------------ ДАТЫ

export const thisDateIsToCorrect = "Введите корректную дату";
export const thisPeriodIsNotCorrect = "Начало периода должно быть не позже окончания";
export const thisPeriodIsBusy = "Период не должен пересекаться с другим периодом";
export const thisAgePeriodIsBusy =
  "Пересечение возрастных периодов в рамках одного набора параметров";

export const minPeriod = (minValue: `${number} ${string}`) =>
  `Период должен быть не менее ${minValue}`;
export const maxPeriod = (maxValue: `${number} ${string}`) =>
  `Период должен быть не более ${maxValue}`;

// ------------------------------ ИКОНКИ

export const missingIcon = "Иконка должна быть загружена";
export const falseAcceptIcon = "Допустимый формат - .svg";
export const falseSizeIcon = "Размер иконки не должен быть больше 20Кб";
export const falseWidthIcon = "Ширина иконки не должна быть больше 24px";
export const falseHeightIcon = "Высота иконки не должна быть больше 24px";
export const falseDownloadIcon = "При загрузке иконки произошла ошибка";

// ------------------------------ ФОТОГРАФИИ

export const missingPhoto = "Фотография должна быть загружена";
export const falseAcceptPhoto = (accept: string[]) =>
  `${accept.length === 1 ? "Допустимый формат" : "Допустимые форматы"} - ${accept.join(", ")}`;
export const falseSizePhoto = (maxSize: number) =>
  `Размер фотографии не должен быть больше ${fileSizeConversion(maxSize)}`;
export const falseCountPhotos = (maxCount: number) =>
  `Фотографий не должно быть больше ${maxCount} - перед сохранением удалите, пожалуйста, лишнее`;

// ------------------------------ ФАЙЛЫ

export const missingFile = "Файл должен быть загружен";
export const requiredSingleFile = "Необходимо загрузить один файл";
export const falseAcceptFile = (accept: string[]) =>
  `${accept.length === 1 ? "Допустимый формат" : "Допустимые форматы"} - ${accept.join(", ")}`;
export const falseSizeFile = (maxSize: number) =>
  `Размер файла не должен быть больше ${fileSizeConversion(maxSize)}`;
export const maxCountFiles = (maxCount: number) => `Файлов не должно превышать ${maxCount}`;
export const minCountFiles = (minCount: number) => `Минимально файлов ${minCount}`;

// ------------------------------ ФАЙЛЫ

export const missingDocument = "Необходимо загрузить документы";
export const requiredSingleDocument = "Необходимо выбрать хотя бы один документ";
export const noDocumentSelected = "Необходимо выбрать документы, которые хотите добавить";
// export const falseSizeDocument = (maxSize: number) =>
//   `Размер документа не должен превышать ${fileSizeConversion(maxSize)}`;
// todo: нужно? Кстати, нужно из схем валидации убрать сообщения. И Возможно их переработать с учётом того, что похожие уже есть, возможно их можно универсализировать
