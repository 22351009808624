import { Avatar } from "@digitalatom/ui";
import React, { FC } from "react";
import { Card, Name, NameAnrRoles, Roles } from "./ProfileCard.styles";

export const ProfileCard: FC<PropsType> = (props) => {
  const { initials, userIcon, fullName, role } = props;

  return (
    <Card>
      <Avatar photo={userIcon} initials={initials} own disabled />

      {!!fullName && (
        <NameAnrRoles>
          <Name>{fullName}</Name>

          <Roles>{role}</Roles>
        </NameAnrRoles>
      )}
    </Card>
  );
};

type PropsType = {
  initials: string;
  fullName: string;
  role?: string;
  userIcon?: string;
  isNotClickable?: boolean;
};
