import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import { lifecycleApi, PaginationType, ProcessTypeType } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../utils/useQueryKeys";
import { formatDate } from "../../utils/workingWithDates";
import { applicationsTypeState, ApplicationsTypeType, currentDateInterval } from "../index";

// ------------------------------ ФИЛЬТРЫ

export const tasksAbsoluteInitialValues: FiltersType = {
  status: "",
  sanatorium: "",
  since: null,
  until: null,
};

export const taskFiltersState = atom<FiltersType>(tasksAbsoluteInitialValues);

// ------------------------------ ЗАДАЧИ

const tasksAtom = atomWithQuery((get) => {
  const applicationsType = get(applicationsTypeState);
  const currentDate = get(currentDateInterval);
  const filters = get(taskFiltersState);
  const { startIndex, size, query } = getPaginationQueryKeys(get);

  const args = { applicationsType, currentDate, filters, query, startIndex, size };

  return tasksQuery(args);
});

export const tasksState = loadable(tasksAtom);

const tasksQuery = (props: TasksPropsType) => {
  const { applicationsType } = props;

  const { args, keys } = processTasksArgs(props);

  return {
    queryKey: [...keys, applicationsType, "applicationsState"],
    queryFn: () =>
      fetchAll(() =>
        applicationsType === "inbox"
          ? lifecycleApi.getMyGroupTasks(args)
          : applicationsType === "in-progress"
          ? lifecycleApi.getMyTasks(args)
          : lifecycleApi.getMyCompletedTasks(args)
      ),
    keepPreviousData: true,
  };
};

// ------------------------------ ЗАДАЧИ - ВХОДЯЩИЕ - ОБЩЕЕ КОЛИЧЕСТВО

const inboxApplicationsTotalCountAtom = atomWithQuery(() => {
  const args = { process: "APPLICATION" as ProcessTypeType, size: 1 };

  return {
    queryKey: ["inboxApplicationsTotalCountState"],
    queryFn: () => fetchTotalCount(() => lifecycleApi.getMyGroupTasks(args)),
    keepPreviousData: true,
  };
});

export const inboxApplicationsTotalCountState = loadable(inboxApplicationsTotalCountAtom);

// ------------------------------ ЗАДАЧИ - В РАБОТЕ - ОБЩЕЕ КОЛИЧЕСТВО

const applicationsInProgressTotalCountAtom = atomWithQuery(() => {
  const args = { process: "APPLICATION" as ProcessTypeType, size: 1 };

  return {
    queryKey: ["applicationsInProgressTotalCountState"],
    queryFn: () => fetchTotalCount(() => lifecycleApi.getMyTasks(args)),
    keepPreviousData: true,
  };
});

export const applicationsInProgressTotalCountState = loadable(applicationsInProgressTotalCountAtom);

// ------------------------------ АРГУМЕНТЫ и КЛЮЧИ

export const useTasksKeys = () => {
  const applicationsType = useAtomValue(applicationsTypeState);
  const currentDate = useAtomValue(currentDateInterval);
  const filters = useAtomValue(taskFiltersState);
  const { query, size, startIndex } = usePaginationQueryKeys();

  const args = { applicationsType, currentDate, filters, query, size, startIndex };

  return processTasksArgs(args).keys;
};

const processTasksArgs = (props: TasksPropsType) => {
  const { filters, currentDate, query, size, startIndex, applicationsType } = props;

  const process = "APPLICATION" as ProcessTypeType;

  const statusId = filters.status || undefined;
  const sanatoriumId = filters.sanatorium || undefined;
  const startDate = filters.since
    ? formatDate({ date: filters.since, type: "forBackend" })
    : undefined;
  const endDate = filters.until
    ? formatDate({ date: filters.until, type: "forBackend" })
    : undefined;
  const year = currentDate[0]?.getFullYear();
  const fullNameQuery = query;
  const from = startIndex;

  const args = {
    process,
    statusId,
    sanatoriumId,
    ...(!["inbox", "in-progress"].includes(applicationsType as string)
      ? { startDate, endDate }
      : {}),
    year,
    fullNameQuery,
    from,
    size,
  };

  const keys = [
    process,
    statusId,
    sanatoriumId,
    startDate,
    endDate,
    year,
    fullNameQuery,
    from,
    size,
  ];

  return { keys, args };
};

type TasksPropsType = PaginationType & {
  applicationsType: Omit<ApplicationsTypeType, "all" | "my">;
  currentDate: DateRange<Date>;
  filters: FiltersType;
  query?: string;
};

type FiltersType = {
  status: ProcessTypeType | "";
  sanatorium: "";
  since?: Date | null;
  until?: Date | null;
};
