import React from "react";
import { colors } from "../../styles";

export const Triangle = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3306 4.5L21.5268 13.5C23.4513 16.8333 21.0456 21 17.1966 21H6.80433C2.95533 21 0.549706 16.8333 2.47421 13.5L7.67036 4.5C9.59486 1.16667 14.4061 1.16667 16.3306 4.5ZM14.5986 5.5C13.4439 3.5 10.5571 3.5 9.40241 5.5L4.20626 14.5C3.05156 16.5 4.49493 19 6.80433 19H17.1966C19.506 19 20.9494 16.5 19.7947 14.5L14.5986 5.5Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
