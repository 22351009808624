import { useEffect, useRef } from "react";

export const timerForQueries = async (time?: number) =>
  new Promise((resolve) => setTimeout(resolve, time || 1500));

export const useCancelTimeout = () => {
  const refId = useRef<number>();

  const timer = async (time?: number) =>
    new Promise<void>((resolve) => {
      refId.current = setTimeout(resolve, time || 1500);
    });

  useEffect(() => () => clearTimeout(refId.current), []);

  return {
    timer,
  };
};
