import React, { FC } from "react";
import { JustBlock } from "../JustBlock";
import { Container } from "./TwoBlocks.styles";

export const TwoBlocks: FC<PropsType> = (props) => {
  const { leftBlockChildren, rightBlockChildren } = props;

  return (
    <Container>
      <JustBlock>{leftBlockChildren}</JustBlock>
      <JustBlock>{rightBlockChildren}</JustBlock>
    </Container>
  );
};

type PropsType = {
  leftBlockChildren: JSX.Element;
  rightBlockChildren: JSX.Element;
};
