import type { History, Transition } from "history";
import { useCallback, useContext, useEffect, useState } from "react";
import { UNSAFE_NavigationContext, useLocation, useNavigate } from "react-router-dom";
import { ClosePageConfirmType } from "../Page";
import { matchPath } from "./matchPath";

export const useCloseConfirmModal = (closePageConfirm?: ClosePageConfirmType) => {
  // ------------------------------ ХУКИ

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const navigationContext = useContext(UNSAFE_NavigationContext);
  const navigator = navigationContext.navigator as History;

  // ------------------------------ СТЕЙТЫ

  const [isOpen, setIsOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [isNavigationConfirmed, setNavigationConfirmed] = useState(false);

  // ------------------------------ ОПРЕДЕЛЕНИЕ НАДОБНОСТИ ОКНА ПОДТВЕРЖДЕНИЯ ВЫХОДА

  const needConfirm = !!closePageConfirm;

  // ------------------------------ Ф-Я ЗАКРЫТИЯ ОКНА ПОДТВЕРЖДЕНИЯ

  const handleClose = () => setIsOpen(false);

  // ------------------------------ Ф-ИИ БЛОКИРОВКИ НАВИГАЦИИ

  const blockNavigation = useCallback(
    (nextLocation: Transition) => {
      if (isNavigationConfirmed || nextLocation.location.pathname === pathname) return true;

      setIsOpen(true);
      setLastLocation(nextLocation);
      return false;
    },
    [isNavigationConfirmed, location]
  );

  // ------------------------------ Ф-Я ПОДТВЕРЖДЕНИЯ НАВИГАЦИИ

  const confirmNavigation = useCallback(() => {
    setIsOpen(false);
    setNavigationConfirmed(true);
    closePageConfirm?.onConfirm && closePageConfirm.onConfirm();
  }, []);

  // ------------------------------ ЭФФЕКТЫ

  useEffect(() => {
    return () => setLastLocation(null);
  }, []);

  useEffect(() => {
    if (!isNavigationConfirmed || !lastLocation) return;

    navigate(`${lastLocation.location.pathname}${lastLocation.location.search}`);
    setNavigationConfirmed(false);
  }, [isNavigationConfirmed, lastLocation]);

  useEffect(() => {
    if (!needConfirm) return;

    const unblock = navigator.block((tx: Transition) => {
      const pathException = closePageConfirm.pathException ?? [];
      const withoutConfirm =
        matchPath(tx.location.pathname, pathException) || tx.location.pathname === pathname;

      if (withoutConfirm) {
        unblock();
        tx.retry();
        return;
      }

      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blockNavigation(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blockNavigation, needConfirm]);

  return {
    confirmNavigation,
    isOpen,
    handleClose,
  };
};
