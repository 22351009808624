import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { BigOtherType, EntityRequestBaseType, treatmentMethodsApi } from "../../api";
import { fetchAll, fetchItems } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";

export const treatmentMethodsModalDataState = atom<BigOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const treatmentMethodsAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return treatmentMethodsQuery(args);
});

export const treatmentMethodsState = loadable(treatmentMethodsAtom);

const treatmentMethodsQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "treatmentMethodsState"],
    queryFn: () => fetchAll(() => treatmentMethodsApi().getAll(args)),
    // keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const treatmentMethodsAllState = atomWithQuery(() => ({
  queryKey: ["treatmentMethodsAllState"],
  queryFn: () => fetchItems(() => treatmentMethodsApi().getAll()),
}));
