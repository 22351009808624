import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { BigOtherType, EntityRequestBaseType, typicalMedicalProgramsApi } from "../../api";
import { fetchAll, fetchItems } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";

export const typicalMedicalProgramsModalDataState = atom<BigOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const typicalMedicalProgramsAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return typicalMedicalProgramsQuery(args);
});

export const typicalMedicalProgramsState = loadable(typicalMedicalProgramsAtom);

const typicalMedicalProgramsQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "typicalMedicalProgramsState"],
    queryFn: () => fetchAll(() => typicalMedicalProgramsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const typicalMedicalProgramsAllState = atomWithQuery(() => ({
  queryKey: ["typicalMedicalProgramsAllState"],
  queryFn: () => fetchItems(() => typicalMedicalProgramsApi().getAll()),
}));
