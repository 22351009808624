import { ColorsType } from "../api";

export const colors = {
  transparent: "transparent",
  black: "#000000",
  blackOpacity: "#00000015", // 15 - это 0.08 в RGBA
  white: "#FFFFFF",
  whiteOpacity: "#FFFFFFCC", // CC - это 0.8 в RGBA
  gray: "#EFEFEF",

  grayscaleLogo: "#5F6062",
  grayscaleChips: "#E3E9ED",
  grayscaleOffWhite: "#FCFCFC",
  grayscaleBackground: "#F7F7FC",
  grayscaleHoverBackground: "#F4F4F4",
  grayscaleDisabled: "#F2F2F2",
  grayscaleDarkDisabled: "#BABBC6",
  grayscaleInput: "#EFF0F6",
  grayscaleLine: "#D9DBE9",
  grayscaleIcons: "#A4B3C6",
  grayscaleDisabledIcons: "#DAE0E7",
  grayscalePlaceholder: "#A0A3BD",
  grayscaleLabel: "#6E7191",
  grayscaleLabelOpacityA0: "#6E7191A0", // A0 - это 0.63 в RGBA
  grayscaleLabelOpacity77: "#6E719177", // 77 - это 0.47 в RGBA
  grayscaleBody: "#4E4B66",
  grayscaleAsh: "#262338",
  grayscaleBeautifulBlack: "#14142B",
  grayscaleBeautifulBlackOpacityCC: "#14142BCC", // CC - это 0.8 в RGBA

  primaryBackground: "#F9FBFF",
  primaryBackgroundOpacity: "#F9FBFF80", // 80 - это 0.5 в RGBA
  primaryActiveElement: "#E0F2FF",
  primaryActiveElementOpacity: "#E0F2FFCC", // CC - это 0.8 в RGBA
  primaryLight: "#CEEAFF",
  primaryDefault: "#118EFF",
  primaryDefaultDark: "#1473F2",
  primaryDark: "#0000FF",

  blue: "#4392CE",
  lightBlue: "#E7F4FD",
  indigo: "#025EA1",
  purple: "#610BEF",

  redBackground: "#FAEBEB",
  redLight: "#F2CCCC",
  redDefault: "#E01010",
  redDark: "#B00000",

  greenBackground: "#EBFFE3",
  greenLight: "#A6F787",
  greenDefault: "#4BB34B",
  greenDark: "#067306",

  warningBackground: "#FFF4DB",
  warningLight: "#FFE6B0",
  warningDefault: "#EAAC30",
  warningDark: "#946300",

  yellowForPinkLogo: "#FCC30B",
  redForPinkLogo: "#FF3A21",
  pinkForPinkLogo: "#FF00FF",
  orangeForPinkLogo: "#FB7C59",
  crimsonForPinkLogo: "#FE3076",
  fuchsiaForPinkLogo: "#FF0CED",

  statesActive2: "#CAE0FF",

  violet: "#7433ff",
  pink: "#ffa3fd",
} as Record<string, ColorsType>;
