import styled from "@emotion/styled";
import { Autocomplete, AutocompleteProps, ChipTypeMap, Popper } from "@mui/material";
import { ElementType } from "react";
import * as styles from "../../styles";

const { borders, colors, margins, overflowOneLine } = styles;
const { overflowLines, shadow, text, flex, size } = styles;

const { grayscaleHoverBackground, grayscaleLabel, grayscaleLine, grayscalePlaceholder } = colors;
const { grayscaleBeautifulBlack, grayscaleDisabled, redBackground } = colors;

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
`;

export const Title = styled.h2`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 0 6 0" })};
`;

export const Item = styled(Autocomplete)<ItemPropsType>`
  ${size({ h: 56, w: "100%" })};
  ${borders({ radius: 16, none: true })};
  ${({ haslabel }) => margins({ p: haslabel ? "8 16" : "16" })};

  background: ${({ color, haserror, disabled }) =>
    haserror ? redBackground : disabled ? grayscaleDisabled : color};
  cursor: auto;

  .Mui-disabled {
    cursor: no-drop;
  }

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
  }

  .MuiOutlinedInput-root {
    ${({ haslabel }) => margins({ m: haslabel ? "16 0 0" : "0", p: "0 0 8" })};
  }

  .MuiButtonBase-root {
    ${size({ s: 40 })};

    position: absolute;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
    right: -8px;

    :hover {
      background: ${grayscaleHoverBackground};
      cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

      & > svg > path {
        fill: ${grayscaleLabel};
      }
    }
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-root {
    display: none;
  }

  input {
    ${margins({ p: "0", needImportant: true })};

    z-index: 1;
  }

  label {
    ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
    ${margins({ m: "0" })};

    position: absolute;
    left: 0;
    top: 0;
    transform: none;

    &.Mui-focused,
    &.Mui-disabled {
      color: ${grayscaleLabel};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & ItemPropsType
) => JSX.Element;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400 })};
`;
export const MenuDescriptionWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400, color: grayscalePlaceholder })};
  ${margins({ p: "0 0 0 8" })}
`;

export const PaperProps = (size: string) =>
  ({
    sx: {
      maxHeight: 264,
      width: size === "small" ? 372 : 472,
      maxWidth: "120%",
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "hidden",

      ml: -2,
      padding: 1,

      ul: {
        maxHeight: 247,
        width: "100%",
        overflowY: "auto",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: grayscaleHoverBackground, div: overflowLines(2) },
          "&:last-child": { marginBottom: 0 },
        },

        "::-webkit-scrollbar": {
          width: 8,
        },

        "::-webkit-scrollbar-thumb": {
          background: grayscaleLine,
          borderRadius: "99em",
        },
      },
    },
  } as const);

export const PopperComponent = styled(Popper)`
  ${margins({ p: "16 0 0" })};
`;

export const ResetButton = styled.span`
  ${flex({ vertical: "center" })};

  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 52%;
  transform: translateY(-50%);

  & > svg {
    ${size({ s: 24 })};

    &:hover path {
      fill: ${grayscaleLabel};
    }

    path {
      fill: ${grayscalePlaceholder};
    }
  }
`;

export const Description = styled.span`
  ${text({ color: colors.grayscaleLabel, size: 12, weight: 500, height: 16 })};
  ${margins({ p: "0 16" })};
`;

type ItemPropsType = {
  haslabel: number;
  color: string;
  haserror: number;
  disabled: boolean;
};
