import { AxiosResponse } from "axios";
import { queryClientAtom } from "jotai/query";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { useMutation } from "react-query";
import { IdType } from "../api";
import { createOrUpdateStatusState, deletionStatusState, selectedRowsState } from "../atoms";
import { OnDeleteReturnType } from "../uiKit";
import { timerForQueries } from "./timerForQueries";

// ------------------------------ СОЗДАНИЕ ДАННЫХ

export const useQuery = <VOBJ>() => {
  const queryClient = useAtomValue(queryClientAtom);

  const setCreateOrUpdateStatus = useUpdateAtom(createOrUpdateStatusState);
  const setDeletionStatus = useUpdateAtom(deletionStatusState);
  const setSelectedRows = useUpdateAtom(selectedRowsState);

  return {
    useCreate: (props: CreatePropsType<VOBJ>) => {
      const { api, queryKey, queryKeyForOneEntity } = props;

      return useMutation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        async (props: { data: VOBJ }) => api.create!(props),

        {
          onMutate: () => setCreateOrUpdateStatus(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);
            if (queryKeyForOneEntity)
              queryKeyForOneEntity.forEach((key) => queryClient.invalidateQueries(key));

            setCreateOrUpdateStatus(false);
          },
        }
      );
    },

    useUpdate: (props: UpdatePropsType<VOBJ>) => {
      const { api, queryKey, queryKeyForOneEntity } = props;

      return useMutation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any
        async (props: (IdType & { data: VOBJ }) | any) => api.update!(props),

        {
          onMutate: () => setCreateOrUpdateStatus(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);
            if (queryKeyForOneEntity)
              queryKeyForOneEntity.forEach((key) => queryClient.invalidateQueries(key));

            setCreateOrUpdateStatus(false);
          },
        }
      );
    },

    useDelete: (props: DeletePropsType<VOBJ>) => {
      const { api, queryKey } = props;

      return useMutation(
        async (ids: string[]) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const promises = ids.map(async (id) => api.delete!(id));
          return Promise.all(promises);
        },

        {
          onMutate: () => setDeletionStatus(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);

            setDeletionStatus(false);
            setSelectedRows([]);
          },
        }
      );
    },
  };
};

type CreatePropsType<VOBJ> = {
  api: { create?: (props: { data: VOBJ }) => Promise<AxiosResponse<IdType>> };
  queryKey: QueryKeyType;
  queryKeyForOneEntity?: QueriesKeyType;
};

type UpdatePropsType<VOBJ> = {
  api: { update?: (props: IdType & { data: VOBJ }) => Promise<AxiosResponse<VOBJ>> };
  queryKey: QueryKeyType;
  queryKeyForOneEntity?: QueriesKeyType;
};

type DeletePropsType<VOBJ> = {
  api: { delete?: (id: string) => OnDeleteReturnType<VOBJ> };
  queryKey: QueryKeyType;
};

export type ApiType<VOBJ> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAll?: (props?: any) => Promise<AxiosResponse>; // todo: типизировать any
  get?: (id: string) => Promise<AxiosResponse>;
  create?: CreatePropsType<VOBJ>["api"]["create"];
  update?: UpdatePropsType<VOBJ>["api"]["update"];
  delete?: DeletePropsType<VOBJ>["api"]["delete"];
};

export type QueryKeyType = (string | string[] | number | boolean | undefined | null)[];

export type QueriesKeyType = QueryKeyType[];
