import React, { FC } from "react";
import { setSanitizeHTML } from "../../../utils/setSanitizeHTML";
import { Content } from "./StyledTextInput.styles";

export const HtmlContent: FC<PropsType> = (props) => {
  const { html } = props;

  return <Content dangerouslySetInnerHTML={setSanitizeHTML(html)} />;
};

type PropsType = {
  html: string;
};
