import styled from "@emotion/styled";
import { borders, button, colors, flex, margins, size, text } from "../../../styles";

const { grayscaleHoverBackground, grayscaleLabel, grayscaleBeautifulBlack } = colors;

export const Container = styled.button<{ long: boolean; xLong: boolean }>`
  ${button};
  ${flex({ horizontal: "left" })};
  ${({ long, xLong }) => size({ h: 40, w: long ? 180 : xLong ? 328 : 135 })};
  ${borders({ radius: 8 })};

  p {
    ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack })};
    ${margins({ m: "0 0 0 8" })};
  }

  svg {
    ${margins({ m: "0 0 0 16" })};
  }

  &:hover {
    background: ${grayscaleHoverBackground};

    svg > path {
      fill: ${grayscaleLabel};
    }
  }
`;
