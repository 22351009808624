import { hrApi } from "./api";
import { MediumOtherType, SanatoriumAspectType } from "./dictionaries";
import { EmployeeType } from "./employees";
import { entitiesBase } from "./request";
import { SanatoriumType } from "./sanatoriums";
import { EntityRequestBaseType, IdType } from "./types";

// ------------------------------ РЕЙТИНГ

// todo: точно нужно? - задача 18150

export const feedbackApi = <R>() =>
  entitiesBase<FeedbackItemType, R, EntityRequestBaseType>(hrApi, "resort_Rating"); // todo: изменить эндпоинт - задача 18150

// ------------------------------ ТИПЫ - РЕЙТИНГ

export type FeedbackItemType = IdType & {
  sanatorium: SanatoriumType;
  employee: EmployeeType;
  overall: number;
  overallReason: string;
  effectiveness: MediumOtherType;
  effectivenessReason: string;
  aspects: SanatoriumAspectType[];
  improvements: MediumOtherType[];
  improvementOther: string;
  recommendation: number;
  recommendationReason: string;
};

// получаемые данные

export type FeedbackType = FeedbackItemType[];
