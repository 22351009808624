import React, { FC } from "react";
import { OtherWithIconType } from "../../../api";
import { ServiceIcon } from "../../ServiceIcon";
import { Features } from "../RoomsSanatoriumCard.styles";

export const ServicesView: FC<PropsType> = (props) => {
  const { services } = props;

  if (!services || !services.length) return null;

  return (
    <Features>
      {services.map(({ id, name, icon }) => (
        <ServiceIcon key={id} name={name} icon={icon} />
      ))}
    </Features>
  );
};

type PropsType = {
  services?: OtherWithIconType[];
};
