import React from "react";
import { colors } from "../../styles";

export const EmptyBox = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="46" viewBox="0 0 62 46" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7741 17.5375H49.7834V2.81546C49.2827 2.45242 48.6704 2.24414 48.0223 2.24414H13.978C13.5543 2.24414 13.146 2.33312 12.7741 2.49615V17.5375ZM49.9934 19.5375H12.5334L9.88374 26.4631H22.1744C24.1131 26.4631 25.6847 28.0348 25.6847 29.9735C25.6847 30.8076 26.3609 31.4838 27.1951 31.4838H35.514C36.3482 31.4838 37.0244 30.8076 37.0244 29.9735C37.0244 28.0348 38.596 26.4631 40.5347 26.4631H52.2014L49.9934 19.5375ZM58.7252 26.4631H54.3006L51.7834 18.5679V6.87581L58.7252 26.4631ZM7.74237 26.4631L10.7741 18.5387V5.30338L3.27502 26.4631H7.74237ZM2.74316 40.7565V28.4631H22.1744C23.0086 28.4631 23.6847 29.1393 23.6847 29.9735C23.6847 31.9122 25.2564 33.4838 27.1951 33.4838H35.514C37.4528 33.4838 39.0244 31.9122 39.0244 29.9735C39.0244 29.1393 39.7006 28.4631 40.5347 28.4631H59.2571V40.7565C59.2571 42.4134 57.914 43.7565 56.2571 43.7565H5.74316C4.08631 43.7565 2.74316 42.4134 2.74316 40.7565ZM9.26517 3.57391C9.97252 1.57802 11.8604 0.244141 13.978 0.244141H48.0223C50.1398 0.244141 52.0277 1.57802 52.7351 3.57391L61.1997 27.4578L61.2571 27.6199V27.7919V40.7565C61.2571 43.518 59.0185 45.7565 56.2571 45.7565H5.74316C2.98174 45.7565 0.743164 43.518 0.743164 40.7565V27.7919V27.6199L0.800607 27.4578L9.26517 3.57391Z"
      fill={colors.grayscaleLine}
    />
  </svg>
);
