import { css } from "@emotion/react";

export const mediaSizes = (props: MediaSizesProps) => {
  const { size, field, values } = props;

  const sizesValues = { ...sizes, ...size };

  let medias = "";

  const keySize = Object.keys(sizesValues) as (keyof typeof sizesValues)[];

  for (let i = 0; i < keySize.length; i++) {
    if (values[i]) {
      medias += `@media screen and (min-width: ${sizesValues[keySize[i]]}px) { ${field}: ${
        values[i]
      }; }`;
    }
  }

  return css`
    ${medias};
  `;
};

type MediaSizesProps = {
  size?: {
    [Key in keyof typeof sizes]?: number | undefined;
  };
  field: string;
  values: string[];
};

const sizes = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
