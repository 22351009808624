import React, { FC } from "react";
import { Download } from "../../Icons";
import { Container } from "./ExportBlankButton.styles";

export const ExportBlankButton: FC<PropsType> = (props) => {
  const { typeButton, onClick } = props;

  return (
    <Container typeButton={typeButton} onClick={onClick}>
      <Download />
    </Container>
  );
};

type PropsType = {
  typeButton: "primary" | "secondary";
  onClick: () => void;
};
