import React from "react";
import { colors } from "../../styles";

export const BigCrossInCircle = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 30C6.25 16.8832 16.8832 6.25 30 6.25C43.1167 6.25 53.75 16.8832 53.75 30C53.75 43.1167 43.1167 53.75 30 53.75C16.8832 53.75 6.25 43.1167 6.25 30ZM21.1623 38.8387C20.186 37.8625 20.186 36.2795 21.1623 35.3032L26.4655 30.0002L21.1619 24.6967C20.1856 23.7204 20.1856 22.1375 21.1619 21.1612C22.1382 20.1849 23.7211 20.1849 24.6974 21.1612L30.001 26.4648L35.3045 21.1612C36.2808 20.1849 37.8638 20.1849 38.84 21.1612C39.8162 22.1375 39.8162 23.7204 38.84 24.6967L33.5365 30.0002L38.8395 35.3032C39.816 36.2795 39.816 37.8625 38.8395 38.8387C37.8633 39.8153 36.2803 39.8153 35.304 38.8387L30.001 33.5357L24.6978 38.8387C23.7215 39.8153 22.1386 39.8153 21.1623 38.8387Z"
      fill={colors.redDefault}
    />
  </svg>
);
