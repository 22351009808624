import { CircularProgress } from "@mui/material";
import { useAtom } from "jotai";
import React, { ChangeEvent, DragEvent, RefObject, useState } from "react";
import { useWatch } from "react-hook-form";
import { PhotosType } from "../../api";
import { statusPhotosInputState } from "../../atoms";
import { validatePhotos } from "../../utils/validatePhotos";
import * as elements from "./PhotosInput.styles";

const { Title, Text, Description, Progress, Percent, LoadBarAndText, LoadBar } = elements;

export const usePhotosInput = ({ name, accept, maxSize, setValue, setError }: PropsType) => {
  // ------------------------------ АТОМЫ

  const [status, setStatus] = useAtom(statusPhotosInputState);

  // ------------------------------ СТЕЙТЫ

  const [progress, setProgress] = useState(0);
  const [timerSuccess, setTimerSuccess] = useState<ReturnType<typeof setTimeout>>();
  const [timerIdLoaded, setTimerIdLoaded] = useState<ReturnType<typeof setTimeout>>();
  const [timerProgress, setTimerProgress] = useState<ReturnType<typeof setInterval>>();

  // ------------------------------ РАБОТА С ФОРМОЙ

  const value = useWatch({ name }) as PhotosType;

  const onChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!files || !Array.from(files).length) return;

    addPhotos(files);
  };

  const handleRemove = (ref: RefObject<HTMLInputElement>) => {
    setProgress(0);

    clearTimeout(timerSuccess as ReturnType<typeof setTimeout>);
    clearTimeout(timerIdLoaded as ReturnType<typeof setTimeout>);
    clearInterval(timerProgress as ReturnType<typeof setInterval>);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const input = ref.current!;
    input.value = "";
    setValue([]);
    setStatus("empty");
  };

  const handleDrop = ({ dataTransfer: { files } }: DragEvent<Element>) => {
    if (!files || !Array.from(files).length) return;

    addPhotos(files);
  };

  // ------------------------------ ДОБАВЛЕНИЕ ИКОНКИ

  const addPhotos = async (photos: FileList) => {
    const photosObj = Array.from(photos).map((photo) => ({
      id: "",
      file: photo,
      name: photo.name,
      description: "",
      isMain: false,
    }));

    setStatus("progress");

    const promises = photosObj.map(
      async ({ file }) => await validatePhotos({ photo: file as File, accept, maxSize })
    );

    const photosInvalid = await Promise.all(promises);
    const errorMessage = photosInvalid.find(Boolean);

    if (errorMessage) {
      setStatus("error");
      setError(errorMessage);
      setTimerIdLoaded(setTimeout(() => setStatus("wasFile"), 3000));
      return;
    }
    setValue([...(value ? Array.from(value) : []), ...photosObj]);

    const intervalTimer = setInterval(() => {
      setProgress((prevState) => (prevState >= 100 ? 0 : prevState + 20));
    }, 200);

    setTimerProgress(intervalTimer);

    setTimerSuccess(setTimeout(() => setStatus("success"), 1000));
    setTimerIdLoaded(setTimeout(() => setStatus("wasFile"), 2000));

    setTimeout(() => {
      setProgress(0);
      clearInterval(intervalTimer as ReturnType<typeof setInterval>);
    }, 1000);
  };

  // ------------------------------ ОБОБЩЕННЫЙ ЭЛЕМЕНТ

  const element = ({ icon, needProgress, label, description }: ElementPropsType) => (
    <LoadBarAndText status={status}>
      {icon ? <LoadBar status={status}>{icon}</LoadBar> : null}

      {needProgress ? (
        <Progress>
          <CircularProgress variant="determinate" value={progress} />
          <Percent>{`${Math.round(progress)}%`}</Percent>
        </Progress>
      ) : null}

      {label || description ? (
        <Text status={status}>
          {label ? <Title status={status}>{label}</Title> : null}
          {description ? <Description status={status}>{description}</Description> : null}
        </Text>
      ) : null}
    </LoadBarAndText>
  );

  return {
    onChange,
    handleRemove,
    handleDrop,
    element,
  };
};

type PropsType = {
  name: string;
  accept: string[];
  maxSize: number;
  setValue: (data: PhotosType) => void;
  setError: (message: string) => void;
};

type ElementPropsType = {
  icon?: JSX.Element;
  buttonIcon?: JSX.Element;
  onClick?: () => void;
  needEmptiness?: boolean;
  needProgress?: boolean;
  label?: string;
  description?: string;
};
