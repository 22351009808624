import styled from "@emotion/styled";
import { borders, colors, margins } from "../../../styles";

const { grayscaleLine, primaryBackgroundOpacity } = colors;

export const Container = styled.div`
  ${borders({ side: "top", color: grayscaleLine })};
  ${margins({ p: "16 64" })};

  background: ${primaryBackgroundOpacity};
`;
