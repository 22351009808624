import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, OtherWithIconType, sanatoriumServicesApi } from "../../api";
import { fetchAll, fetchItems } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";

export const sanatoriumServicesModalDataState = atom<OtherWithIconType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const sanatoriumServicesAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return sanatoriumServicesQuery(args);
});

export const sanatoriumServicesState = loadable(sanatoriumServicesAtom);

const sanatoriumServicesQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "sanatoriumServicesState"],
    queryFn: () => fetchAll(() => sanatoriumServicesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const sanatoriumServicesAllState = atomWithQuery(() => ({
  queryKey: ["sanatoriumServicesAllState"],
  queryFn: () => fetchItems(() => sanatoriumServicesApi().getAll()),
}));
