import React from "react";
import { colors } from "../../styles";

export const Human = ({ type }: PropsType) => {
  const svgArgs = { fill: "none", xmlns: "http://www.w3.org/2000/svg" };

  const pathArgs = {
    fillRule: "evenodd" as const,
    clipRule: "evenodd" as const,
    fill: colors.grayscaleBody,
  };

  return type === "adult" ? (
    <svg width="14" height="32" viewBox="0 0 14 32" {...svgArgs}>
      <path
        {...pathArgs}
        d="M11.1066 5.21053C11.1066 7.53594 9.22148 9.42105 6.89607 9.42105C4.57066 9.42105 2.68555 7.53594 2.68555 5.21053C2.68555 2.88512 4.57066 1 6.89607 1C9.22148 1 11.1066 2.88512 11.1066 5.21053ZM8.30947 10.666C10.7684 10.666 12.7895 12.6534 12.7895 15.1123L12.8232 19.3565C12.8232 20.4344 12.1158 21.4113 11.0716 21.7818L10.5326 21.9502V29.7523C10.4989 30.4597 9.92632 30.9986 9.21895 30.9986H4.60421C3.89684 30.9986 3.32421 30.4597 3.29053 29.7523V21.9502L2.75158 21.7818C1.70737 21.445 1 20.4681 1 19.3565V15.1123C1.03368 12.6534 3.02105 10.666 5.48 10.666H8.30947Z"
      />
    </svg>
  ) : type === "child" ? (
    <svg width="11" height="24" viewBox="0 0 11 24" {...svgArgs}>
      <path
        {...pathArgs}
        d="M8.44036 4.06002C8.44036 5.7716 7.05285 7.15911 5.34127 7.15911C3.6297 7.15911 2.24219 5.7716 2.24219 4.06002C2.24219 2.34845 3.6297 0.960938 5.34127 0.960938C7.05285 0.960938 8.44036 2.34845 8.44036 4.06002ZM6.38002 8.07422C8.18988 8.07422 9.67744 9.53699 9.67744 11.3469L9.70224 14.4707C9.70224 15.2641 9.18159 15.9831 8.41302 16.2558L8.01633 16.3798V22.1224C7.99154 22.643 7.57006 23.0397 7.04942 23.0397H3.65282C3.13217 23.0397 2.7107 22.643 2.6859 22.1224V16.3798L2.28922 16.2558C1.52065 16.0079 1 15.2889 1 14.4707V11.3469C1.02479 9.53699 2.48756 8.07422 4.29743 8.07422H6.38002Z"
      />
    </svg>
  ) : (
    <svg width="11" height="24" viewBox="0 0 11 24" {...svgArgs}>
      <path
        {...pathArgs}
        d="M7.646 11.3574V11.3495C7.646 10.6704 7.07895 10.0887 6.36052 10.0887H4.28548C3.5953 10.0887 3.01547 10.646 3 11.3662V14.3689L3.97807 14.6745C4.39554 14.805 4.67979 15.1916 4.67979 15.629V21H5.99091V15.629C5.99091 15.1916 6.27516 14.805 6.69264 14.6745L7.6699 14.3691L7.646 11.3574ZM8.38616 16.2407L7.99091 16.3642V22.086C7.96621 22.6048 7.54626 23 7.0275 23H3.64321C3.12445 23 2.7045 22.6048 2.67979 22.086V16.3642L2.28455 16.2407C1.51876 15.9936 1 15.2773 1 14.4621V11.3495C1.01706 10.1038 1.7178 9.02318 2.74393 8.47396C3.20328 8.2281 3.72785 8.08873 4.28548 8.08873H6.36052C6.91753 8.08873 7.44391 8.22779 7.90542 8.47314C8.93805 9.0221 9.646 10.1032 9.646 11.3495L9.67071 14.4621C9.67071 15.2526 9.15195 15.9689 8.38616 16.2407ZM7.55339 6.22436C8.08511 5.66966 8.41184 4.91692 8.41184 4.08786C8.41184 2.38248 7.02936 1 5.32398 1C3.6186 1 2.23612 2.38248 2.23612 4.08786C2.23612 4.91656 2.56257 5.66901 3.09388 6.22363C3.42236 6.56652 3.82913 6.8338 4.28548 6.99674C4.61002 7.11261 4.95963 7.17572 5.32398 7.17572C5.6876 7.17572 6.03653 7.11287 6.36052 6.99744C6.81735 6.83468 7.22457 6.56739 7.55339 6.22436ZM5.32398 5.17572C5.92479 5.17572 6.41184 4.68867 6.41184 4.08786C6.41184 3.48705 5.92479 3 5.32398 3C4.72317 3 4.23612 3.48705 4.23612 4.08786C4.23612 4.68867 4.72317 5.17572 5.32398 5.17572Z"
      />
    </svg>
  );
};

type PropsType = {
  type: HumanTypeType;
};

export type HumanTypeType = "adult" | "child" | "childWithoutPlace";
