import { DatePicker } from "@mui/lab";
import React, { FC, useState } from "react";
import { startAndEndMethods } from "../../utils/workingWithDates";
import { DateIntervalAtomType } from "../Header/HeaderNavigation/HeaderNavigation";
import { DateSwitcher, DateSwitcherText, PaperProps } from "./DateSwitcher.styles";
import { useDateSwitcher } from "./useDateSwitcher";

export const MonthsSwitcher: FC<PropsType> = (props) => {
  const methods = useDateSwitcher(props);
  const { setDateInterval, startDate, minDate, maxDate, text, arrow } = methods;

  const [open, setOpen] = useState(false);

  return (
    <>
      {arrow("left").month}

      <DatePicker
        views={["month", "year"]}
        value={startDate}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(newDate) =>
          setDateInterval([
            startAndEndMethods.start.year(newDate as Date),
            startAndEndMethods.end.year(newDate as Date),
          ])
        }
        renderInput={(inputProps) => (
          <DateSwitcher onClick={() => setOpen(true)} ref={inputProps.inputRef}>
            <DateSwitcherText>{text({ inputProps }).month}</DateSwitcherText>
          </DateSwitcher>
        )}
        minDate={minDate}
        maxDate={maxDate}
        PaperProps={PaperProps}
      />

      {arrow("right").month}
    </>
  );
};

type PropsType = {
  dateIntervalAtom: DateIntervalAtomType;
};
