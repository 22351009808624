import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { currentUserState, organizationState } from "../atoms";
import { WarningPage } from "../pages/WarningPage";
import "../styles/index.css";
import { Loader } from "../uiKit";
import { getErrorMessage } from "../utils/getErrorMessage";
import { routesAndElements } from "./routesAndElements";

const Icons = lazy(() => import("../uiKit/Icons/Icons"));
const Buttons = lazy(() => import("../uiKit/Buttons/Buttons"));
const Inputs = lazy(() => import("../uiKit/Inputs/Inputs"));
const ExampleConfirmModal = lazy(() => import("../uiKit/ConfirmModal/ExampleConfirmModal"));

export const AppRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const clearPathname = pathname.substring(0, pathname.indexOf("&"));

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const currentUser = useAtomValue(currentUserState);
  const setOrganization = useUpdateAtom(organizationState);

  const user = currentUser.state === "hasData" && currentUser.data.res;
  const roleModel = currentUser.state === "hasData" ? currentUser.data.roleModel : undefined;
  const error = currentUser.state === "hasError" && currentUser.error;
  const loading = currentUser.state === "loading";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  const hash = window.location.hash;

  useEffect(() => {
    if (!hash) {
      setTimeout(() => navigate("/"), 10);
    } else if (clearPathname) setTimeout(() => navigate(clearPathname), 10);
  }, [hash, clearPathname]);

  useEffect(() => {
    setOrganization(user ? user.organization : "");
  }, [currentUser]);

  !!error &&
    getErrorMessage(error)?.includes("The user is missing the main_department attribute") && (
      <WarningPage code="not_main_department" />
    );

  if (!user || !roleModel) return loading ? <Loader /> : <WarningPage code="not_auth" />;

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routesAndElements(roleModel).map(({ path, element }, i) => (
          <Route key={i} path={path} element={element} />
        ))}

        {/* ------------------------------ СТИЛИ ------------------------------ */}
        {/* todo: временный раздел - убрать, когда будет готова библиотека */}

        {roleModel.superUser && (
          <>
            <Route path="/styles/icons" element={<Icons />} />
            <Route path="/styles/buttons" element={<Buttons />} />
            <Route path="/styles/inputs" element={<Inputs />} />
            <Route path="/styles/confirm-modal" element={<ExampleConfirmModal />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};
