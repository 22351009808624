import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { LittleOtherType, aspectGroupsApi } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const aspectGroupsModalDataState = atom<LittleOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const aspectGroupsAtom = atomWithQuery(() => ({
  queryKey: "aspectGroupsState",
  queryFn: () => fetchItems(() => aspectGroupsApi().getAll()),
}));

export const aspectGroupsState = loadable(aspectGroupsAtom);
