import React, { FC } from "react";
import { CrossInCircle } from "../../Icons";
import { Container } from "./RemoveSearchButton.styles";

export const RemoveSearchButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <CrossInCircle />
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
};
