import { AutocompleteRenderInputParams } from "@mui/material";
import React, { FC, SyntheticEvent } from "react";
import { OptionType } from "../../utils/getOptions";
import { OpenListButton, TrashButton } from "../Buttons";
import { Cross } from "../Icons";
import * as elements from "./SelectAutocompleteMultilineInput.styles";
import { CategoryOptionType } from "./useSelectAutocompleteMultilineInput";

export const SelectedItem: FC<PropsType> = (props) => {
  const { id, result, inputMethods } = props;

  const { markOptions, handleOpenSelectedItem, isOpen, handleDelete } = inputMethods;

  const { Item, Label, Button, ButtonContainer, Header, Description } = elements;

  const selectedOption = markOptions.find(({ option }) => id === option.id) ?? undefined;

  return (
    <Item result={result}>
      {selectedOption && (
        <>
          <Header>
            <Label result={result}>{selectedOption.option.name}</Label>

            {result === "list" ? (
              <TrashButton onClick={() => handleDelete(id)} />
            ) : (
              <Button onClick={() => handleDelete(id)}>
                <div />
                <Cross />
              </Button>
            )}

            {selectedOption.option.description ? (
              <ButtonContainer>
                <OpenListButton isOpen={isOpen(id)} onClick={() => handleOpenSelectedItem(id)} />
              </ButtonContainer>
            ) : null}
          </Header>

          {isOpen(id) && <Description>{selectedOption.option.description}</Description>}
        </>
      )}
    </Item>
  );
};

type PropsType = {
  id: string;
  result: "list" | "chips";
  inputMethods: {
    autocompleteValue: OptionsType;
    renderInput: (params: AutocompleteRenderInputParams) => JSX.Element;
    markOptions: OptionsType;
    handleChange: (
      _evt: SyntheticEvent<Element, Event>,
      arg: (string | CategoryOptionType)[] | null
    ) => void;
    handleOpenSelectedItem: (id: string) => void;
    isOpen: (id: string) => boolean;
    handleDelete: (id: string) => void;
  };
};

type OptionsType = {
  category: string;
  option: OptionType;
}[];
