import styled from "@emotion/styled";
import { colors, flex, size, text } from "../../../styles";

const { grayscaleAsh, indigo } = colors;

export const Container = styled.div`
  ${flex({ gap: 8, vertical: "center" })};

  cursor: pointer;
`;

export const Icon = styled.div`
  ${flex({ horizontal: "center" })};
  ${size({ w: 40 })};

  svg {
    :hover {
      transition: transform 1.5s cubic-bezier(0, 0.95, 0.83, -0.52);
      transform: rotate(360deg);
      transform-origin: 43% center;
    }
  }
`;

export const LogoSeparator = styled.div`
  ${size({ h: "100%", w: 1 })};

  background: ${grayscaleAsh};
`;

export const Text = styled.div`
  ${flex({ gap: 2, isColumn: true, vertical: "center" })};

  letter-spacing: 0.1em;
`;

export const TextMedTech = styled.p`
  ${text({ size: 13, height: 16, weight: 700, color: grayscaleAsh, uppercase: true })};
`;

export const TextRosatom = styled.p`
  ${text({ size: 10, height: 13, weight: 400, color: indigo, uppercase: true })};
`;
