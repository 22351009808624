import React, { FC, MouseEvent, useState } from "react";
import { Pagination, SearchButton, SearchInput } from "../../uiKit";
import { NotificationCard } from "./NotificationCard/NotificationCard";
import * as elements from "./Notifications.styles";
import { useNotification } from "./useNotification";

export const Notifications: FC<PropsType> = (props) => {
  const { open, onClose, closeProfileMenu } = props;

  const { Overlay, Container, Header, TitleAndButtons, Title } = elements;
  const { Buttons, NotificationList, NotificationListWrapper } = elements;

  const notification = useNotification();

  const { notifications, totalCount } = notification;

  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [notificationIsOpen, setNotificationIsOpen] = useState<string>("");

  return (
    <Overlay open={open} onClose={onClose}>
      <Container>
        <Header visibleSearch={visibleSearch}>
          <TitleAndButtons>
            <Title>Уведомления</Title>

            <Buttons>
              <SearchButton
                onClick={() => setVisibleSearch((prevState) => !prevState)}
                visibleSearch={false}
              />
            </Buttons>
          </TitleAndButtons>

          {visibleSearch && <SearchInput inModal />}
        </Header>

        <NotificationListWrapper hasPagination={totalCount >= 10}>
          <NotificationList>
            {notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
                notificationIsOpen={notificationIsOpen}
                setNotificationIsOpen={setNotificationIsOpen}
                onClose={onClose}
                closeProfileMenu={closeProfileMenu}
              />
            ))}
          </NotificationList>
        </NotificationListWrapper>

        {totalCount >= 50 ? <Pagination totalCount={totalCount} inModal /> : null}
      </Container>
    </Overlay>
  );
};

type PropsType = {
  open: boolean;
  onClose: () => void;
  needDownload?: {
    viewButton: boolean;
    element: JSX.Element;
  };
  closeProfileMenu: (evt: MouseEvent<HTMLElement>) => void;
};
