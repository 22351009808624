import { TestConfig, ValidationError } from "yup";
import { falseAcceptPhoto, falseSizePhoto, missingPhoto } from "../constants/forValidationSchemes";

export const validatePhotos = (props: PropsType) => {
  const { photo, accept, maxSize } = props;

  if (!photo) return missingPhoto;

  if (photo && accept.every((item) => !photo.name.toLowerCase().includes(item.toLowerCase())))
    return falseAcceptPhoto(accept);

  if (photo && photo.size > maxSize) return falseSizePhoto(maxSize);
};

export const validatePhotosObj = (props: Omit<PropsType, "photo">): TestConfig<File, unknown> => ({
  name: "validate-photos",
  message: (msg) => msg,
  test: async (value, { path }) => {
    const errorText = validatePhotos({ photo: value, ...props });
    return !errorText || new ValidationError(errorText, value, path);
  },
});

type PropsType = {
  photo: File;
  accept: string[];
  maxSize: number;
};
