import React from "react";
import { colors } from "../../styles";

export const BigCheck = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 6.25C16.8832 6.25 6.25 16.8832 6.25 30C6.25 43.1167 16.8832 53.75 30 53.75C43.1167 53.75 53.75 43.1167 53.75 30C53.75 16.8832 43.1167 6.25 30 6.25ZM37.409 22.659C38.2878 21.7803 39.7122 21.7803 40.591 22.659C41.4698 23.5377 41.4698 24.9623 40.591 25.841L29.091 37.341C28.2122 38.2198 26.7878 38.2198 25.909 37.341L19.409 30.841C18.5303 29.9622 18.5303 28.5377 19.409 27.659C20.2877 26.7802 21.7123 26.7802 22.591 27.659L27.5 32.568L37.409 22.659Z"
      fill={colors.greenDefault}
    />
  </svg>
);
