import React, { FC, MouseEvent, useState } from "react";
import { PopoverHover } from "../../uiKit";
import { Container } from "./PopoverHoverWrapper.styles";

export const PopoverHoverWrapper: FC<PropsType> = (props) => {
  const { text, children } = props;

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) => {
    setAnchorPopoverHoverElement(currentTarget);
  };

  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
  };

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = popoverHoverIsOpen ? "popover-hover" : undefined;

  return (
    <>
      <Container
        aria-owns={idPopoverHover}
        aria-haspopup="true"
        onMouseEnter={(evt) => openPopoverHover(evt)}
        onMouseLeave={closePopoverHover}
      >
        {children}
      </Container>

      <PopoverHover
        id={idPopoverHover}
        isOpen={popoverHoverIsOpen}
        element={anchorPopoverHoverElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {text}
      </PopoverHover>
    </>
  );
};

type PropsType = {
  text: string;
};
