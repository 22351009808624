import React, { FC, useState } from "react";
import { PhotosType } from "../../../../api";
import { CenteredModal } from "../../../../uiKit";
import { useQueryPhoto } from "../../../../utils/getFile";
import { Photo, PhotoCarousel } from "../../../Photo";
import * as elements from "../../SanatoriumCard/SanatoriumCard.styles";

export const PhotoBlock: FC<PropsType> = (props) => {
  const { photos } = props;

  const canOpenPhotos = !!photos.length;

  const [openDetailPhotoIndex, setOpenDetailPhotoIndex] = useState<number | null>(null);

  const { PhotoGallery, Photos, QuantityPhoto, QuantityPhotoDiffuser } = elements;

  const mainPhoto = photos.find(({ isMain }) => isMain) ?? photos[0];
  const allAdditionalPhotos = photos.filter(({ id }) => mainPhoto.id !== id);
  const additionalPhotos = allAdditionalPhotos.slice(0, 5);
  const seventhPhoto = allAdditionalPhotos[6];
  const seventhPhotoFile = seventhPhoto?.preview || seventhPhoto?.file;

  const uploadedPhoto = useQueryPhoto(
    typeof seventhPhotoFile === "string" ? seventhPhotoFile : undefined
  );
  const photo = typeof seventhPhotoFile === "string" ? uploadedPhoto : seventhPhotoFile;

  return (
    <div>
      <Photos canOpenPhotos={canOpenPhotos}>
        <Photo
          file={mainPhoto?.file}
          height={366}
          ultraWidth
          bgSize="contain"
          onClick={() => canOpenPhotos && setOpenDetailPhotoIndex(0)}
        />

        <PhotoGallery>
          {additionalPhotos.map(({ id, file, preview }, index) => (
            <Photo
              key={id}
              file={preview || file}
              height={100}
              onClick={() => setOpenDetailPhotoIndex(index + 1)}
            />
          ))}

          {photos.length > 6 && (
            <QuantityPhoto
              backgroundImage={photo ? URL.createObjectURL(photo) : undefined}
              onClick={() => setOpenDetailPhotoIndex(0)}
            >
              {photo && <QuantityPhotoDiffuser />}

              <p>
                {photos.length - 6}
                <br />
                фото
              </p>
            </QuantityPhoto>
          )}
        </PhotoGallery>
      </Photos>

      <CenteredModal
        title=""
        isOpen={openDetailPhotoIndex !== null}
        width="min"
        onClose={() => setOpenDetailPhotoIndex(null)}
      >
        <PhotoCarousel
          photos={[mainPhoto, ...allAdditionalPhotos]}
          startShowedPhotoIndex={openDetailPhotoIndex ?? 0}
        />
      </CenteredModal>
    </div>
  );
};

type PropsType = {
  photos: PhotosType;
};
