import React from "react";
import { colors } from "../../styles";

export const CheckCircleOutline = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12C19.5 16.1421 16.1421 19.5 12 19.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM14.9636 9.06359C15.3151 8.71212 15.8849 8.71212 16.2364 9.06359C16.5879 9.41506 16.5879 9.98491 16.2364 10.3364L11.6364 14.9364C11.2849 15.2879 10.7151 15.2879 10.3636 14.9364L7.7636 12.3364C7.41213 11.9849 7.41213 11.4151 7.7636 11.0636C8.11508 10.7121 8.68492 10.7121 9.0364 11.0636L11 13.0272L14.9636 9.06359Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
