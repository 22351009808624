import React from "react";
import { colors } from "../../styles";

export const CrossOnCircle = ({ isFilled = false }: PropsType) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {isFilled ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.500122 10C0.500122 4.75329 4.75341 0.5 10.0001 0.5C15.2468 0.5 19.5001 4.75329 19.5001 10C19.5001 15.2467 15.2468 19.5 10.0001 19.5C4.75341 19.5 0.500122 15.2467 0.500122 10ZM6.46504 13.5355C6.07452 13.145 6.07452 12.5118 6.46504 12.1213L8.58632 10.0001L6.46489 7.87867C6.07436 7.48815 6.07436 6.85499 6.46489 6.46446C6.85541 6.07394 7.48858 6.07394 7.8791 6.46446L10.0005 8.5859L12.1219 6.46447C12.5124 6.07395 13.1456 6.07395 13.5361 6.46447C13.9266 6.85499 13.9266 7.48816 13.5361 7.87868L11.4147 10.0001L13.5359 12.1213C13.9265 12.5118 13.9265 13.145 13.5359 13.5355C13.1454 13.9261 12.5122 13.9261 12.1217 13.5355L10.0005 11.4143L7.87925 13.5355C7.48873 13.9261 6.85556 13.9261 6.46504 13.5355Z"
        fill={colors.grayscaleLabel}
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10ZM10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5C15.2467 19.5 19.5 15.2467 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5ZM6.46492 13.5355C6.0744 13.145 6.0744 12.5118 6.46492 12.1213L8.5862 10.0001L6.46477 7.87867C6.07424 7.48815 6.07424 6.85499 6.46477 6.46446C6.85529 6.07394 7.48846 6.07394 7.87898 6.46446L10.0004 8.5859L12.1218 6.46447C12.5123 6.07395 13.1455 6.07395 13.536 6.46447C13.9265 6.85499 13.9265 7.48816 13.536 7.87868L11.4146 10.0001L13.5358 12.1213C13.9264 12.5118 13.9264 13.145 13.5358 13.5355C13.1453 13.9261 12.5121 13.9261 12.1216 13.5355L10.0004 11.4143L7.87913 13.5355C7.48861 13.9261 6.85544 13.9261 6.46492 13.5355Z"
        fill={colors.grayscaleLabel}
      />
    )}
  </svg>
);

type PropsType = {
  isFilled?: boolean;
};
