import { AxiosResponse } from "axios";
import { SetStateAction } from "jotai";
import { queryClientAtom } from "jotai/query";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { ReactNode, Suspense, useEffect } from "react";
import { Resolver } from "react-hook-form";
import { EntitiesType } from "../../api";
import { addFormState, paginationState } from "../../atoms";
import { WarningPage } from "../../pages/WarningPage";
import { pagesMoreOne } from "../../utils/needPagination";
import { ApiType, QueryKeyType } from "../../utils/useQuery";
import { ConfirmModal } from "../ConfirmModal";
import { ErrorBoundary } from "../ErrorBoundary";
import { Filters } from "../Filters";
import { Form } from "../Form";
import { Header, HeaderPagesSwitcher, RouterType } from "../Header";
import { Loader } from "../Loader";
import { Pagination } from "../Pagination";
import { GridsType, OBJType, Table, TitlesType } from "../Table";
import { Main, PageContainer } from "./Page.styles";
import { useCloseConfirmModal } from "./utils/useCloseConfirmModal";

export const Page = <DATUM extends { id?: string }, OBJ extends OBJType, FLDS, VOBJ, FLTRS>(
  props: PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const { children, methods, needPagination = false, needScroll = false } = props;

  const { totalCount, canView, navigation, routes, closePageConfirm } = methods;

  const setPagination = useUpdateAtom(paginationState);

  const addFormIsOpen = useAtomValue(addFormState);

  const closePageConfirmMethods = useCloseConfirmModal(closePageConfirm);
  const { confirmNavigation, isOpen, handleClose } = closePageConfirmMethods;

  const queryClient = useAtomValue(queryClientAtom);

  useEffect(() => {
    setPagination({ page: 0, quantity: 10 });

    return () => {
      queryClient.invalidateQueries(["totalCountNewNotificationsState"]);
    };
  }, []);

  const pagesSwitcher = routes ? <HeaderPagesSwitcher routers={routes} /> : null;

  return canView ? (
    <PageContainer>
      <Header navigation={navigation} pagesSwitcher={pagesSwitcher} />

      <Main
        className="main"
        needPagination={needPagination}
        needScroll={!!children && needScroll}
        id="pageContainer"
      >
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>{children ?? <Table {...props} />}</Suspense>
        </ErrorBoundary>
      </Main>

      {needPagination && pagesMoreOne(totalCount) && <Pagination totalCount={totalCount ?? 0} />}

      {isOpen && (
        <ConfirmModal
          question={{
            text: closePageConfirm?.confirmText ?? "Вы точно хотите уйти со страницы",
            description: closePageConfirm?.description,
            coloredButton: { onClick: confirmNavigation, text: "Продолжить" },
          }}
          isOpen
          onClose={handleClose}
        />
      )}

      <Filters methods={methods} />

      {addFormIsOpen && <Form methods={methods} />}
    </PageContainer>
  ) : (
    <WarningPage text="Нет данных" />
  );
};

export type PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = {
  children?: ReactNode;
  methods: MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>;
  needPagination?: boolean;
  isPageWithWideTable?: boolean;
  needScroll?: boolean;
  needRightBlock?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  panelHeight?: string;
  withoutEditForm?: boolean;
};

export type ClosePageConfirmType = {
  confirmText: string;
  description?: string;
  onConfirm?: () => void;
  pathException?: string[];
};

export type MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = {
  // ---------- страница (навигация)

  navigation: JSX.Element;
  routes?: RouterType[];
  closePageConfirm?: ClosePageConfirmType;

  // ---------- данные

  data?: DATUM[];
  loadableData?: Loadable<EntitiesType<DATUM>>;
  dataIds?: string[];
  totalCount?: number;

  // ---------- данные (константы)

  tableTitle?: string;
  addFormName?: string;
  addFormTitle?: string;
  editFormName?: string;
  editFormTitle?: string;
  questionText?: string | ((props: { entity?: string; count?: number }) => string);
  questionTextDescription?: string | ((props: { entity?: string; count?: number }) => string);
  successText?: string;
  queryKey: QueryKeyType;
  api: ApiType<VOBJ>;

  // ---------- данные (права)

  canView: boolean;
  canEdit?: boolean;

  // ---------- формы

  fields?: ((isEdit: boolean) => JSX.Element) | JSX.Element;
  initialValuesAddForm?: FLDS;
  initialValuesEditForm?: (arg: DATUM) => FLDS;
  validationSchema?: Resolver;
  valuesObject?: (values: FLDS, i?: number) => VOBJ;
  modalData?: DATUM | undefined;
  setModalData?: (datum: SetStateAction<DATUM | undefined>) => void;
  onSubmit?: (args: { values: FLDS; isEdit?: boolean; id?: string }) => Promise<void> | void;
  onDelete?: (id: string, data: DATUM) => OnDeleteReturnType<OBJ>;
  onEdit?: (id: DATUM) => void;

  // ---------- фильтры

  filtersFormName?: string;
  filtersFormTitle?: string;
  fieldsFilters?: JSX.Element | ((val: FLTRS) => JSX.Element);
  filters?: FLTRS;
  setFilters?: (arg: FLTRS) => void;
  absoluteInitialValues?: FLTRS;
  initialValuesFilters?: FLTRS;
  filtersValidationSchema?: Resolver;

  // ---------- таблицы

  titlesInit?: TitlesType;
  gridsInit?: GridsType;
  object?: (datum: DATUM, number?: number) => OBJ;
  additionalMenuButtons?: (
    datum: DATUM
  ) => { name: string; onClick: () => void; icon?: JSX.Element }[];
};

type Loadable<Value> =
  | { state: "loading" }
  | { state: "hasError"; error: unknown }
  | { state: "hasData"; data: ResolveType<Value> };

type ResolveType<T> = T extends Promise<infer V> ? V : T;

export type OnDeleteReturnType<OBJ> =
  | Promise<AxiosResponse<unknown, unknown>>
  | void
  | Promise<OBJ>
  | OBJ[];
