import map from "lodash/map";
import React, { FC } from "react";
import { EntitiesType, LittleOtherType, RatingGroupType } from "../../../api";
import { SliderNumber, SliderNumberInput } from "../../index";
import * as elements from "./SanatoriumRatingCard.styles";

export const SanatoriumRatingCard: FC<SanatoriumRatingCardType> = (props) => {
  const { aspectsValues, aspectGroups, editMode } = props;

  const { SanatoriumRatingContainer } = elements;

  const commonProps = { step: 0.1, min: 0, max: 10 };

  return (
    <SanatoriumRatingContainer>
      {map(aspectGroups, ({ name, id }, num) =>
        editMode ? (
          <SliderNumberInput
            key={name}
            name={`rating[${num}]`}
            defaultValue={aspectsValues?.find((val) => val.id === id)?.value || 0}
            title={name}
            {...commonProps}
          />
        ) : (
          <SliderNumber
            key={name}
            defaultValue={aspectsValues?.find((val) => val.id === id)?.value || 0}
            title={name}
            {...commonProps}
          />
        )
      )}
    </SanatoriumRatingContainer>
  );
};

export type SanatoriumRatingCardType = {
  aspectsValues?: RatingGroupType[];
  aspectGroups?: EntitiesType<LittleOtherType>;
  editMode?: boolean;
};
