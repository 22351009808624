import styled from "@emotion/styled";
import { button, buttonOnlyIcon, buttonTextDefault, colors, margins } from "../../../styles";

const { grayscaleIcons } = colors;

export const Container = styled.button`
  ${button};
  ${buttonOnlyIcon};
  ${buttonTextDefault};

  ${margins({ p: "0" })};

  & > svg > path {
    fill: ${grayscaleIcons};
  }
`;
