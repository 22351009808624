import styled from "@emotion/styled";
import { borders, colors, flex, grid, margins, size } from "../../styles";

const { white, grayscaleInput } = colors;

export const Container = styled.div<{ isExpand: boolean }>`
  ${grid({ rows: "72px 1fr 124px" })};
  ${({ isExpand }) =>
    size({ h: "100vh", w: isExpand ? 300 : 72, min: { w: isExpand ? 300 : 72 } })};
  ${borders({ side: "right", color: grayscaleInput })};

  transition: ${({ isExpand }) =>
    isExpand ? "min-width 300ms ease-in-out, width 300ms ease-in-out" : "none"};
  background: ${white};
`;

export const Header = styled.div`
  ${flex({ isColumn: true, vertical: "center" })};
  ${margins({ m: "0 16" })};
`;

export const MainWrapper = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Main = styled.div<{ isExpand: boolean }>`
  ${flex({ isColumn: true })};
  ${({ isExpand }) => size({ min: { h: "100%", w: isExpand ? 268 : 40 } })};
  ${margins({ m: "0 16" })};
`;

export const Footer = styled.div`
  ${flex({ isColumn: true })};
  ${margins({ m: "0 8" })};
`;

export const FooterSeparator = styled.div`
  ${size({ h: 1, w: "100%" })};

  background: ${grayscaleInput};
`;
