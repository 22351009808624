import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../styles";

const { white, grayscaleBeautifulBlack } = colors;

export const BlockWrapper = styled.div<{ lastInput: boolean }>`
  ${borders({ radius: 24 })};
  ${({ lastInput }) => margins({ p: lastInput ? "24 24 8 " : "24" })};

  background: ${white};
`;

export const Header = styled.div<{ needTop?: boolean }>`
  ${flex({ horizontal: "evenly" })};
  ${({ needTop }) => margins({ p: `${needTop ? 24 : 0} 0 24 0` })};
`;

export const NavigateHeader = styled.div`
  ${text({ size: 20, height: 26, weight: 800 })};
  ${margins({ p: "0 0 24" })};
`;

export const Title = styled.div`
  ${text({ size: 24, height: 34, weight: 400, color: grayscaleBeautifulBlack, align: "center" })};
`;

export const ChildrenWrapper = styled.div`
  ${margins({ p: "24 0 0" })};
`;

export const Footer = styled.div``;

export const FooterContent = styled.div`
  ${margins({ m: "24 0 0 0" })};

  & > button {
    ${size({ w: "max" })};
  }
`;
