import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { borders, colors, flex, grid, grid as gridStyles, margins, size } from "../../styles";

const { white, grayscaleInput } = colors;

const SkeletonElement = styled(Skeleton)`
  ${size({ w: "100%" })};
  ${borders({ radius: 16 })};

  background: ${white};
  transform: none;
  transform-origin: none;
  -webkit-transform: none;
`;

export const SkeletonForWideTable = styled(SkeletonElement)`
  ${size({ h: 15, w: 230 })};
  ${borders({ radius: 4 })};
  ${margins({ m: "0 auto 3 16" })};

  &:nth-of-type(2) {
    ${size({ h: 14, w: 100 })};
    ${margins({ m: "0 auto 1 16" })};
  }

  &:last-child {
    ${size({ w: 40 })};
    ${margins({ m: "0 auto 0 16" })};
  }
`;

export const SkeletonForWideTableStatus = styled(SkeletonElement)`
  ${size({ h: 34, w: 70 })};
  ${borders({ totalRadius: true })};
  ${margins({ m: "16 auto" })};
`;

export const SkeletonForWideTableDate = styled(SkeletonElement)`
  ${size({ h: 14, w: 90 })};
  ${borders({ radius: 4 })};
  ${margins({ m: "16 auto" })};
`;

export const SkeletonForWideTableCard = styled(SkeletonElement)`
  ${size({ h: 111, w: 588 })};
  ${borders({ radius: 12 })};
  ${margins({ m: "16" })};
`;

export const SkeletonForTable = styled(SkeletonElement)`
  ${size({ h: 24 })};
  ${borders({ radius: 4 })};
  ${margins({ m: "0 30% 0 16" })};
`;

export const SkeletonForPanel = styled(SkeletonElement)`
  ${size({ h: 120 })};
  ${margins({ m: "0 0 16" })};
`;

export const SkeletonForCharts = styled(SkeletonElement)`
  ${size({ h: "60%", min: { w: 983 }, max: { h: `calc(100vh - 84px)` } })};
  ${margins({ m: "16 0 0" })};
`;

export const SkeletonForCard = styled(SkeletonElement)`
  ${size({ h: 232, w: "100%", max: { h: `calc(100vh - 84px)` } })};
  ${margins({ m: "16 0 0" })};
`;

export const TableSimulation = styled.div<{ forWideTable?: boolean }>`
  ${({ forWideTable }) => grid({ gap: 1, rows: `repeat(5, ${forWideTable ? "140px" : "48px"})` })};
`;

export const Row = styled.div<{ grids: string }>`
  ${({ grids }) => gridStyles({ columns: grids })};
  ${borders({ side: "bottom", color: grayscaleInput })};

  &:last-child {
    ${borders({ none: true })};
  }
`;

export const Item = styled.div`
  ${flex({ isColumn: true, totalCenter: true })};
  ${borders({ side: "right", color: grayscaleInput })};

  &:last-child {
    ${borders({ none: true })};
  }
`;
