import { buttonOnlyIcon, overflowLines } from "@digitalatom/ui";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { DownloadStatusType } from "../../api";
import * as styles from "../../styles";

const { borders, button, colors, flex, margins, overflowOneLine, size, text, grid } = styles;

const { grayscaleInput, grayscaleBeautifulBlack, grayscaleLabel } = colors;
const { grayscaleBackground, primaryActiveElement, primaryDefault } = colors;
const { redDefault, greenDefault, primaryDefaultDark, grayscaleAsh } = colors;

export const Container = styled.div<{ status: DownloadStatusType; little?: boolean }>`
  ${({ status }) =>
    grid({
      gap: 16,
      columns: `${status === "empty" ? "88px" : "40px"} calc(100% - 40px * ${
        status === "empty" ? 4 : 3
      } - 16px * 2 - 2px * ${status === "empty" ? 2 : 1}) 88px`,
    })};
  ${({ little }) => size({ h: little ? 72 : 157, w: "100%" })};
  ${({ status }) =>
    borders({
      width: 2,
      ...(status === "empty" ? { style: "dashed" } : {}),
      color: status === "empty" ? primaryActiveElement : grayscaleInput,
      radius: 16,
    })};
  ${margins({ p: `${16 - 2}` })};

  align-items: center;
  position: relative;
  cursor: ${({ status }) => (status === "empty" ? "pointer" : "default")};

  :hover {
    ${({ status }) =>
      borders({
        width: 2,
        ...(status === "empty" ? { style: "dashed" } : {}),
        color: status === "empty" ? primaryDefault : grayscaleInput,
      })};
  }
`;

export const IconAndText = styled.div<{ status: DownloadStatusType }>`
  ${flex({ isColumn: true, horizontal: "center", vertical: "center" })};
  ${size({ w: "100%" })};
`;

export const LoadBar = styled.div<{ status: DownloadStatusType }>`
  ${flex({ isColumn: true, totalCenter: true })};
  ${size({ s: 40 })};
  ${({ status }) =>
    borders({
      ...(status === "empty" || status === "loaded"
        ? { none: true }
        : {
            width: 3,
            color:
              status === "error"
                ? redDefault
                : status === "success"
                ? greenDefault
                : grayscaleBackground,
          }),
      totalRadius: true,
    })};
  ${text({ size: 10, height: 13, weight: 900 })};
  ${margins({ m: "0" })};
`;

export const Progress = styled.div`
  ${size({ s: 40 })};
  ${margins({ m: "0" })};

  position: relative;

  .MuiCircularProgress-root {
    color: ${primaryDefault};
  }
`;

export const Percent = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
  ${borders({ width: 3, color: grayscaleBackground, totalRadius: true })};
  ${text({ size: 10, height: 10, weight: 900, color: grayscaleBeautifulBlack })};

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const Text = styled.div`
  ${flex({ isColumn: true })};
`;

export const Title = styled.h4<TitlePropsType>`
  ${({ status, fullWidth }) => {
    const notFile = status === "progress" || status === "error" || status === "success";
    const color = notFile ? grayscaleLabel : primaryDefault;

    return text({
      size: 15,
      height: 20,
      weight: 700,
      color: fullWidth ? grayscaleAsh : color,
      align: status === "empty" ? "center" : "start",
    });
  }};
  ${({ status }) => (status === "wasFile" ? overflowOneLine : overflowLines(2))};

  :hover {
    ${({ needDownload }) => needDownload && "cursor: pointer"};
  }
`;

export const UploadLink = styled.span`
  ${text({ size: 15, height: 20, weight: 700, color: primaryDefault, line: "bottom" })};

  cursor: pointer;

  :hover {
    color: ${primaryDefaultDark};
  }
`;

export const Description = styled.p<{ status: DownloadStatusType }>`
  ${({ status }) => {
    const color = status === "error" ? redDefault : grayscaleLabel;
    return text({ size: 13, height: 20, weight: 400, color: color });
  }};
`;

export const Icon = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
`;

export const Buttons = styled.div`
  ${flex({ gap: 2, vertical: "center" })};
  ${size({ w: `calc(40px * 2 + 2px)` })};
`;

export const Button = styled.button`
  ${button};
  ${buttonOnlyIcon};
`;

const expandWidth = keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: calc(100% - 40px * 2 - 14px * 2 - 16px * 2);
    opacity: 1;
  }
`;

export const TextInputWrapper = styled.div`
  ${size({ w: `calc(100% - 40px * 2 - 14px * 2 - 16px * 2)` })};

  position: absolute;
  left: calc(14px + 40px + 16px);
  animation: ${expandWidth} 0.3s ease-in-out forwards;
`;

export const Input = styled.input`
  display: none;
`;

export const FileBlocks = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${size({ w: "100%" })};
`;

export const FileBlock = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${size({ w: "100%" })};
`;

type TitlePropsType = {
  status: DownloadStatusType;
  needDownload?: boolean;
  fullWidth?: boolean;
};
