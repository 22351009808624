import styled from "@emotion/styled";
import { colors, flex, size, text } from "../../../styles";

const { black, grayscaleLabel, grayscalePlaceholder, primaryDefault } = colors;

export const PriceAndButton = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  white-space: pre-line;
`;

export const Price = styled.div`
  ${flex({ gap: 24 })};

  div {
    ${flex({ isColumn: true })};
  }

  h4 {
    ${text({ size: 13, height: 17, weight: 400, color: grayscaleLabel })};
  }

  p {
    ${text({ size: 32, height: 38, weight: 700, color: black })};
  }

  span {
    ${text({ size: 32, height: 38, weight: 700, color: grayscalePlaceholder, line: "center" })};
  }
`;

export const Icon = styled.div`
  ${size({ h: 20 })};

  svg > path {
    fill: ${primaryDefault};
  }
`;

export const Hosted = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })}
`;
