import map from "lodash/map";
import React, { FC, MouseEvent, useRef, useState } from "react";
import { ConfirmModal, NavigateButton } from "../..";
import { preventDefault } from "../../../utils/preventDefault";
import { ConfirmModalPropsType } from "../../ConfirmModal";

export const NavigateButtonWithConfirm: FC<PropsType> = (props) => {
  const { confirmData, navigateButtonData } = props;
  const { question, ...confirmDataWithoutConfirmData } = confirmData;

  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const spanChildrenRef = useRef<HTMLSpanElement | null>(null);

  const recursiveClickChildren = (el: HTMLElement | null) => {
    if (typeof el?.click === "function") el?.click();
    map(el?.children, recursiveClickChildren);
  };

  const wrapperOnclickHandler = (evt: MouseEvent<HTMLSpanElement>) => {
    if (!confirmIsOpen) {
      setConfirmIsOpen(true);
      preventDefault(evt);
    }
  };

  return (
    <>
      <span ref={spanChildrenRef} onClickCapture={wrapperOnclickHandler}>
        <NavigateButton
          link={navigateButtonData.link ?? ""}
          title={navigateButtonData.title ?? ""}
        />
      </span>

      {confirmIsOpen && (
        <ConfirmModal
          {...confirmDataWithoutConfirmData}
          question={
            question?.text
              ? {
                  text: question.text,
                  coloredButton: {
                    onClick: () => recursiveClickChildren(spanChildrenRef.current),
                    text:
                      question && "text" in question.coloredButton
                        ? question.coloredButton.text
                        : "",
                  },
                }
              : undefined
          }
          isOpen
          onClose={() => setConfirmIsOpen(false)}
        />
      )}
    </>
  );
};

type PropsType = {
  confirmData: Omit<ConfirmModalPropsType, "isOpen" | "onClose">;
  navigateButtonData: { link: string | -1; title: string };
};
