import React, { FC } from "react";
import { Container, NavigationButton } from "./HeaderPagesSwitcher.styles";
import { useHeaderPagesSwitcher } from "./useHeaderPagesSwitcher";

export const HeaderPagesSwitcher: FC<PropsType> = (props) => {
  const { routers } = props;

  const headerPagesSwitcherMethods = useHeaderPagesSwitcher();
  const { handleClickButton, popoverHoverIsOpen } = headerPagesSwitcherMethods;
  const { handleMouseEnter, closePopoverHover, hint } = headerPagesSwitcherMethods;

  return (
    <Container>
      {routers.map((router, i) => (
        <NavigationButton
          key={i}
          isActive={router.typeButton === router.typeTable}
          onClick={(evt) => handleClickButton(evt, router)}
          blocked={+!!router.blocked}
          aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(evt) => handleMouseEnter(evt, router)}
          onMouseLeave={closePopoverHover}
        >
          {router.text}
        </NavigationButton>
      ))}

      {hint}
    </Container>
  );
};

type PropsType = {
  routers: RouterType[];
  setVisibleSearch?: (arg: boolean) => void;
};

export type RouterType = {
  text: string | JSX.Element;
  blocked?: boolean;
  blockedText?: string;
  onClick?: (arg: string) => void;
  typeTable?: string | null;
  typeButton?: string;
};
