import React, { FC } from "react";
import { DateIntervalAtomType } from "../Header/HeaderNavigation/HeaderNavigation";
import { DateSwitcher, DateSwitcherText, QuartersPicker } from "./DateSwitcher.styles";
import { useDateSwitcher } from "./useDateSwitcher";

export const QuarterSwitcher: FC<PropsType> = (props) => {
  const methods = useDateSwitcher(props);
  const { text, arrow } = methods;

  return (
    <>
      {arrow("left").quarter}

      <QuartersPicker>
        <DateSwitcher>
          <DateSwitcherText>{text({}).quarter}</DateSwitcherText>
        </DateSwitcher>
      </QuartersPicker>

      {arrow("right").quarter}
    </>
  );
};

type PropsType = {
  dateIntervalAtom: DateIntervalAtomType;
};
