import styled from "@emotion/styled";
import { borders, colors, flex, margins, overflowOneLine, size, text } from "../../../../../styles";

const { grayscaleAsh, white, grayscalePlaceholder, redBackground, redDefault } = colors;

export const InputBlock = styled.div<{ hasError: boolean }>`
  ${flex({ gap: 8, horizontal: "left", vertical: "center" })};
  ${size({ h: 56 })};
  ${margins({ p: "0 16" })};

  grid-column: span 3;
  background: ${({ hasError }) => (hasError ? redBackground : white)};
  position: relative;

  ${borders({ radius: 16 })};
`;

export const InputText = styled.span<{ placeholder: string }>`
  ${({ placeholder }) =>
    text({
      size: 16,
      weight: 40,
      height: 23,
      color: placeholder ? grayscaleAsh : grayscalePlaceholder,
    })};
  ${overflowOneLine};
`;

export const ErrorText = styled.span`
  ${text({ size: 13, height: 19, weight: 400, color: redDefault, align: "center" })};
  ${margins({ m: "0 auto" })};

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
