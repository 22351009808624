import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { ContactsForProfileType, ContactTypeType, CurrentUserType, userApi } from "../api";
import { emptyObject, incorrectRes } from "../constants/errorsMessages";
import { rights, RoleModelType } from "../constants/rights";

// ------------------------------ ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

export const currentUserAtom = atomWithQuery(() => currentUserQuery());

export const currentUserQuery = () => ({
  queryKey: ["currentUser"],
  queryFn: fetchCurrentUser,
  keepPreviousData: true,
});

export const currentUserState = loadable(currentUserAtom);

const fetchCurrentUser = async (): Promise<UserType> => {
  const res = await userApi().get();

  if (!res) throw new Error(incorrectRes);
  if (Object.keys(res).length === 0) throw new Error(emptyObject);

  const containsRoles = (roles: string[]) => res?.roles.some((role) => roles?.includes(role));

  const roles = {
    // ------------------------------ СУПЕРПОЛЬЗОВАТЕЛЬ (разработчик)

    superUser: containsRoles(["system-full-access"]),

    // ------------------------------ РОЛИ

    operator: containsRoles(["resort-operator-role"]),
    sk: containsRoles(["resort-sk-role"]),
    ok: containsRoles(["resort-ok-role"]),
    osrCa: containsRoles(["resort-osr-ca-role"]),
    osr: containsRoles(["resort-osr-role"]),
    department: containsRoles(["resort-department-role"]),
    module: containsRoles(["resort-module-role"]),
    employee: containsRoles(["resort-employee-role"]),
  };

  const getContactIds = (type: ContactTypeType) =>
    res.contacts?.filter((contact) => contact.type === type);

  const formattedContacts = {
    EMAIL: getContactIds("EMAIL"),
    WORK_NUMBER: getContactIds("WORK_NUMBER"),
    PHONE_NUMBER: getContactIds("PHONE_NUMBER"),
  };

  const resWithFormattedContacts = { ...res, contacts: formattedContacts };

  return {
    res: resWithFormattedContacts,
    settings: res.settings ? JSON.parse(res.settings) : "",
    roleModel: { ...roles, ...rights(roles) },
  };
};

export type UserType = {
  res: Omit<CurrentUserType, "contacts"> & {
    contacts: Record<ContactTypeType, ContactsForProfileType>;
  };
  settings: SettingsType;
  roleModel: RoleModelType;
};

export type SettingsType = Record<
  TablesNameType,
  {
    tableSettings?: { name: string; visible: boolean }[];
    contactsSetting?: Record<ContactTypeType, string[]>;
  }
>;

type TablesNameType =
  | "employees"
  | "documents"
  | "countries"
  | "regions"
  | "treatmentProfiles"
  | "treatmentMethods"
  | "sanatoriumServices"
  | "workingConditionsClasses"
  | "occupationalFactors"
  | "typicalMedicalPrograms"
  | "sanatoriumAspects"
  | "aspectGroups"
  | "improveServices"
  | "patientOutcomes"
  | "statuses"
  | "documentTypes"
  | "whyNot"
  | "allApplications"
  | "meApplications"
  | "inProgressApplications"
  | "processedApplications"
  | "registries"
  | "calculationSettings"
  | "currentUser";

export type TypeSettingsType = keyof SettingsType;
