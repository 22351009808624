import styled from "@emotion/styled";
import { borders, colors, margins, size } from "../../styles";

const { white, grayscaleInput } = colors;

export const Container = styled.header`
  ${size({ w: "100%" })};
  ${borders({ side: "bottom", color: grayscaleInput })};
  ${margins({ m: "0", p: "16 0 0 0" })};

  position: relative;
  background: ${white};
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
`;
