import { useAtom } from "jotai";
import React, { useState } from "react";
import { FormProvider, useForm as useRhfForm } from "react-hook-form";
import { addFormState } from "../../atoms";
import { FormContainer } from "../../styles";
import { convertFileToBase64 } from "../../utils/convertFileToBase64";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { preventDefault } from "../../utils/preventDefault";
import { useQuery } from "../../utils/useQuery";
import { validateChanges } from "../../utils/validateChanges";
import { ErrorText } from "../ErrorBoundary/ErrorBoundary.styles";
import { MethodsType } from "../Page";
import { RightModal, RightModalActions, RightModalBody } from "../RightModal";
import { FormButtons } from "./FormButtons";

export const Form = <DATUM, OBJ, FLDS, VOBJ, FLTRS>(
  props: PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const { id, methods, isEdit = false, withoutModal = false } = props;

  const { addFormName, editFormName, editFormTitle, addFormTitle, validationSchema } = methods;
  const { initialValuesAddForm, initialValuesEditForm, fields, modalData, setModalData } = methods;
  const { api, queryKey, valuesObject, onSubmit } = methods;

  const [addFormIsOpen, setAddFormIsOpen] = useAtom(addFormState);

  const handleClose = () =>
    modalData && setModalData && !addFormIsOpen ? setModalData(undefined) : setAddFormIsOpen(false);

  const initialValuesObject =
    modalData && initialValuesEditForm ? initialValuesEditForm(modalData) : initialValuesAddForm;

  const rhfMethods = useRhfForm({
    defaultValues: initialValuesObject,
    resolver: validationSchema,
    mode: "all",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = rhfMethods;
  const { isValid, isDirty } = rhfMethods.formState;

  const [error, setError] = useState();

  const create = useQuery<VOBJ>().useCreate({ api, queryKey });
  const update = useQuery<VOBJ>().useUpdate({ api, queryKey });

  // useEffect(() => {
  //   setError(modalData && isEdit ? getErrorMessage(update.error) : getErrorMessage(create.error));
  // }, [create.error, update.error]);
  // ошибка стабильно определяется без useQuery?
  // можно isLoading определить без useQuery?

  const isLoading = modalData && isEdit ? update.isLoading : create.isLoading;

  if (
    !api ||
    !queryKey ||
    !fields ||
    !initialValuesObject ||
    !initialValuesEditForm ||
    !initialValuesAddForm ||
    !editFormTitle ||
    !addFormTitle ||
    !editFormName ||
    !addFormName
  )
    return null;

  const formName = modalData && isEdit ? editFormName : addFormName;

  const form = (
    <FormProvider {...rhfMethods}>
      <FormContainer
        id={formName}
        onSubmit={(evt) => {
          preventDefault(evt);

          handleSubmit(async (values) => {
            if (!validateChanges({ initialValues: initialValuesObject, values })) return;

            for (const key in values) {
              if (values[key] === "") {
                values[key] = null as unknown as FLDS[Extract<keyof FLDS, string>];
              }
            }

            if (
              "icon" in values &&
              (values as unknown as FLDS & { icon: { type: string } }).icon?.type ===
                "image/svg+xml"
            ) {
              const icon = await convertFileToBase64(values.icon);
              values = { ...values, icon };
            }

            try {
              onSubmit
                ? await onSubmit({ values: values as unknown as FLDS, isEdit, id })
                : valuesObject
                ? !modalData || !isEdit
                  ? await create.mutateAsync({ data: valuesObject(values as unknown as FLDS) })
                  : id
                  ? await update.mutateAsync({ data: valuesObject(values as unknown as FLDS), id })
                  : undefined
                : undefined;

              handleClose();
            } catch (err) {
              setError(getErrorMessage(err));
              console.error("err:", getErrorMessage(err));
            }
          })(evt);
        }}
      >
        <RightModalBody>
          {fields ? (typeof fields !== "function" ? fields : fields(isEdit)) : null}
        </RightModalBody>

        {!!error && <ErrorText>{error}</ErrorText>}

        <RightModalActions>
          <FormButtons
            formName={formName}
            onClose={handleClose}
            saveOrAdd={modalData && isEdit ? "save" : "add"}
            disabled={isLoading || (isDirty && !isValid)}
          />
        </RightModalActions>
      </FormContainer>
    </FormProvider>
  );

  return withoutModal ? (
    form
  ) : (
    <RightModal
      title={modalData && isEdit ? editFormTitle : addFormTitle}
      isOpen
      onClose={handleClose}
      needBlur
    >
      {form}
    </RightModal>
  );
};

type PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = {
  id?: string;
  methods: Pick<
    MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>,
    | "addFormName"
    | "editFormName"
    | "editFormTitle"
    | "addFormTitle"
    | "validationSchema"
    | "initialValuesAddForm"
    | "initialValuesEditForm"
    | "fields"
    | "modalData"
    | "setModalData"
    | "api"
    | "queryKey"
    | "onSubmit"
    | "valuesObject"
  >;
  isEdit?: boolean;
  withoutModal?: boolean;
};
