import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { LittleOtherType, countriesApi } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const countriesModalDataState = atom<LittleOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const countriesState = atomWithQuery(() => ({
  queryKey: "countriesState",
  queryFn: () => fetchItems(() => countriesApi().getAll()),
}));
