import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import { borders, colors, margins, size, text } from "../../styles";

const { white, grayscaleBeautifulBlackOpacityCC } = colors;

export const Container = styled(Popover)`
  pointer-events: none;

  .MuiPaper-root {
    ${size({ max: { w: 300 } })};
    ${borders({ radius: 8 })};

    background: ${grayscaleBeautifulBlackOpacityCC};
    box-shadow: none;
  }
` as typeof Popover;

export const Text = styled.div`
  ${text({ size: 16, height: 21, weight: 400, color: white })};
  ${margins({ m: "4 8" })};
`;
