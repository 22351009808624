import styled from "@emotion/styled";
import { colors, flex, margins, size, text } from "../../../styles";

const { grayscaleLine, grayscaleBody } = colors;

export const Container = styled.div`
  ${flex({ vertical: "center" })};
  ${size({ min: { w: 0 } })};
  ${margins({ p: "12 20" })};

  position: sticky;
  top: 64px;
  background: ${grayscaleLine};
  overflow: hidden;
  z-index: 2;

  h3 {
    ${text({ size: 15, height: 20, weight: 400, color: grayscaleBody })};
  }
`;
