import React from "react";
import { colors } from "../../styles";

export const BuildingAndTree = ({ isFilled = false }: PropsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    {isFilled ? (
      <>
        <path
          d="M11.3201 3H5.0901C3.1001 3 2.1001 4.01 2.1001 6.02V22H7.5001V18.25C7.5001 18.0511 7.57912 17.8603 7.71977 17.7197C7.86042 17.579 8.05119 17.5 8.2501 17.5C8.44901 17.5 8.63978 17.579 8.78043 17.7197C8.92108 17.8603 9.0001 18.0511 9.0001 18.25V22H14.3001V6.02C14.3001 4.01 13.3101 3 11.3201 3ZM10.7501 12.75H5.8001C5.3901 12.75 5.0501 12.41 5.0501 12C5.0501 11.59 5.3901 11.25 5.8001 11.25H10.7501C11.1601 11.25 11.5001 11.59 11.5001 12C11.5001 12.41 11.1601 12.75 10.7501 12.75ZM10.7501 9H5.8001C5.3901 9 5.0501 8.66 5.0501 8.25C5.0501 7.84 5.3901 7.5 5.8001 7.5H10.7501C11.1601 7.5 11.5001 7.84 11.5001 8.25C11.5001 8.66 11.1601 9 10.7501 9Z"
          fill={colors.grayscaleIcons}
        />
        <path
          d="M23 21.251H20.73V18.251C21.68 17.941 22.37 17.051 22.37 16.001V14.001C22.37 12.691 21.3 11.621 19.99 11.621C18.68 11.621 17.61 12.691 17.61 14.001V16.001C17.61 17.041 18.29 17.921 19.22 18.241V21.251H1C0.59 21.251 0.25 21.591 0.25 22.001C0.25 22.411 0.59 22.751 1 22.751H19.93C19.95 22.751 19.96 22.761 19.98 22.761C20 22.761 20.01 22.751 20.03 22.751H23C23.41 22.751 23.75 22.411 23.75 22.001C23.75 21.591 23.41 21.251 23 21.251Z"
          fill={colors.grayscaleIcons}
        />
      </>
    ) : (
      <>
        <path
          d="M19.781 22.76C19.371 22.76 19.031 22.42 19.031 22.01V17.55C19.031 17.14 19.371 16.8 19.781 16.8C20.191 16.8 20.531 17.14 20.531 17.55V22.01C20.531 22.42 20.201 22.76 19.781 22.76Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 12.5C17 10.8431 18.3431 9.5 20 9.5C21.6569 9.5 23 10.8431 23 12.5V15.5C23 17.1569 21.6569 18.5 20 18.5C18.3431 18.5 17 17.1569 17 15.5V12.5ZM20 11.5C19.4477 11.5 19 11.9477 19 12.5V15.5C19 16.0523 19.4477 16.5 20 16.5C20.5523 16.5 21 16.0523 21 15.5V12.5C21 11.9477 20.5523 11.5 20 11.5Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4C4.34315 4 3 5.34315 3 7V22H1V7C1 4.23858 3.23858 2 6 2H10C12.7614 2 15 4.23858 15 7V22H13V7C13 5.34315 11.6569 4 10 4H6Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 22C0 21.4477 0.447715 21 1 21L23 21C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23L1 23C0.447715 23 0 22.5523 0 22Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 8C5 7.44772 5.44772 7 6 7H10C10.5523 7 11 7.44772 11 8C11 8.55228 10.5523 9 10 9H6C5.44772 9 5 8.55228 5 8Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 12C5 11.4477 5.44772 11 6 11H10C10.5523 11 11 11.4477 11 12C11 12.5523 10.5523 13 10 13H6C5.44772 13 5 12.5523 5 12Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 17C8.55228 17 9 17.4477 9 18L9 22C9 22.5523 8.55228 23 8 23C7.44772 23 7 22.5523 7 22L7 18C7 17.4477 7.44772 17 8 17Z"
          fill={colors.grayscaleIcons}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 17C20.5523 17 21 17.4477 21 18L21 22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22L19 18C19 17.4477 19.4477 17 20 17Z"
          fill={colors.grayscaleIcons}
        />
      </>
    )}
  </svg>
);

type PropsType = {
  isFilled?: boolean;
};
