import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ru } from "date-fns/locale";
import { Provider } from "jotai";
import { queryClientAtom } from "jotai/query";
import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";
import { theme } from "../styles";
import "../styles/index.css";
import { App } from "./App";
import { AppAuth } from "./AppAuth";
import { keycloakInstance } from "./keycloak";

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 0 } } });

export const Main: FC = () => (
  <ReactKeycloakProvider authClient={keycloakInstance}>
    <QueryClientProvider client={queryClient}>
      <Provider initialValues={[[queryClientAtom, queryClient]] as const}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <HashRouter>
              <AppAuth>
                <App />
              </AppAuth>
            </HashRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </ReactKeycloakProvider>
);
