import { css } from "@emotion/react";
import { opacify } from "polished";
import { borders } from "./borderStyles";
import { colors } from "./colors";
import { flex } from "./displayStyles";
import { margins } from "./marginsStyles";
import { size } from "./sizeStyles";
import { text } from "./textStyles";

const { transparent, white, grayscaleBeautifulBlack } = colors;
const { grayscaleInput, grayscaleHoverBackground, grayscaleLine, grayscaleDisabled } = colors;
const { redDark, redDefault, redLight } = colors;
const { greenDark, greenDefault, greenBackground } = colors;

export const button = css`
  ${flex({ totalCenter: true })};
  ${size({ h: 48 })};
  ${borders({ none: true, totalRadius: true })};
  ${margins({ p: "0" })};

  background: ${transparent};
  cursor: pointer;

  p {
    ${text({ size: 16, height: 16, weight: 700 })};
  }
`;

export const buttonOnlyText = css`
  p {
    ${margins({ m: "0" })};
  }
`;

export const buttonTwoIcons = css`
  p {
    ${margins({ m: "0 8" })};
  }
`;

export const buttonLeftIcons = css`
  p {
    ${margins({ m: "0 0 0 8" })};
  }
`;

export const buttonRightIcons = css`
  p {
    ${margins({ m: "0 8 0 0" })};
  }
`;

export const buttonOnlyIcon = css`
  ${flex({ totalCenter: true, notResize: true })};
  ${size({ s: 40 })};
  ${margins({ p: "0" })};
`;

// ------------------------------ ЧЁРНЫЕ КНОПКИ

export const buttonPrimaryDefault = css`
  ${margins({ p: "16" })};
  background: ${grayscaleBeautifulBlack};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${white};
  }
  path {
    fill: ${white};
  }
  :hover {
    background: ${opacify(-0.3, grayscaleBeautifulBlack)};
  }
`;

export const buttonSecondaryDefault = css`
  ${margins({ p: "16" })};
  background: ${grayscaleInput};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${grayscaleBeautifulBlack};
  }
  path {
    fill: ${grayscaleBeautifulBlack};
  }
  :hover {
    background: ${opacify(-0.5, grayscaleInput)};
  }
`;

export const buttonOutlinedDefault = css`
  ${margins({ p: "16" })};
  background: ${white};
  outline: 2px solid ${grayscaleBeautifulBlack};
  outline-offset: -2px;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${grayscaleBeautifulBlack};
  }
  path {
    fill: ${grayscaleBeautifulBlack};
  }
  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const buttonTextDefault = css`
  ${margins({ p: "16" })};
  background: ${white};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${grayscaleBeautifulBlack};
  }
  path {
    fill: ${grayscaleBeautifulBlack};
  }
  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const buttonTransparentDefault = css`
  ${margins({ p: "16" })};
  background: ${transparent};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${white};
  }
  path {
    fill: ${white};
  }
  :hover {
    background: ${opacify(-0.85, grayscaleBeautifulBlack)};
  }
`;

// ------------------------------ КРАСНЫЕ КНОПКИ

export const buttonPrimaryAttention = css`
  ${margins({ p: "16" })};
  background: ${redDefault};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${white};
  }
  path {
    fill: ${white};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonSecondaryAttention = css`
  ${margins({ p: "16" })};
  background: ${redLight};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${redDark};
  }
  path {
    fill: ${redDark};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonOutlinedAttention = css`
  ${margins({ p: "16" })};
  background: ${white};
  outline: 2px solid ${redDefault};
  outline-offset: -2px;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${redDefault};
  }
  path {
    fill: ${redDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonTextAttention = css`
  ${margins({ p: "16" })};
  background: ${white};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${redDefault};
  }
  path {
    fill: ${redDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

// ------------------------------ ЗЕЛЁНЫЕ КНОПКИ

export const buttonPrimaryPositive = css`
  ${margins({ p: "16" })};
  background: ${greenDefault};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${white};
  }
  path {
    fill: ${white};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonSecondaryPositive = css`
  ${margins({ p: "16" })};
  background: ${greenBackground};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${greenDark};
  }
  path {
    fill: ${greenDark};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonOutlinedPositive = css`
  ${margins({ p: "16" })};
  background: ${white};
  outline: 2px solid ${greenDefault};
  outline-offset: -2px;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${greenDefault};
  }
  path {
    fill: ${greenDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonTextPositive = css`
  ${margins({ p: "16" })};
  background: ${white};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${greenDefault};
  }
  path {
    fill: ${greenDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

// ------------------------------ НЕАКТИВНЫЕ КНОПКИ

export const buttonDisabled = css`
  ${margins({ p: "16" })};
  background: ${grayscaleDisabled};
  cursor: no-drop;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${grayscaleLine};
  }
`;

export const buttonOutlinedDisabled = css`
  ${margins({ p: "16" })};
  background: ${white};
  outline: 2px solid ${grayscaleDisabled};
  outline-offset: -2px;
  cursor: no-drop;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${grayscaleLine};
  }
`;

export const buttonTextDisabled = css`
  ${margins({ p: "16" })};
  background: ${white};
  cursor: no-drop;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${grayscaleLine};
  }
`;
