import { useAtomValue } from "jotai/utils";
import React, { FC, Suspense, useState } from "react";
import { currentUserAtom, menuIsExpandState } from "../../atoms";
import { SectionsType } from "../../constants/sections";
import { backendURI } from "../../utils/backendURI";
import { Logo as LogoIcon } from "../Icons";
import { Loader } from "../Loader";
import { Container, Footer, FooterSeparator, Header, Main, MainWrapper } from "./LeftMenu.styles";
import { ExpandButton, Logo, Profile, SectionButtons, WidgetsButton } from "./components";

export const LeftMenu: FC<PropsType> = (props) => {
  const { sections } = props;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  const [openSubmenu, setOpenSubMenu] = useState<string>();

  const baseUrl = process.env.REACT_APP_SKL_URL ? "localhost:8080" : backendURI();

  const { roleModel } = useAtomValue(currentUserAtom);

  return roleModel ? (
    <Suspense fallback={<Loader />}>
      <Container isExpand={menuIsExpand}>
        <Header>
          <Logo logo={<LogoIcon />} />
        </Header>

        <MainWrapper>
          <Main isExpand={menuIsExpand}>
            <WidgetsButton baseUrl={baseUrl} />

            <SectionButtons
              sections={sections}
              openSubmenu={openSubmenu}
              setOpenSubMenu={setOpenSubMenu}
            />
          </Main>
        </MainWrapper>

        <Footer>
          <FooterSeparator />

          <ExpandButton setOpenSubMenu={setOpenSubMenu} />

          <Profile baseUrl={baseUrl} />
        </Footer>
      </Container>
    </Suspense>
  ) : null;
};

type PropsType = {
  sections: SectionsType;
};
