import styled from "@emotion/styled";
import * as styles from "../../../styles";
import { ButtonTypeType } from "./UniversalTextButton";

const { button, buttonDisabled, buttonLeftIcons, buttonOnlyText, buttonPrimaryDefault } = styles;
const { buttonRightIcons, buttonSecondaryDefault, buttonTextDefault, buttonTwoIcons } = styles;
const { buttonPrimaryAttention, buttonSecondaryAttention, buttonPrimaryPositive } = styles;
const { buttonOutlinedDefault, colors } = styles;

const { primaryDefault } = colors;

export const Container = styled.button<PropsType>`
  ${button};
  ${({ typeButton, enabled }) =>
    enabled
      ? typeButton === "primary"
        ? buttonPrimaryDefault
        : typeButton === "text"
        ? buttonTextDefault
        : typeButton === "attention"
        ? buttonPrimaryAttention
        : typeButton === "attention-secondary"
        ? buttonSecondaryAttention
        : typeButton === "positive"
        ? buttonPrimaryPositive
        : typeButton === "outlined"
        ? buttonOutlinedDefault
        : buttonSecondaryDefault
      : buttonDisabled};
  ${({ hasIconStart, hasIconEnd }) =>
    hasIconStart && hasIconEnd
      ? buttonTwoIcons
      : hasIconStart && !hasIconEnd
      ? buttonLeftIcons
      : !hasIconStart && hasIconEnd
      ? buttonRightIcons
      : buttonOnlyText};

  ${({ needPoint }) =>
    needPoint
      ? `position: relative;
         ::before {
           content: '';

           position: absolute;
           height: 8px;
           width: 8px;
           top: 4px;
           right: 4px;
           background: ${primaryDefault};
           border-radius: 99em;`
      : undefined};
`;

type PropsType = {
  typeButton: ButtonTypeType;
  needPoint: boolean;
  enabled: boolean;
  hasIconStart: boolean;
  hasIconEnd: boolean;
};
