import { useAtom } from "jotai";
import React from "react";
import { FormProvider, useForm as useRhfForm, useWatch } from "react-hook-form";
import { filtersFormState } from "../../atoms";
import { FormContainer } from "../../styles";
import { FormButtons } from "../Form";
import { MethodsType } from "../Page";
import { RightModal, RightModalActions, RightModalBody } from "../RightModal";

export const Filters = <DATUM, OBJ, FLDS, VOBJ, FLTRS>(
  props: PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const { children, methods } = props;

  const { api, queryKey } = methods;
  const { filtersFormName = "filtersForm", filtersFormTitle, fieldsFilters, setFilters } = methods;
  const { initialValuesFilters, filtersValidationSchema } = methods;

  const [filtersFormIsOpen, setFiltersFormIsOpen] = useAtom(filtersFormState);

  const handleClose = () => setFiltersFormIsOpen(false);

  const rhfMethods = useRhfForm({
    defaultValues: initialValuesFilters,
    resolver: filtersValidationSchema,
    mode: "all",
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset } = rhfMethods;

  // const handleClear = () =>
  //   !absoluteInitialValues
  //     ? undefined
  //     : Object.keys(absoluteInitialValues).forEach((key) =>
  //         setValue(key, absoluteInitialValues[key as keyof typeof absoluteInitialValues])
  //       ); // todo: почему не сделать то же самое методом очистки формы?

  if (!api || !queryKey) return null;

  return (
    <RightModal
      title={filtersFormTitle ?? "Фильтры"}
      isOpen={filtersFormIsOpen}
      onClose={handleClose}
      size="small"
    >
      <FormProvider {...rhfMethods}>
        {children ? (
          children
        ) : (
          <RightModalBody>
            <FormContainer
              id={filtersFormName}
              onSubmit={handleSubmit(async (values) => {
                if (!setFilters) return;

                setFilters(values as FLTRS);

                handleClose();
              })}
            >
              {fieldsFilters ? <Fields fieldsFilters={fieldsFilters} /> : null}
            </FormContainer>
          </RightModalBody>
        )}

        <RightModalActions>
          <FormButtons
            formName={filtersFormName}
            onClose={handleClose}
            saveOrAdd="clear"
            disabled={false}
            // onClear={handleClear}
            onClear={() => reset()}
          />
        </RightModalActions>
      </FormProvider>
    </RightModal>
  );
};

type PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = {
  methods: MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>;
  children?: JSX.Element;
};

const Fields = <FLTRS,>(props: FieldsPropsType<FLTRS>) => {
  const { fieldsFilters } = props;

  const values = useWatch();

  return typeof fieldsFilters === "function" ? (
    <>{fieldsFilters(values as unknown as FLTRS)}</>
  ) : (
    fieldsFilters
  );
};

type FieldsPropsType<FLTRS> = {
  fieldsFilters: JSX.Element | ((val: FLTRS) => JSX.Element);
};
