import { CircularProgress } from "@mui/material";
import React, { FC, RefObject } from "react";
import * as elements from "./DocumentsInput.styles";

export const InputProgress: FC<PropsType> = (props) => {
  const { element, fileNames, filesSize, progress } = props;

  const { Container, Title, Description, Text, Progress, Percent } = elements;

  return (
    <Container status="progress" as="div">
      <Progress>
        <CircularProgress variant="determinate" value={progress} />
        <Percent>{`${Math.round(progress)}%`}</Percent>
      </Progress>

      <Text>
        <Title status="progress">{fileNames(element)}</Title>
        <Description status="progress">{filesSize(element)}</Description>
      </Text>
    </Container>
  );
};

type PropsType = {
  element: RefObject<HTMLInputElement>;
  fileNames: (element?: RefObject<HTMLInputElement>) => string;
  filesSize: (element?: RefObject<HTMLInputElement>) => string | 0;
  progress: number;
};
