import React, { FC } from "react";
import { ContentWithRightBlock } from "../ContentWithRightBlock/ContentWithRightBlock";
import { JustBlock } from "../JustBlock";
import { ContentBlock } from "./ContentWithJustBlock.styles";

export const ContentWithJustBlock: FC<PropsType> = (props) => {
  const { children, titleRightBlock, rightBlock } = props;

  return (
    <ContentWithRightBlock
      rightContent={<JustBlock title={titleRightBlock}>{rightBlock}</JustBlock>}
    >
      <ContentBlock>{children}</ContentBlock>
    </ContentWithRightBlock>
  );
};

type PropsType = {
  titleRightBlock: string;
  rightBlock: JSX.Element;
};
