import React, { FC } from "react";
import { Backdrop, ModalContent } from "./ConfirmModal.styles";

export const Container: FC<PropsType> = (props) => {
  const { isOpen, onClose, children } = props;

  return (
    <Backdrop onClose={onClose} open={isOpen}>
      <ModalContent onClick={(evt) => evt.stopPropagation()}>{children}</ModalContent>
    </Backdrop>
  );
};

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
};
