import React from "react";
import { colors } from "../../styles";

export const Ok = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8636 5.36361C20.215 5.01214 20.7849 5.01214 21.1364 5.36361C21.4878 5.71508 21.4878 6.28493 21.1364 6.6364L9.63637 18.1364C9.28489 18.4879 8.71505 18.4879 8.36357 18.1364L3.36358 13.1364C3.01211 12.7849 3.01211 12.2151 3.36358 11.8636C3.71505 11.5121 4.2849 11.5121 4.63637 11.8636L8.99997 16.2272L19.8636 5.36361Z"
      fill={colors.primaryDefault}
    />
  </svg>
);
