import styled from "@emotion/styled";
import { colors, flex, overflowOneLine, text } from "../../styles";

const { grayscaleAsh, grayscalePlaceholder } = colors;

export const Card = styled.div`
  ${flex({ gap: 16, vertical: "center" })};

  overflow: hidden;
`;

export const NameAnrRoles = styled.div`
  overflow: hidden;
`;

export const Name = styled.p`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 700, color: grayscaleAsh })};
`;

export const Roles = styled.p`
  ${overflowOneLine};
  ${text({ size: 14, height: 17, weight: 400, color: grayscalePlaceholder })};
`;
