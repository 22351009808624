import styled from "@emotion/styled/macro";
import { StatusColorsType } from "../../../api";
import { borders, colors, flex, margins, statusColors, text } from "../../../styles";

const { grayscaleBeautifulBlack } = colors;

export const Container = styled.p<{ color: StatusColorsType }>`
  ${flex({ vertical: "center" })};
  ${borders({ radius: 16 })};
  ${text({ size: 15, height: 19, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ p: "6 10" })};

  background: ${({ color }) => statusColors[color ?? "GRAY"].border};
`;
