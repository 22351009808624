import React, { FC } from "react";
import { Card } from "./RoomsSanatoriumCard.styles";

export const BaseCard: FC<BaseCardPropsType> = (props) => {
  const { size = "standard", active = true, children, isReadOnly } = props;

  return (
    <Card size={size} active={active} isReadOnly={isReadOnly}>
      {children}
    </Card>
  );
};

export type BaseCardPropsType = {
  size?: "little" | "ultra" | "standard";
  active?: boolean;
  isReadOnly?: boolean;
};
