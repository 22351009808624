import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { backendURI } from "../utils/backendURI";
import { getToken } from "../utils/useCachingKeycloak";

const instance = (baseURL: string | undefined) => {
  const paramsSerializer = (params: AxiosRequestConfig) =>
    qs.stringify(params, { arrayFormat: "brackets" });

  const instance = axios.create({ paramsSerializer });

  instance.interceptors.request.use((config) => {
    const cacheToken = getToken("token");
    const cacheKcToken = getToken("kc_token");

    const kcToken = cacheKcToken?.token ?? "";
    const token = cacheToken?.token ?? "";

    const liferayQuery = config.url === "/organizations";

    return {
      ...config,
      baseURL,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: liferayQuery ? `Bearer ${token}` : `Bearer ${kcToken}`,
        ...(liferayQuery ? { "kc-token": kcToken ?? "" } : null),
      },
    };
  });

  return instance;
};

export const mrApi = process.env.REACT_APP_MR_URL
  ? instance(process.env.REACT_APP_MR_URL)
  : instance(`https://${backendURI()}/o/medtech-api-2.0/v1.0`);
export const hrApi = process.env.REACT_APP_SKL_URL
  ? instance(process.env.REACT_APP_SKL_URL)
  : instance(`https://health-resort.${backendURI()}/rest/entities`);
export const hrApiRest = process.env.REACT_APP_SKL_REST
  ? instance(process.env.REACT_APP_SKL_REST)
  : instance(`https://health-resort.${backendURI()}/rest`);
export const hrServicesApi = process.env.REACT_APP_SKL_SERVICES_URL
  ? instance(process.env.REACT_APP_SKL_SERVICES_URL)
  : instance(`https://health-resort.${backendURI()}/rest/services`);
export const hrFilesApi = process.env.REACT_APP_SKL_FILES_URL
  ? instance(process.env.REACT_APP_SKL_FILES_URL)
  : instance(`https://health-resort.${backendURI()}/rest/files`);
export const psApi = process.env.REACT_APP_PS_URL
  ? instance(process.env.REACT_APP_PS_URL)
  : instance(`https://proxy-search.${backendURI()}`);
export const orgServicesApi = process.env.REACT_APP_ORG_SERVICES_URL
  ? instance(process.env.REACT_APP_ORG_SERVICES_URL)
  : instance(`https://orgstructure.${backendURI()}/services/api`);
