import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { borders, colors, flex, grid: gridStyles, margins, overflowOneLine, text, size } = styles;

const { white, grayscaleInput, grayscaleLabel, grayscaleOffWhite } = colors;

export const Container = styled.div<ContainerPropsType>`
  ${({ grid }) => gridStyles({ columns: grid })};
  ${size({ h: 64, min: { w: 0 } })};
  ${borders({ side: "bottom", color: grayscaleInput })};

  position: sticky;
  top: 0;
  background: ${grayscaleLabel};
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  z-index: 2;
`;

export const TableHeadItem = styled.div`
  ${flex({ gap: 14, vertical: "center" })};
  ${size({ min: { w: 0 } })};
  ${borders({ side: "right", color: grayscaleInput })};
  ${margins({ p: "19 2 19 20" })};

  overflow: hidden;

  &.check,
  &.menu {
    ${flex({ totalCenter: true })};
    ${margins({ p: "0" })};
  }

  &:last-of-type {
    ${borders({ none: true })};
  }

  p {
    ${overflowOneLine};
    ${text({ size: 17, height: 24, weight: 700, color: grayscaleOffWhite })};
  }

  svg > path {
    fill: ${white};
  }
`;

type ContainerPropsType = {
  needCheck: boolean;
  needMenu: boolean;
  grid: string;
};
