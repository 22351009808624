import React, { ForwardedRef, forwardRef, MouseEvent, ReactNode } from "react";
import { PopoverHover, usePopoverHover } from "../../PopoverHover";
import { Container } from "./FormButton.styles";

export const FormButton = forwardRef(
  (props: PropsType, ref: ForwardedRef<HTMLButtonElement | null>) => {
    const { text, type = "submit", icon, formName } = props;
    const { active = true, variant = "default", onClick, disabledHintText } = props;

    const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({
      anchorOrigin: { vertical: "bottom", horizontal: "left" },
      transformOrigin: { vertical: -6, horizontal: "left" },
    });

    return (
      <div onMouseEnter={openPopoverHover} onMouseLeave={closePopoverHover}>
        <Container
          ref={ref}
          form={formName}
          type={type}
          active={active}
          disabled={!active}
          needIcon={!!icon}
          onClick={onClick}
          variant={variant}
        >
          {icon}
          <p>{text}</p>
        </Container>

        {disabledHintText ? (
          <PopoverHover {...propsPopoverHover}>{disabledHintText}</PopoverHover>
        ) : null}
      </div>
    );
  }
);

FormButton.displayName = "FormButton";

type PropsType = {
  text: string;
  icon?: ReactNode;
  formName?: string;
  type?: "button" | "submit";
  active?: boolean;
  variant?: "text" | "default";
  onClick?: ((evt: MouseEvent) => Promise<void>) | (() => void);
  disabledHintText?: string;
};
