import styled from "@emotion/styled";
import { button, buttonOnlyIcon, colors, size } from "../../../styles";

const { transparent, white, grayscaleHoverBackground, grayscaleBeautifulBlack } = colors;

export const Container = styled.div<{ disabled: boolean }>`
  ${button};
  ${buttonOnlyIcon};

  svg {
    ${size({ s: 20 })};
  }

  svg > path {
    fill: ${white};
  }

  :hover {
    background: ${({ disabled }) => (disabled ? transparent : grayscaleHoverBackground)};

    div {
      cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
    }

    svg > path {
      fill: ${({ disabled }) => (disabled ? white : grayscaleBeautifulBlack)};
    }
  }
`;
