import React, { FC, useState } from "react";
import { ConfirmModal, UniversalTextButton } from "../..";
import { OptionalType } from "../../../utils/optionalType";
import { ConfirmModalPropsType } from "../../ConfirmModal";
import { UniversalTextButtonPropsType } from "../UniversalTextButton/UniversalTextButton";

export const ButtonWithConfirm: FC<PropsType> = (props) => {
  const { confirmData, textButtonData, visible } = props;

  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  return (
    <>
      {visible && (
        <UniversalTextButton
          {...(textButtonData as Omit<UniversalTextButtonPropsType, "onClick">)}
          onClick={() => setConfirmIsOpen(true)}
        />
      )}

      {confirmIsOpen && (
        <ConfirmModal {...confirmData} isOpen onClose={() => setConfirmIsOpen(false)} />
      )}
    </>
  );
};

type PropsType = {
  confirmData: OptionalType<ConfirmModalPropsType, "isOpen" | "onClose">;
  textButtonData: Omit<UniversalTextButtonPropsType, "onClick">;
  visible: boolean;
};
