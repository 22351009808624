import React from "react";
import { colors } from "../../styles";

export const CheckForSwitch = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4636 7.2636C13.8151 6.91213 14.3849 6.91213 14.7364 7.2636C15.0879 7.61507 15.0879 8.18492 14.7364 8.53641L10.1364 13.1364C9.7849 13.4879 9.2151 13.4879 8.8636 13.1364L6.2636 10.5364C5.91213 10.1849 5.91213 9.61511 6.2636 9.26361C6.61508 8.91211 7.18492 8.91211 7.5364 9.26361L9.5 11.2272L13.4636 7.2636Z"
      fill={colors.primaryDefault}
    />
  </svg>
);
