import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { rehabActivitiesApi, RehabActivityType } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const rehabActivitiesModalDataState = atom<RehabActivityType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const rehabActivitiesAtom = atomWithQuery(() => {
  return rehabActivitiesQuery();
});

export const rehabActivitiesState = loadable(rehabActivitiesAtom);

const rehabActivitiesQuery = () => {
  return {
    queryKey: ["rehabActivitiesState"],
    queryFn: () => fetchItems(() => rehabActivitiesApi().getAll()),
    keepPreviousData: true,
  };
};
