import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, regionsApi, RegionType } from "../../api";
import { fetchAll, fetchItems } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";

export const regionsModalDataState = atom<RegionType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const regionsAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return regionsQuery(args);
});

export const regionsState = loadable(regionsAtom);

const regionsQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "country.name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "regionsState"],
    queryFn: () => fetchAll(() => regionsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const regionsAllState = atomWithQuery(() => ({
  queryKey: ["regionsAllState"],
  queryFn: () => fetchItems(() => regionsApi().getAll()),
}));
