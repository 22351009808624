import React, { FC, MouseEvent, useState } from "react";
import { Square, SquareFilledWithCheck, SquareWithCheck } from "../Icons";
import { PopoverHover } from "../PopoverHover";
import { Container } from "./Check.styles";

export const Check: FC<PropsType> = (props) => {
  const { checked = false, variant = "outline", disabled = false } = props;

  const [anchorCheck, setAnchorCheck] = useState<HTMLElement | undefined>();

  const openHint = ({ currentTarget }: MouseEvent<HTMLElement>) => setAnchorCheck(currentTarget);

  const closeHint = () => setAnchorCheck(undefined);

  return (
    <div
      aria-owns={anchorCheck && "hint"}
      aria-haspopup="true"
      onMouseEnter={(evt) => openHint(evt)}
      onMouseLeave={closeHint}
    >
      <Container
        icon={<Square />}
        checkedIcon={variant === "outline" ? <SquareWithCheck /> : <SquareFilledWithCheck />}
        checked={checked}
        disabled={disabled}
      />

      <PopoverHover
        id="hint"
        isOpen={!!anchorCheck && disabled && "hintText" in props && props.hintText !== ""}
        element={anchorCheck}
        onClose={closeHint}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: 12, horizontal: -12 }}
      >
        {"hintText" in props ? props.hintText : ""}
      </PopoverHover>
    </div>
  );
};

type PropsType = {
  checked: boolean;
  variant?: CheckboxVariantType;
} & (
  | {
      disabled?: false;
    }
  | {
      disabled: true;
      hintText: string;
    }
);

export type CheckboxVariantType = "outline" | "filled";
