import React, { FC } from "react";
import { Narrow, Wider } from "./Separator.styles";

export const Separator: FC<PropsType> = (props) => {
  const { type, marginTop, marginBottom } = props;

  return type === "wider" || type === "longWider" ? (
    <Wider long={type === "longWider"} marginTop={marginTop} marginBottom={marginBottom} />
  ) : (
    <Narrow long={type === "longNarrow"} marginTop={marginTop} marginBottom={marginTop} />
  );
};

type PropsType = {
  type: "narrow" | "longNarrow" | "wider" | "longWider";
  marginTop?: string;
  marginBottom?: string;
};
