import styled from "@emotion/styled";
import { colors, flex, margins, text } from "../../../styles";

const { grayscaleBeautifulBlack, grayscaleDarkDisabled } = colors;

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div<{ isColumn: boolean }>`
  ${({ isColumn }) => flex({ gap: 8, isColumn: isColumn })};
`;

export const Title = styled.h5`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 0 8 0" })};
`;

export const RadioTitle = styled.span`
  ${flex({ gap: 8 })};
  ${margins({ p: "4" })};
`;

export const RadioWrapper = styled.span`
  ${flex({ isColumn: true })}
`;

export const RadioDescription = styled.span`
  ${text({ color: colors.grayscaleLabel, size: 12, weight: 500, height: 16 })};
`;

export const Text = styled.span<{ disabled?: boolean }>`
  ${({ disabled }) =>
    text({
      size: 16,
      height: 23,
      weight: 400,
      color: disabled ? grayscaleDarkDisabled : grayscaleBeautifulBlack,
    })};
`;
