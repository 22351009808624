export const useDateInput = (
  nameProps?: { start: string; stop: string },
  placeholderProps?: { start: string; stop: string },
  label?: { start: string; stop: string }
) => {
  const maxStartDate = (endDate: Date, maxDate?: Date) =>
    maxDate
      ? endDate
        ? maxDate >= endDate
          ? endDate
          : maxDate
        : maxDate
      : endDate
      ? endDate
      : undefined;

  const minEndDate = (startDate: Date, minDate?: Date) =>
    minDate
      ? startDate
        ? minDate >= startDate
          ? minDate
          : startDate
        : minDate
      : startDate
      ? startDate
      : undefined;

  const name = {
    start: nameProps?.start ?? "startDate",
    stop: nameProps?.stop ?? "endDate",
  };

  const placeholder = {
    start: placeholderProps?.start ? placeholderProps.start : label ? "С" : "Действует c *",
    stop: placeholderProps?.stop ? placeholderProps.stop : label ? "По" : "Действует по",
  };

  return {
    maxStartDate,
    minEndDate,

    name,
    placeholder,
  };
};
