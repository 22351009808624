import { useAtomValue } from "jotai/utils";
import { MouseEvent, useState } from "react";
import { menuIsExpandState } from "../../../atoms";

export const useHint = (type: TypeType, text?: string) => {
  const menuIsExpand = useAtomValue(menuIsExpandState);

  const [anchorElement, setAnchorElement] = useState<Element | undefined>(undefined);
  const [popoverHoverText, setPopoverHoverText] = useState("");

  const openHint = (evt: MouseEvent<Element>) => {
    const isExpandButton = type === "expandButton";
    const isNotExtendedSections =
      !menuIsExpand && (type === "menu" || type === "folderButton" || type === "sectionButton");
    const innerText = (evt.target as HTMLElement)?.innerText;
    const notFitSize = menuIsExpand && innerText && innerText.length > 25;

    if (isExpandButton || isNotExtendedSections || notFitSize) {
      setAnchorElement(evt.currentTarget);
      setPopoverHoverText(text || (evt.target as HTMLElement).innerText);
    }
  };

  const closeHint = () => {
    setAnchorElement(undefined);
    setPopoverHoverText("");
  };

  const argsForHint = {
    "aria-owns": anchorElement && "hint",
    "aria-haspopup": true,
    onMouseEnter: openHint,
    onMouseLeave: closeHint,
  };

  return {
    argsForHint,

    anchorElement,
    popoverHoverText,
    setPopoverHoverText,
  };
};

export type PopoverHoverMethodsType = {
  argsForHint: {
    "aria-owns": string | undefined;
    "aria-haspopup": boolean;
    onMouseEnter: (evt: MouseEvent<Element>) => void;
    onMouseLeave: () => void;
  };
  anchorElement: Element | undefined;
  popoverHoverText: string;
  setPopoverHoverText: (arg: string) => void;
};

export type TypeType =
  | "menu"
  | "expandButton"
  | "folderButton"
  | "sectionButton"
  | "subSectionButton";
