import React, { FC, MouseEvent, ReactNode, useState } from "react";
import { PopoverModal } from "../../PopoverModal";
import { Container } from "./UniversalTextButton.styles";

export const UniversalTextButton: FC<UniversalTextButtonPropsType> = (props) => {
  const { onClick, text, iconStart, iconEnd } = props;
  const { needPoint = false, type = "primary", enabled = true } = props;

  // ------------------------------ < ВЫЗОВ МЕНЮ ЭКСПОРТА

  const [exportMenuElement, setExportMenuElement] = useState<HTMLElement | null>(null);

  const openProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportMenuElement(evt.currentTarget);
  };

  const closeExportMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportMenuElement(null);
  };

  const exportMenuIsOpen = !!exportMenuElement;

  // ------------------------------ ВЫЗОВ МЕНЮ ЭКСПОРТА >

  return (
    <>
      <Container
        type="button"
        typeButton={type}
        needPoint={needPoint}
        enabled={enabled}
        onClick={
          "menuElement" in props ? openProfileMenu : enabled && !!onClick ? onClick : undefined
        }
        hasIconStart={!!iconStart}
        hasIconEnd={!!iconEnd}
      >
        {iconStart}
        <p>{text}</p>
        {iconEnd}
      </Container>

      {"menuElement" in props && (
        <PopoverModal
          id="exportMenu"
          isOpen={exportMenuIsOpen}
          element={exportMenuElement}
          onClose={closeExportMenu}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: -10 }}
        >
          {props.menuElement}
        </PopoverModal>
      )}
    </>
  );
};

export type UniversalTextButtonPropsType = {
  text: ReactNode;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  needPoint?: boolean;
  type?: ButtonTypeType;
  enabled?: boolean;
} & (
  | {
      onClick?: (arg?: MouseEvent<HTMLButtonElement>) => void;
      menuElement: ReactNode;
    }
  | {
      onClick: (arg?: MouseEvent<HTMLButtonElement>) => void;
    }
);

export type ButtonTypeType =
  | "primary"
  | "secondary"
  | "text"
  | "attention"
  | "attention-secondary"
  | "positive"
  | "outlined"
  | undefined;
