import { DragEvent, FormEvent, MouseEvent } from "react";

export const preventDefault = (
  evt:
    | SubmitEvent
    | DragEvent<Element>
    | FormEvent<HTMLAnchorElement>
    | MouseEvent<HTMLSpanElement>
    | FormEvent<HTMLFormElement>
) => {
  evt.preventDefault();
  evt.stopPropagation();
};
