import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, sanatoriumsApi, sanatoriumsServiceApi } from "../../api";
import { fetchAll } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";

export const confirmModalSuccessState = atom<boolean>(false);

// ------------------------------ ТЕКУЩИЙ САНАТОРИЙ

export const sanatoriumIdState = atom<string | undefined>(undefined);

// ------------------------------ РЕДАКТИРОВАНИЕ ИЛИ ДОБАВЛЕНИЕ

export const isEditSanatorium = atom<boolean>(false);

// ------------------------------ МЕНЯЛИСЬ ЛИ САНАТОРИИ

export const sanatoriumsChangedState = atom<boolean>(false);

// ------------------------------ ДАННЫЕ

const sanatoriumsAtom = atomWithQuery((get) => {
  const { startIndex, size, query } = getPaginationQueryKeys(get);
  const sanatoriumsChanged = get(sanatoriumsChangedState);

  const args = { startIndex, size, query, sanatoriumsChanged };

  return sanatoriumsQuery(args);
});

export const sanatoriumsState = loadable(sanatoriumsAtom);

const sanatoriumsQuery = (props: PropsType) => {
  const { startIndex, size, query, sanatoriumsChanged } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, sanatoriumsChanged, "sanatoriumsState"],
    queryFn: () => fetchAll(() => sanatoriumsApi().getAll(args)),
  };
};

// ------------------------------ КОНКРЕТНЫЙ САНАТОРИЙ

const sanatoriumAtom = atomWithQuery((get) => {
  const id = get(sanatoriumIdState);

  return sanatoriumQuery(id);
});

export const sanatoriumState = loadable(sanatoriumAtom);

const sanatoriumQuery = (id?: string) => ({
  queryKey: [id, "sanatoriumState"],
  queryFn: () => sanatoriumsServiceApi.get(id ?? ""),
  enabled: !!id,
});

type PropsType = EntityRequestBaseType & {
  sanatoriumsChanged: boolean;
};
