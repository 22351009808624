import { useAtomValue } from "jotai/utils";
import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms";
import { Container, Icon, LogoSeparator, Text, TextMedTech, TextRosatom } from "./Logo.styles";

export const Logo: FC<PropsType> = (props) => {
  const { logo } = props;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate("/")}>
      <Icon>{logo}</Icon>

      {menuIsExpand && (
        <>
          <LogoSeparator />

          <Text>
            <TextMedTech>MedTech_</TextMedTech>
            <TextRosatom>rosatom</TextRosatom>
          </Text>
        </>
      )}
    </Container>
  );
};

type PropsType = {
  logo: ReactNode;
};
