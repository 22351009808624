import styled from "@emotion/styled";
import { Drawer } from "@mui/material";
import { borders, colors, flex, margins, shadow, size as sizeStyles, text } from "../../styles";

const { grayscaleLabelOpacity77, grayscaleBeautifulBlack, grayscaleLabel } = colors;
const { primaryActiveElementOpacity } = colors;

export const Container = styled(Drawer)<PropsType>`
  .MuiModal-root {
    position: relative;
  }

  .MuiBackdrop-root {
    ${({ needblur }) =>
      needblur
        ? `background: ${primaryActiveElementOpacity}; backdrop-filter: blur(10px); opacity: 0.6`
        : "background: none"};
  }

  .MuiPaper-root {
    ${({ size }) => sizeStyles({ w: size === "small" ? 500 : 600 })};
    ${margins({ m: "0", p: "0" })};

    box-shadow: ${({ needblur }) => (needblur ? "none" : shadow)};
    overflow-y: overlay;

    &::-webkit-scrollbar {
      ${sizeStyles({ w: 10 })};
    }

    &::-webkit-scrollbar-thumb {
      ${borders({ totalRadius: true })};

      background: ${grayscaleLabelOpacity77};
    }
  }
`;

export const Head = styled.div`
  ${margins({ m: "64 64 40" })};
`;

export const TitleAndCloseButton = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${margins({ m: "0" })};
`;

export const Title = styled.h4`
  ${text({ size: 24, height: 34, weight: 800, color: grayscaleBeautifulBlack })};
`;

export const Description = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};
  ${margins({ m: "8 0 0" })};
`;

export const Main = styled.div`
  ${flex({ isColumn: true, vertical: "evenly" })};
  ${sizeStyles({ h: "100%" })};
`;

type PropsType = {
  size: string;
  needblur: number;
};
