import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { BigOtherType, EntityRequestBaseType, treatmentProfilesApi } from "../../api";
import { fetchAll, fetchItems } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";

export const treatmentProfilesModalDataState = atom<BigOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const treatmentProfilesAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return treatmentProfilesQuery(args);
});

export const treatmentProfilesState = loadable(treatmentProfilesAtom);

const treatmentProfilesQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "treatmentProfilesState"],
    queryFn: () => fetchAll(() => treatmentProfilesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const treatmentProfilesAllState = atomWithQuery(() => ({
  queryKey: ["treatmentProfilesAllState"],
  queryFn: () => fetchItems(() => treatmentProfilesApi().getAll()),
}));
