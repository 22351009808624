export const rights = (roles: RolesType) => {
  const { employee, department, osr, osrCa, ok, sk, operator, module, superUser } = roles;

  const all = employee || department || osr || osrCa || ok || sk || operator || module || superUser;
  const allWithoutModule =
    employee || department || osr || osrCa || ok || sk || operator || superUser;
  const allWithoutOperator =
    employee || department || osr || osrCa || ok || sk || module || superUser;

  return {
    // ------------------------------ СПРАВОЧНИКИ

    canViewAndEditDictionariesForForm: osrCa || module || superUser,

    canViewDictionariesForEmployee: osr || osrCa || module || superUser,
    canEditDictionariesForEmployee: osrCa || module || superUser,

    canViewAspectGroupsDictionary: osrCa || module || superUser,

    canViewEmployeeDictionary: department || osr || osrCa || module || superUser,
    canViewOrganizationEmployees: osr || osrCa || superUser,
    canViewAllEmployees: module || superUser,
    canEditEmployeeDictionary: osr || osrCa || module || superUser,

    canViewOtherDictionaries: osr || osrCa || operator || module || superUser,
    canEditOtherDictionaries: osrCa || operator || module || superUser,

    canViewSanatoriumsDictionary: all,
    canEditSanatoriumsDictionary: osrCa || operator || module || superUser,

    // ------------------------------ ЗАЯВКИ

    canViewAllApplications: osr || osrCa || superUser,
    canViewMyApplications: employee || department || osr || osrCa || ok || sk || superUser,
    canViewInboxApplications: osr || osrCa || ok || operator || superUser,
    canViewApplicationsInProgress: osr || osrCa || ok || operator || superUser,
    canViewProcessedApplications: osrCa || osr || ok || sk || operator || superUser,

    canViewAndEditApplication: allWithoutModule,

    canViewApplicationRejection: employee || department || osr || osrCa || operator || superUser,
    canEditApplicationRejection: employee || department || superUser,
    canAgreementApplicationRejection: osr || osrCa || superUser,

    canViewAndEditAlternativeApplication: employee || department || osrCa || operator || superUser,

    canCreateApplicationForOtherEmployee: department || osr || osrCa || superUser,

    canDownloadApplications: osr || osrCa || superUser,

    // ------------------------------ РЕЕСТР ЗАЯВОК

    canViewAndEditCreateApplicationRegistry: osr || osrCa || sk || operator || superUser,

    canViewApplicationRegistry: osr || osrCa || sk || operator || superUser,
    canEditApplicationRegistry: osr || osrCa || superUser,
    canSendApplicationRegistryToSk: osr || osrCa || superUser,
    canDeleteApplicationRegistry: osr || osrCa || superUser,

    canApproveApplicationRegistry: sk || superUser,
    canDownloadApplicationRegistry: osr || osrCa || sk || operator || superUser,
    canDownloadIpr: osr || operator || superUser,

    canViewAllApplicationRegistries: osrCa || sk || superUser,

    canMakeDecisionOfCommissionOnApplication: sk || superUser,

    // ------------------------------ РЕЕСТР ПУТЁВОК

    canViewAndEditCreateTravelPackageRegistry: osr || osrCa || superUser,

    canViewTravelPackageRegistry: osr || osrCa || operator || superUser,
    canEditTravelPackageRegistry: osr || osrCa || superUser,
    canDownloadTravelPackageRegistry: osr || osrCa || superUser,

    // ------------------------------ АНКЕТА ОБРАТНОЙ СВЯЗИ ПО ОРГАНИЗАЦИИ СКЛ И РОМ

    // canViewFeedback: employee || department || osr || osrCa || superUser,
    // canEditFeedback: employee || department || superUser,
    canViewFeedback: superUser,
    canEditFeedback: superUser,

    // ------------------------------ АДМИНИСТРИРОВАНИЕ

    canViewAdministration: osr || osrCa || module || superUser,
    canViewAndEditPrice: osrCa || module || superUser,
    canViewAndEditFrequency: osrCa || module || superUser,
    canViewReporting: osr || osrCa || superUser,

    // ------------------------------ ПОДБОР/ПРОСМОТР САНАТОРИЯ

    canViewAndEditSanatoriums: employee || department || osr || osrCa || superUser,

    // ------------------------------ САНАТОРИИ

    canViewSanatoriumList: employee || department || osr || osrCa || ok || sk || superUser,
    canChooseSanatorium: allWithoutOperator,

    // ------------------------------ САНАТОРИЙ

    canViewAndEditSanatorium: module || superUser,
    canEditSanatoriumRating: osrCa || superUser,

    // ------------------------------ УВЕДОМЛЕНИЯ

    canViewNotifications: allWithoutModule,

    // ------------------------------ УВЕДОМЛЕНИЯ

    canViewDocumentations: all,
    canEditDocumentations: osrCa || module || superUser,

    // ------------------------------ ПРОФИЛЬ

    canViewProfile: employee || department || osr || osrCa || ok || sk || superUser,
    canEditProfile: employee || department || osr || osrCa || ok || sk || superUser,

    canViewOtherProfile: department || osr || osrCa || superUser,
    canEditOtherProfile: department || osr || osrCa || superUser,
  };
};

type RolesType = {
  employee: boolean;
  department: boolean;
  osr: boolean;
  osrCa: boolean;
  ok: boolean;
  sk: boolean;
  operator: boolean;
  module: boolean;
  superUser: boolean;
};

export type RoleModelType = RolesType & ReturnType<typeof rights>;
