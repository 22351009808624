import React, { memo, PropsWithChildren } from "react";
import { FilePropsType } from "../../api";
import { SizeType } from "../../styles";
import { ShieldNoPicture } from "../../uiKit/Icons/ShieldNoPicture";
import { useQueryPhoto } from "../../utils/getFile";
import { useWasInViewport } from "../../utils/useWasInViewport";
import { ActiveColor, ActiveWhite, Container, EmptyImageWrapper, Image } from "./Photo.styles";

export const Photo = memo((props: PropsType) => {
  const { children, file, height = 100, width, ultraWidth = false } = props;
  const { bgSize, onClick, active = false } = props;

  const { containerRef, wasInViewport } = useWasInViewport<HTMLDivElement>({ threshold: 0.1 });

  const uploadedPhoto = useQueryPhoto(typeof file === "string" && wasInViewport ? file : undefined);
  const photo = typeof file === "string" ? uploadedPhoto : file;

  return (
    <Container
      ref={containerRef}
      height={height}
      width={width}
      ultraWidth={ultraWidth}
      onClick={onClick}
    >
      {photo ? (
        <Image backgroundImage={URL.createObjectURL(photo)} bgSize={bgSize}>
          {children}
        </Image>
      ) : (
        <EmptyImageWrapper>
          <ShieldNoPicture />
        </EmptyImageWrapper>
      )}

      {active && (
        <>
          <ActiveWhite />
          <ActiveColor />
        </>
      )}
    </Container>
  );
});

Photo.displayName = "Photo";

type PropsType = PropsWithChildren<{
  file?: FilePropsType;
  height?: SizeType;
  width?: SizeType;
  ultraWidth?: boolean;
  bgSize?: "cover" | "contain";
  onClick?: () => void;
  active?: boolean;
}>;
