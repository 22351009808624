import { yupResolver } from "@hookform/resolvers/yup";
import { addDays } from "date-fns";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React from "react";
import { useWatch } from "react-hook-form";
import * as Yup from "yup";
import {
  allActiveSanatoriumsState,
  currentDateIntervalForSanatoriums,
  regionsAllState,
  sanatoriumsFiltersState,
} from "../../../atoms";
import { SliderInput } from "../../../components";
import * as messages from "../../../constants/forValidationSchemes";
import { containRehabOptions } from "../../../constants/options";
import { colors } from "../../../styles";
import {
  BuildingAndTree,
  DaySwitcher,
  Geo,
  MedPlus,
  SelectAutocompleteInput,
  SelectAutocompleteMultilineInput,
} from "../../../uiKit";
import * as options from "../../../utils/getOptions";
import { isDateObject, today } from "../../../utils/workingWithDates";
import { VacationersDropdownForm } from "../Rooms/components/PersonDropdownForm/VacationersDropdownForm";
import { FilterContainer } from "../SanatoriumListPage.styles";
import { TreatmentProfileFilterFields } from "../TreatmentProfileFilterFields/TreatmentProfileFilterFields";

const {
  maxPeriod,
  minPeriod,
  numbers,
  required,
  thisDateIsToCorrect,
  onlyInteger,
  unavailableOption,
} = messages;

export const useFilters = (isAlternative?: boolean) => {
  // ------------------------------ АТОМЫ

  const [filters, setFilters] = useAtom(sanatoriumsFiltersState);

  const sanatoriums = useAtomValue(allActiveSanatoriumsState);

  // ------------------------------ ХУКИ

  const treatmentProfileOptions = options.useTreatmentProfileOptions();
  const regionOptions = options.useRegionOptions();
  const countryOptions = options.useCountryOptions();

  // ------------------------------ КОНСТАНТЫ

  const sanatoriumOptions =
    sanatoriums.state === "hasData" && sanatoriums.data
      ? sanatoriums.data.items.map(({ id, name }) => ({ id, name }))
      : [];

  const formName = "sanatoriumListFiltersPanel";
  const filtersFormName = "sanatoriumListFilters";

  // ------------------------------ ФОРМА-ФИЛЬТРОВ - ПОЛЯ

  const fieldsPanelFilter = (shortVersion: boolean) => (
    <>
      {shortVersion ? (
        <>
          <DaySwitcher
            typeInterval="twoDate"
            dateIntervalAtom={currentDateIntervalForSanatoriums}
            min={today}
            color={colors.white}
          />
          <VacationersDropdownForm />
        </>
      ) : (
        <>
          <CityRegionSelect />

          <SelectAutocompleteMultilineInput
            name="sanatoriums"
            options={sanatoriumOptions}
            placeholder="Название санатория"
            color={colors.white}
            iconStart={<BuildingAndTree isFilled />}
            result="text"
            clearable
          />

          <SelectAutocompleteInput
            name="isRehab"
            options={containRehabOptions}
            placeholder="Вид лечения"
            color={colors.white}
            iconStart={<MedPlus />}
            disabled={isAlternative}
          />

          <DaySwitcher
            typeInterval="twoDate"
            dateIntervalAtom={currentDateIntervalForSanatoriums}
            min={today}
            color={colors.white}
            disabled={isAlternative}
          />
        </>
      )}
    </>
  );

  const subTextSlider = (value: number) =>
    value ? (value === 10 ? "Рейтинг 10" : `Рейтинг ${value} и выше`) : "Любой рейтинг";

  const fieldsFilters = (
    <FilterContainer>
      <SliderInput name="rating" title="Рейтинг" subText={subTextSlider} track="inverted" />

      <TreatmentProfileFilterFields name="profiles" />
    </FilterContainer>
  );

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const filtersValidationSchema = yupResolver(
    Yup.object().shape({
      cityRegion: Yup.array().of(
        Yup.string().oneOf(
          [countryOptions, regionOptions].flat().map(({ id }) => id),
          unavailableOption
        )
      ),
      sanatoriums: Yup.array().of(
        Yup.string().oneOf(
          sanatoriumOptions.map(({ id }) => id),
          unavailableOption
        )
      ),
      isRehab: Yup.string().oneOf(
        [...containRehabOptions, { id: "" }].map(({ id }) => id),
        unavailableOption
      ),
      startDate: Yup.date().typeError(thisDateIsToCorrect).required(required),
      endDate: Yup.date()
        .typeError(thisDateIsToCorrect)
        .test("period_min", minPeriod("2 недель"), (_, { parent: { startDate, endDate } }) =>
          isDateObject(startDate) && isDateObject(endDate)
            ? addDays(startDate, 13) <= endDate
            : true
        )
        .test("period_max", maxPeriod("3 недель"), (_, { parent: { startDate, endDate } }) =>
          isDateObject(startDate) && isDateObject(endDate)
            ? addDays(startDate, 20) >= endDate
            : true
        )
        .required(required),

      rating: Yup.number().integer(onlyInteger).typeError(numbers),
      profiles: Yup.array()
        .of(
          Yup.string().oneOf(
            treatmentProfileOptions.map(({ id }) => id),
            unavailableOption
          )
        )
        .required(required),
    })
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЙ ЗНАЧЕНИЯ

  const sanatoriumListAbsoluteInitialValues = {
    ...filters,
    rating: 0,
    profiles: [],
  };

  return {
    filters,
    setFilters,

    formName,
    fieldsFilters,
    filtersFormName,

    filtersValidationSchema,
    fieldsPanelFilter,
    initialValuesFilters: sanatoriumListAbsoluteInitialValues,
    absoluteInitialValues: sanatoriumListAbsoluteInitialValues,
  };
};

const CityRegionSelect = () => {
  const allCities = useAtomValue(regionsAllState);

  const name = "cityRegion";

  const values = useWatch({ name }) as string[];

  const currentCountry = allCities.find(
    (city) => city.id === values[0] || city.country.id === values[0]
  )?.country.id;

  const regionOptions = options.useRegionOptions(currentCountry);
  const countryOptions = options.useCountryOptions(currentCountry);

  const regionsAndCountries = [countryOptions, regionOptions];

  return (
    <SelectAutocompleteMultilineInput
      name={name}
      options={regionsAndCountries}
      category={["Страна", "Регион"]}
      placeholder="Страна или регион"
      iconStart={<Geo />}
      color={colors.white}
      result="text"
      clearable
    />
  );
};
