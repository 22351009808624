/**
 *
 * ------------------------------------------------------------------------------------------
 * ФОРМАТИРОВАНИЕ ЦЕН
 * добавление в цены знака валюты и пробелов, отделяющих разрядности
 *
 * -
 *
 * @param price - стоимость, которую необходимо отформатировать
 *
 */

export const formatPrice = (price: string | number) =>
  /^\d+$/.test(String(price)) ? `${String(price).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽` : "—";
