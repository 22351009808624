import React, { FC, ReactNode } from "react";
import { NavigateButton, Separator } from "../../uiKit";
import * as elements from "./TitleBlock.styles";

export const TitleBlock: FC<TitleBlockPropsType> = (props) => {
  const { children, title, navigate, footerContent, lastInput, titleContent } = props;

  const { BlockWrapper, Header, Footer, FooterContent } = elements;
  const { NavigateHeader, ChildrenWrapper, Title } = elements;

  return (
    <BlockWrapper lastInput={!!lastInput}>
      {navigate && (
        <>
          <NavigateHeader>
            <NavigateButton link={navigate.route} title={navigate.title} />
          </NavigateHeader>
          <Separator type="longWider" />
        </>
      )}

      {title && (
        <>
          <Header needTop={!!navigate}>
            {typeof title === "string" ? <Title>{title}</Title> : title}
            {titleContent}
          </Header>
          <Separator type="longWider" />
        </>
      )}

      <ChildrenWrapper>{children}</ChildrenWrapper>

      {footerContent && (
        <Footer>
          <Separator type="longWider" />
          <FooterContent> {footerContent}</FooterContent>
        </Footer>
      )}
    </BlockWrapper>
  );
};

type TitleBlockPropsType = {
  title?: string | ReactNode;
  titleContent?: ReactNode;
  navigate?: { title: string; route: string };
  footerContent?: ReactNode;
  lastInput?: boolean;
};
