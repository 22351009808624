import React, { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Star } from "../Icons";
import { Container } from "./StarInput.styles";

export const StarInput: FC<PropsType> = (props) => {
  const { name, label, disabled } = props;

  const context = useFormContext();
  const setValue = (data: boolean) => context.setValue(name, data);

  const value = useWatch({ name }) as boolean;

  return (
    <Container onClick={() => !disabled && setValue(!value)}>
      <Star active={value} />
      {label}
    </Container>
  );
};

type PropsType = {
  name: string;
  label: string;
  disabled: boolean;
};
