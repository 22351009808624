import React, { FC } from "react";
import { FileLinkType } from "../../api";
import { downloadFile } from "../../utils/getFile";
import { FileName } from "./FileLink.styles";

export const FileLink: FC<PropsType> = (props) => {
  const { name, file } = props;

  return <FileName onClick={() => downloadFile(file, name)}>{name}</FileName>;
};

type PropsType = {
  name: string;
  file: FileLinkType;
};
