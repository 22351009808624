import React from "react";
import { colors } from "../../styles";

export const Ruble = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM13.1306 6.4501H9.64781C9.4269 6.4501 9.24781 6.62919 9.24781 6.8501V12.0126H8.14C7.91909 12.0126 7.74 12.1917 7.74 12.4126V13.0813C7.74 13.3023 7.91909 13.4813 8.14 13.4813H9.24781V14.7459H8.14C7.91909 14.7459 7.74 14.925 7.74 15.1459V15.5959C7.74 15.8168 7.91909 15.9959 8.14 15.9959H9.24781V17.35C9.24781 17.5709 9.4269 17.75 9.64781 17.75H10.5978C10.8187 17.75 10.9978 17.5709 10.9978 17.35V15.9959H13.7697C13.9906 15.9959 14.1697 15.8168 14.1697 15.5959V15.1459C14.1697 14.925 13.9906 14.7459 13.7697 14.7459H10.9978V13.497H13.0759C15.2634 13.497 16.7088 12.2386 16.7088 10.2073C16.7088 8.16824 15.2947 6.4501 13.1306 6.4501ZM12.6384 7.99697C14.1619 7.99697 14.9197 8.77041 14.9197 9.99697C14.9197 11.2235 14.1619 11.997 12.6384 11.997H10.9978V7.99697H12.6384Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
