import React, { FC, MouseEvent } from "react";
import { Container, Text } from "./PopoverHover.styles";

export const PopoverHover: FC<PropsType> = (props) => {
  const { id, isOpen, element, onClose, anchorOrigin, transformOrigin, children } = props;

  return (
    <Container
      id={id}
      open={isOpen}
      anchorEl={element}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disableRestoreFocus
    >
      <Text>{children}</Text>
    </Container>
  );
};

type PropsType = {
  id: string | undefined;
  isOpen: boolean;
  element: Element | HTMLElement | SVGSVGElement | null | undefined;
  onClose: (evt: MouseEvent<HTMLElement>) => void;
  anchorOrigin: PositionType;
  transformOrigin: PositionType;
};

export type PositionType = {
  vertical: "top" | "center" | "bottom" | number;
  horizontal: "left" | "center" | "right" | number;
};
