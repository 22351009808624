import { hrServicesApi } from "./api";
import { ApplicationType, FrequencyStatusForTasks } from "./applications";
import { LittleOtherType, StatusType } from "./dictionaries";
import { ResortEmployeeType } from "./employees";
import { DocumentType } from "./file";
import { PhotosType } from "./photos";
import { BackendDateType, BigBackendDateType, IdType, PeriodType, ProcessTypeType } from "./types";

// ------------------------------ ЗАДАЧИ

export const lifecycleApi = {
  // ---------------------------------------- заявки

  getTaskById: (params: GetTaskPropsType) =>
    hrServicesApi.get<TasksType>("/businessProcessApi/getTaskById", { params }),

  getMyTasks: (props: GetTasksPropsType) => getTasks(props, "getMyTasks"),
  getMyGroupTasks: (props: GetTasksPropsType) => getTasks(props, "getMyGroupTasks"),
  getMyCompletedTasks: (props: GetTasksPropsType) => getTasks(props, "getMyCompletedTasks"),

  takeToWork: (params: { taskId: string }) =>
    hrServicesApi.get("/businessProcessApi/takeToWork", { params }),
  returnToGroupTasks: (params: { taskIds: string }) =>
    hrServicesApi.get("/businessProcessApi/returnToGroupTasks", { params }),

  completeTaskWithOutcome: (data: CompleteTaskWithOutcomePropsType) =>
    hrServicesApi.post("/businessProcessApi/completeTaskWithOutcome", data),
  cancelYourApplication: (data: CancelYourApplicationPropsType) =>
    hrServicesApi.post("/applicantScopeService/cancelYourApplication", data),

  makeDecisionOfCommissionOnApplication: (params: MakeDecisionOfCommissionOnApplicationPropsType) =>
    hrServicesApi.get("/applicantScopeService/makeDecisionOfCommissionOnApplication", { params }),

  getProcessVariablesByKey: (params: GetProcessVariablesByKeyPropsType) =>
    hrServicesApi.get<ProcessVariablesType>("/businessProcessApi/getProcessVariablesByKey", {
      params,
    }),

  // ---------------------------------------- реестры

  startRegistryBusinessProcess: (params: RegistryPropsType) =>
    hrServicesApi.get<TasksType>("/registryProcess/startRegistryBusinessProcess", { params }),
  rejectRegistry: (params: RegistryPropsType) =>
    hrServicesApi.get<TasksType>("/registryProcess/rejectRegistry", { params }),
};

const getTasks = async (
  props: GetTasksPropsType,
  method: "getMyTasks" | "getMyGroupTasks" | "getMyCompletedTasks"
) => {
  const params = { ...props, withTotalCount: true };

  const res = await hrServicesApi.get<TasksResponseType>(`/businessProcessApi/${method}`, {
    params,
  });

  return { ...res, data: { totalCount: res.data.totalCount, items: res.data.tasks } };
};

// ------------------------------ ТИПЫ - ЗАДАЧИ

export type TaskType = IdType & {
  processName: ProcessTypeType;
  taskName: TaskNameType;
  taskDescription: string;
  processVariables: ProcessVariablesType;
  comment?: string;
  createTime: Date;
};

type ProcessVariablesType = TaskResultType & {
  commentAuthorFullName: string;
  commentAuthorPosition: string;
  applicationNumber: string;
  document: DocumentType;
  initiator: ResortEmployeeType;
  application?: ShortApplicationType;
  reason?: LittleOtherType;
  refusalComment: string;
  comment?: string;
  registryId?: string;
};

type TaskResultType = {
  [K in TaskResultName]?: {
    outcomes: {
      outcomeId: string;
      executionId: string;
      taskDefinitionKey: string;
      date: BigBackendDateType;
      comment: string;
      authorFullName: string;
      authorPosition: string;
      authorPersonnelNumber: string;
    }[];
  };
};

export type TaskResultName = `${TaskNameType}_result`;

export type ShortApplicationType = IdType &
  PeriodType & {
    applicationId: string;
    applicationNumber: string;
    employee: ResortEmployeeType;
    applicant?: ResortEmployeeType;
    sanatoriumName: string;
    sanatoriumPhotos?: PhotosType;
    containsRehab: boolean;
    frequencyStatus: FrequencyStatusForTasks;
    status: StatusType;
    createdDate: BackendDateType;
  };

export type TaskNameType =
  | "Employee_corrects_application_OK"
  | "Employee_corrects_application_OSR"
  | "Decision_completeness_application_OSR"
  | "Confirmation_revoked_application_OSR"
  | "Confirmation_by_OSR_administrator"
  | "Check_by_Administrator_OK"
  | "Decision_from_sanatorium"
  | "Employee_decision"
  | "Decision_operator_SKL"
  | "Osr_decision_on_unconfirmed_voucher";

// ----- передаваемые значения

export type OutcomeType = "APPROVE" | "REJECT" | "ADJUSTMENT";

export type GetTaskPropsType = {
  taskId: string;
};

export type GetTasksPropsType = {
  process: ProcessTypeType;
  applicationNumber?: number;
  startDate?: BackendDateType;
  endDate?: BackendDateType;
  statusId?: string;
  sanatoriumId?: string;
  fullNameQuery?: string;
  year?: number;
  from?: number;
  size?: number;
  withTotalCount?: boolean;
};

export type CompleteTaskWithOutcomePropsType = {
  decision: {
    taskId: string;
    outcome: OutcomeType;
    comment?: string;
  };
};

export type CancelYourApplicationPropsType = {
  applicationRefusal: {
    applicationNumber: number;
    reason: IdType;
    document?: IdType;
    comment?: string;
  };
};

export type MakeDecisionOfCommissionOnApplicationPropsType = {
  applicationNumber: string;
  outcome: Exclude<OutcomeType, "APPROVE">;
  comment?: string;
};

export type GetProcessVariablesByKeyPropsType = {
  key: ApplicationType["number"];
};

// ------------------------------ ТИПЫ - РЕЕСТРЫ

export type RegistryPropsType = {
  registryId: string;
};

// ----- получаемые значения

export type TasksType = TaskType[];

export type TasksResponseType = { tasks: TasksType; totalCount: number };

export type ShortApplicationsType = ShortApplicationType[];
