import React from "react";
import { colors } from "../../styles";

export const BigWarning = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <path
      d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5ZM27.5 40V30C27.5 28.6193 28.6193 27.5 30 27.5C31.3807 27.5 32.5 28.6193 32.5 30V40C32.5 41.3807 31.3807 42.5 30 42.5C28.6193 42.5 27.5 41.3807 27.5 40ZM26.8754 20.6249V19.3755C26.8754 17.9948 27.9947 16.8755 29.3754 16.8755H30.6251C32.0058 16.8755 33.1251 17.9948 33.1251 19.3755V20.6249C33.1251 22.0057 32.0058 23.1249 30.6251 23.1249H29.3754C27.9947 23.1249 26.8754 22.0057 26.8754 20.6249Z"
      fill={colors.warningDefault}
    />
  </svg>
);
