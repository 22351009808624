import styled from "@emotion/styled";
import { button, buttonOnlyText, colors, size } from "../../../styles";

const { grayscaleHoverBackground } = colors;

export const Container = styled.button`
  ${button};
  ${buttonOnlyText};
  ${size({ s: 40 })};

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;
