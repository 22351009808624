import React from "react";
import { colors } from "../../styles";

export const Expand = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99998 0.9C9.99998 0.402944 10.4029 0 10.9 0H15.1C15.5971 0 16 0.402944 16 0.9V5.10001C16 5.59706 15.5971 6.00001 15.1 6.00001C14.6029 6.00001 14.2 5.59706 14.2 5.10001V3.07279L10.5364 6.7364C10.1849 7.08787 9.61508 7.08787 9.2636 6.7364C8.91213 6.38493 8.91213 5.81508 9.2636 5.46361L12.9272 1.8H10.9C10.4029 1.8 9.99998 1.39706 9.99998 0.9Z"
      fill={colors.grayscaleIcons}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7364 9.2636C7.08787 9.61508 7.08787 10.1849 6.7364 10.5364L3.07279 14.2H5.09818C5.59524 14.2 5.99818 14.6029 5.99818 15.1C5.99818 15.5971 5.59524 16 5.09818 16H0.9C0.402944 16 0 15.5971 0 15.1V10.9C0 10.403 0.402944 10 0.9 10C1.39706 10 1.8 10.403 1.8 10.9V12.9272L5.46361 9.2636C5.81508 8.91213 6.38493 8.91213 6.7364 9.2636Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
