import { useEffect, useRef, useState } from "react";

export const useWasInViewport = <RefElType extends HTMLElement>(observerOptions: PropsType) => {
  const [wasInViewport, setWasInViewPort] = useState(false);
  const containerRef = useRef<RefElType | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const onViewportCallback = (entries: IntersectionObserverEntry[]) => {
      if (entries.find((entry) => entry.isIntersecting)) {
        setWasInViewPort(true);
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(onViewportCallback, observerOptions);

    observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  return { containerRef, wasInViewport };
};

type PropsType = IntersectionObserverInit;
