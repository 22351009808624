import React from "react";
import { colors } from "../../styles";

export const Download = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 3H15V9H18.0343C18.2552 9 18.4343 9.17909 18.4343 9.4C18.4343 9.50609 18.3922 9.60783 18.3172 9.68284L12.2828 15.7172C12.1266 15.8734 11.8734 15.8734 11.7172 15.7172L5.68284 9.68284C5.52663 9.52663 5.52663 9.27337 5.68284 9.11716C5.75786 9.04214 5.8596 9 5.96569 9H9V3ZM6 18H18C18.5523 18 19 18.4477 19 19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19C5 18.4477 5.44772 18 6 18Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
