import React, { FC } from "react";
import { Container } from "./TableTitleRow.styles";

export const TableTitleRow: FC<PropsType> = (props) => {
  const { text } = props;

  return (
    <Container>
      <h3>{text}</h3>
    </Container>
  );
};

type PropsType = {
  text: string;
};
