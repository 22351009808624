import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { DocumentType, familyMembersApi } from "../../api";
import { fetchById } from "../../utils/fetchData";

export const documentsModalDataState = atom<DocumentType | undefined>(undefined);

// --------------------------- ДАННЫЕ ДЛЯ ВЫБОРА РАНЕЕ ЗАГРУЖЕННЫХ ДОКУМЕНТОВ

export const familyMemberIdForSelectDocumentsState = atom<string | undefined>(undefined);

export const familyMemberDocumentsForSelectAtom = atomWithQuery((get) => {
  const id = get(familyMemberIdForSelectDocumentsState);

  return {
    queryKey: [id, "documentsForSelect"],
    queryFn: () => {
      if (!id) return [];
      const api = familyMembersApi();
      return fetchById(() => api.get(id)).then((data) => data.documents);
    },
  };
});

export const familyMemberDocumentsForSelectState = loadable(familyMemberDocumentsForSelectAtom);
