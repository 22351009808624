import React from "react";
import { colors } from "../../styles";

export const Pencil = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.188 6.27246L17.727 9.81146L9.10415 18.4354C8.3309 19.2086 7.37951 19.78 6.33366 20.0993L3.43116 20.9853C3.25488 21.0392 3.06836 20.9399 3.01454 20.7636C2.99515 20.7001 2.99515 20.6322 3.01454 20.5687L3.90054 17.6665C4.21988 16.6205 4.79139 15.6689 5.56478 14.8956L14.188 6.27246ZM19.1391 3.4437L20.5574 4.86197C21.1066 5.4111 21.1409 6.28016 20.6604 6.86932L20.5573 6.98324L18.788 8.75146L15.248 5.21146L17.0177 3.44365C17.6036 2.85796 18.5533 2.85798 19.1391 3.4437Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
