import React, { FC, ReactNode } from "react";
import { Layout } from "./ContentWithRightBlock.styles";

export const ContentWithRightBlock: FC<PropsType> = (props) => {
  const { children, rightContent } = props;

  return (
    <Layout>
      {children}
      {rightContent}
    </Layout>
  );
};

type PropsType = {
  rightContent: ReactNode;
};
