import { hrApi } from "./api";
import { entitiesBase } from "./request";
import { FileLinkType, FilePropsType, IdType } from "./types";

// ------------------------------ ФОТОГРАФИИ

export const photosApi = <PH>() => entitiesBase<PhotoType, PH, undefined>(hrApi, "resort_Photo");

// ------------------------------ ТИПЫ - ФОТОГРАФИИ

export type PhotoType = IdType & {
  name: string;
  description: string;
  file: FilePropsType;
  isMain: boolean;
  preview?: FileLinkType;
};

export type PhotosType = PhotoType[];
