import { AxiosError } from "axios";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { createOrUpdateStatusState, deletionStatusState, lifecycleStatusState } from "../../atoms";
import { Columns } from "../../styles";
import { getErrorMessage, unknownErrorText } from "../../utils/getErrorMessage";
import { UniversalTextButton } from "../Buttons";
import { Loader } from "../Loader";
import { ConfirmModalPropsType, StatusType } from "./ConfirmModal";
import { Description, LoaderContainer, Text } from "./ConfirmModal.styles";

export const Question: FC<ConfirmModalPropsType & QuestionPropsType> = (props) => {
  const { question, success, onClose, setStatus, setError } = props;

  const deletionStatus = useAtomValue(deletionStatusState);
  const createOrUpdateStatus = useAtomValue(createOrUpdateStatusState);
  const lifecycleStatus = useAtomValue(lifecycleStatusState);

  const handleOk = async () => {
    setStatus("process");
    try {
      if (question && "onDelete" in question.coloredButton) {
        await Promise.all([question && (await question.coloredButton.onDelete())]);
        setStatus("successfulDeleted");
      } else if (question && "onClick" in question.coloredButton) {
        await question?.coloredButton?.onClick();
        setStatus("success");
      }
    } catch (err) {
      const error = getErrorMessage(err);
      setError(error === unknownErrorText ? (err as AxiosError).message ?? "" : error);
      setStatus("error");
    }
  };

  return deletionStatus || createOrUpdateStatus || lifecycleStatus ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : question ? (
    <>
      <Text>{question?.text}</Text>
      <Description>{question?.description}</Description>

      <Columns columns={2}>
        <UniversalTextButton
          text={question.outlinedButton?.text ?? "Отменить"}
          type="outlined"
          onClick={async () => {
            question.outlinedButton?.onClick && (await question.outlinedButton.onClick());
            onClose();
          }}
        />

        <UniversalTextButton
          text={"onDelete" in question.coloredButton ? "Удалить" : question.coloredButton.text}
          type={
            "onDelete" in question.coloredButton ? "attention" : success ? "positive" : "secondary"
          }
          onClick={async () => {
            if (success) {
              await handleOk();
            } else if ("onClick" in question.coloredButton) {
              if (question.coloredButton.onClick() instanceof Promise)
                await question.coloredButton.onClick();
              else question.coloredButton.onClick();
              onClose();
            }
          }}
        />
      </Columns>
    </>
  ) : null;
};

type QuestionPropsType = {
  setStatus: (arg: StatusType) => void;
  setError: (arg: string | undefined) => void;
};
