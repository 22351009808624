import { ConditionSearchType } from "../api";

export const getQuerySearchConditions = (
  query: string,
  properties: string[]
): ConditionSearchType => {
  const words = query.trim().split(/\s+/);

  const conditions = words.map((word) => ({
    group: "OR",
    conditions: properties.map((property) => ({
      property: property,
      operator: "contains",
      value: word,
    })),
  }));

  return {
    group: "AND",
    conditions: conditions,
  };
};
