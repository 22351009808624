import { hrApi } from "./api";
import { PricesType } from "./prices";
import { entitiesBase } from "./request";
import { RoomWithAccommodationType } from "./rooms";
import { AccommodationCategoryType, AccommodationTypeType, IdType } from "./types";

// ------------------------------ РАЗМЕЩЕНИЯ

export const accommodationsApi = <R>() =>
  entitiesBase<RoomWithAccommodationType, R, undefined>(hrApi, "resort_Accommodation");

// ------------------------------ ТИПЫ - РАЗМЕЩЕНИЯ

export type AccommodationType = IdType & {
  room?: string | RoomWithAccommodationType;
  type: AccommodationTypeType;
  category: AccommodationCategoryType | "";
  ageSince: number;
  ageUntil: number;
  numberCondition: number | null;
  prices: PricesType;
};

// ----- получаемые данные

export type AccommodationsType = AccommodationType[];
