import styled from "@emotion/styled";
import { borders, colors, flex, grid, margins, size, text } from "../../styles";

const { grayscaleLabel, primaryDefaultDark } = colors;

export const HeaderList = styled.div`
  ${flex({ horizontal: "right" })};
  ${size({ w: "100%" })};
`;

export const HeaderTitle = styled.div`
  ${margins({ m: "0 auto 0 0" })};
`;

export const Title = styled.p`
  ${text({ size: 20, height: 26, weight: 800 })};
`;

export const SubTitle = styled.p`
  ${text({ size: 12, height: 26, weight: 500, color: grayscaleLabel })};
`;

export const SortContainer = styled.div`
  ${margins({ m: "0 0 0 8" })};
`;

export const FormFilter = styled.form<{ shortVersion?: boolean }>`
  ${({ shortVersion }) =>
    grid({
      gap: 4,
      columns: shortVersion ? "250px 1fr 125px" : "repeat(3, 1fr) 250px 125px",
      itemsInCenter: true,
    })};
  ${borders({ radius: 16 })};
  ${({ shortVersion }) => margins({ m: shortVersion ? "-8 24 24" : "16 0 0", p: "4" })};

  background: ${primaryDefaultDark};
`;

export const FilterContainer = styled.div`
  ${margins({ m: "40 0 0" })};

  & > div {
    ${margins({ m: "0 0 40" })};
  }
`;
