import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { modalSearchQueryState, searchQueryState } from "../../atoms";
import { RemoveSearchButton } from "../Buttons";
import { Loupe } from "../Icons";
import { Container, SearchInputElement, SearchInputLabel } from "./SearchInput.styles";

export const SearchInput: FC<PropsType> = (props) => {
  const { inModal = false } = props;

  const [query, setQuery] = useAtom(inModal ? modalSearchQueryState : searchQueryState);
  const [value, setValue] = useState("");
  const [_, setSearchParams] = useSearchParams();

  const deleteValue = () => {
    setQuery("");
    setValue("");
  };

  useEffect(() => {
    return deleteValue;
  }, [setQuery]);

  const debounced = useDebouncedCallback((value) => {
    setSearchParams({ page: String(1) });
    setQuery(value);
  }, 500);

  return (
    <Container inModal={inModal}>
      <SearchInputLabel loupePosition={query === "" ? "left" : "right"}>
        <Loupe />

        {/* {searchQuery ? (
          <MatchesCount>1/{searchResult.length}</MatchesCount>
        ) : null} */}

        {query ? <RemoveSearchButton onClick={deleteValue} /> : null}

        <SearchInputElement
          placeholder="Введите запрос для поиска"
          onChange={({ target: { value } }) => {
            setValue(value);
            debounced(value);
          }}
          value={value}
          autoFocus
        />
      </SearchInputLabel>

      {/* {searchQuery ? (
        <PrevNextButtons>
          <Button>
            <ArrowDown />
          </Button>
          <Button>
            <ArrowUp />
          </Button>
        </PrevNextButtons>
      ) : null} */}
    </Container>
  );
};

type PropsType = {
  inModal?: boolean;
};
