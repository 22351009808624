import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { sanatoriumAspectsApi, SanatoriumAspectType } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const sanatoriumAspectsModalDataState = atom<SanatoriumAspectType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const sanatoriumAspectsState = atomWithQuery(() => ({
  queryKey: "sanatoriumAspectsState",
  queryFn: async () => fetchItems(() => sanatoriumAspectsApi().getAll()),
}));
