import React from "react";
import { colors } from "../../styles";

export const Book = ({ isFilled = false }: PropsType) =>
  isFilled ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C4 4.79086 5.79086 3 8 3H16C18.2091 3 20 4.79086 20 7V17C20 19.2091 18.2091 21 16 21H8C5.79086 21 4 19.2091 4 17V7ZM7 10C7 9.44772 7.44772 9 8 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H8C7.44772 11 7 10.5523 7 10ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H14C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13H8Z"
        fill={colors.grayscalePlaceholder}
      />
    </svg>
  ) : (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H12C13.1046 2 14 2.89543 14 4V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V4C2 2.89543 2.89543 2 4 2ZM0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V14C16 16.2091 14.2091 18 12 18H4C1.79086 18 0 16.2091 0 14V4ZM4 7C4 6.44772 4.44772 6 5 6H11C11.5523 6 12 6.44772 12 7C12 7.5523 11.5523 8 11 8H5C4.44772 8 4 7.5523 4 7ZM4 11C4 10.4477 4.44772 10 5 10H9C9.5523 10 10 10.4477 10 11C10 11.5523 9.5523 12 9 12H5C4.44772 12 4 11.5523 4 11Z"
        fill={colors.grayscalePlaceholder}
      />
    </svg>
  );

type PropsType = {
  isFilled?: boolean;
};
