import { AxiosResponse } from "axios";
import { hrFilesApi } from "./api";
import { MediumOtherType } from "./dictionaries";
import { BigBackendDateType, FileLinkType, FilePropsType, FileType, IdType } from "./types";

// ------------------------------ ФАЙЛЫ

export const fileApi = {
  upload: (props: FormData): Promise<AxiosResponse<FileType>> =>
    hrFilesApi.post("", props, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
      },
    }),
  download: (params: { fileRef: string }): Promise<AxiosResponse<BlobPart>> =>
    hrFilesApi.get("", { params, responseType: "arraybuffer" }),
};

// ------------------------------ ТИПЫ - ФАЙЛЫ

// ----- получаемые данные

export type DocumentsType = DocumentType[];

export type DocumentType = IdType & {
  file: FilePropsType;
  name: string;
  type: MediumOtherType;
  comment: string;
  createdDate: BigBackendDateType;
};

export type LinkedDocumentsType = LinkedDocumentType[];

export type LinkedDocumentType = Omit<DocumentType, "file"> & { file: FileLinkType };

export type DocumentsForFormType = DocumentForFormType[];

export type DocumentForFormType = Omit<DocumentType, "type"> & {
  type?: string;
  description?: string;
};
