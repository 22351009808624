import { documentIds } from "./documentIds";
import { statusIds } from "./statusIds";

export const RussianFederationId = "aba4912b-5ca6-4675-a146-c5f7118edaa5";

export const medicalDocumentIds = [
  documentIds.extract_from_medical_record_027y_04,
  documentIds.certificate_f_070_y_04,
];

export const nonEditableIds = [
  RussianFederationId,
  ...Object.values(documentIds),
  ...Object.values(statusIds),
];
