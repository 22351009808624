import React from "react";
import { colors } from "../../styles";

export const Export = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 13H10V6.99999H13.0343C13.2552 6.99999 13.4343 6.82091 13.4343 6.6C13.4343 6.49391 13.3922 6.39217 13.3172 6.31715L7.28284 0.282837C7.12663 0.126628 6.87337 0.126628 6.71716 0.282837L0.682843 6.31715C0.526633 6.47336 0.526633 6.72663 0.682843 6.88284C0.757857 6.95785 0.859599 6.99999 0.965685 6.99999H4V13ZM1 15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H1C0.447715 17 0 16.5523 0 16C0 15.4477 0.447715 15 1 15Z"
      fill={colors.primaryDefault}
    />
  </svg>
);
