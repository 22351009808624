import styled from "@emotion/styled";
import React, { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { colors, size, text } from "../../../styles";
import { useRhfField } from "../../../utils/useRHF";
import { CloseButton } from "../../Buttons";
import * as elements from "./TextInput.styles";

const TextInput: FC<PropsType> = (props) => {
  const { type = "text", name, title, label, placeholder = "", multiline = false } = props;
  const { prepend, autoFocus = false, disabled = false, internal = false } = props;
  let { postfix } = props;

  const { Wrapper, ResetButton, TextInputContainer, Input, Label, Title, MultilineInput, Prepend } =
    elements;

  const fieldMethods = useRhfField(name);
  const { error, register } = fieldMethods;
  const context = useFormContext();
  const setValue = (data: string) => context.setValue(name, data);

  let value = useWatch({ name }) as string;
  value = (type === "number" && value === null) || !value ? "" : value;

  postfix = type === "number" && value === null ? "" : postfix;

  const [heightPrepend, setHeightPrepend] = useState(0);

  const prependContainerRef = useRef<HTMLDivElement | null>(null);

  const hasLabel = !!label;
  const hasError = !!error;

  const containerProps = { value, hasLabel, hasError, internal, disabled };
  const generalProps = { value, placeholder, heightPrepend, autoComplete: "off", autoFocus };
  const inputProps = { ...register, ...containerProps, ...generalProps };

  const handleKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (type === "number" && ["-", "+", "e"].includes(evt.key)) evt.preventDefault();
  };

  useEffect(() => {
    setHeightPrepend(prependContainerRef?.current?.clientHeight ?? 0);
  }, [prepend, prependContainerRef]);

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}

      {multiline && !!value && (
        <ResetButton>
          <CloseButton onClick={() => setValue("")} type="cross" inverted />
        </ResetButton>
      )}

      <TextInputContainer
        {...containerProps}
        postfix={postfix}
        prependContainerRef={prependContainerRef}
        multiline={multiline}
      >
        {label && <Label multiline={multiline}>{label}</Label>}

        {prepend ? (
          <Prepend ref={prependContainerRef} multiline={multiline}>
            {prepend}
          </Prepend>
        ) : null}

        {multiline ? (
          <MultilineInput {...inputProps} />
        ) : (
          <Input type={type} {...inputProps} onKeyPress={handleKeyPress} />
        )}

        {multiline && value?.length <= 1000 && (
          <Description>
            {value?.length}/1000
            {value?.length === 1000 ? " – Достигнуто допустимое количество символов" : ""}
          </Description>
        )}

        {hasError && (
          <Error>
            {multiline && error === "Слишком длинное значение"
              ? `${value?.length}/1000 – Достигнуто допустимое количество символов`
              : error}
          </Error>
        )}
      </TextInputContainer>
    </Wrapper>
  );
};

export default TextInput;

type PropsType = {
  type?: "text" | "number";
  name: string;
  label?: string;
  title?: string;
  placeholder?: string;
  multiline?: boolean;
  prepend?: JSX.Element;
  postfix?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  internal?: boolean;
};

const Error = styled.p<{ bottom?: number }>`
  ${size({ w: "95%" })};
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  left: 16px;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-12px")};
  white-space: nowrap;
  z-index: 1;
`;

const Description = styled.p<{ bottom?: number }>`
  ${size({ w: "95%" })};
  ${text({ size: 12, height: 12, weight: 400, color: colors.grayscaleLabel })};

  position: absolute;
  left: 16px;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-12px")};
  white-space: nowrap;
  z-index: 1;
`;
