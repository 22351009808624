import React, { FC } from "react";
import { HtmlContent } from "../../uiKit";
import { Description } from "./RoomsSanatoriumCard.styles";

export const DescriptionBlock: FC<PropsType> = (props) => {
  const { maxLines, description } = props;

  return (
    <Description max={maxLines}>
      <HtmlContent html={description} />
    </Description>
  );
};

type PropsType = {
  description: string;
  maxLines: number;
};
