import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { registriesApi, registriesTravelPackageApi, RegistryType } from "../../api";
import { RoleModelType } from "../../constants/rights";
import { statusIds as statusIdList } from "../../constants/statusIds";
import { fetchById } from "../../utils/fetchData";
import { getQueryKey } from "../../utils/useQueryKeys";
import { startAndEndMethods, today } from "../../utils/workingWithDates";
import { currentUserAtom } from "../index";

export const registriesModalDataState = atom<RegistryType | undefined>(undefined);

// ------------------------------ ФИЛЬТРЫ

export const registriesFiltersState = atom<FiltersType>({ status: [] });

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateIntervalForRegisters = atom<DateRange<Date>>([
  startAndEndMethods.start.year(today),
  startAndEndMethods.end.year(today),
]);

// ------------------------------ ДАННЫЕ ЗАЯВКИ

const registriesAtom = atomWithQuery((get) => {
  const dateInterval = get(currentDateIntervalForRegisters);
  const filters = get(registriesFiltersState);
  const { query } = getQueryKey(get);
  const { roleModel } = get(currentUserAtom);

  return registriesQuery({ dateInterval, filters, query, roleModel });
});

export const registriesState = loadable(registriesAtom);

const registriesQuery = (props: PropsRegistersType) => {
  const { filters, dateInterval, query, roleModel } = props;
  const { sk, operator } = roleModel;

  const statusIds = operator
    ? [statusIdList.approved_registry]
    : sk
    ? [statusIdList.approved_registry, statusIdList.onApproval_registry]
    : filters.status || undefined;
  const organizationCode = filters.organization || undefined;
  const startDate = dateInterval[0] ?? today;

  const args = {
    organizationCode,
    statusIds,
    year: startDate.getFullYear().toString(),
    nameQuery: query,
  };

  return {
    queryKey: [statusIds, organizationCode, startDate.toDateString(), "registriesState"],
    queryFn: () => fetchById(() => registriesApi().getRegistriesWithFilters(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ ПУТЕВКИ

const registriesTravelPackageAtom = atomWithQuery((get) => {
  const dateInterval = get(currentDateIntervalForRegisters);
  const filters = get(registriesFiltersState);
  const { query } = getQueryKey(get);

  return registriesTravelPackageQuery({ dateInterval, filters, query });
});

export const registriesTravelPackageState = loadable(registriesTravelPackageAtom);

const registriesTravelPackageQuery = (props: PropsTravelPackageType) => {
  const { filters, dateInterval, query } = props;

  const organizationCode = filters.organization || undefined;
  const startDate = dateInterval[0] ?? today;

  const args = {
    year: startDate.getFullYear().toString(),
    organizationCode,
    nameQuery: query,
  };

  return {
    queryKey: [organizationCode, startDate.toDateString(), "registriesTravelPackageState"],
    queryFn: () => fetchById(() => registriesTravelPackageApi().getRegistriesWithFilters(args)),
    keepPreviousData: true,
  };
};

type PropsRegistersType = {
  dateInterval: DateRange<Date>;
  filters: FiltersType;
  query?: string;
  roleModel: RoleModelType;
};

type PropsTravelPackageType = {
  dateInterval: DateRange<Date>;
  filters: FiltersType;
  query?: string;
};

type FiltersType = {
  status: string[];
  organization?: string;
};
