import { hrApi } from "./api";
import { entitiesBase } from "./request";
import { RoomWithAccommodationType } from "./rooms";
import { IdType, PeriodType } from "./types";

// ------------------------------ ЦЕНЫ

export const pricesApi = <P>() => entitiesBase<PriceType, P, undefined>(hrApi, "resort_Price");

// ------------------------------ ТИПЫ - ЦЕНЫ

export type PriceType = IdType &
  PeriodType & {
    room: RoomWithAccommodationType | string;
    value: number;
    accommodation: string;
  };

// ----- получаемые данные

export type PricesType = PriceType[];
