import styled from "@emotion/styled";
import { ColorsType } from "../../../api";
import { borders, colors, flex, grid as gridStyles, margins, size, text } from "../../../styles";

const { transparent, grayscaleBody, grayscaleInput, warningLight } = colors;

export const Container = styled.div<{ grid: string }>`
  ${({ grid }) => gridStyles({ columns: grid })};
  ${size({ min: { w: 0 } })};

  overflow: hidden;
  background: ${transparent};

  &:last-child {
    ${borders({ side: "bottom", color: grayscaleInput })};
  }
`;

export const TableBodyItem = styled.div<TableBodyItemPropsType>`
  ${size({ min: { w: 0 } })};
  ${({ color }) => (color ? `background: ${color}` : undefined)};
  ${borders({ side: "right", color: grayscaleInput })};
  ${({ needMerge }) => (!needMerge ? borders({ side: "top", color: grayscaleInput }) : undefined)};
  ${margins({ p: "14 20" })};

  word-wrap: break-word;
  overflow: hidden;

  &.menu {
    ${flex({ horizontal: "center", vertical: "top" })};
    ${size({ h: "100%" })};
    ${margins({ p: "0" })};
  }

  &.check {
    ${flex({ horizontal: "center", vertical: "top" })};
    ${size({ h: "100%" })};
    ${margins({ p: "4 0 0 0" })};

    .MuiCheckbox-root {
      ${size({ s: 40 })};
    }
  }
`;

export const TableBodyItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableBodyItemText = styled.div`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBody })};
`;

export const HighlightedText = styled.span`
  background: ${warningLight};
`;

type TableBodyItemPropsType = {
  color?: ColorsType;
  needMerge?: boolean;
};
