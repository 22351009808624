import { AxiosResponse } from "axios";
import { hrApi, hrApiRest, hrServicesApi } from "./api";
import { StatusType } from "./dictionaries";
import { lifecycleApi, TaskType } from "./lifecycle";
import { entitiesBase } from "./request";
import { BigBackendDateType, IdType } from "./types";

// ------------------------------ РЕЕСТРЫ

export const registriesApi = <R>() => ({
  ...entitiesBase<RegistryType, R, RegistriesRequestType>(hrApi, "resort_Registry"),

  getRegistryWithTask: async (id: string) => {
    const registryRequest = hrApi.get(`/resort_Registry/${id}?fetchPlan=registry-bp-fetch-plan`);
    const registryResponse = await registryRequest;
    const registry = registryResponse.data;

    const tasksResponse = await lifecycleApi.getMyTasks({ process: "REGISTRY" });
    const task = tasksResponse.data.items.find((task) => task.processVariables.registryId === id);

    return { ...registryRequest, data: { registry, task } };
  },

  delete: (id: string) => hrServicesApi.post("/registryService/deleteRegistry", { registryId: id }),

  // downloadRegistry: (data: RegistryDownloadPropsType) =>
  //   hrApiRest.post("/downloadRegistryController/download/registry", data, {
  //     responseType: "arraybuffer",
  //   }),
  getRegistriesWithFilters: (props: GetRegistriesWithFiltersRequestType) =>
    hrServicesApi.post<RegistriesType>("/registryFilterService/getRegistryWithFilters", props),
});

export const registriesTravelPackageApi = <R>() => ({
  ...entitiesBase<RegistryType, R, RegistriesRequestType>(hrApi, "resort_VoucherRegistry"),
  getRegistriesWithFilters: (props: GetRegistriesWithFiltersRequestType) =>
    hrServicesApi.post<RegistriesType>(
      "/registryFilterService/getVoucherRegistryWithFilters",
      props
    ),
  delete: (id: string) =>
    hrServicesApi.post("/registryService/deleteVoucherRegistry", { voucherRegistryId: id }),

  getRegistryWithTask: async (id: string): Promise<AxiosResponse<RegistryWithTaskResponseType>> => {
    const registryRequest = hrApi.get(`/resort_VoucherRegistry/${id}`);
    const registryResponse = await registryRequest;
    const registry = registryResponse.data;

    const tasksResponse = await lifecycleApi.getMyTasks({ process: "REGISTRY" });
    const task = tasksResponse.data.items.find((task) => task.processVariables.registryId === id);

    return { ...registryRequest, data: { registry, task } };
  },

  getDocTravelPackage: async (props: RegistryDocPropsType) =>
    hrApiRest.post(`/downloadRegistryController/download/word/vouchers`, props, {
      responseType: "blob",
    }),
  getExcelTravelPackage: async (props: RegistryDocPropsType) =>
    hrApiRest.post(`/downloadRegistryController/download/excel/vouchers`, props, {
      responseType: "blob",
    }),
});

// ------------------------------ ТИПЫ - РЕГИСТРЫ

export type RegistryType = IdType & {
  name: string;
  status: StatusType;
  historyStatus: HistoryStatusType[];
  createdDate: BigBackendDateType;
};

export type HistoryStatusType = IdType & {
  status: StatusType & { createdDate: BigBackendDateType };
  createdDate?: BigBackendDateType;
  comment: string;
};

// ----- передаваемые значения

export type RegistriesRequestType = {
  query?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
};

export type GetRegistriesWithFiltersRequestType = {
  organizationCode?: string;
  statusIds?: string[];
  year?: string;
  nameQuery?: string;
};

export type RegistryDocPropsType = {
  registryId: string;
  sanatoriumName?: string;
};
// type RegistryDownloadPropsType = Partial<PeriodType> & {
//   sanatoriumName?: string;
//   registryId: string;
// };

// ----- получаемые значения

export type RegistriesResponseType = { items: RegistriesType; totalCount: number };
export type RegistryWithTaskResponseType = { registry: RegistryType; task: TaskType | undefined };

export type RegistriesType = RegistryType[];
