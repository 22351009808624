import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../../styles";

const { grayscaleAsh, grayscaleHoverBackground } = colors;

export const Container = styled.div<{ isExpand: boolean }>`
  ${({ isExpand }) =>
    flex({ gap: 12, horizontal: isExpand ? "left" : "center", vertical: "center" })};
  ${size({ h: 40 })};
  ${borders({ radius: 12 })};
  ${margins({ m: "40 0" })};

  cursor: pointer;

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const Icon = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ w: 40 })};
`;

export const Text = styled.p`
  ${text({ size: 17, height: 22, weight: 600, color: grayscaleAsh })};
`;
