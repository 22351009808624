import React from "react";
import { colors } from "../../styles";

export const NineDots = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447723 0.447754 0 1 0H3C3.55225 0 4 0.447723 4 1V3C4 3.55228 3.55225 4 3 4H1C0.447754 4 0 3.55228 0 3V1ZM5 1C5 0.447723 5.44775 0 6 0H8C8.55225 0 9 0.447723 9 1V3C9 3.55228 8.55225 4 8 4H6C5.44775 4 5 3.55228 5 3V1ZM11 0C10.4478 0 10 0.447723 10 1V3C10 3.55228 10.4478 4 11 4H13C13.5522 4 14 3.55228 14 3V1C14 0.447723 13.5522 0 13 0H11ZM0 6C0 5.44772 0.447754 5 1 5H3C3.55225 5 4 5.44772 4 6V8C4 8.55228 3.55225 9 3 9H1C0.447754 9 0 8.55228 0 8V6ZM6 5C5.44775 5 5 5.44772 5 6V8C5 8.55228 5.44775 9 6 9H8C8.55225 9 9 8.55228 9 8V6C9 5.44772 8.55225 5 8 5H6ZM10 6C10 5.44772 10.4478 5 11 5H13C13.5522 5 14 5.44772 14 6V8C14 8.55228 13.5522 9 13 9H11C10.4478 9 10 8.55228 10 8V6ZM1 10C0.447754 10 0 10.4477 0 11V13C0 13.5523 0.447754 14 1 14H3C3.55225 14 4 13.5523 4 13V11C4 10.4477 3.55225 10 3 10H1ZM5 11C5 10.4477 5.44775 10 6 10H8C8.55225 10 9 10.4477 9 11V13C9 13.5523 8.55225 14 8 14H6C5.44775 14 5 13.5523 5 13V11ZM11 10C10.4478 10 10 10.4477 10 11V13C10 13.5523 10.4478 14 11 14H13C13.5522 14 14 13.5523 14 13V11C14 10.4477 13.5522 10 13 10H11Z"
      fill={colors.grayscaleAsh}
    />
  </svg>
);
