import React, { FC, ReactNode } from "react";
import { Separator } from "../../uiKit";
import { pagesMoreOne } from "../../utils/needPagination";
import { Block, Container, MainBlock } from "./Wrapper.styles";

export const Wrapper: FC<PropsType> = (props) => {
  const { children, header, footer, totalCount } = props;

  return (
    <Container>
      {header ? (
        <>
          <Block>{header}</Block>
          <Separator type="wider" />
        </>
      ) : null}

      <MainBlock isNeededBottomAdditionalMargin={pagesMoreOne(totalCount)}>{children}</MainBlock>

      {footer ? (
        <>
          <Separator type="wider" />
          <Block>{footer}</Block>
        </>
      ) : null}
    </Container>
  );
};

type PropsType = {
  header?: ReactNode;
  footer?: ReactNode;
  totalCount?: number;
};
