import styled from "@emotion/styled";
import { colors, flex, text } from "../../../../../../../styles";

const { grayscaleAsh, grayscaleLabel } = colors;

export const Container = styled.div`
  ${flex({ gap: 15, horizontal: "evenly", vertical: "center" })};
`;

export const TitleBlock = styled.div``;

export const Title = styled.p`
  ${text({ size: 15, height: 20, weight: 700, color: grayscaleAsh })};
`;

export const Description = styled.p`
  ${text({ size: 13, height: 17, weight: 400, color: grayscaleLabel })};
`;
