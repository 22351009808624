import React from "react";
import { colors } from "../../styles";

export const ArrowLeft = () => (
  <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6247 11.7808C6.05596 11.4358 6.12588 10.8065 5.78087 10.3753L2.28062 5.99998L5.78087 1.62467C6.12588 1.19341 6.05596 0.564116 5.62469 0.219107C5.19343 -0.125903 4.56414 -0.0559812 4.21913 0.375281L0.219131 5.37528C-0.0730437 5.7405 -0.0730437 6.25945 0.219131 6.62467L4.21913 11.6247C4.56414 12.0559 5.19343 12.1259 5.6247 11.7808Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
