import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { roomHistoryApi, sanatoriumHistoryApi } from "../../api";
import { roomIdState, sanatoriumIdState } from "../index";

// ------------------------------ ИСТОРИЯ - САНАТОРИИ

const sanatoriumHistoryAtom = atomWithQuery((get) => {
  const sanatoriumId = get(sanatoriumIdState);

  const args = { sanatoriumId };

  return sanatoriumHistoryQuery(args);
});

export const sanatoriumHistoryState = loadable(sanatoriumHistoryAtom);

const sanatoriumHistoryQuery = (props: SanatoriumHistoryPropsType) => {
  const { sanatoriumId } = props;

  return {
    queryKey: [sanatoriumId, "sanatoriumHistoryState"],
    queryFn: async () => (await sanatoriumHistoryApi.getAll(sanatoriumId ?? "")).data,
    enabled: !!sanatoriumId,
  };
};

type SanatoriumHistoryPropsType = {
  sanatoriumId?: string;
};

// ------------------------------ ИСТОРИЯ - КОМНАТЫ

const roomHistoryAtom = atomWithQuery((get) => {
  const roomId = get(roomIdState);

  const args = { roomId };

  return roomHistoryQuery(args);
});

export const roomHistoryState = loadable(roomHistoryAtom);

const roomHistoryQuery = (props: RoomHistoryPropsType) => {
  const { roomId } = props;

  return {
    queryKey: [roomId, "roomHistoryState"],

    queryFn: async () => (await roomHistoryApi.getAll(roomId ?? "")).data,
    enabled: !!roomId,
  };
};

type RoomHistoryPropsType = {
  roomId?: string;
};
