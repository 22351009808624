import React from "react";
import { colors } from "../../styles";

export const MedPlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4C15 3.44769 14.5527 3 14 3H10C9.44727 3 9 3.44769 9 4V9H4C3.44727 9 3 9.44769 3 10V14C3 14.5523 3.44727 15 4 15H9V20C9 20.5523 9.44727 21 10 21H14C14.5527 21 15 20.5523 15 20V15H20C20.5527 15 21 14.5523 21 14V10C21 9.44769 20.5527 9 20 9H15V4Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
