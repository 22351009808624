import styled from "@emotion/styled";
import { StatusColorsType } from "../../api";
import { borders, flex, size, statusColors } from "../../styles";

export const Point = styled.div<{ color: StatusColorsType }>`
  ${flex({ notResize: true })};
  ${size({ s: 14 })};
  ${({ color }) =>
    borders({ width: 3, color: statusColors[color ?? "GRAY"].border, totalRadius: true })};

  background: ${({ color }) => statusColors[color ?? "GRAY"].background};
`;
