import styled from "@emotion/styled";
import { borders, colors, flex, margins, size } from "../../../styles";

const { grayscaleHoverBackground } = colors;

export const Container = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
  ${borders({ radius: 12 })};
  ${margins({ m: "8 0 6 8" })};

  cursor: pointer;

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;
