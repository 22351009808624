import { SelectChangeEvent } from "@mui/material";
import { useAtom } from "jotai";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { modalPaginationState, paginationState } from "../../atoms";
import { OpenListButton } from "../Buttons";

export const usePagination = (totalCount: number, inModal: boolean) => {
  const [pagination, setPagination] = useAtom(paginationState);
  const [modalPagination, setModalPagination] = useAtom(modalPaginationState);

  const { quantity: quantityPagination, page: pagePagination } = pagination;
  const { quantity: quantityModal, page: pageModal } = modalPagination;
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page") ?? "1");

  const value =
    (inModal ? quantityModal : quantityPagination) > 100 ||
    Number(inModal ? quantityModal : quantityPagination) === Number(totalCount)
      ? "все"
      : inModal
      ? quantityModal
      : quantityPagination;

  const handleChange = (_: ChangeEvent<unknown>, value: number) =>
    setSearchParams({ page: String(value) });

  const handleChangeQuantity = (evt: SelectChangeEvent<unknown>) => {
    const number = evt.target.value === "все" ? totalCount : Number(evt.target.value);

    !inModal
      ? setPagination({ quantity: number, page: 0 })
      : setModalPagination({ quantity: number, page: 0 });
  };

  const count = Math.ceil(Number(totalCount) / (inModal ? quantityModal : quantityPagination));

  const hasMoreOnePage = !!totalCount && count !== 1;

  // ------------------------------ < ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ

  const [isOpenList, setIsOpenList] = useState(false);

  const handleOpenList = () => setIsOpenList((prevState) => !prevState);

  const openListIcon = () => <OpenListButton isOpen={isOpenList} />;

  // ------------------------------ ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ >

  // ------------------------------ < ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА

  const [isWidthLessThanMinimum, setIsWidthLessThanMinimum] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const widthMeter = () => setIsWidthLessThanMinimum(window.innerWidth <= 1024);
    window.addEventListener("resize", widthMeter);

    return () => window.removeEventListener("resize", widthMeter);
  }, []);

  // ------------------------------ ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА >

  // ------------------------------ < УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ

  useEffect(() => {
    if (!inModal && page - 1 !== pagePagination)
      setPagination({ quantity: quantityPagination, page: page - 1 });
    else if (inModal && page - 1 !== pageModal)
      setModalPagination({ quantity: quantityModal, page: page - 1 });

    count < page && setSearchParams({ page: String(count) });
  }, [page, count]);

  // ------------------------------ УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ >

  return {
    value,

    page,
    handleChange,
    handleChangeQuantity,

    count,

    hasMoreOnePage,

    isOpenList,
    handleOpenList,
    openListIcon,

    isWidthLessThanMinimum,
  };
};
