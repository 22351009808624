import React, { FC, ReactNode } from "react";
import { SanatoriumType } from "../../../api";
import { Photo } from "../../Photo";
import { BaseCard } from "../BaseCard";
import { DescriptionBlock } from "../DescriptionBlock";
import { RatingBlock } from "../RatingBlock";
import { Content } from "../RoomsSanatoriumCard.styles";
import { ServicesView } from "../ServicesView/ServicesView";
import { Title } from "../Title";

export const SanatoriumDictionaryCard: FC<PropsType> = (props) => {
  const { sanatorium, header, onClick } = props;

  const { photos, name, address, description } = sanatorium;
  const { services, active, overallRatingOverridden, rating } = sanatorium;
  const mainPhoto = photos?.find(({ isMain }) => isMain) ?? photos?.[0];

  const allRating = `${rating?.length ?? 0}`;
  const averageRating = `${overallRatingOverridden ?? "—"}`;

  return (
    <BaseCard active={active} size="ultra">
      <Photo file={mainPhoto?.file} height={360} ultraWidth />
      <Content>
        {header}
        <Title onClick={onClick} title={name} subTitle={address}>
          <RatingBlock allRating={allRating} averageRating={averageRating} />
        </Title>
        <DescriptionBlock description={description} maxLines={services?.length ? 3 : 5} />
        <ServicesView services={services} />
      </Content>
    </BaseCard>
  );
};

type PropsType = {
  sanatorium: SanatoriumType;
  header?: ReactNode;
  onClick: () => void;
};
