import React, { FC } from "react";
import { useNotification } from "../useNotification";
import { Indicator } from "./NewNotificationsIndicator.styles";

export const NewNotificationsIndicator: FC<PropsType> = (props) => {
  const { type } = props;

  const { totalCountOfNew } = useNotification();

  return totalCountOfNew > 0 ? (
    <Indicator forMenu={type === "forMenu"}>{totalCountOfNew}</Indicator>
  ) : null;
};

type PropsType = {
  type: "forIcon" | "forMenu";
};
