import React, { FC } from "react";
import { RoomWithAccommodationType } from "../../api";
import { PopoverHover, usePopoverHover } from "../../uiKit";
import { Container } from "./Humans.styles";
import { useHumans } from "./useHumans";

export const Humans: FC<PropsType> = (props) => {
  const { room } = props;

  const humansMethods = useHumans(room);
  const { hintText, renderHumans } = humansMethods;

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({
    anchorOrigin: { vertical: "top", horizontal: "right" },
    transformOrigin: { vertical: "bottom", horizontal: "right" },
  });

  return (
    <Container onMouseEnter={openPopoverHover} onMouseLeave={closePopoverHover}>
      {renderHumans("adult")}
      {renderHumans("child")}
      {renderHumans("childWithoutPlace")}

      <PopoverHover {...propsPopoverHover}>{hintText}</PopoverHover>
    </Container>
  );
};

type PropsType = {
  room: RoomWithAccommodationType;
};
