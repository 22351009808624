import React from "react";
import { colors } from "../../styles";

export const Radio = ({ checked, disabled }: { checked: boolean; disabled?: boolean }) => (
  <>
    {checked ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M12 1.5C18.0751 1.5 23 6.42487 23 12.5C23 18.5751 18.0751 23.5 12 23.5C5.92487 23.5 1 18.5751 1 12.5C1 6.42487 5.92487 1.5 12 1.5ZM12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5ZM12 5.5C15.866 5.5 19 8.63401 19 12.5C19 16.366 15.866 19.5 12 19.5C8.13401 19.5 5 16.366 5 12.5C5 8.63401 8.13401 5.5 12 5.5Z"
          fill={colors.primaryDefaultDark}
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M12 1.5C18.0751 1.5 23 6.42487 23 12.5C23 18.5751 18.0751 23.5 12 23.5C5.92487 23.5 1 18.5751 1 12.5C1 6.42487 5.92487 1.5 12 1.5ZM12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5Z"
          fill={disabled ? colors.grayscaleDisabledIcons : colors.grayscaleIcons}
        />
      </svg>
    )}
  </>
);
