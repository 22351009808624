import React, { FC } from "react";
import { PopoverHover } from "../../PopoverHover";
import { PositionType } from "../../PopoverHover/PopoverHover";
import { PopoverHoverMethodsType, TypeType } from "./useHint";

export const Hint: FC<PropsType> = (props) => {
  const { type, popoverHoverMethods } = props;

  const { argsForHint, anchorElement, popoverHoverText } = popoverHoverMethods;

  const anchorOriginHorizontal = type !== "subSectionButton" ? 44 : 276;
  const anchorOrigin = { vertical: "center", horizontal: anchorOriginHorizontal } as PositionType;
  const transformOrigin = { vertical: "center", horizontal: "left" } as PositionType;

  return (
    <PopoverHover
      id="hint"
      isOpen={!!anchorElement}
      element={anchorElement}
      onClose={argsForHint.onMouseLeave}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {popoverHoverText}
    </PopoverHover>
  );
};

type PropsType = {
  type: TypeType;
  popoverHoverMethods: PopoverHoverMethodsType;
};
