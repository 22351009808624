import React from "react";
import { colors } from "../../styles";

export const ThumbUp = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.63636C3.34315 9.63636 2 10.9795 2 12.6364V17C2 18.6569 3.34315 20 5 20L5 9.63636ZM6.92617 9H7V20H16.2631C17.6005 20 18.7764 19.1147 19.1462 17.8294L20.8984 11.7385C21.45 9.82085 20.0107 7.90909 18.0153 7.90909H14.9374C14.0393 7.90909 13.3425 7.12523 13.4477 6.23332L13.7402 3.75447C13.9132 2.28844 12.7678 1 11.2916 1C10.7982 1 10.3524 1.29418 10.1583 1.74779L7.30175 8.42319C7.2091 8.6397 7.08109 8.83383 6.92617 9Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
