import styled from "@emotion/styled";
import { StatusColorsType } from "../../../api";
import { borders, colors, flex, lifecycleStatusColors, margins, size, text } from "../../../styles";

const { black, grayscaleAsh, grayscalePlaceholder, grayscaleBeautifulBlack } = colors;

export const Container = styled.div`
  ${margins({ m: "0 24 12" })};
`;

export const TitleAndNav = styled.div`
  ${flex({ horizontal: "evenly" })};
  ${margins({ m: "0 0 12" })};
`;

export const Title = styled.h1`
  ${text({ size: 20, height: 26, weight: 800, color: black })};
  ${flex({ gap: 8 })};
`;

export const Status = styled.span<{ color: StatusColorsType }>`
  ${text({ size: 13, height: 18, weight: 700, color: grayscaleAsh })};

  background: ${({ color }) => lifecycleStatusColors[color]};
  ${margins({ p: "4 8" })};
  ${borders({ radius: 100 })};
`;

export const BreadcrumbsNav = styled.div`
  ${flex()};
  ${text({ size: 15, height: 20, weight: 400 })};
`;

export const BreadcrumbsLink = styled.p`
  color: ${grayscalePlaceholder};
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: ${grayscaleBeautifulBlack};
  }
`;

export const Separator = styled.span`
  color: ${grayscalePlaceholder};
`;

export const SubTitle = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack })};
`;

export const Buttons = styled.div`
  ${flex({ horizontal: "evenly" })};
  ${size({ h: 48 })};
`;

export const LeftButtons = styled.div`
  ${flex({ gap: 16 })};
`;

export const RightButtons = styled.div`
  ${flex({ vertical: "center" })};
`;
