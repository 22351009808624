import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import {
  BooleanType,
  employeesApi,
  EmployeeType,
  EntityRequestBaseType,
  orgEmployeesApi,
} from "../../api";
import { fetchAll } from "../../utils/fetchData";
import { getPaginationQueryKeys } from "../../utils/useQueryKeys";
import { currentUserAtom } from "../index";

export const employeesModalDataState = atom<EmployeeType | undefined>(undefined);

export const needUpdateVipAtom = atomWithImmer<boolean>(false);

// ------------------------------ ФИЛЬТРЫ

export const employeesAbsoluteInitialValues: FiltersType = {
  mainDepartment: "",
  organization: "",
  status: "ACTIVE",
  vip: "false",
};

export const employeesFiltersState = atom<FiltersType>(employeesAbsoluteInitialValues);

type FiltersType = {
  mainDepartment: string;
  organization: string;
  status: string;
  vip: BooleanType;
};

// ------------------------------ РАБОТНИКИ

const employeesAtom = atomWithQuery((get) => {
  const { startIndex, size, query } = getPaginationQueryKeys(get);
  const filters = get(employeesFiltersState);
  const currentUser = get(currentUserAtom);
  const personnelNumbers = get(employeesWithVipAtom);
  const { roleModel, res } = currentUser;

  const { canViewAllEmployees, canViewOrganizationEmployees } = roleModel;
  const { department: userDepartment, organization: userOrganization } = res;

  const canSeeDepartments = canViewAllEmployees || canViewOrganizationEmployees;
  const canSeeOrgs = canViewAllEmployees;
  const mainDepartment = canSeeDepartments ? filters.mainDepartment || undefined : userDepartment;
  const organization = canSeeOrgs ? filters.organization || undefined : userOrganization;

  const status = filters.status || "ACTIVE";
  const vip = filters.vip === "true";

  const args = {
    ...(startIndex || startIndex === 0 ? { from: startIndex } : {}),
    ...(size || size === 0 ? { size } : {}),
    ...(query ? { searchQuery: query } : {}),
    ...(organization ? { organization } : {}),
    ...(mainDepartment ? { mainDepartment } : {}),
    ...(status ? { status } : {}),
    ...(vip ? { personnelNumbers: `${personnelNumbers.length ? personnelNumbers : ["—"]}` } : {}),
  };

  return employeesQuery(args);
});

export const employeesState = loadable(employeesAtom);

const employeesQuery = (args: PropsType) => {
  const { startIndex, size, query, organization, mainDepartment, status } = args;

  return {
    queryKey: [startIndex, size, query, organization, mainDepartment, status, "employeesState"],
    queryFn: () => fetchAll(() => orgEmployeesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ VIP РАБОТНИКИ

export const employeesWithVipAtom = atomWithQuery((get) => {
  const needUpdateVip = get(needUpdateVipAtom);

  return employeesWithVipQuery(needUpdateVip);
});

export const employeesWithVipState = loadable(employeesWithVipAtom);

const employeesWithVipQuery = (upd: boolean) => ({
  queryKey: [upd, "employeesWithVipAtom"],
  queryFn: async () => (await employeesApi().getVipEmployeeIds()).data,
});

// ------------------------------ ТИПЫ

type PropsType = Partial<EntityRequestBaseType> & {
  organization?: string;
  mainDepartment?: string;
  personnelNumbers?: string;
  status?: string;
  vip?: boolean;
};
