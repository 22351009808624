import React, { FC } from "react";
import { ArrowLeft, ArrowRight } from "../../Icons";
import { PopoverHover } from "../../PopoverHover";
import { usePopoverHover } from "../../PopoverHover/usePopoverHover";
import { Container } from "./LeftAndRightArrowsButtons.styles";

export const LeftAndRightArrowsButtons: FC<PropsType> = (props) => {
  const { onClick, orientation, disabled = false } = props;

  const { propsPopoverHover, openPopoverHover, closePopoverHover } = usePopoverHover({});

  return (
    <Container
      onClick={onClick}
      onMouseEnter={disabled ? openPopoverHover : undefined}
      onMouseLeave={disabled ? closePopoverHover : undefined}
      disabledButton={disabled}
    >
      {orientation === "left" ? <ArrowLeft /> : <ArrowRight />}

      <PopoverHover {...propsPopoverHover}>
        {disabled &&
          (orientation === "right"
            ? "На будущий год статистика отсутствует"
            : "На предыдущий год статистика отсутствует")}
      </PopoverHover>
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
  disabled?: boolean;
  orientation: "left" | "right";
};
