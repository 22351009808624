import React, { FC } from "react";
import { StatusColorsType } from "../../api";
import { Point } from "./StatusPoint.styles";

export const StatusPoint: FC<PropsType> = (props) => {
  const { color } = props;

  return <Point color={color} />;
};

type PropsType = {
  color: StatusColorsType;
};
