import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { calculatePriceSettingsApi, CalculationSettingsType } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const calculationSettingsModalDataState = atom<CalculationSettingsType | undefined>(
  undefined
);

// ------------------------------ ДАННЫЕ

export const calculationSettingsState = atomWithQuery(() => ({
  queryKey: ["calculationSettingsState"],
  queryFn: () => fetchItems(() => calculatePriceSettingsApi().getAll()),
}));
