import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../../styles";

const { grayscaleLine, grayscaleBeautifulBlack, grayscaleBody } = colors;
const { redDefault, redBackground, grayscaleInput } = colors;

export const Container = styled.div<{ disabled: boolean }>`
  ${flex({ gap: 16, vertical: "center" })};

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const Input = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ h: 48, w: 176 })};
  ${borders({ color: grayscaleLine, radius: 100 })};
`;

export const Description = styled.div`
  ${flex({ isColumn: true })};
`;

export const DescriptionText = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack })};
`;

export const Comment = styled.p`
  ${text({ size: 12, height: 14, weight: 400, color: grayscaleBody })};
`;

export const Error = styled.span`
  ${text({ size: 12, height: 14, weight: 400, color: redDefault })};
`;

export const Quantity = styled.input<{ hasError: number }>`
  ${flex({ totalCenter: true })};
  ${size({ h: 40, w: 72 })};
  ${borders({ none: true, radius: 12 })};
  ${text({ size: 16, height: 23, weight: 400, align: "center", color: grayscaleBeautifulBlack })};
  ${margins({ p: "8" })};

  background: ${({ hasError }) => (hasError ? redBackground : grayscaleInput)};
  outline: none;

  -moz-appearance: none;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    ${margins({ m: "0" })};

    -webkit-appearance: none;
  }
`;
