import React from "react";
import { RoomWithAccommodationType } from "../../api";
import { arrFromYears } from "../../constants/dates";
import { adultWords, childWords } from "../../constants/options";
import { Human, HumanTypeType } from "../../uiKit";
import { getInclinedWord } from "../../utils/getInclinedWord";
import { isNotNullOrUndefined } from "../../utils/isNotNullOrUndefined";

export const useHumans = (room: RoomWithAccommodationType) => {
  const { accommodations, withoutAccommodation, withoutAccommodationAge } = room;

  // ------------------------------ КОНСТАНТЫ

  const additionalWords = [
    "дополнительном месте",
    "дополнительных местах",
    "дополнительных местах",
  ];

  // ------------------------------ РАСЧЁТ КОЛИЧЕСТВА

  const adultAccommodations = accommodations.filter(({ category }) => category === "ADULT");
  const childAccommodations = accommodations.filter(
    ({ category, withAccommodation }) => category === "CHILD" && withAccommodation
  );
  const childWithoutPlace = withoutAccommodation ?? 0;

  const count = {
    adult: adultAccommodations.length,
    child: childAccommodations.length,
    childWithoutPlace: childWithoutPlace,
  } as Record<HumanTypeType, number>;

  // ------------------------------ РАСЧЁТ ТЕКСТА

  const mainAdults = adultAccommodations.filter(({ roomType }) => roomType === "MAIN");
  const mainAdultsQuantity = mainAdults.length;
  const additionalAdults = adultAccommodations.filter(({ roomType }) => roomType === "ADDITIONAL");
  const additionalAdultsQuantity = additionalAdults.length;
  const mainChildren = childAccommodations.filter(({ roomType }) => roomType === "MAIN");
  const mainChildrenQuantity = mainChildren.length;
  const additionalChildren = childAccommodations.filter(
    ({ roomType }) => roomType === "ADDITIONAL"
  );
  const additionalChildrenQuantity = additionalChildren.length;

  const mainAdultsText = mainAdultsQuantity
    ? `${mainAdultsQuantity} ${getInclinedWord(mainAdultsQuantity, adultWords)}`
    : "";

  const additionalAdultsText = additionalAdultsQuantity
    ? `, ${additionalAdultsQuantity} ${getInclinedWord(
        additionalAdultsQuantity,
        adultWords
      )} на ${getInclinedWord(additionalAdultsQuantity, additionalWords)}`
    : "";

  const mainChildrenText = mainChildrenQuantity
    ? `, ${mainChildrenQuantity} ${getInclinedWord(mainChildrenQuantity, childWords)}`
    : "";

  const additionalChildrenText = additionalChildrenQuantity
    ? `, ${additionalChildrenQuantity} ${getInclinedWord(
        additionalChildrenQuantity,
        childWords
      )} на ${getInclinedWord(additionalChildrenQuantity, additionalWords)}`
    : "";

  const childWithoutPlaceText =
    childWithoutPlace && isNotNullOrUndefined(withoutAccommodationAge)
      ? `, ${childWithoutPlace} ${getInclinedWord(
          childWithoutPlace,
          childWords
        )} до ${withoutAccommodationAge} ${getInclinedWord(
          withoutAccommodationAge ?? 0,
          arrFromYears
        )} без предоставления места`
      : "";

  const hintText = `${mainAdultsText}${additionalAdultsText}${mainChildrenText}${additionalChildrenText}${childWithoutPlaceText}`;

  // ------------------------------ ОТОБРАЖЕНИЕ ИКОНОК

  const renderHumans = (type: HumanTypeType) =>
    Array.from({ length: count[type] }, (_, i) => <Human key={`${type}-${i}`} type={type} />);

  return {
    renderHumans,

    hintText,
  };
};
