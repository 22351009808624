import styled from "@emotion/styled";
import { button, colors, size } from "../../../styles";

const { transparent, white, grayscaleHoverBackground, grayscaleBeautifulBlack } = colors;

export const Container = styled.button<GearButtonPropsType>`
  ${button};
  ${({ needMenu }) => size({ s: needMenu ? 40 : 28 })};

  position: absolute;
  top: ${({ needMenu }) => (needMenu ? "10px" : 0)};
  right: ${({ needMenu }) => (needMenu ? "7px" : 0)};
  z-index: 3;

  div {
    ${button};
  }

  svg {
    ${({ menuModalIsOpen, needMenu }) =>
      size({ s: needMenu ? (menuModalIsOpen ? 20 : 24) : menuModalIsOpen ? 16 : 20 })};
  }

  svg > path {
    fill: ${white};
  }

  :hover {
    background: ${({ needSelectingColumns }) =>
      needSelectingColumns ? grayscaleHoverBackground : transparent};

    svg > path {
      fill: ${({ needSelectingColumns }) =>
        needSelectingColumns ? grayscaleBeautifulBlack : white};
    }
  }
`;

type GearButtonPropsType = {
  menuModalIsOpen: boolean;
  needMenu: boolean;
  needSelectingColumns: boolean;
};
