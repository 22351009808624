import { Badge } from "@digitalatom/ui";
import React, { FC, ReactNode } from "react";
import { RoomWithAccommodationType } from "../../../api";
import { Info } from "../../../uiKit";
import { Humans } from "../../Humans/Humans";
import { Photo } from "../../Photo";
import { BaseCard } from "../BaseCard";
import { Content, Details, Hosted, ReadOnlyInfo } from "../RoomsSanatoriumCard.styles";
import { Title } from "../Title";

export const RoomListCard: FC<PropsType> = (props) => {
  const { room, isReadOnly, priceElement, roomsText, openMoreInfoModal } = props;
  const { name, photos, vip } = room.room;

  const mainPhoto = photos?.find(({ isMain }) => isMain) ?? photos?.[0];

  return (
    <BaseCard isReadOnly={isReadOnly} size="little">
      <Photo file={mainPhoto?.file} height={450} />
      <Content>
        <Title title={name} subTitle={roomsText}>
          <Hosted>
            <Humans room={room} />
          </Hosted>
        </Title>
        {vip && <Badge text="VIP" design="minor" type="accent" />}
        <Details onClick={openMoreInfoModal}>Подробнее о номере</Details>
        {isReadOnly ? (
          <ReadOnlyInfo>
            <Info />
            Для данного номера не за все дни периода установлены цены
          </ReadOnlyInfo>
        ) : (
          priceElement
        )}
      </Content>
    </BaseCard>
  );
};

type PropsType = {
  isReadOnly?: boolean;
  room: RoomWithAccommodationType;
  roomsText: string;
  openMoreInfoModal: () => void;
  priceElement: ReactNode;
};
