import styled from "@emotion/styled";
import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { borders, colors, flex, grid, margins, shadow, size, text } from "../../styles";

const { transparent, grayscaleHoverBackground, grayscalePlaceholder, grayscaleLabel } = colors;
const { grayscaleAsh, grayscaleBeautifulBlack, primaryActiveElement, redBackground } = colors;

export const Wrapper = styled.div``;

export const Container = styled.div<ContainerPropsType>`
  ${({ column, merge }) =>
    grid({
      gap: merge ? 0 : 16,
      columns: column ? undefined : `1fr ${merge ? "17px" : ""} 1fr`,
      rows: column ? `1fr ${merge ? "17px" : ""} 1fr` : undefined,
      itemsInCenter: true,
    })};
  ${size({ w: "100%" })};
  ${borders({ radius: 16 })};

  position: relative;
  background: ${({ error, merge, backgroundcolor }) =>
    error ? redBackground : merge ? backgroundcolor : transparent};
`;

export const ContainerCalendar = styled.div`
  ${margins({ m: "0" })};

  position: relative;
`;

export const Label = styled.p`
  ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};

  position: absolute;
  left: 16px;
  top: 6px;
  z-index: 1;
`;

export const Calendar = styled(DatePicker)``;

export const InputAndIcon = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  * {
    cursor: inherit !important;
  }
`;

export const Input = styled(TextField)<InputPropsType>`
  ${size({ h: 56, w: "100%" })};
  ${borders({ none: true, radius: 16 })};
  ${margins({ p: "0" })};

  background: ${({ haserror, backgroundcolor }) => (haserror ? redBackground : backgroundcolor)};

  input {
    ${margins({ p: "0 0 0 14" })};
  }

  .MuiFormLabel-root {
    ${text({ size: 16, height: 24, weight: 400, color: grayscalePlaceholder })};
    ${({ needlabel }) => margins({ m: needlabel ? "8 0 0" : "0" })};

    opacity: 1;
  }

  .MuiInputLabel-shrink {
    display: none;
  }

  /* .MuiInputBase-input {
    ${margins({ p: "0" })};
  } */

  .MuiInputBase-root {
    ${({ needlabel }) => size({ h: needlabel ? 48 : 56 })};
    ${text({ size: 16, height: 24, weight: 400, color: grayscaleAsh })};
    ${({ needlabel }) => margins({ m: needlabel ? "16 0 0" : "0", p: "0" })};

    .MuiInputBase-input::placeholder {
      ${text({ size: 16, height: 24, weight: 400, color: grayscalePlaceholder })};

      opacity: 1;
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        ${size({ s: 40 })};
        ${({ needlabel }) => margins({ m: needlabel ? "-5 6 0 0" : "0 6 0 0" })};

        z-index: 1;

        svg {
          display: none;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      ${borders({ none: true })};
    }
  }
`;

export const Icon = styled.div<{ needlabel: number }>`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
  ${borders({ totalRadius: true })};

  position: absolute;
  top: ${({ needlabel }) => (needlabel ? "14px" : "9px")};
  right: 6px;

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const PaperProps = {
  sx: {
    borderRadius: 4,
    boxShadow: shadow,
    padding: 1,

    "&:has(div)": { margin: "30px" },

    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root, .MuiPickersDay-root": {
      "&:hover, &:focus, &:active": {
        background: grayscaleHoverBackground,
      },
    },

    ".PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersMonth-root.Mui-selected, .MuiPickersDay-root.Mui-selected ":
      {
        background: primaryActiveElement,
        color: grayscaleLabel,

        "&:hover": {
          background: grayscaleHoverBackground,
          cursor: "default",
        },

        "&:focus, &:active": {
          background: primaryActiveElement,
          color: grayscaleLabel,
        },
      },
  },
};

export const Title = styled.h5`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 0 8 0" })};
`;

type ContainerPropsType = {
  error: boolean;
  column: boolean;
  merge: boolean;
  backgroundcolor: string;
};

type InputPropsType = {
  needlabel: number;
  haserror: number;
  backgroundcolor: string;
};
