import React, { FC } from "react";
import { RightSubtitleContainer } from "./WithRightBlock.styles";

export const RightSubtitle: FC<PropsType> = (props) => {
  const { text, first = false } = props;

  return (
    <RightSubtitleContainer first={first}>
      <div />
      <p>{text}</p>
      <div />
    </RightSubtitleContainer>
  );
};

type PropsType = {
  text: string;
  first?: boolean;
};
