import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import * as api from "../api";
import { currentUserAtom } from "./index";

const { departmentsApi, organizationsApi, positionsApi } = api;

// ------------------------------ ОРГАНИЗАЦИЯ

export const organizationState = atom<string>("");

// ------------------------------ ОСНОВНОЕ ПОДРАЗДЕЛЕНИЕ

const mainDepartmentState = atom<string>("");

// ------------------------------ ПОДРАЗДЕЛЕНИЕ

const departmentState = atom<string>("");

// ------------------------------ СПИСОК ОРГАНИЗАЦИЙ

const organizationsAtom = atomWithQuery(() => organizationsQuery());

export const organizationsState = loadable(organizationsAtom);

const organizationsQuery = () => ({
  queryKey: "organizationsState",
  queryFn: fetchOrganizations,
});

const fetchOrganizations = async () =>
  (await organizationsApi.get()).data.items.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ОСНОВНЫХ ПОДРАЗДЕЛЕНИЙ

const mainDepartmentsAtom = atomWithQuery((get) => {
  const organizationCode = get(organizationState);
  const { roleModel } = get(currentUserAtom);
  const { module } = roleModel;

  return mainDepartmentsQuery({ organizationCode: module ? undefined : organizationCode });
});

export const mainDepartmentsState = loadable(mainDepartmentsAtom);

const mainDepartmentsQuery = ({ organizationCode }: api.DepartmentsFiltersType) => ({
  queryKey: [organizationCode, "mainDepartmentsState"],
  queryFn: () => fetchMainDepartments({ organizationCode, isMainDepartment: true }),
  keepPreviousData: true,
});

const fetchMainDepartments = async (props: api.DepartmentsFiltersType) =>
  (await departmentsApi.get(props)).data.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ПОДРАЗДЕЛЕНИЙ

const departmentsAtom = atomWithQuery((get) => {
  const mainDepartmentCode = get(mainDepartmentState);

  return departmentsQuery({ mainDepartmentCode });
});

export const departmentsState = loadable(departmentsAtom);

const departmentsQuery = ({ mainDepartmentCode }: api.DepartmentsFiltersType) => {
  const args = mainDepartmentCode ? { mainDepartmentCode } : {};

  return {
    queryKey: [mainDepartmentCode, "departmentsState"],
    queryFn: () => fetchDepartments(args),
    keepPreviousData: true,
  };
};

const fetchDepartments = async (props: api.DepartmentsFiltersType) =>
  (await departmentsApi.get(props)).data.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ДОЛЖНОСТЕЙ

const positionsAtom = atomWithQuery((get) => {
  const organizationCode = get(organizationState);
  const departmentCode = get(departmentState);

  return positionsQuery({ organizationCode, departmentCode });
});

export const positionsState = loadable(positionsAtom);

const positionsQuery = ({ organizationCode, departmentCode }: api.PositionsFiltersType) => {
  const args = { organizationCode, departmentCode };

  return {
    queryKey: [organizationCode, departmentCode, "positionsState"],
    queryFn: () => fetchPositions(args),
  };
};

const fetchPositions = async (props: api.PositionsFiltersType) =>
  (await positionsApi.get(props)).data.items.map((item) => ({ ...item, id: item.code }));
