import React from "react";
import { colors } from "../../styles";

export const PinkLogo = () => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4476_117523" maskUnits="userSpaceOnUse" x="0" y="0" width="68" height="68">
      <g clipPath="url(#clip0_4476_117523)">
        <circle cx="26.4452" cy="42.8002" r="8.14837" fill="white" />
        <circle cx="26.4549" cy="25.1996" r="8.14837" fill="white" />
        <circle cx="41.7642" cy="33.9999" r="8.14837" fill="white" />
      </g>
    </mask>
    <g mask="url(#mask0_4476_117523)">
      <g filter="url(#filter0_f_4476_117523)">
        <circle cx="18.3237" cy="17.0782" r="36.2056" fill={colors.yellowForPinkLogo} />
      </g>
      <g filter="url(#filter1_f_4476_117523)">
        <circle cx="54.5025" cy="36.9603" r="36.2056" fill={colors.redForPinkLogo} />
      </g>
      <g filter="url(#filter2_f_4476_117523)">
        <circle cx="17.9978" cy="62.3831" r="36.2056" fill={colors.pinkForPinkLogo} />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_4476_117523"
        x="-50.4754"
        y="-51.7209"
        width="137.598"
        height="137.598"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="16.2967" result="effect1_foregroundBlur_4476_117523" />
      </filter>
      <filter
        id="filter1_f_4476_117523"
        x="-14.2966"
        y="-31.8388"
        width="137.598"
        height="137.598"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="16.2967" result="effect1_foregroundBlur_4476_117523" />
      </filter>
      <filter
        id="filter2_f_4476_117523"
        x="-50.8013"
        y="-6.41599"
        width="137.598"
        height="137.598"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="16.2967" result="effect1_foregroundBlur_4476_117523" />
      </filter>
      <clipPath id="clip0_4476_117523">
        <rect
          width="31.6157"
          height="33.8972"
          fill="white"
          transform="translate(18.2969 17.0513)"
        />
      </clipPath>
    </defs>
  </svg>
);
