import styled from "@emotion/styled";
import { PopperProps as PopperPropsType } from "@mui/material";
import { borders, colors, flex, grid, margins, shadow, size, text } from "../../styles";
import { TypeIntervalType } from "../Header/HeaderNavigation/HeaderNavigation";

const { transparent, white, grayscaleHoverBackground, grayscaleInput, grayscaleLabel } = colors;
const { grayscalePlaceholder, grayscaleBeautifulBlack, primaryActiveElement } = colors;

export const Container = styled.div<{ needPeriodSwitcher?: boolean }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${({ needPeriodSwitcher }) => size({ w: needPeriodSwitcher ? `calc(195px + 300px)` : 195 })};
  ${margins({ m: "0 0 0 16" })};

  path {
    fill: ${grayscaleBeautifulBlack};
  }
`;

export const DateSwitcher = styled.div<{ color?: string }>`
  ${flex({ isColumn: true, totalCenter: true })};
  ${({ color }) => (color ? undefined : size({ h: 40 }))};
  ${borders({ totalRadius: true })};

  :hover {
    background: ${({ color }) => (color ? transparent : grayscaleHoverBackground)};
  }
`;

export const DateSwitcherText = styled.p<DateSwitcherTextPropsType>`
  ${({ typeInterval }) => (typeInterval === "twoDate" ? size({ min: { w: 184 } }) : undefined)};
  ${({ color, disabled }) =>
    text({
      size: color ? 16 : 13,
      height: color ? 23 : 21,
      weight: color ? 400 : 700,
      color: disabled ? grayscalePlaceholder : grayscaleBeautifulBlack,
      align: "center",
    })};
  ${({ color }) => margins({ m: color ? "0" : "0 18" })};

  white-space: nowrap;
`;

export const PaperProps = {
  sx: {
    borderRadius: 4,
    boxShadow: shadow,
    padding: 1,

    "&:has(div)": { margin: "10px" },

    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root, .MuiPickersDay-root": {
      "&:hover, &:focus, &:active": {
        background: grayscaleHoverBackground,
      },
    },

    ".PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersMonth-root.Mui-selected, .MuiPickersDay-root.Mui-selected ":
      {
        background: primaryActiveElement,
        color: grayscaleLabel,

        "&:hover": {
          background: grayscaleHoverBackground,
          cursor: "default",
        },

        "&:focus, &:active": {
          background: primaryActiveElement,
          color: grayscaleLabel,
        },
      },
  },
};

export const PeriodSwitcherContainer = styled.div`
  ${grid({ gap: 4, columns: "repeat(4, 1fr)" })};
  ${size({ h: 32, w: 400 })};
  ${borders({ totalRadius: true })};
  ${margins({ m: "0 16 0 0", p: "4" })};

  background: ${grayscaleInput};
`;

export const PeriodSwitcherButton = styled.div<{ enabled: boolean }>`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};

  background: ${({ enabled }) => (enabled ? white : transparent)};
`;

export const PeriodSwitcherButtonText = styled.p`
  ${text({ size: 13, height: 21, weight: 700, color: grayscaleBeautifulBlack })};
`;

export const QuartersPicker = styled.div`
  ${text({ size: 13, height: 21, weight: 700, color: grayscaleBeautifulBlack })};
`;

export const PopperProps = {
  sx: {
    ".MuiPaper-root": PaperProps.sx,
  },
} as Partial<PopperPropsType>;

export const DateIntervalWrapper = styled.div<{ color: string; disabled?: boolean }>`
  ${flex({ gap: 8, totalCenter: true })};
  ${size({ h: 56 })};
  ${borders({ radius: 16 })};
  ${margins({ p: "16" })};

  position: relative;
  background: ${({ color }) => color};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

type DateSwitcherTextPropsType = {
  color?: string;
  typeInterval?: TypeIntervalType;
  disabled?: boolean;
};
