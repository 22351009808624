import { useAtomValue } from "jotai/utils";
import { notificationsState, totalCountNewNotificationsState } from "../../atoms";
import { useLoadableData } from "../../utils/useLoadableData";

export const useNotification = () => {
  // ------------------------------ АТОМЫ

  const { data: notifications, totalCount } = useLoadableData(notificationsState, []);
  const totalCountNewData = useAtomValue(totalCountNewNotificationsState);

  // ------------------------------ ДАННЫЕ

  const totalCountOfNew = totalCountNewData.state === "hasData" ? totalCountNewData.data : 0;

  return {
    notifications,
    totalCount,
    totalCountOfNew,
  };
};
