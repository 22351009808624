import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { occupationalFactorsApi, OtherWithIconType } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const occupationalFactorsModalDataState = atom<OtherWithIconType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const occupationalFactorsState = atomWithQuery(() => ({
  queryKey: "occupationalFactorsState",
  queryFn: () => fetchItems(() => occupationalFactorsApi().getAll()),
}));
