import React from "react";
import { colors } from "../../styles";

export const PaperClip = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3558 12.1982C14.7077 11.8471 15.2776 11.8478 15.6286 12.1997C15.9797 12.5516 15.979 13.1214 15.6271 13.4725L11.0643 18.0245C8.55361 20.5293 4.48776 20.5245 1.98298 18.0138C1.95572 17.9864 1.95572 17.9864 1.92868 17.9587C-0.651319 15.3095 -0.641666 11.0843 1.95042 8.44696L8.7862 1.49176C10.588 -0.341463 13.5347 -0.366977 15.3679 1.43477C15.407 1.47324 15.4455 1.5124 15.4833 1.55223L15.5148 1.58555C17.3243 3.49435 17.304 6.49121 15.4689 8.37537L8.5768 15.4519C7.51656 16.5405 5.79579 16.626 4.63278 15.648L4.55711 15.5843C3.39786 14.6094 3.24841 12.8793 4.22332 11.7201C4.25904 11.6776 4.29605 11.6362 4.33428 11.596L8.97265 6.71523C9.31506 6.35493 9.88473 6.34042 10.245 6.68284C10.6053 7.02525 10.6198 7.59491 10.2774 7.95521L5.63906 12.836C5.62592 12.8498 5.6132 12.864 5.60092 12.8786C5.26586 13.277 5.31722 13.8717 5.71565 14.2067L5.79132 14.2704C6.23246 14.6413 6.88516 14.6089 7.28732 14.196L14.1795 7.11949C15.3404 5.92747 15.3532 4.03149 14.2085 2.82389L14.1769 2.79057C14.1538 2.76614 14.1302 2.74213 14.1062 2.71854C12.982 1.61362 11.1749 1.62926 10.07 2.75348L3.23418 9.70868C1.32817 11.648 1.32107 14.7549 3.2182 16.7029C3.23766 16.7228 3.23766 16.7228 3.25728 16.7425C5.05994 18.5494 7.98609 18.5529 9.79302 16.7502L14.3558 12.1982Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
