import styled from "@emotion/styled";
import { borders, colors, flex, margins, overflowOneLine, size, text } from "../../styles";

const { white, whiteOpacity, grayscaleAsh } = colors;
const { grayscaleLabelOpacityA0, grayscaleLabelOpacity77 } = colors;

export const TableWrapper = styled.div<TableWrapperPropsType>`
  ${({ needPagination, needScroll, panelHeight }) =>
    size({
      s: "100%",
      max: { h: needPagination || needScroll ? "100%" : `calc(100% - ${panelHeight})` },
    })};

  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 16px 16px 0 0;

  &::-webkit-scrollbar {
    ${size({ h: 16 })};

    background: ${({ needPagination }) => (needPagination ? grayscaleLabelOpacityA0 : white)};
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};

    background: ${({ needPagination }) =>
      needPagination ? whiteOpacity : grayscaleLabelOpacity77};
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;

export const Container = styled.div<ContainerPropsType>`
  ${({ needPagination, needScroll, needRightBlock }) => {
    const baseSize = `calc(1024px - 73px - 16px * 2)`;
    return size({
      w: "100%",
      min: {
        ...(needPagination || needScroll ? { h: "100%" } : {}),
        w: needRightBlock ? `calc(${baseSize} - 300px - 16px)` : baseSize,
      },
    });
  }};
  ${({ needPagination, needScroll }) =>
    margins({ p: needPagination ? "0 0 106 0" : needScroll ? "0 0 140 0" : "0" })};

  background: ${white};
  border-radius: 16px 16px 0 0;
`;

export const TitleList = styled.div<{ isDraggingOver?: boolean }>`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ m: "0 8 16" })};

  overflow-y: hidden;

  div {
    ${flex({ vertical: "center" })};
  }

  p {
    ${size({ min: { w: 200 }, max: { w: 300 } })};
    ${overflowOneLine};
    ${text({ size: 14, height: 18, weight: 600, color: grayscaleAsh })};
    ${margins({ m: "0 0 0 12" })};
  }
`;

type TableWrapperPropsType = {
  needPagination: boolean;
  needScroll: boolean;
  panelHeight?: string;
};

type ContainerPropsType = {
  needPagination: boolean;
  needScroll: boolean;
  needRightBlock: boolean;
};
