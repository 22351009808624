import React, { FC, MouseEvent, useState } from "react";
import { Star } from "../../Icons";
import { PopoverHover } from "../../PopoverHover";
import { Container } from "./StarButton.styles";

export const StarButton: FC<PropsType> = (props) => {
  const { isActive, hintText, onClick } = props;

  // ------------------------------ < ПОДСКАЗКА К ЗВЁЗДОЧКАМ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) => {
    setAnchorPopoverHoverElement(currentTarget);
  };

  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
  };

  const starPopoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = starPopoverHoverIsOpen ? "popover-hover" : undefined;

  // ------------------------------ ПОДСКАЗКА К ЗВЁЗДОЧКАМ >

  return (
    <Container
      type="button"
      onClick={onClick}
      aria-owns={starPopoverHoverIsOpen ? "popover-hover" : undefined}
      aria-haspopup="true"
      onMouseEnter={openPopoverHover}
      onMouseLeave={closePopoverHover}
    >
      <Star active={isActive} />

      <PopoverHover
        id={idPopoverHover}
        isOpen={starPopoverHoverIsOpen}
        element={anchorPopoverHoverElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
      >
        {hintText}
      </PopoverHover>
    </Container>
  );
};

type PropsType = {
  isActive: boolean;
  hintText: string;
  onClick: () => void;
};
