import React from "react";
import { colors } from "../../styles";

export const Logo = () => (
  <svg width="31" height="34" viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.693 8.53079C15.693 12.8617 12.1821 16.3726 7.85113 16.3726C3.52021 16.3726 0.00930451 12.8617 0.00930451 8.53079C0.00930451 4.19987 3.52021 0.688965 7.85113 0.688965C12.1821 0.688965 15.693 4.19987 15.693 8.53079ZM15.6837 25.4691C15.6837 29.8001 12.1727 33.311 7.84183 33.311C3.51091 33.311 0 29.8001 0 25.4691C0 21.1382 3.51091 17.6273 7.84183 17.6273C12.1727 17.6273 15.6837 21.1382 15.6837 25.4691ZM22.5845 24.8418C26.9154 24.8418 30.4263 21.3309 30.4263 17C30.4263 12.669 26.9154 9.15814 22.5845 9.15814C18.2535 9.15814 14.7426 12.669 14.7426 17C14.7426 21.3309 18.2535 24.8418 22.5845 24.8418Z"
      fill="url(#paint0_linear_2716_6394)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2716_6394"
        x1="39.2597"
        y1="37.3025"
        x2="2.71721"
        y2="-2.09084"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor={colors.grayscaleLogo} />
      </linearGradient>
    </defs>
  </svg>
);
