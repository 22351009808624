import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { margins, button, buttonPrimaryDefault } = styles;
const { buttonSecondaryDefault, buttonTwoIcons, size } = styles;

export const Container = styled.button<{ typeButton: "primary" | "secondary" }>`
  ${button};
  ${({ typeButton }) => (typeButton === "primary" ? buttonPrimaryDefault : buttonSecondaryDefault)};
  ${buttonTwoIcons};
  ${size({ w: "100%" })};
  ${margins({ m: "-2 0" })};
`;
