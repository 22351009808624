import React from "react";
import { colors } from "../../styles";

export const Timer = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3724 5.31912C15.7375 4.25199 13.7718 3.8122 11.8381 4.08091C9.90433 4.34961 8.13303 5.30867 6.85105 6.78111C5.95792 7.80693 5.41662 8.81641 5.16898 9.9883L6.36963 8.97976C6.79251 8.62454 7.4233 8.67939 7.77852 9.10227C8.13375 9.52516 8.0789 10.1559 7.65601 10.5112L4.79374 12.9155C4.37787 13.2648 3.7592 13.2183 3.40019 12.8108L0.752583 9.80542C0.387506 9.39101 0.4275 8.75911 0.841911 8.39403C1.25632 8.02895 1.88822 8.06895 2.2533 8.48336L3.21282 9.57255C3.532 8.06281 4.22918 6.74671 5.34265 5.46782C6.94163 3.6313 9.15091 2.43509 11.5628 2.09994C13.9747 1.76479 16.4264 2.31333 18.4655 3.64432C20.5047 4.97531 21.9936 6.99893 22.6575 9.34175C23.3214 11.6846 23.1155 14.1884 22.0779 16.3914C21.0402 18.5943 19.2407 20.3475 17.0116 21.3276C14.7825 22.3076 12.2741 22.4483 9.9493 21.7237C7.62454 20.9991 5.64032 19.4581 4.36279 17.385C4.07304 16.9149 4.21931 16.2988 4.68948 16.0091C5.15966 15.7193 5.7757 15.8656 6.06545 16.3358C7.0897 17.9978 8.68056 19.2333 10.5444 19.8143C12.4083 20.3953 14.4194 20.2824 16.2067 19.4967C17.9939 18.7109 19.4366 17.3053 20.2685 15.5391C21.1005 13.7729 21.2656 11.7654 20.7333 9.88704C20.201 8.00868 19.0072 6.38624 17.3724 5.31912ZM13.0234 6.14132C13.5757 6.14132 14.0234 6.58904 14.0234 7.14132V11.5545L16.7583 14.4717C17.136 14.8747 17.1156 15.5075 16.7127 15.8852C16.3098 16.263 15.677 16.2425 15.2992 15.8396L12.2938 12.6339L12.0234 12.3454V11.9499V7.14132C12.0234 6.58904 12.4711 6.14132 13.0234 6.14132Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
