import styled from "@emotion/styled";
import React, { FC } from "react";
import { size } from "../../../styles";

export const prefixIcon = "data:image/svg+xml;base64,";

export const IconSvg: FC<PropType> = ({ icon }) => (
  <Img alt="picture" src={`${prefixIcon}${icon}`} />
);

type PropType = {
  icon: string;
};

export const Img = styled.img`
  ${size({ min: { w: 24 }, max: { w: 24 } })};
`;
