import styled from "@emotion/styled";
import { borders, colors, grid, margins, size } from "../../styles";

const { white, gray, grayscaleInput, grayscaleLabelOpacity77 } = colors;

export const PageContainer = styled.div`
  ${grid({ rows: "min-content 1fr" })};
  ${size({ h: "100vh" })};

  background: ${white};
`;

export const Main = styled.main<{ needPagination?: boolean; needScroll: boolean }>`
  ${size({ w: "100%" })};
  ${borders({ side: "top", color: grayscaleInput })};
  ${({ needPagination }) => margins({ m: "-1 0 0", p: needPagination ? "16 16 0" : "16" })};

  background: ${gray};
  overflow: ${({ needScroll }) => (needScroll ? "auto" : "hidden")};

  &::-webkit-scrollbar {
    ${size({ h: 16 })};

    background: ${white};
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};

    background: ${grayscaleLabelOpacity77};
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;
