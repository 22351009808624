import React from "react";
import { colors } from "../../styles";

export const Order = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" fill="none">
    <path
      d="M6.96586 4.97998L4.48584 2.5L2.00586 4.97998"
      stroke={colors.grayscaleBeautifulBlack}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.48535 14.5V15.5H5.48535V14.5H3.48535ZM5.48535 2.5C5.48535 1.94772 5.03764 1.5 4.48535 1.5C3.93307 1.5 3.48535 1.94772 3.48535 2.5H5.48535ZM5.48535 14.5V2.5H3.48535V14.5H5.48535Z"
      fill={colors.grayscaleBeautifulBlack}
    />
    <path
      d="M9.03223 12.0195L11.5122 14.4995L13.9922 12.0195"
      stroke={colors.grayscaleBeautifulBlack}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5127 2.5V1.5H10.5127V2.5H12.5127ZM10.5127 14.5C10.5127 15.0523 10.9604 15.5 11.5127 15.5C12.065 15.5 12.5127 15.0523 12.5127 14.5H10.5127ZM10.5127 2.5V14.5H12.5127V2.5H10.5127Z"
      fill={colors.grayscaleBeautifulBlack}
    />
  </svg>
);
