import styled from "@emotion/styled";
import { Link } from "react-scroll";
import { borders, colors, flex, grid, margins, size, text } from "../../../styles";

const { grayscaleAsh, grayscaleLabel } = colors;
const { primaryActiveElement, primaryDefault, greenBackground } = colors;

export const Photos = styled.div<{ canOpenPhotos: boolean }>`
  ${flex({ gap: 8, isColumn: true })};

  & > div:first-of-type {
    cursor: ${({ canOpenPhotos }) => (canOpenPhotos ? `pointer` : "default")};
  }
`;

export const PhotoGallery = styled.div`
  ${grid({ gap: 8, columns: "repeat(6, minmax(102px, 1fr))" })};

  & > * {
    cursor: pointer;
  }
`;

export const QuantityPhoto = styled.div<{ backgroundImage?: string }>`
  ${({ backgroundImage }) => (backgroundImage ? "position: relative;" : undefined)};
  ${flex({ totalCenter: true })};
  ${size({ h: 102 })};
  ${borders({ radius: 16 })};

  background: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : primaryActiveElement};
  background-size: cover;

  p {
    ${text({ size: 13, height: 16, weight: 700, color: primaryDefault, align: "center" })};

    z-index: 99;
  }
`;

export const QuantityPhotoDiffuser = styled.div`
  ${size({ s: "100%" })};
  ${borders({ radius: 16 })};

  position: absolute;
  left: 0;
  bottom: 0;
  background: ${primaryActiveElement};
  opacity: 0.8;
`;

export const MainInfo = styled.div`
  ${flex({ horizontal: "evenly" })};
  ${size({ w: "100%" })};
  ${margins({ m: "0 0 24" })};
`;

export const MainInfoWithoutActivity = styled.div`
  ${flex({ gap: 8 })};
`;

export const Rating = styled(Link)<{ clickable?: number }>`
  ${size({ s: "fit", min: { w: 146 } })};
  ${borders({ radius: 12 })};
  ${margins({ p: "8 16" })};

  background: ${greenBackground};

  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
`;

export const RatingScore = styled.span`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleAsh })};
`;

export const RatingReviews = styled.span`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};
  ${margins({ m: "0 0 0 8" })};
`;

export const Activity = styled.div``;

export const SubBlocks = styled.div`
  ${flex({ gap: 16, isColumn: true })};
`;

export const RatingBlockContainer = styled.div`
  ${flex({ gap: 25, isColumn: true })};
`;
