import styled from "@emotion/styled";
import { colors, flex, grid, margins, size, text } from "../../../styles";

const { grayscaleInput, grayscaleLabel } = colors;

export const Container = styled.div<{ typeMainBlock: "oneBlock" | "blockList" }>`
  ${grid({ gap: 16, columns: "minmax(0, 1fr) 300px" })};
  ${({ typeMainBlock }) => margins({ p: typeMainBlock === "blockList" ? "0" : "16 0 0 0" })};
`;

export const List = styled.div`
  ${flex({ gap: 16, isColumn: true })};
`;

export const RightSubtitleContainer = styled.div<{ first: boolean }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ w: "100%" })};
  ${({ first }) => margins({ m: first ? "-8 0 0 0" : "16 0 0 0" })};

  div {
    ${size({ h: 1, w: 85 })};

    background: ${grayscaleInput};
  }

  p {
    ${text({ size: 10, height: 10, weight: 600, color: grayscaleLabel, uppercase: true })};
  }
`;
