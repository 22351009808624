import { AxiosResponse } from "axios";
import { ApplicationsTypeType } from "../atoms";
import { formatDate } from "../utils/workingWithDates";
import { hrApi, hrServicesApi } from "./api";
import { ContactType } from "./contacts";
import { BigOtherType, StatusType } from "./dictionaries";
import { EmployeeType, orgEmployeesApi, ResortEmployeeType } from "./employees";
import { FamilyMemberType } from "./familyMembers";
import { fetchPlans } from "./fetchPlans";
import { LinkedDocumentsType } from "./file";
import {
  GetTasksPropsType,
  lifecycleApi,
  TaskNameType,
  TaskResultName,
  TasksType,
  TaskType,
} from "./lifecycle";
import { HistoryStatusType, RegistryType } from "./registries";
import { entitiesBase, EntityCreateType, EntityUpdateType } from "./request";
import { RoomType } from "./rooms";
import { RatingType, SanatoriumType } from "./sanatoriums";
import * as types from "./types";
import { BigBackendDateType, IdType } from "./types";

// ------------------------------ МАППЕР НЕОБХОДИМЫХ РЕЗУЛЬТАТОВ

// ------------------------------ МАППЕР НЕОБХОДИМЫХ РЕЗУЛЬТАТОВ

const necessaryResultMapper: ResultMapperType = {
  Employee_decision: "Decision_from_sanatorium_result",
  Osr_decision_on_unconfirmed_voucher: "Decision_from_sanatorium_result",
  Decision_from_sanatorium: "Decision_from_sanatorium_result",
};

type ResultMapperType = {
  [key in TaskNameType]?: TaskResultName;
};

// ------------------------------ ЗАЯВКИ

export const applicationsApi = <A>() => ({
  ...entitiesBase<Exclude<ApplicationType, "members" | "documents">, A, ApplicationRequestType>(
    hrApi,
    "resort_Application"
  ),

  getFiltered: (
    props: FilteredApplicationRequestType,
    isVoucher?: boolean
  ): Promise<AxiosResponse<ApplicationsResponseType>> => {
    const path = `/applicationService/${
      isVoucher ? "getFilteredByVoucherRegistry" : "getFiltered"
    }`;
    return hrServicesApi.post(path, props);
  },
  getAllFiltered: async (
    props: FilteredApplicationRequestType,
    isVoucher?: boolean
  ): Promise<AxiosResponse<ApplicationsResponseType>> => {
    const path = `/applicationService/${
      isVoucher ? "getFilteredByVoucherRegistry" : "getFiltered"
    }`;
    const { size: _, ...propsWithoutSize } = props;

    const totalCount = (await hrServicesApi.post(path, { ...props, isVoucher: undefined })).data
      .totalCount;

    return hrServicesApi.post(path, {
      size: totalCount,
      ...propsWithoutSize,
      isVoucher: undefined,
    });
  },
  get: async (id: string): Promise<AxiosResponse<ApplicationWithTaskType>> => {
    const fetchPlan = fetchPlans["resort_Application"];
    const params = { params: { fetchPlan: fetchPlan.get } };

    const res = (await hrApi.get(
      `/resort_Application/${id}`,
      params
    )) as AxiosResponse<ApplicationType>;

    const { data, ...resWithoutData } = res;

    const process = "APPLICATION";
    const applicationNumber = data.number;

    const getTaskPromise = (
      fetchPromise: (props: GetTasksPropsType) => Promise<{ data: { items: TasksType } }>
    ) => fetchPromise({ process, applicationNumber }).then(({ data }) => data?.items?.[0]);

    let [myGroupTask, myTask, myCompletedTask]: (TaskType | undefined)[] = [
      undefined,
      undefined,
      undefined,
    ];

    myGroupTask = await getTaskPromise(lifecycleApi.getMyGroupTasks);
    if (!myGroupTask) myTask = await getTaskPromise(lifecycleApi.getMyTasks);
    if (!myTask) myCompletedTask = await getTaskPromise(lifecycleApi.getMyCompletedTasks);

    const task = myGroupTask || myTask || myCompletedTask;

    let actualCompletedProcessVariables;

    if (!task) {
      try {
        actualCompletedProcessVariables = (
          await lifecycleApi.getProcessVariablesByKey({
            key: applicationNumber,
          })
        ).data;
      } catch {
        actualCompletedProcessVariables = undefined;
      }
    } else {
      const neededResultOutcomeName = necessaryResultMapper[task.taskName];

      if (
        neededResultOutcomeName &&
        (!task.processVariables.comment || task.processVariables.comment === "-")
      ) {
        const outcome = task?.processVariables[neededResultOutcomeName]?.outcomes.sort((a, b) => {
          const aFormatted = formatDate({ date: a.date, type: "object" });
          const bFormatted = formatDate({ date: b.date, type: "object" });
          return bFormatted.getDate() - aFormatted.getDate();
        })[0];

        task.processVariables.comment = outcome?.comment;

        if (outcome?.authorFullName)
          task.processVariables.commentAuthorFullName = outcome?.authorFullName;

        if (outcome?.authorPosition)
          task.processVariables.commentAuthorPosition = outcome?.authorPosition;
      }
    }

    const type = myGroupTask
      ? "inbox"
      : myTask
      ? "in-progress"
      : myCompletedTask
      ? "processed"
      : undefined;

    const employee = (await orgEmployeesApi().getEmployee(data.employee.personnelNumber)).data;

    return {
      ...resWithoutData,
      data: {
        ...data,
        ...(task ? { task: { type, ...task } } : {}),
        actualCompletedProcessVariables,
        employee: { ...data.employee, ...employee },
      },
    };
  },

  create: (props: EntityCreateType<A>): Promise<AxiosResponse<types.IdType>> => {
    const { data } = props;
    return hrServicesApi.post("/applicationService/create", data);
  },
  update: (props: EntityUpdateType<A>): Promise<AxiosResponse<A>> => {
    const { id, data } = props;
    return hrApi.put(`/resort_Application${id !== undefined ? `/${id}` : ""}`, data);
  },

  getApplicationLog: async (data: { applicationId: string }): Promise<ApplicationLogType> => {
    const response = await hrServicesApi.get<ApplicationLogResponseType>(
      "/roomWithMembersLogService/getLogDtoByApplication",
      {
        params: data,
      }
    );
    const responseLogs = response.data;

    const formatChanges = (change: ApplicationLogResponseType[number]["changes"]) => {
      const pairs = change.split("\n");
      const convertKey = (key: string) =>
        key.replace(/-([a-z])/g, (_, group) => group.toUpperCase());

      const result = pairs.reduce((acc, pair) => {
        if (pair.trim() !== "") {
          const [key, value] = pair.split("=");
          const convertedKey = convertKey(key);
          acc[convertedKey as keyof ChangeObjectType] = value;
        }
        return acc;
      }, {} as ChangeObjectType);

      return result;
    };

    const getRoomWIthMembersId = (
      entityInstanceName: ApplicationLogResponseType[number]["entityInstanceName"]
    ) => {
      const regex =
        /-([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
      const match = entityInstanceName.match(regex);

      if (match && match[1]) return match[1];

      return "";
    };

    const formattedLogs = responseLogs.map((response) => ({
      ...response,
      roomWithMembersId: getRoomWIthMembersId(response.entityInstanceName),
      changes: formatChanges(response.changes),
    }));

    return formattedLogs;
  },

  // downloadApplications: (id: string) =>
  //   hrApiRest.get("/downloadApplicationController/download", { params: { id } }),
});

export const documentsForPassingApi = <A>() =>
  entitiesBase<DocumentsForPassingType, A, undefined>(hrApi, "resort_DocumentsForPassing");

// ------------------------------ ТИПЫ - ЗАЯВКИ

export type MemberRoomType = types.IdType & {
  member: FamilyMemberType;
  type: types.AccommodationTypeType;
};

export type RoomWithMembersType = types.IdType & {
  room: RoomType;
  application: ApplicationType;
  members: MemberRoomType[];
  employee?: EmployeeType | boolean;
  lastModifiedDate?: types.BigBackendDateType;
};

export type ApplicationType = types.IdType &
  types.PeriodType & {
    applicant: ResortEmployeeType;
    employee: EmployeeType;
    room: RoomType & { sanatorium: SanatoriumType };
    // rooms: RoomWithAccommodationType[];
    members: RoomWithMembersType[];
    number: number;
    price: number;
    program: BigOtherType;
    documents: LinkedDocumentsType;
    documentsForPassing?: DocumentsForPassingType;
    frequencyStatus: FrequencyStatus;
    status: StatusType;
    statusHistory: HistoryStatusType[];
    registry: RegistryType;
    rating: RatingType;
    comment: string;
    createdDate: types.BigBackendDateType;
    lastDateCompletedApplication?: types.BigBackendDateType;
    lastModifiedDate: types.BigBackendDateType;
    contacts?: ContactType;
  };

export type DocumentsForPassingType = IdType & {
  authorFullName: string;
  authorRole: string;
  documents: LinkedDocumentsType;
};

export type ApplicationWithTaskType = ApplicationType & {
  task?: TaskType & { type?: ApplicationsTypeType };
  actualCompletedProcessVariables?: TaskType["processVariables"];
};

export type AlternativeRoomsStateType = {
  applicationId: string;
  roomId: string;
  roomWithMembersId: string;
  alternativeRoom: RoomType | null;
  alternativeRoomDiscountPrice: number;
}[];

// ----- передаваемые значения

type ApplicationRequestType = Partial<types.EntityRequestBaseType> & {
  employeeId?: string;
  registryId?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
};

type FilteredApplicationRequestType = types.PaginationType & {
  registry: string;
  filter: Partial<types.PeriodType> & {
    query?: string;
    department?: string;
    containsRehab?: types.BooleanType;
    medicalProgramIds?: { uuid: string }[];
    sanatoriumId?: string;
    corrected?: boolean;
  };
};

// ----- получаемые значения

export type ApplicationsResponseType = { items: ApplicationsType; totalCount: number };

export type ApplicationsType = ApplicationType[];

export type FrequencyStatus = "VIOLATED" | "COMPLIED" | "NOT_CALCULATED";

export type FrequencyStatusForTasks = "violated" | "complied" | "not_calculated";

// ----- ТИПЫ LOG

export type ApplicationLogType = (Omit<ApplicationLoggedChangeType, "changes"> & {
  changes: ChangeObjectType;
  roomWithMembersId: string;
})[];

type ApplicationLogResponseType = ApplicationLoggedChangeType[];
type ApplicationLoggedChangeType = {
  attributes: AttributeType[];
  changes: `room-id=${string}\nroom-oldVl=${string}\nroom-oldVlId=${string}\nroom=${string}\n`;
  createTs: BigBackendDateType;
  entity: "resort_RoomWithMembers";
  entityInstanceName: `${string}.RoomWithMembers-037e1ec9-90a8-c443-05f3-1b347a20baa4 ${string}`;
  eventTs: BigBackendDateType;
  id: string;
  type: "MODIFY"; // todo: какие еще могут быть типы?
  username: string;
};

type ChangeObjectType = {
  room: string;
  roomId: string;
  roomOldVl: string;
  roomOldVlId: string;
};

type AttributeType = IdType & {
  valueId: string;
  messagesPack?: "sanatoriumIsChanged";
  oldValueId: string;
  name: string;
  oldValue: string;
  value: string;
};
