export const statusIds = {
  osr_rejected_alternative: "0548ec12-946b-4bbc-abff-42142010ddf7", // "Администратор ОСР отклонил альтернативный вариант"
  osr_approved_alternative: "1fccaa3f-fbc7-4f28-8b0a-a3eaa17e827d", // "Администратор ОСР согласовал Альтернативный вариант"
  osr_rejected_application: "df802841-cd77-424b-a700-6ad79fbceab5", // "Заявка отклонена Администратором ОСР с комментарием"
  osr_approved_completeness: "5d519ed0-f834-4622-9134-24ae94957abe", // "Комплектность заявки согласована Администратором ОСР"
  osr_required_completeness: "c30f5779-f34b-475b-a976-2868b9e31fff", // "Требуются документы для Администратора ОСР"

  operator_rejected_alternative: "425b1fd8-081a-45b6-a02b-c5a3c9bf46ee", // "Альтернативный вариант отклонен Оператором СКЛ"
  operator_approved_alternative: "2bdb58c1-50ed-4359-8485-06ccc028580e", // "Альтернативный вариант путевки согласован оператором СКЛ. Путевка согласована"
  operator_notApproved_osr_approved_application: "7607a938-172b-4856-8b2c-f6790df48302", // "Заявка не подтверждена Оператором СКЛ, предложенный вариант согласован Администратором ОСР, требуется решение заявителя"
  operator_notApproved_application: "8031a9d6-3578-4145-ad92-36ae713fad3f", // "Заявка не подтверждена Оператором СКЛ, требуется решение заявителя"

  sk_rejected_application: "ee330b8e-32b7-422a-a1cc-00d117ae253e", // "Заявка отклонена комиссией СК"
  sk_corrected_application: "d7f4ac21-4205-46cd-affe-0a1602ba4572", // "Корректировка комиссией СК"
  sk_approved_application: "2d40fcfd-d708-4864-b41b-e70459b57355", // "Согласована комиссией СК"
  sk_considering_application: "ac5f78d5-f3f7-4845-8517-b8c7f6cbcb71", // "Заявка на рассмотрении комиссии СК"

  ok_approved_application: "1caa28a7-f5f3-4219-9d15-5d0d28b7f498", // "Согласована Администратором ОК"
  ok_required_modified: "e89e2507-f281-49da-8a2b-8801295033b3", // "Требуется доработка для Администратора ОК"

  employee_modifiedForOsr_application: "0057f080-297b-4506-bbcd-90f59e2fdfd4", // "Доработана Заявителем для Администратора ОСР"
  employee_modified_application: "e8561463-49bd-42ee-a721-33022b28be6b", // "Доработана Работником"
  employee_rejected_application: "5b37c863-c80b-42a6-9b9b-4c05703332c9", // "Заявка отклонена Работником"
  employee_refused_application: "fae87a99-d977-44a0-a349-c2b26772c4af", // "Работник отказался от заявки"
  employee_selected_alternative: "580c322a-0c8f-43e9-9a16-4b98720d5a5f", // "Работник выбрал альтернативный вариант"

  required_endOfTheRest: "1eab4473-a8ca-4ca4-85d1-ec9187759405", // "Ожидание подтверждения прохождения СКЛ\РОМ"

  approved_travelPackage: "01201ca0-7e93-42c1-8d94-d0690b5c4931", // "Путевка согласована"

  created_application: "3ca7a6f5-2c19-496c-a331-4d3a679693b7", // "Создана"
  closed_application: "2a7df0df-372b-4a19-a34f-56c21acb86dd", // "Завершена"
  deleted_application: "958ab324-4c82-4434-a6ad-8010ffcd301a", // "Удалена"

  created_registry: "179b26ae-9845-4b44-a9f6-3eefb5d7dd1d", // "Создан"
  approved_registry: "a33bca35-da9e-43a3-901f-3bb997ba70ca", // "Реестр заявок согласован"
  rejected_registry: "d01a7fe8-8d66-4c32-ba7a-1dcfca0f23b7", // "Реестр заявок отклонён"
  onApproval_registry: "8e7a3c47-ed85-4bc4-8302-a5ce2752de1f", // "На согласовании"
};

export type StatusIdsType = keyof typeof statusIds;
