import React, { FC, MouseEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CheckForSwitch, Cross } from "../../uiKit";
import { Container, Label, Switch } from "./SwitchLabelInput.styles";

export const SwitchLabelInput: FC<PropsType> = (props) => {
  const { name, label, disabled = false, onMouseEnter, onMouseLeave } = props;

  const value = useWatch({ name }) as boolean;
  const context = useFormContext();
  const setValue = (data: boolean) => context.setValue(name, data);

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        if (!disabled) setValue(!value);
      }}
      disabled={disabled}
    >
      <Label disabled={disabled}>{label}</Label>

      <Switch checked={value}>
        <div>{value ? <CheckForSwitch /> : <Cross size={8} />}</div>
      </Switch>
    </Container>
  );
};

type PropsType = {
  name: string;
  label: string;
  disabled?: boolean;
  onMouseEnter?: (evt: MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
};
