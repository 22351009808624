import React, { ReactNode, useEffect } from "react";
import { unknownErrorText } from "../../utils/getErrorMessage";
import { useUserSettings } from "../../utils/useUserSettings";
import { ConfirmModal } from "../ConfirmModal";
import { ErrorText, InfoText } from "../ErrorBoundary/ErrorBoundary.styles";
import { Form } from "../Form";
import { PagePropsType } from "../Page";
import { Skeleton } from "../Skeleton";
import { Container, TableWrapper } from "./Table.styles";
import { TableBodyRow } from "./TableBodyRow";
import { TableHeadRow } from "./TableHeadRow";
import { TableTitleRow } from "./TableTitleRow";
import { useTable } from "./useTable";

/**
 *
 * ------------------------------------------------------------------------------------------
 * КОМПОНЕНТ ТАБЛИЦЫ
 *
 * -
 *
 * @param props - параметры
 * @param props.methods - методы страницы (обязательный)
 * @param props.methods.data - массив данных для наполнения таблицы (DATUM[]) (не обязательный)
 * @param props.methods.loadableData - загружаемые данные со статусами загрузки (Loadable<DATUM[]>) (не обязательный)
 * @param props.methods.dataIds - массив id данных для наполнения таблицы (string[]) (не обязательный)
 * @param props.methods.tableTitle - заголовок таблицы (string) (не обязательный)
 * @param props.methods.questionText - текст запроса на удаление (string | ((props: { entity?: string; count?: number }) => string)) (не обязательный)
 * @param props.methods.questionTextDescription - описание у запросу на удаление (string | ((props: { entity?: string; count?: number }) => string)) (не обязательный)
 * @param props.methods.successText - текст подтверждения удаления (string) (не обязательный)
 * @param props.methods.queryKey - ключи запроса для обновления данных после удаления ((string | string[] | number | boolean | undefined | null)[]) (обязательный)
 * @param props.methods.api - апи для обновления данных после удаления (ApiType<VOBJ>) (обязательный)
 * @param props.methods.canEdit - значение, указывающее на наличие права редактирования данных (boolean) (не обязательный)
 * @param props.methods.modalData - данные для наполнения формы редактирования (DATUM | undefined) (не обязательный)
 * @param props.methods.setModalData - установщик данных для наполнения формы редактирования ((datum: SetStateAction<DATUM | undefined>) => void) (не обязательный)
 * @param props.methods.onDelete - функция удаления строки ((id: string) => | Promise<AxiosResponse<unknown, unknown>> | void | Promise<OBJ> | OBJ[]) (не обязательный)
 * @param props.methods.titlesInit - массив объектов заголовков столбцов (не обязательный)
 * @param props.methods.titlesInit[i].id - идентификатор столбца (number) (обязательный)
 * @param props.methods.titlesInit[i].title - заголовок (string) (обязательный)
 * @param props.methods.titlesInit[i].name - наименование столбца (string) (обязательный)
 * @param props.methods.titlesInit[i].icon - иконка заголовка (ReactNode) (не обязательный)
 * @param props.methods.titlesInit[i].iconPosition - положение иконки ("left" | "right") (не обязательный)
 * @param props.methods.gridsInit - массив ширин столбцов (не обязательный)
 * @param props.methods.gridsInit[i].titleId - идентификатор столбца, null для случаев первого столбца выбора строк и последнего с меню (number | null) (обязательный)
 * @param props.methods.gridsInit[i].size - ширина столбца во фракциях или пикселях, в случае добавления столбцов для выбора строк и меню, их ширина - 56px (string) (обязательный)
 * @param props.methods.object - функция получения данных для наполнения одной строки таблицы ((datum: DATUM, number?: number) => OBJ) (не обязательный)
 * @param props.methods.additionalMenuButtons - функция, возвращающая значение, содержащее дополнительные кнопки меню ({ name: string; onClick: (datum: DATUM) => void; icon?: JSX.Element }[]) (не обязательный)
 * @param props.needPagination - значение, указывающее на необходимость пагинации на странице (boolean) (не обязательный)
 * @param props.isPageWithWideTable - значение, указывающее на необходимость отображения скелетона, имитирующего таблицу с широкими строками (boolean) (не обязательный)
 * @param props.needScroll - значение, указывающее на необходимость скролла на странице (boolean) (не обязательный)
 * @param props.needRightBlock - значение, указывающее на необходимость правого блока на странице (boolean) (не обязательный)
 * @param props.hideDelete - значение, указывающее на необходимость скрытия кнопки удаления в меню строки (boolean) (не обязательный)
 * @param props.hideEdit - значение, указывающее на необходимость скрытия кнопки редактирования в меню строки (boolean) (не обязательный)
 * @param props.panelHeight - высота панели, расположенной между шапкой страницы и таблицей (string) (не обязательный)
 *
 */

export const Table = <DATUM extends { id?: string }, OBJ extends OBJType, FLDS, VOBJ, FLTRS>(
  props: PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const {
    methods,
    panelHeight = "0",
    isPageWithWideTable = false,
    withoutEditForm = false,
  } = props;
  const { hideEdit = false, hideDelete = false } = props;
  const { needPagination = false, needScroll = false, needRightBlock = false } = props;

  const { data, loadableData, dataIds, canEdit = false, successText } = methods;
  const { object, modalData, tableTitle, gridsInit, titlesInit } = methods;

  const { getUserSettings, setUserSettings } = useUserSettings(methods);

  const tableMethods = useTable<OBJ>();
  const { getTitles, getGrids, setSelectedRows, setSelectedColumns } = tableMethods;

  const titles = getTitles(titlesInit ?? []).map(({ id }) => id);

  useEffect(() => {
    setSelectedColumns(titles);
    getUserSettings();

    return () => setSelectedRows([]);
  }, [titlesInit?.length]);

  const { setConfirmModalContent, confirmModalContent } = tableMethods;

  if (!data || !titlesInit || !gridsInit || !object) return null;

  const dataWrapper = (component: JSX.Element) =>
    loadableData ? (
      loadableData.state === "loading" ? (
        isPageWithWideTable ? (
          <Skeleton forElement="wideTable" grids={getGrids(gridsInit)} />
        ) : (
          <Skeleton forElement="table" grids={getGrids(gridsInit)} canEdit={canEdit} />
        )
      ) : loadableData.state === "hasError" ? (
        <ErrorText>{unknownErrorText}</ErrorText>
      ) : object.length === 0 || data.length === 0 ? (
        <InfoText>В данном разделе ещё нет данных</InfoText>
      ) : (
        component
      )
    ) : data.length === 0 ? (
      <InfoText>В данном разделе ещё нет данных</InfoText>
    ) : (
      component
    );

  if (!dataIds || !Array.isArray(data)) return null;

  return (
    <TableWrapper needPagination={needPagination} needScroll={needScroll} panelHeight={panelHeight}>
      <Container
        needPagination={needPagination}
        needScroll={needScroll}
        needRightBlock={needRightBlock}
      >
        <TableHeadRow
          titlesInit={titlesInit}
          gridsInit={gridsInit}
          ids={dataIds}
          data={data}
          needCheck={gridsInit[0].size === "56px"}
          needMenu={gridsInit[gridsInit.length - 1].size === "56px"}
          setUserSettings={setUserSettings}
        />

        {tableTitle ? <TableTitleRow text={tableTitle} /> : null}

        {dataWrapper(
          <>
            {data.map((datum, i) => (
              <TableBodyRow
                key={datum.id || i}
                id={datum.id ?? String(i)}
                datum={datum}
                number={i + 1}
                methods={methods}
                tableMethods={tableMethods}
                needCheck={gridsInit[0].size === "56px"}
                needMenu={gridsInit[gridsInit.length - 1].size === "56px"}
                hideEdit={hideEdit}
                hideDelete={hideDelete}
                needPagination={needPagination}
              />
            ))}
          </>
        )}
      </Container>

      {confirmModalContent && (
        <ConfirmModal
          question={{
            text: confirmModalContent.questionText,
            description: confirmModalContent.description,
            coloredButton: { onDelete: confirmModalContent.deletion },
          }}
          success={{ text: successText ? successText : "Запись удалена" }}
          isOpen
          onClose={() => setConfirmModalContent(undefined)}
        />
      )}

      {canEdit && modalData && !withoutEditForm ? (
        <Form id={modalData.id} methods={methods} isEdit />
      ) : null}
    </TableWrapper>
  );
};

export type OBJType = Record<string, ReactNode> & EditPropertiesOBJType;

export type EditPropertiesOBJType = {
  notEditable?: boolean;
  notDeletable?: boolean;
  needMerge?: string;
};
