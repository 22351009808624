import styled from "@emotion/styled";
import { Slider as SliderMui } from "@mui/material";
import { borders, colors, margins, text } from "../../styles";

const { black, white, grayscaleLine, grayscaleLabel, primaryDark } = colors;

export const SliderContainer = styled.div``;

export const Title = styled.p`
  ${text({ size: 17, height: 24, weight: 700, color: black })};
  ${margins({ m: "0 0 16" })};
`;

export const Slider = styled(SliderMui)`
  .MuiSlider-track {
    ${borders({ color: grayscaleLine, radius: 100 })};

    background: ${grayscaleLine};
  }

  .MuiSlider-rail {
    ${borders({ radius: 100 })};

    background: ${primaryDark};
  }

  .MuiSlider-thumbColorPrimary {
    background: ${white};
  }
`;

export const SubTitle = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};
  ${margins({ m: "0 0 12" })};
`;
