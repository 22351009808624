import React from "react";
import { colors } from "../../styles";

export const ExclamationMark = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.5C15.2467 19.5 19.5 15.2467 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5ZM8.1403 5.62403C8.0657 5.02718 8.5311 4.5 9.1326 4.5H10.867C11.4685 4.5 11.9339 5.02718 11.8593 5.62403L11.1093 11.624C11.0468 12.1245 10.6214 12.5 10.117 12.5H9.8826C9.3783 12.5 8.9529 12.1245 8.8903 11.624L8.1403 5.62403ZM11.4998 15C11.4998 15.8284 10.8282 16.5 9.9998 16.5C9.1714 16.5 8.4998 15.8284 8.4998 15C8.4998 14.1716 9.1714 13.5 9.9998 13.5C10.8282 13.5 11.4998 14.1716 11.4998 15Z"
      fill={colors.redDefault}
    />
  </svg>
);
