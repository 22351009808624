import styled from "@emotion/styled";
import { button, colors, size } from "../../../styles";

const { transparent, grayscaleHoverBackground } = colors;

export const Container = styled.button<{ disabledButton?: boolean }>`
  ${button};
  ${size({ s: 40 })};

  flex: 0 0 auto;
  opacity: ${({ disabledButton }) => (disabledButton ? "0.2" : "1")};
  cursor: ${({ disabledButton }) => (disabledButton ? "default" : "pointer")};

  :hover {
    background: ${({ disabledButton }) =>
      disabledButton ? transparent : grayscaleHoverBackground};
  }
`;
