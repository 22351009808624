import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, size, text } from "../../../styles";

const { grayscaleInput, grayscalePlaceholder, grayscaleBeautifulBlack } = colors;

export const SanatoriumRatingContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
`;

export const CommonInfoContainer = styled.div`
  ${flex({ gap: 20, vertical: "center" })};
`;

export const TotalRating = styled.span`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ w: 144 })};
  ${text({ size: 16, height: 24, weight: 16, color: grayscaleBeautifulBlack })};
  ${borders({ radius: 12 })};
  ${margins({ p: "2 8" })};

  background: ${grayscaleInput};
  opacity: 0.5;
`;

export const CountOfVotes = styled.span`
  ${flex({ vertical: "center" })};
  ${size({ w: 144 })};
  ${borders({ radius: 12 })};
  ${text({ size: 16, height: 24, weight: 16, color: grayscalePlaceholder })};
  ${margins({ p: "2 8" })};

  background: ${grayscaleInput};
`;

export const IconContainer = styled.span`
  ${flex()};
`;

export const RatingInfoBlock = styled.div`
  ${size({ w: "fit" })};

  display: inline-block;
`;

export const InfoTitle = styled.span`
  ${text({ size: 15, weight: 700, height: 20 })};
`;
