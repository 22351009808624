import { hrApi } from "./api";
import { DocumentType } from "./file";
import { entitiesBase } from "./request";
import { BackendDateType, IdType } from "./types";

// ------------------------------ ДОКУМЕНТАЦИЯ

export const documentationsApi = <D>() =>
  entitiesBase<DocumentationType, D, undefined>(hrApi, "resort_Documentation");

// ------------------------------ ТИПЫ - ДОКУМЕНТАЦИЯ

export type DocumentationType = IdType & {
  file: Omit<DocumentType, "type">;
  comment: string;
  createdDate: BackendDateType;
};

export type DocumentationsType = DocumentationType[];
