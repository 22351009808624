import { FamilyMemberTypeType, StatusColorsType } from "../api";
import { getInclinedWord } from "../utils/getInclinedWord";
import { arrYears } from "./dates";

// ------------------------------ ПОЛ

export const sexOptions = [
  { id: "MALE", name: "Мужской" },
  { id: "FEMALE", name: "Женский" },
];

// ------------------------------ ВИДЫ САНАТОРИЕВ/КОМНАТ

export const containRehabOptions = [
  { id: "true", name: "РОМ" },
  { id: "false", name: "СКЛ" },
];

export const rehabActivitiesTypeOptions = [
  { id: "DIAGNOSTICS", name: "Диагностика" },
  { id: "CONSULTATION", name: "Консультация" },
  { id: "TREATMENT", name: "Лечебная программа" },
] as { id: RehabActivityTypeType; name: string }[];

export type RehabActivityTypeType = "DIAGNOSTICS" | "CONSULTATION" | "TREATMENT";

// ------------------------------ VIP / НЕ VIP

export const vipStatusOptions = [
  { id: "false", name: "Все" },
  { id: "true", name: "VIP" },
];

// ------------------------------ СТАТУСЫ СОТРУДНИКОВ

export const statusOptions = [
  { id: "ACTIVE", name: "Активен" },
  { id: "FIRED", name: "Уволен" },
  { id: "SUSPENDED", name: "Приостановлен" },
  { id: "PENSIONER", name: "Пенсионер" },
  { id: "REMOVED", name: "Удален" },
];

// ------------------------------ ТИПЫ СТАТУСОВ ЗАЯВОК

export const statusTypeOptions = [
  { id: "APPLICATION", name: "Заявка" },
  { id: "REGISTRY", name: "Реестр" },
];

// ------------------------------ ТИПЫ РАЗДЕЛОВ С ЗАЯВКАМИ

export const applicationsTypeOptions = [
  { id: "all", name: "Все заявки" },
  { id: "my", name: "Мои заявки" },
  { id: "inbox", name: "Входящие заявки" },
  { id: "in-progress", name: "Заявки в работе" },
  { id: "processed", name: "Обработанные заявки" },
] as const;

// ------------------------------ СТАТУСЫ СОТРУДНИКОВ

export const statusColorOptions = [
  { id: "RED", name: "Красный" },
  { id: "YELLOW", name: "Жёлтый" },
  { id: "BLUE", name: "Синий" },
  { id: "GREEN", name: "Зелёный" },
  { id: "GRAY", name: "Серый" },
] as { id: StatusColorsType; name: string }[];

// ------------------------------ РОЛИ

export const roleOptions = [
  { id: "system-full-access", name: "Суперпользователь" },
  { id: "resort-operator-role", name: "Оператор СКЛ" },
  { id: "resort-sk-role", name: "Администратор СК" },
  { id: "resort-ok-role", name: "Администратор ОК" },
  { id: "resort-osr-ca-role", name: "Администратор ОСР ЦА" },
  { id: "resort-osr-role", name: "Администратор ОСР" },
  { id: "resort-department-role", name: "Администратор Подразделения" },
  { id: "resort-module-role", name: "Администратор Модуля" },
  { id: "resort-employee-role", name: "Работник" },
];

// ------------------------------ ТИПЫ ЧЛЕНОВ СЕМЬИ

export const adultWords = ["взрослый", "взрослых", "взрослых"];
export const childWords = ["ребёнок", "ребёнка", "детей"];

export const employeeOption: FamilyMemberOptionType = {
  id: "EMPLOYEE" as FamilyMemberTypeType,
  name: "Работник",
  nameForQuestion: "работника",
  deleteLabel: "удалён",
};
export const spouseOption: FamilyMemberOptionType = {
  id: "SPOUSE" as FamilyMemberTypeType,
  name: "Супруг(-а)",
  nameForQuestion: "супруга(-у)",
  deleteLabel: "удалён(-а)",
};
const childOption: FamilyMemberOptionType = {
  id: "CHILD" as FamilyMemberTypeType,
  name: "Ребёнок",
  nameForQuestion: "ребёнка",
  deleteLabel: "удалён",
};
export const otherOption: FamilyMemberOptionType = {
  id: "OTHER" as FamilyMemberTypeType,
  name: "Другое",
  alternativeName: "Другой член семьи",
  nameForQuestion: "члена семьи",
  deleteLabel: "удалён",
};

export const familyMemberOptions = [spouseOption, childOption, otherOption];
export const familyAdultMembersOptions = [employeeOption, spouseOption, otherOption];
export const familyMemberAndEmployeeOptions = [
  employeeOption,
  spouseOption,
  childOption,
  otherOption,
];

type FamilyMemberOptionType = {
  id: FamilyMemberTypeType;
  name: string;
  alternativeName?: string;
  nameForQuestion: string;
  deleteLabel: string;
};

// ------------------------------ ДЕТСКИЕ ГОДЫ

export const ageOptions = Array.from({ length: 19 }, (_, i) => ({
  id: i + 1,
  name: `${i + 1} ${getInclinedWord(i + 1, arrYears)}`,
}));

// ------------------------------ ПЕРИОДИЧНОСТИ

export const frequencyToText = (frequency: number) =>
  frequency === 1 ? "Ежегодно" : `1 раз в ${frequency} ${getInclinedWord(frequency, arrYears)}`;

export const useFrequencyOptions = (length: number) =>
  Array.from({ length }, (_, i) => ({
    id: i + 1,
    name: frequencyToText(i + 1),
  }));

// ------------------------------ ТИПЫ КОНТАКТОВ

export const contactOptions = [
  {
    id: "EMAIL",
    name: "Электронная почта",
    nameForQuestion: "электронную почту",
    nameForLabel: "Приоритетная электронная почта",
    deleteLabel: "удалена",
    placeholder: "ivanov@rosenergoatom.ru",
  },
  {
    id: "WORK_NUMBER",
    name: "Рабочий телефон",
    nameForQuestion: "рабочий телефон",
    nameForLabel: "Приоритетный рабочий телефон",
    deleteLabel: "удалён",
    placeholder: "12345678910",
  },
  {
    id: "PHONE_NUMBER",
    name: "Мобильный телефон",
    nameForQuestion: "мобильный телефон",
    nameForLabel: "Приоритетный мобильный телефон",
    deleteLabel: "удалён",
    placeholder: "+7XXXXXXXXXX",
  },
];

// ------------------------------ ТИПЫ РАЗМЕЩЕНИЙ

export const memberRoomTypeOptions = [
  { id: "MAIN", name: "Основное" },
  { id: "ADDITIONAL", name: "Дополнительное" },
];

export const memberRoomTypeWithInfantOptions = [
  ...memberRoomTypeOptions,
  { id: "INFANT", name: "Без предоставления места" },
];

// ------------------------------ КАТЕГОРИИ ОТДЫХАЮЩИХ

export const vacationersCategoryOptions = [
  { id: "ADULT", name: "Взрослый" },
  { id: "CHILD", name: "Ребёнок" },
];
