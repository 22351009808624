import React from "react";
import { colors } from "../../styles";

export const TriangleRight = () => (
  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0710011 10.1264C0.0773811 10.9895 0.162461 11.204 1.29676 10.5274C1.29676 10.5107 6.68985 6.99384 6.68158 6.9935C8.34111 6.11606 8.34112 5.74598 6.68986 4.83479C6.68986 4.81808 1.29676 1.28586 1.29676 1.28586C0.150421 0.606493 0.0756012 0.951896 0.0708012 1.85354L0.0710011 10.1264Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
