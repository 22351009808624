import { NamePartsType } from "../api";

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ ИНИЦИАЛОВ
 *
 * -
 *
 * @param nameParts - части имени
 * @param nameParts.firstName - имя (string)
 * @param nameParts.middleName - отчество (string) (не обязательный)
 * @param nameParts.lastName - фамилия (string) (не обязательный)
 *
 */

export const getUserInitials = (nameParts: NamePartsType) => {
  const { firstName, middleName, lastName } = nameParts;

  const firstLetter = firstName[0].toUpperCase();
  const secondLetter =
    middleName?.[0].toUpperCase() ?? lastName?.[0].toUpperCase() ?? firstName[1].toLowerCase();

  return `${firstLetter}${secondLetter}`;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ ПОЛНОГО ИМЕНИ (ФИО)
 *
 * -
 *
 * @param props - параметры
 * @param props.nameParts - части имени
 * @param props.nameParts.firstName - имя (string)
 * @param props.nameParts.middleName - отчество (string) (не обязательный)
 * @param props.nameParts.lastName - фамилия (string) (не обязательный)
 * @param props.withoutLastName - указание на то, что фамилию следует исключить (boolean) (не обязательный)
 * @param props.withoutMiddleName - указание на то, что отчество следует исключить (boolean) (не обязательный)
 *
 */

export const getFullName = (props: PropsType) => {
  const { nameParts, withoutLastName = false, withoutMiddleName = false } = props;

  if (!nameParts) return "";

  const { firstName, middleName, lastName } = nameParts;

  const formattedFirstName = firstName ? ` ${firstName}` : "";
  const formattedMiddleName = withoutMiddleName ? "" : middleName ? ` ${middleName}` : "";
  const formattedLastName = withoutLastName ? "" : lastName ?? "";

  return `${formattedLastName}${formattedFirstName}${formattedMiddleName}`.trimStart();
};

type PropsType = {
  nameParts: NamePartsType | null;
  withoutLastName?: boolean;
  withoutMiddleName?: boolean;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ ИМЕНИ ПОЛЬЗОВАТЕЛЯ (ФамилияИмОт)
 * в соответствии с Приказом МИД России от 12.02.2020 № 2113
 *
 * -
 *
 * @param nameParts - части имени
 * @param nameParts.firstName - имя (string)
 * @param nameParts.middleName - отчество (string) (не обязательный)
 * @param nameParts.lastName - фамилия (string) (не обязательный)
 *
 */

export const getUsername = (nameParts: NamePartsType) => {
  const { firstName, middleName, lastName } = nameParts;

  const transliteratedFirstName = (
    Array.from(firstName.toLowerCase()) as (keyof typeof transliterationRules)[]
  )
    .slice(0, 2)
    .map((letter) => transliterationRules[letter])
    .join("");
  const transliteratedMiddleName = middleName
    ? (Array.from(middleName.toLowerCase()) as (keyof typeof transliterationRules)[])
        .slice(0, 2)
        .map((letter) => transliterationRules[letter])
        .join("")
    : "";
  const transliteratedLastName = lastName
    ? (Array.from(lastName.toLowerCase()) as (keyof typeof transliterationRules)[])
        .map((letter) => transliterationRules[letter])
        .join("")
    : "";

  return `${transliteratedLastName.charAt(0).toUpperCase() + transliteratedLastName.slice(1)}${
    transliteratedFirstName.charAt(0).toUpperCase() + transliteratedFirstName.slice(1)
  }${transliteratedMiddleName.charAt(0).toUpperCase() + transliteratedMiddleName.slice(1)}`;
};

const transliterationRules = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "e",
  ж: "zh",
  з: "z",
  и: "i",
  й: "i",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "kh",
  ц: "ts",
  ч: "ch",
  ш: "sh",
  щ: "shch",
  ъ: "ie",
  ы: "y",
  ь: "",
  э: "e",
  ю: "iu",
  я: "ia",
};
