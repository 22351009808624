import React from "react";
import { colors } from "../../styles";

export const Geo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5 9.5C5 12.2296 6.93161 16.2009 10.7948 21.4139L10.7948 21.414C11.2881 22.0795 12.2275 22.2193 12.8931 21.726C13.0119 21.6379 13.1171 21.5328 13.2051 21.414C17.0684 16.2009 19 12.2296 19 9.5C19 5.63 15.87 2.5 12 2.5C8.13 2.5 5 5.63 5 9.5ZM9.5 9.5C9.5 8.12 10.62 7 12 7C13.38 7 14.5 8.12 14.5 9.5C14.5 10.88 13.38 12 12 12C10.62 12 9.5 10.88 9.5 9.5Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
