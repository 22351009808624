import styled from "@emotion/styled";
import { Pagination, Select } from "@mui/material";
import { borders, colors, flex, margins, overflowOneLine, shadow, size, text } from "../../styles";

const { transparent, white, black, grayscaleHoverBackground, grayscaleLabelOpacityA0 } = colors;
const { grayscaleLabel, grayscaleIcons, grayscaleAsh, grayscaleLine } = colors;
const { grayscaleInput, grayscaleBeautifulBlack } = colors;

export const Overlay = styled.div<OverlayProps>`
  ${({ menuIsExpand, inModal }) =>
    size({ w: inModal ? "100%" : menuIsExpand ? `calc(100% - 332px)` : `calc(100% - 104px)` })};

  position: absolute;
  right: ${({ inModal }) => (inModal ? 0 : "16px")};
  bottom: ${({ isWidthLessThanMinimum, inModal }) =>
    inModal ? "0" : isWidthLessThanMinimum ? "16px" : "0"};
  border-bottom-right-radius: ${({ inModal }) => (inModal ? "16px" : "none")};
  border-bottom-left-radius: ${({ inModal }) => (inModal ? "16px" : "none")};
`;

export const Blur = styled.div<{ inModal: boolean }>`
  ${size({ s: "100%" })};

  position: absolute;
  bottom: 0;
  background: linear-gradient(${transparent}, ${grayscaleLabelOpacityA0});
  -webkit-mask: linear-gradient(${transparent}, ${black});
  backdrop-filter: blur(2px);
  border-bottom-right-radius: ${({ inModal }) => (inModal ? "inherit" : "none")};
  border-bottom-left-radius: ${({ inModal }) => (inModal ? "inherit" : "none")};
`;

export const Container = styled.div`
  ${flex({ gap: 32, horizontal: "center" })};
  ${margins({ p: "24 0" })};
`;

export const PaginationItem = styled(Pagination)`
  ${borders({ color: grayscaleInput, radius: 16 })};
  ${margins({ p: "8 16" })};

  background: ${white};
  color: ${grayscaleBeautifulBlack};
  z-index: 1;

  .MuiButtonBase-root:hover {
    background: ${grayscaleInput};
  }

  .MuiButtonBase-root.Mui-selected {
    background: ${grayscaleLine};
  }

  .MuiPaginationItem-previousNext {
    & > svg > path {
      fill: ${grayscaleLabel};
    }

    &.Mui-disabled {
      & > svg > path {
        fill: ${grayscaleIcons};
      }
    }

    :hover {
      & > svg > path {
        fill: ${grayscaleBeautifulBlack};
      }
    }
  }
` as typeof Pagination;

export const QuantitySelection = styled.div`
  ${borders({ color: grayscaleInput, radius: 16 })};
  ${margins({ p: "8 16" })};

  background: ${white};
  color: ${grayscaleBeautifulBlack};
  z-index: 1;
`;

export const Input = styled(Select)`
  ${size({ h: 40, w: 100 })};
  ${borders({ radius: 16 })};
  ${margins({ p: "8 0 8 16" })};

  background: ${transparent};
  cursor: pointer;

  .MuiSelect-select {
    ${margins({ m: "0", p: "0", needImportant: true })};

    z-index: 1;
  }

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
    ${margins({ p: "0" })};
  }
`;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 16, height: 24, weight: 400, color: grayscaleAsh })};
`;

export const MenuProps = {
  sx: {
    height: "100%",

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: "8px",

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": { width: 8 },

      "&::-webkit-scrollbar-thumb": { background: grayscaleLine, borderRadius: "99em" },
    },
    ".MuiPopover-paper": { width: 130 },
  },
  anchorOrigin: { vertical: 0, horizontal: -30 },
  transformOrigin: { vertical: 300, horizontal: 0 },
};

type OverlayProps = {
  isWidthLessThanMinimum: boolean;
  menuIsExpand: boolean;
  inModal: boolean;
};
