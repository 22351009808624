import styled from "@emotion/styled";
import { borders, button, colors, margins, size } from "../../../styles";

const { transparent, grayscaleHoverBackground, grayscaleDisabled } = colors;
const { grayscaleIcons, grayscaleLabel } = colors;

export const Container = styled.button<PropsType>`
  ${button};
  ${size({ s: 40 })};
  ${({ menuModalIsOpen }) => (menuModalIsOpen ? borders({ color: grayscaleIcons }) : undefined)};
  ${({ fromCard }) => margins({ m: fromCard ? "0 auto" : "4 auto 0" })};

  :hover {
    background: ${({ disabled }) => (disabled ? transparent : grayscaleHoverBackground)};
    cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};

    & > svg > path {
      fill: ${({ disabled }) => (disabled ? grayscaleDisabled : grayscaleLabel)};
    }
  }

  & > svg > path {
    fill: ${({ disabled }) => (disabled ? grayscaleDisabled : grayscaleIcons)};
  }
`;

type PropsType = {
  menuModalIsOpen: boolean;
  fromCard: boolean;
  disabled: boolean;
};
