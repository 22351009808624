import styled from "@emotion/styled";
import { borders, button, colors, flex, size, text } from "../../../../styles";

const { grayscaleLabel, grayscaleInput, grayscaleBody, grayscaleBeautifulBlack } = colors;
const { grayscaleIcons, grayscaleHoverBackground } = colors;

export const Container = styled.div`
  ${flex({ gap: 8, isColumn: true })};
`;

export const TitleAndArray = styled.div<{ isNeedSeparatedLine?: boolean }>`
  ${flex({ gap: 12, horizontal: "evenly", vertical: "center" })};
  ${size({ h: 37 })};
  ${({ isNeedSeparatedLine }) =>
    isNeedSeparatedLine ? borders({ side: "bottom", color: grayscaleInput }) : undefined};
`;

export const Title = styled.p<{ selected?: boolean }>`
  ${({ selected }) =>
    text({
      size: selected ? 15 : 10,
      height: selected ? 19 : 10,
      weight: selected ? 400 : 800,
      uppercase: !selected,
      color: selected ? grayscaleBeautifulBlack : grayscaleLabel,
    })};
`;

export const ExpansionButton = styled.button`
  ${button};
  ${size({ s: 24 })};

  path {
    fill: ${grayscaleIcons};
  }

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const Content = styled.div``;

export const Text = styled.span`
  ${text({ size: 16, height: 23, weight: 400, color: grayscaleBody })};
`;
