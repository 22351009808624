import styled from "@emotion/styled";
import { button, buttonOnlyIcon, colors, flex, size } from "../../../styles";

export const CarouselWrapper = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${size({ min: { w: "60vw" }, max: { w: "90vw" } })};
`;

export const Carousel = styled.div`
  ${flex({ gap: 16, vertical: "center" })};
  ${size({ h: "60vh", w: "100%", min: { h: 570 } })};
`;

export const PhotoContainer = styled.div`
  ${size({ s: "100%" })};
`;

export const NavigationButton = styled.button`
  ${button};
  ${buttonOnlyIcon};

  background: ${colors.grayscaleBeautifulBlack};
  opacity: 0.4;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }

  path {
    fill: ${colors.white};
  }
`;

export const Miniatures = styled.div`
  ${flex({ gap: 8 })};
  ${size({ w: "100%" })};

  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
`;
