import { hrServicesApi } from "./api";
import { ContactsForProfileType } from "./contacts";
import { OtherWithIconType } from "./dictionaries";
import { LocationType, orgEmployeesApi, PositionType } from "./employees";
import { DocumentsType } from "./file";
import { BackendDateType, IdType, NameType, RolesType, SexType } from "./types";

// ------------------------------ ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

export const userApi = () => ({
  get: async (): Promise<CurrentUserType> => {
    const currentEmployee = (
      await hrServicesApi.get("/employeeService/getCurrentEmployee", {
        data: { fetchPlan: "full" },
      })
    ).data;

    const id = currentEmployee.personnelNumber;

    const employeeById = (await orgEmployeesApi().getEmployee(id)).data;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { functionalPosition, status, created, ...employeeWithoutExcess } = employeeById;

    return { ...currentEmployee, ...employeeWithoutExcess };
  },

  update: (props: CurrentUserSettingsCreateType) =>
    hrServicesApi.post("/userSettingsService/setUserSettings", props),
});

// ------------------------------ ТИПЫ - ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

// ----- передаваемые значения

export type CurrentUserSettingsCreateType = {
  settings: string;
};

// ----- получаемые значения

export type CurrentUserType = IdType &
  NameType & {
    orgId: string;
    personnelNumber: string;
    organization: string;
    department: string;
    documents: DocumentsType;
    roles: RolesType;
    imageInBase64: string;
    settings: string;
    sex?: SexType;
    birthDate: BackendDateType;
    factor: OtherWithIconType;
    position?: PositionType;
    location?: LocationType;
    address?: string;
    contacts: ContactsForProfileType;
    insuranceCode?: string;
    startPositionDate: BackendDateType;
    employmentDate?: BackendDateType;
  };
