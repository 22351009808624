import { AxiosResponse } from "axios";
import { hrApi } from "./api";
import { OtherWithIconType, WorkingConditionsClassType } from "./dictionaries";
import { fetchPlans } from "./fetchPlans";
import { organizationsApi } from "./organization";
import { entitiesBase, EntitiesResponseType } from "./request";
import { IdType } from "./types";

// ------------------------------ РАСЧЁТ СТОИМОСТИ ПУТЁВКИ

export const calculatePriceSettingsApi = <CS>() => ({
  ...entitiesBase<CalculationSettingsType, CS, undefined>(hrApi, "resort_CalculatePriceSettings"),
  getAll: async (): Promise<AxiosResponse<EntitiesResponseType<CalculationSettingsType>>> => {
    const organizations = (await organizationsApi.get()).data.items;

    const params = {
      params: { returnCount: true, fetchPlan: fetchPlans.resort_CalculatePriceSettings },
    };

    const res = await hrApi.get("/resort_CalculatePriceSettings", params);

    const items = (res.data as CalculationSettingsType[]).map((datum) => ({
      ...datum,
      organizationName: organizations.find(({ code }) => code === datum.organization)?.name,
    }));

    return { ...res, data: { items, totalCount: Number(res.headers["x-total-count"]) } };
  },
});

// ------------------------------ ТИПЫ - РАСЧЁТ СТОИМОСТИ ПУТЁВКИ

// ----- получаемые данные

export type CalculationSettingsType = IdType & {
  organization: string;
  organizationName?: string;
  normativePrice: number;
  spouseDiscount: number;
};

// ------------------------------ КРАТНОСТЬ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

export const frequencySettingsApi = <FS>() =>
  entitiesBase<FrequencySettingsType, FS, undefined>(
    hrApi,
    "resort_VouchersFrequencyCalculationRule"
  );

// ------------------------------ ТИПЫ - КРАТНОСТЬ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

// ----- получаемые данные

export type FrequencySettingsType = IdType & {
  experienceFrom: number;
  experienceUpTo?: number;
  frequency: number;
  normalWorkingConditions: boolean;
  factor: OtherWithIconType | null;
  conditionClass: WorkingConditionsClassType | null;
};
