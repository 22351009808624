import styled from "@emotion/styled";
import { borders, colors, flex, size, SizeType } from "../../styles";

const { transparent, white, grayscaleBackground, primaryDefaultDark } = colors;
const { grayscaleInput } = colors;

export const Container = styled.div<ImagePropsType>`
  ${({ height, width }) => size({ h: height, ...(width ? { w: width } : {}) })};
  ${({ ultraWidth }) => borders({ radius: ultraWidth ? 24 : 16 })};

  position: relative;
  background: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : grayscaleInput};
  background-size: cover;
  overflow: hidden;
`;

export const Image = styled.div<{ backgroundImage?: string; bgSize?: "cover" | "contain" }>`
  ${size({ s: "100%" })};

  background: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : grayscaleBackground};
  background-size: ${({ bgSize }) => bgSize || "cover"};
  background-repeat: no-repeat;
  background-position: center center;
`;

const Active = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${transparent};
`;

export const ActiveWhite = styled(Active)`
  ${borders({ width: 6, color: white, radius: 16 })};

  z-index: 1;
`;

export const ActiveColor = styled(Active)`
  ${borders({ width: 2, color: primaryDefaultDark, radius: 16 })};

  z-index: 2;
`;

export const EmptyImageWrapper = styled.div`
  ${flex({ vertical: "center", horizontal: "center" })};
  height: 100%;
`;

type ImagePropsType = {
  backgroundImage?: string;
  height: SizeType;
  width?: SizeType;
  ultraWidth: boolean;
};
