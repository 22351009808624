import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { atomWithStorage, loadable } from "jotai/utils";
import { AlternativeRoomsStateType, applicationsApi } from "../../api";
import { fetchById } from "../../utils/fetchData";
import { applicationIdState } from "../index";

export const alternativeRoomsState = atomWithStorage<AlternativeRoomsStateType>(
  "alternativeRooms",
  []
);

// ------------------------------ ЗАЯВКА

export const applicationAtom = atomWithQuery((get) => {
  const id = get(applicationIdState);

  return {
    queryKey: [id, "applicationState"],
    queryFn: () => fetchById(() => applicationsApi().get(id ?? "")),
    enabled: !!id,
  };
});

export const applicationState = loadable(applicationAtom);

// ------------------------------ ИЗМЕНЕНИЯ

export const applicationLogsAtom = atomWithQuery((get) => {
  const applicationId = get(applicationIdState);

  return {
    queryKey: [applicationId, "applicationLogsState"],
    queryFn: () => applicationsApi().getApplicationLog({ applicationId }),
    enabled: !!applicationId,
  };
});

export const applicationLogsState = loadable(applicationAtom);

// ------------------------------ ID КОМНАТЫ С ПОСТОЯЛЬЦАМИ ПРИ ВЫБОРЕ АЛЬТЕРНАТИВЫ

export const roomWithMembersChangeState = atom<string | undefined>(undefined);
