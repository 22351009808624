import styled from "@emotion/styled";
import { colors, flex, margins, size, text } from "../../styles";

const { warningDark } = colors;

export const Container = styled.div`
  ${flex({ isColumn: true, horizontal: "center", vertical: "center" })};
  ${size({ s: "100%" })};
`;

export const WarningText = styled.p`
  ${text({ size: 28, height: 30, weight: 500, color: warningDark, uppercase: true })};
`;

export const Description = styled.p`
  ${text({ size: 18, height: 20, weight: 400 })};
  ${margins({ m: "40 0 0 0" })};
`;

export const Mail = styled.p`
  ${text({ size: 18, height: 20, weight: 400 })};
  ${margins({ m: "40 0 0 0" })};
`;
