import React from "react";
import { colors } from "../../styles";

export const Person = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0256 10.7503C12.0171 10.7504 12.0085 10.7505 12 10.7505C10.8954 10.7505 10 9.85506 10 8.75049C10 7.64592 10.8954 6.75049 12 6.75049C11.9999 6.75049 11.9998 6.75049 11.9997 6.75049C10.8952 6.75049 9.99974 7.64592 9.99974 8.75049C9.99974 9.85506 10.8952 10.7505 11.9997 10.7505C12.0084 10.7505 12.017 10.7504 12.0256 10.7503ZM11.9997 12.7505C14.2089 12.7505 15.9997 10.9596 15.9997 8.75049C15.9997 6.54135 14.2089 4.75049 11.9997 4.75049C9.7906 4.75049 7.99974 6.54135 7.99974 8.75049C7.99974 10.9596 9.7906 12.7505 11.9997 12.7505ZM18.8239 16.1987C18.3967 15.3322 16.9403 13.7505 11.9997 13.7505C7.05921 13.7505 5.60276 15.3322 5.17557 16.1987C5.0072 16.5402 5.09589 16.9292 5.31767 17.2387C6.08221 18.3056 8.24392 20.7505 11.9997 20.7505C15.7556 20.7505 17.9173 18.3056 18.6818 17.2387C18.9036 16.9292 18.9923 16.5402 18.8239 16.1987Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
