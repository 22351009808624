import React, { FC } from "react";
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFormTrigger,
  UseFormUnregister,
} from "react-hook-form";
import { DocumentsForFormType } from "../../api";
import { FormSubTitle } from "../../styles";
import { OptionsType } from "../../utils/getOptions";
import { DocumentItem } from "./DocumentItem";
import { FileBlocks } from "./DocumentsInput.styles";

export const DocumentList: FC<ListPropsType> = (props) => {
  const { documents, forEditForm } = props;

  return (
    <>
      {forEditForm ? null : <FormSubTitle>Загруженные документы</FormSubTitle>}

      <FileBlocks>
        {documents.map((document, i) => (
          <DocumentItem key={i} i={i} document={document} {...props} />
        ))}
      </FileBlocks>
    </>
  );
};

export type ListPropsType = {
  name: string;
  documents: DocumentsForFormType | [];
  trigger: UseFormTrigger<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  replaceDocument: UseFieldArrayReplace<FieldValues, string>;
  removeDocument: UseFieldArrayRemove;
  forEditForm: boolean;
  options?: OptionsType;
  optionsLabel?: string;
  descriptionLabel?: string;
};
