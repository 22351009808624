import React, { FC } from "react";
import { StatusColorsType } from "../../../api";
import { Container } from "./ColorBlock.styles";

export const ColorBlock: FC<PropsType> = (props) => {
  const { color, text } = props;

  return <Container color={color}>{text}</Container>;
};

type PropsType = {
  color: StatusColorsType;
  text: string;
};
