import React from "react";
import { colors } from "../../styles";

export const CheckMark = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5C15.2467 19.5 19.5 15.2467 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5ZM12.9636 7.06361C13.3151 6.71214 13.8849 6.71214 14.2364 7.06361C14.5879 7.41508 14.5879 7.98493 14.2364 8.3364L9.6364 12.9364C9.2849 13.2879 8.7151 13.2879 8.3636 12.9364L5.7636 10.3364C5.41213 9.9849 5.41213 9.4151 5.7636 9.0636C6.11508 8.7121 6.68492 8.7121 7.0364 9.0636L9 11.0272L12.9636 7.06361Z"
      fill={colors.greenDefault}
    />
  </svg>
);
