import { useUpdateAtom } from "jotai/utils";
import React, { MouseEvent, useEffect, useState } from "react";
import { visibleSearchState } from "../../../atoms";
import { PopoverHover } from "../../PopoverHover";
import { RouterType } from "./HeaderPagesSwitcher";

export const useHeaderPagesSwitcher = () => {
  // ------------------------------ АТОМЫ

  const setVisibleSearch = useUpdateAtom(visibleSearchState);

  // ------------------------------ ЭФФЕКТЫ

  useEffect(() => setVisibleSearch(false), []);

  // ------------------------------ ДЕЙСТВИЕ ПО ВКЛАДКЕ

  const handleClickButton = (evt: MouseEvent<HTMLButtonElement>, router: RouterType) => {
    if (!router.onClick || !router.typeButton) return;

    router.onClick(router.typeButton);
    setVisibleSearch && setVisibleSearch(false);

    if (router.blocked) evt.preventDefault();
  };

  // ------------------------------ ПОДСКАЗКА К ЗАБЛОКИРОВАННОЙ КНОПКЕ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );
  const [popoverHoverText, setPopoverHoverText] = useState<string | null>(null);

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>, text: string) => {
    setAnchorPopoverHoverElement(currentTarget);
    setPopoverHoverText(text);
  };

  const handleMouseEnter = (evt: MouseEvent<HTMLButtonElement>, router: RouterType) =>
    router.blocked && router.blockedText ? openPopoverHover(evt, router.blockedText) : undefined;

  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
    setPopoverHoverText(null);
  };

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = popoverHoverIsOpen ? "popover-hover" : undefined;

  const hint = popoverHoverText ? (
    <PopoverHover
      id={idPopoverHover}
      isOpen={popoverHoverIsOpen}
      element={anchorPopoverHoverElement}
      onClose={closePopoverHover}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: 6, horizontal: "center" }}
    >
      {popoverHoverText}
    </PopoverHover>
  ) : null;

  return {
    handleClickButton,

    popoverHoverIsOpen,
    handleMouseEnter,
    closePopoverHover,
    hint,
  };
};
