import styled from "@emotion/styled";
import { borders, colors, margins, size } from "../../styles";

const { grayscaleInput, grayscaleOffWhite } = colors;

const Line = styled.div<PropsType>`
  ${({ long }) => size({ w: long ? `calc(100% + 48px)` : "100%" })};
  ${borders({ side: "top", color: grayscaleInput })};
  ${({ long, marginTop, marginBottom }) =>
    margins({
      m: `${typeof marginTop === "number" ? marginTop : 0} 0 ${
        typeof marginBottom === "number" ? marginBottom : 0
      } ${long ? -24 : 0}`,
    })};
`;

export const Narrow = styled(Line)<PropsType>`
  ${size({ h: 1 })};
`;

export const Wider = styled(Line)<PropsType>`
  ${size({ h: 8 })};

  background: ${grayscaleOffWhite};
`;

type PropsType = {
  long: boolean;
  marginTop?: string;
  marginBottom?: string;
};
