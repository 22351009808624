import React from "react";
import { colors } from "../../styles";

export const CrossInCircle = ({ color = colors.grayscaleLabel }: { color?: string }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C26.6274 8 32 13.3726 32 20ZM24.7364 16.5364C25.0879 16.1849 25.0879 15.6151 24.7364 15.2636C24.3849 14.9121 23.8151 14.9121 23.4636 15.2636L20 18.727L16.5364 15.2636C16.1849 14.9121 15.6151 14.9121 15.2636 15.2636L15.1831 15.3558C14.9146 15.7085 14.9414 16.2142 15.2636 16.5364L18.727 20L15.2636 23.4636C14.9121 23.8151 14.9121 24.3849 15.2636 24.7364C15.6151 25.0879 16.1849 25.0879 16.5364 24.7364L20 21.273L23.4636 24.7364C23.8151 25.0879 24.3849 25.0879 24.7364 24.7364L24.817 24.6442C25.0854 24.2915 25.0586 23.7858 24.7364 23.4636L21.273 20L24.7364 16.5364Z"
      fill={color}
    />
  </svg>
);
